import React from 'react';
import { message } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useStore } from 'easy-peasy';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import SettingsV2Component from './settings-v2.component';
import { useApp } from '../../../../common/use-app';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import { useEnableSettingsV2Layout } from '../../../../common/use-app';
import { RootState } from '../../../../../store/models/root.model';
import { canUserSwitchSettingsV2 } from '../temporary-settings-version-switcher/temporary-settings-version-switcher.component';

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

type SettingsV2Props = RouteComponentProps<{ appId: string; organisationId: string }>;

const SettingsV2 = (props: SettingsV2Props) => {
  const {
    match: {
      params: { appId, organisationId },
    },
    history,
  } = props;

  const { t } = useTranslation();

  const appState = useApp({ appId });

  const { app, canSwitchSettingsV2 } = useStore(
    (state: RootState) => {
      const app = (state.organisationApps.data &&
        state.organisationApps.data[organisationId] &&
        state.organisationApps.data[organisationId][appId]
      ) || null;

      return {
        app,
        canSwitchSettingsV2: canUserSwitchSettingsV2(app),
      }
    },
    [appId],
  );

  const enableSettingsV2State = useEnableSettingsV2Layout(app ? app.id : '');
  const onEnableSettingsV2 = React.useCallback((enable: boolean) => {
    enableSettingsV2State.mutate({ id: appId, enable },
      {
        onSuccess: () => {
          history.replace(
            `/organisations/${organisationId}/apps/installations/${appId}/content`,
          );
        },
        onError: () => {
          message.error('Failed switching to settings v2 layout');
        },
      },
    );
  }, [appId, history, organisationId, enableSettingsV2State]);

  return (
    <>
      {appState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {appState.isSuccess && app && (
        <SettingsV2Component
          app={app}
          organisationId={organisationId}
          onEnableSettingsV2={onEnableSettingsV2}
          canSwitchSettingsV2={canSwitchSettingsV2}
        />
      )}

      {appState.isError && <ErrorMessage>{t('errorDuringFetchingApp')}</ErrorMessage>}
    </>
  );
};

export default SettingsV2;
