import React, {
  useMemo,
  useLayoutEffect,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Redirect, Link, RouteComponentProps, Switch } from 'react-router-dom';
import { matchPath } from 'react-router';
import styled, { StyledComponent } from '@emotion/styled';
import { Button, Col, Menu, Row, Typography as AntdTypography, message } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { Tool } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header, { Subheader } from '../../../common/app-layout/header';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import Logos from './logos/logos.component';
import Colors from './colors/colors.component';
import UserInterface from './user-interface/user-interface.component';
import SocialMedia from './social-media/social-media.component';
import Typography from './typography/typography.component';
import moment from 'moment';
import {
  useCreateTenantSettings,
  useTenantSettings,
  useUpdateTenantSettings,
} from '../../../common/use-branding';
import Overlay from '../../../common/overlay/overlay.component';
import Spinner from '../../../common/spinner/spinner.component';
import permissionKeys, { getPermissionPath } from '../../../../utils/auth/permissions';
import { TenantSettings } from '../../../../store/types/branding';
import isEqual from 'lodash/isEqual';
import { removeEmptyProperties } from './utils';

const { Text } = AntdTypography;

type BrandingProps = RouteComponentProps<{ organisationId: string }>;

const PATHS = ['/logos', '/colors', '/typography', '/social-media', '/user-interface'];

const Branding = (props: BrandingProps) => {
  const [settingsData, setSettingsData] = useState<TenantSettings>({});
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const previousState = useRef<TenantSettings>({});
  const isComponentMounted = useRef<boolean>(false);

  const {
    location,
    match,
    match: {
      params: { organisationId },
    },
  } = props;

  const { t } = useTranslation();

  const selectedMenuItemKeys = useMemo(() => {
    const matchedMenuPath = PATHS.map((path) =>
      matchPath(location.pathname, {
        path: `${match.path}${path}`,
      }),
    ).find((pathMatch) => !!pathMatch);

    return matchedMenuPath ? [matchedMenuPath.url] : [];
  }, [location.pathname, match.path]);

  const getTenantSettingsPermissionPath = useCallback(
    (permissionKey: string) => getPermissionPath(organisationId, permissionKey),
    [organisationId],
  );

  useLayoutEffect(() => {
    const sidebarMenu = document.getElementById('sidebarMenu');
    const menuItem = sidebarMenu && sidebarMenu.querySelector('.ant-menu-item-selected');
    const isSidebarMenuVertical =
      sidebarMenu && window.getComputedStyle(sidebarMenu).display === 'block';

    if (!menuItem || isSidebarMenuVertical) {
      return;
    }

    menuItem.scrollIntoView();
  }, []);

  const lastEditedTimestamp = useMemo(
    () => moment(new Date()).format('DD/MM/YYYY hh:mm A'),
    [],
  );

  const { data: tenantSettingsData, isLoading, isSuccess, isError } = useTenantSettings(
    organisationId,
  );

  const { tenantSettingsId } = useMemo(
    () => ({
      tenantSettingsId: isSuccess && tenantSettingsData ? tenantSettingsData.id : '',
    }),
    [isSuccess, tenantSettingsData],
  );

  const {
    mutateAsync: createTenantSettings,
    isLoading: isCreateLoading,
    isError: isCreateError,
  } = useCreateTenantSettings({
    tenantId: organisationId,
  });

  const {
    mutateAsync: updateTenantSettings,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useUpdateTenantSettings({
    tenantId: organisationId,
    tenantSettingsId,
  });

  useEffect(() => {
    if (!isLoading) {
      const settings =
        tenantSettingsData && tenantSettingsData.settings
          ? tenantSettingsData.settings
          : {};
      isComponentMounted.current = true;
      setSettingsData((prevState) => {
        const settingsObj = removeEmptyProperties({ ...prevState, ...settings });
        previousState.current = settingsObj;
        return settingsObj;
      });
    }
  }, [tenantSettingsData, setSettingsData, isLoading]);

  const handleSaveTenantSettings = useCallback(async () => {
    const body = {
      tenantId: organisationId,
      settings: { ...settingsData },
    };

    try {
      if (tenantSettingsId) {
        await updateTenantSettings(body);
        message.success(t('branding.successUpdatingData'));
      } else {
        await createTenantSettings(body);
        message.success(t('branding.successSavingData'));
      }
      setSaveButtonDisabled(true);
    } catch (e) {
      message.error(t('errorSavingData'));
      throw e;
    }
  }, [
    updateTenantSettings,
    createTenantSettings,
    tenantSettingsId,
    t,
    organisationId,
    settingsData,
  ]);

  const onFormChangeHandler = useCallback(
    (formData) => {
      //TODO: Remove object comparison. Will create a separate PR
      if (isComponentMounted.current) {
        const key = Object.keys(formData)[0];
        setSettingsData((prevState) => {
          const currentState = removeEmptyProperties({
            ...prevState,
            [key]: { ...formData[key] },
          });
          if (!isEqual(currentState, previousState.current)) {
            setSaveButtonDisabled(false);
          }
          return currentState;
        });
      }
    },
    [setSettingsData],
  );

  const isDataUpdateLoading = isCreateLoading || isUpdateLoading;
  const isDataUpdateError = isCreateError || isUpdateError;

  return (
    <ErrorBoundary>
      <Header
        tabTitle={t('branding.title')}
        title={t('branding.title')}
        icon={<Tool size={20} color="#fff" />}
      />
      {isLoading && !isError ? (
        <Overlay>
          <Spinner />
        </Overlay>
      ) : (
        <>
          {isDataUpdateLoading && !isDataUpdateError && (
            <StyledOverLay>
              <Spinner />
            </StyledOverLay>
          )}
          <Subheader
            components={[
              <LastEdited key="last-edited">
                {t('lastEditedTimestamp', { timestamp: lastEditedTimestamp })}
              </LastEdited>,
              <SaveButton
                disabled={isSaveButtonDisabled}
                key="save"
                size="large"
                type="primary"
                onClick={handleSaveTenantSettings}
              >
                {t('saveAllChanges')}
              </SaveButton>,
            ]}
          />
          <BrandingContainer>
            <RowStyled gutter={{ md: 20, xl: 40 }}>
              <StickyColumn>
                <SidebarBrandingMenu
                  id="sidebarMenu"
                  mode="inline"
                  selectedKeys={selectedMenuItemKeys}
                >
                  <MenuItem key={`${match.url}/logos`}>
                    <Link to={`${match.url}/logos`}>
                      <span>{t('branding.menuLogos')}</span>
                    </Link>
                  </MenuItem>
                  <MenuItem key={`${match.url}/colors`}>
                    <Link to={`${match.url}/colors`}>
                      <span>{t('branding.menuColors')}</span>
                    </Link>
                  </MenuItem>
                  <MenuItem key={`${match.url}/typography`}>
                    <Link to={`${match.url}/typography`}>
                      <span>{t('branding.menuTypography')}</span>
                    </Link>
                  </MenuItem>
                  <MenuItem key={`${match.url}/social-media`}>
                    <Link to={`${match.url}/social-media`}>
                      <span>{t('branding.menuSocialMedia')}</span>
                    </Link>
                  </MenuItem>
                  <MenuItem key={`${match.url}/user-interface`}>
                    <Link to={`${match.url}/user-interface`}>
                      <span>{t('branding.menuUserInterface')}</span>
                    </Link>
                  </MenuItem>
                </SidebarBrandingMenu>
              </StickyColumn>
              <ContentColumn>
                <Switch>
                  <ProtectedRouteContainer
                    permissionPath={getTenantSettingsPermissionPath(
                      permissionKeys.organizations.viewAll,
                    )}
                    path={`${match.path}/logos`}
                    render={() => (
                      <Logos
                        {...props}
                        logoSettings={{
                          logos: settingsData.logos || {},
                        }}
                        onFormChangeHandler={onFormChangeHandler}
                      />
                    )}
                  />
                  <ProtectedRouteContainer
                    path={`${match.path}/colors`}
                    permissionPath={getTenantSettingsPermissionPath(
                      permissionKeys.organizations.viewAll,
                    )}
                    render={() => (
                      <Colors
                        {...props}
                        colorSettings={{
                          colors: settingsData.colors || {},
                        }}
                        onFormChangeHandler={onFormChangeHandler}
                      />
                    )}
                  />
                  <ProtectedRouteContainer
                    path={`${match.path}/typography`}
                    permissionPath={getTenantSettingsPermissionPath(
                      permissionKeys.organizations.viewAll,
                    )}
                    render={() => (
                      <Typography
                        {...props}
                        typographySettings={{
                          typography: settingsData.typography || {},
                        }}
                        onFormChangeHandler={onFormChangeHandler}
                      />
                    )}
                  />
                  <ProtectedRouteContainer
                    path={`${match.path}/social-media`}
                    permissionPath={getTenantSettingsPermissionPath(
                      permissionKeys.organizations.viewAll,
                    )}
                    render={() => (
                      <SocialMedia
                        {...props}
                        socialMediaSettings={{
                          socialMedia: settingsData.socialMedia || {},
                        }}
                        onFormChangeHandler={onFormChangeHandler}
                      />
                    )}
                  />
                  <ProtectedRouteContainer
                    path={`${match.path}/user-interface`}
                    permissionPath={getTenantSettingsPermissionPath(
                      permissionKeys.organizations.viewAll,
                    )}
                    render={() => (
                      <UserInterface
                        {...props}
                        buttonsSettings={{
                          buttons: settingsData.buttons || {},
                        }}
                        onFormChangeHandler={onFormChangeHandler}
                      />
                    )}
                  />
                  <Redirect to={`${match.url}/logos`} />
                </Switch>
              </ContentColumn>
            </RowStyled>
          </BrandingContainer>
        </>
      )}
    </ErrorBoundary>
  );
};

const BrandingContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 64px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const SidebarBrandingMenu = styled(Menu)`
  margin: 0 40px 0 5px;
  background: unset;
  border: none;
  display: block;

  @media (max-width: 1199px) {
    display: -webkit-box;
    overflow-x: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    width: 100%;

    margin-left: 0;

    .ant-menu-item {
      width: unset;
    }
  }

  @media (min-width: 1200px) {
    display: block;
  }
`;

const MenuItem = styled(Menu.Item)`
  height: 30px !important;

  span {
    padding: 4px;
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-menu-item {
    padding-left: 0 !important;
  }

  &.ant-menu-item-active {
    background-color: unset !important;

    span {
      background-color: rgb(221 230 235 / 30%);
      border-radius: 4px;
    }
  }

  &.ant-menu-item-selected {
    font-weight: 600;
    background-color: unset !important;

    span {
      background-color: #dde6eb;
      border-radius: 4px;
      color: #2364aa;
    }
  }

  &.ant-menu-item-selected::after {
    opacity: 0;
  }
`;

const RowStyled = styled(Row)`
  @media (min-width: 1200px) {
    display: flex;
  }
`;

export const StickyColumn = styled(Col)`
  width: 100%;
  z-index: 1;

  @media (min-width: 1200px) {
    position: sticky;
    display: block;
    width: 25%;
    top: 16px;
    height: fit-content;
  }
`;

const ContentColumn = styled(Col)`
  width: 100%;

  @media (min-width: 1200px) {
    display: block;
    width: 75%;
  }

  @media (min-width: 1400px) {
    display: block;
    width: 50%;
  }
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<Button>,
  Pick<ButtonProps & React.ClassAttributes<Button>, keyof ButtonProps>,
  any
>;
const SaveButton = styled(Button)`
  background-color: #00ae48;
  border-color: #00ae48;
  height: 36px;
  font-size: 14px;
  margin-left: auto;

  :hover,
  :active,
  :focus {
    background-color: #00ae48e8;
    border-color: #00ae48e8;
  }
` as StyledButtonType;

const LastEdited = styled(Text)`
  color: #676973;
  font-size: 13px;
`;

const StyledOverLay = styled(Overlay)`
  position: absolute;
  z-index: 999;
  background: rgb(252 252 252 / 48%);
`;

export default Branding;
