import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PhyhubDevicesBaseActionModal from '../phyhub-devices-base-action-modal/phyhub-devices-base-action-modal.component';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';

interface PhyhubDevicesRestartModalProps {
  isVisible: boolean;
  devices: PhyhubDevice[];
  onCancel: () => void;
  onRestartConfirm: (deviceIds: string[]) => void;
}

const PhyhubDevicesRestartModal: FC<PhyhubDevicesRestartModalProps> = (props) => {
  const { isVisible, devices, onRestartConfirm } = props;

  const { t } = useTranslation();

  const handleOk = useCallback(
    (selectedDeviceIds: string[]) => {
      onRestartConfirm(selectedDeviceIds);
    },
    [onRestartConfirm],
  );

  return (
    <PhyhubDevicesBaseActionModal
      isVisible={isVisible}
      cancelText={t('phyhubDevices.modal.restart.cancelText')}
      okText={t('phyhubDevices.modal.restart.okText')}
      title={t('phyhubDevices.modal.restart.title')}
      affectedDevices={devices}
      skipperDevices={[]}
      onCancel={props.onCancel}
      onOk={handleOk}
    />
  );
};

export default PhyhubDevicesRestartModal;
