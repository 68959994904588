import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PhyhubDeviceType from '../../../phyhub-device-type/phyhub-device-type.component';
import { PhyhubDeviceStatusEnum } from '../../../../../../services/phyhub/enums/phyhub-device-status.enum';
import { PhyhubDeviceOs } from '../../../../../../services/phyhub/types/phyhub-device-os.interface';
import { PHYHUB_DEVICE_OS_TYPE_TRANSLATION_MAP } from '../../../../../../store/types/phyhub-devices';

interface PhyhubDevicesTableTypeCellProps {
  status: PhyhubDeviceStatusEnum;
  os: PhyhubDeviceOs | null;
}

const PhyhubDevicesTableTypeCell: FC<PhyhubDevicesTableTypeCellProps> = (props) => {
  const { status, os } = props;

  const { t } = useTranslation();

  if (!os) {
    return t('notAvailable');
  }

  // TODO: Use translation for osName (add mapping from os.type to translation key)
  return (
    <PhyhubDeviceType
      arch={os.arch}
      osName={t(PHYHUB_DEVICE_OS_TYPE_TRANSLATION_MAP[os.type])}
      status={status}
    />
  );
};

export default PhyhubDevicesTableTypeCell;
