import * as React from 'react';
import { Layout } from 'antd';
import styled from '@emotion/styled';
import SidebarContainer from './sidebar/sidebar.container';
import Header from './header/header.component';
import Content from './content/content.component';
import Navbar from './navbar/navbar.component';
import Footer from './footer/footer.component';

interface AppLayoutProps {
  children: React.ReactNode;
}

const StyledLayout = styled(Layout)`
  min-height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
`;

const AppLayout = ({ children }: AppLayoutProps) => {
  return <StyledLayout>{children}</StyledLayout>;
};

// TO DO: Rename Navbar to Header, Header to something else
// Check old Header dependencies first
AppLayout.Navbar = Navbar;
AppLayout.Sidebar = SidebarContainer;
AppLayout.Content = Content;
AppLayout.Header = Header;
AppLayout.Footer = Footer;

export default AppLayout;
