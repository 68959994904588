import React from 'react';
import styled from '@emotion/styled';
import OmboriLogoSvg from '../../../../assets/images/phygrid-icon.svg';
import { useAppTheme } from '../../../../utils/use-app-theme';

// Define props type
type FooterProps = {
  showPoweredBy?: boolean;
  fullWidth?: boolean;
};

// Footer component
export default function Footer({
  showPoweredBy = false,
  fullWidth = false,
}: FooterProps) {
  const appTheme = useAppTheme();

  return (
    <FooterContainer fullWidth={fullWidth}>
      {(showPoweredBy || appTheme.showPoweredByInIdentifiedSession) && (
        <FooterLeft>
          Powered by&nbsp;
          <OmboriLink target="_blank" href="https://phygrid.com">
            Phygrid, an Ombori company
            <OmboriLogo src={OmboriLogoSvg} />
          </OmboriLink>
        </FooterLeft>
      )}
      <FooterRight>
        <OmboriLink target="_blank" href="https://phygrid.com/privacy">
          Privacy Policy
        </OmboriLink>
        &nbsp;and&nbsp;
        <OmboriLink target="_blank" href="https://phygrid.com/terms">
          Terms of Service
        </OmboriLink>
      </FooterRight>
    </FooterContainer>
  );
}

// Updated styled-components to accept `fullWidth` prop
const FooterContainer = styled.div<{ fullWidth: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  left: ${({ fullWidth }) => (fullWidth ? '0' : '320px')};
  font-size: 10px;
  display: flex;
  background: #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0 0 10px 10px;
  padding: 0px 16px;

  a {
    color: #202020;
  }

  @media screen and (max-width: 768px) {
    padding: 12px;
    display: none;
  }
`;

const FooterLeft = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

const FooterRight = styled(FooterLeft)`
  justify-content: flex-end;
`;

const OmboriLink = styled.a`
  color: #5f06e4;
  text-decoration: none;
  justify-content: center;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

const OmboriLogo = styled.img`
  margin-left: 4px;
  height: 16px;
`;
