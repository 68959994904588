import { Col, Row, Tabs } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps, Switch, Route } from 'react-router-dom';
import React, { useCallback } from 'react';
import Header from '../../../common/app-layout/header/header.component';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import User from '../../../../store/types/user';
import Organization from '../../../../store/types/organisation';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import usePermissions from '../../../../utils/auth/use-permissions';
import OrganisationReport from './organisation-report/organisation-report.container';
import TenantReport from './tenant-report/tenant-report.container';
import QueueOverviewContainer from '../queue-details/overview/overview.container';
import { useAnalyticsParams } from '../../../common/use-analytics-params';

interface OrganizationOverview extends RouteComponentProps<{ organisationId: string }> {
  user: User | null;
  organisation: Organization;
}

const OrganizationOverviewContent = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative;
  flex: 1;
`;

const OrganizationOverviewHeader = styled(Header)`
  padding-bottom: 0;
`;

const OrganizationOverviewTabBar = styled(TabBar)`
  margin-top: 24px;
`;

const OrganizationOverview = (props: OrganizationOverview) => {
  const { match, location, history, organisation } = props;
  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(match.params.organisationId);

  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    [history],
  );

  const { customOverviewDashboard, dataResidency } = organisation;
  const { organisationId } = match.params;

  const { analyticsQueryParams } = useAnalyticsParams();

  return (
    <>
      <OrganizationOverviewHeader title="Overview">
        <Row>
          <Col>
            <OrganizationOverviewTabBar
              onChange={handleTabChange}
              activeKey={`${location.pathname}${location.search}`}
              lang={i18n.language}
            >
              {/* {permissions.organizations.viewAnalyticsOverview && (
                <Tabs.TabPane tab={t('default')} key={`${match.url}/default`} />
              )} */}
              {isAllowed(permissionKeys.organizations.viewSingleAnalytics) && (
                <Tabs.TabPane
                  tab={t('tenantReport')}
                  key={`${match.url}/tenant-analytics${analyticsQueryParams}`}
                />
              )}
              {!!dataResidency && isAllowed(permissionKeys.queues.viewAnalytics) && (
                <Tabs.TabPane
                  tab={t('virtualManagement')}
                  key={`${match.url}/queue-analytics`}
                />
              )}

              {!!customOverviewDashboard &&
                isAllowed(permissionKeys.organizations.viewAllAnalytics) && (
                  <Tabs.TabPane
                    tab={t('customAnalytics')}
                    key={`${match.url}/${customOverviewDashboard}`}
                  />
                )}
            </OrganizationOverviewTabBar>
          </Col>
        </Row>
      </OrganizationOverviewHeader>
      <OrganizationOverviewContent>
        <Switch>
          <Route
            path={match.path}
            exact
            render={() => (
              <Redirect
                to={`${match.url}/${
                  organisation.dataResidency
                    ? `tenant-analytics${analyticsQueryParams}`
                    : organisation.customOverviewDashboard
                }`}
              />
            )}
          />

          {/* <ProtectedRouteContainer
            roles={rolePermissionsList.organizations.viewAnalyticsOverview}
            path={`${match.path}/default`}
            component={OrganisationReport}
          /> */}
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.organizations.viewAllAnalytics,
            )}
            path={`${match.path}/tenant-analytics`}
            component={TenantReport}
          />

          {!!customOverviewDashboard && (
            <ProtectedRouteContainer
              permissionPath={getPermissionPath(
                organisationId,
                permissionKeys.organizations.viewAllAnalytics,
              )}
              path={`${match.path}/${customOverviewDashboard}`}
              render={(routeProps) => (
                <OrganisationReport
                  {...routeProps}
                  reportName={customOverviewDashboard}
                />
              )}
            />
          )}
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.queues.viewAnalytics,
            )}
            path={`${match.path}/queue-analytics`}
            component={QueueOverviewContainer}
          />
        </Switch>
      </OrganizationOverviewContent>
    </>
  );
};

export default OrganizationOverview;
