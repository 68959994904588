import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import { Icon } from '../../../../../schema-form/common';

interface PhyhubDevicesTableSettingsActionsProps {
  deviceId: string;
  onDeleteClick: () => void;
}

const PhyhubDevicesTableSettingsActions: FC<PhyhubDevicesTableSettingsActionsProps> = (
  props,
) => {
  const { deviceId } = props;

  const { t } = useTranslation();

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu>
          <Menu.Item key={`${deviceId}-remove`}>
            <Button
              type="link"
              icon="delete"
              size="small"
              // TODO: Rewrite it with reused component
              style={{ color: '#f5222d' }}
              onClick={props.onDeleteClick}
            >
              {t('deleteDevice')}
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Icon type="small-dash" />
    </Dropdown>
  );
};

export default PhyhubDevicesTableSettingsActions;
