import { action, Action, thunk, Thunk } from 'easy-peasy';
import Injections from '../../injections.interface';
import { ApiError } from '../../../services/api/api-error';
import AnalyticsEmbed from '../../types/analytics-embed';
import Contact from '../../types/contact';
import { DataResidencyEnum } from '../../types/organisation';

export const GRID_OVERVIEW_EMBED_KEY = '__grid-overview';
export const KEY_GRID_ANALYTICS_OVERVIEW = '__grid-analytics-overview';

interface FetchAppSignalsReportProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  timeZone: string;
  installationId: string;
  deviceId?: string;
  events?: string[];
  dataResidency: DataResidencyEnum;
}

export interface AnalyticsModel {
  reports: {
    [key: string]: any;
  };
  embeds: {
    [organizationId: string]: {
      [reportName: string]: AnalyticsEmbed;
    };
  };
  loading: {
    [key: string]: boolean;
  };
  error: {
    [key: string]: ApiError | undefined;
  };
  embedLoading: {
    [organizationId: string]: {
      [reportName: string]: boolean;
    };
  };
  embedError: {
    [organizationId: string]: {
      [reportName: string]: ApiError | undefined;
    };
  };
  setLoading: Action<AnalyticsModel, { key: string; loading: boolean }>;
  setError: Action<AnalyticsModel, { key: string; error: ApiError | undefined }>;
  setReport: Action<AnalyticsModel, { key: string; data: any }>;
  setEmbed: Action<AnalyticsModel, { key: string; reportName: string; data: any }>;
  setEmbedLoading: Action<
    AnalyticsModel,
    { key: string; reportName: string; loading: boolean }
  >;
  setEmbedError: Action<
    AnalyticsModel,
    { key: string; reportName: string; error: ApiError | undefined }
  >;
  fetchOrganizationReport: Thunk<AnalyticsModel, any, Injections>;
  fetchQueueReport: Thunk<AnalyticsModel, any, Injections>;
  fetchAppReport: Thunk<AnalyticsModel, any, Injections>;
  fetchAppSignalsReport: Thunk<
    AnalyticsModel,
    FetchAppSignalsReportProps,
    Injections,
    {},
    Promise<void>
  >;
  fetchOrganizationEmbed: Thunk<
    AnalyticsModel,
    { organizationId: string; customOverviewDashboard?: string; silent?: boolean },
    Injections
  >;
  fetchOrganizationQueuesEmbed: Thunk<
    AnalyticsModel,
    { organizationId: string; silent?: boolean },
    Injections
  >;
  fetchGridAnalyticsOverviewReport: Thunk<
    AnalyticsModel,
    { silent?: boolean },
    Injections
  >;
  fetchInstallationReport: Thunk<
    AnalyticsModel,
    { organisationId: string; appId: string; commonAppId: string; silent?: boolean },
    Injections
  >;
  fetchTenantReport: Thunk<
    AnalyticsModel,
    { organisationId: string; silent?: boolean },
    Injections
  >;
  fetchSpacesReport: Thunk<
    AnalyticsModel,
    { spaceId: string; silent?: boolean },
    Injections
  >;
}

const analyticsModel: AnalyticsModel = {
  reports: {},
  embeds: {},
  loading: {},
  error: {},
  embedLoading: {},
  embedError: {},
  setEmbedLoading: action((state, { key, reportName, loading }) => {
    state.embedLoading[key] = {
      [reportName]: loading,
    };
  }),
  setEmbedError: action((state, { key, reportName, error }) => {
    state.embedError[key] = {
      [reportName]: error,
    };
  }),
  setReport: action((state, { key, data }) => {
    state.reports[key] = data;
  }),
  setEmbed: action((state, { key, reportName, data }) => {
    state.embeds[key] = {
      ...state.embeds[key],
      [reportName]: data,
    };
  }),
  setLoading: action((state, { key, loading }) => {
    state.loading[key] = loading;
  }),
  setError: action((state, { key, error }) => {
    state.error[key] = error;
  }),
  fetchOrganizationReport: thunk(
    async (actions, { organizationId, organizationName, params }, { injections }) => {
      actions.setError({ key: organizationId, error: undefined });
      actions.setLoading({ key: organizationId, loading: true });
      try {
        const data = await injections.apiService.get<any>(
          `/api/analytics/report?organizationName=${encodeURIComponent(
            organizationName,
          )}&params=${encodeURIComponent(JSON.stringify(params))}`,
        );
        actions.setReport({ key: organizationId, data });
      } catch (error) {
        actions.setError({ key: organizationId, error: undefined });
      } finally {
        actions.setLoading({ key: organizationId, loading: false });
      }
    },
  ),
  fetchQueueReport: thunk(
    async (actions, { organizationId, organizationName, params }, { injections }) => {
      actions.setError({ key: organizationId, error: undefined });
      actions.setLoading({ key: organizationId, loading: true });
      try {
        const data = await injections.apiService.get<any>(
          `/api/analytics/queue-report?organizationName=${encodeURIComponent(
            organizationName,
          )}&params=${encodeURIComponent(JSON.stringify(params))}`,
        );
        actions.setReport({ key: organizationId, data });
      } catch (error) {
        actions.setError({ key: organizationId, error: undefined });
      } finally {
        actions.setLoading({ key: organizationId, loading: false });
      }
    },
  ),
  fetchAppReport: thunk(async (actions, { appId, appName, params }, { injections }) => {
    actions.setError({ key: appId, error: undefined });
    actions.setLoading({ key: appId, loading: true });
    try {
      const data = await injections.apiService.get<any>(
        `/api/analytics/report?appName=${encodeURIComponent(
          appName,
        )}&params=${encodeURIComponent(JSON.stringify(params))}`,
      );
      actions.setReport({ key: appId, data });
    } catch (error) {
      actions.setError({ key: appId, error: undefined });
    } finally {
      actions.setLoading({ key: appId, loading: false });
    }
  }),
  fetchAppSignalsReport: thunk(
    async (
      actions,
      {
        tenantId,
        installationId,
        dateFrom,
        dateTo,
        timeZone,
        deviceId,
        events,
        dataResidency,
      },
      { injections },
    ) => {
      actions.setError({ key: installationId, error: undefined });
      actions.setLoading({ key: installationId, loading: true });
      try {
        const queryParts = [
          `installationId=${installationId}`,
          `dateFrom=${dateFrom}`,
          `dateTo=${dateTo}`,
          `timeZone=${timeZone}`,
          deviceId ? `deviceId=${deviceId}` : '',
          events ? `events=${JSON.stringify(events)}` : '',
        ];

        const query = queryParts.filter((queryPart) => !!queryPart).join('&');

        const data = await injections.gridDataService.get<Contact>(
          `/v1/${tenantId}/analytics/report?${query}`,
          dataResidency,
        );

        actions.setReport({ key: installationId, data });
      } catch (error) {
        actions.setError({ key: installationId, error: undefined });
      } finally {
        actions.setLoading({ key: installationId, loading: false });
      }
    },
  ),
  fetchOrganizationEmbed: thunk(
    async (
      actions,
      { organizationId, customOverviewDashboard, silent },
      { injections },
    ) => {
      const reportName = customOverviewDashboard || '_default';

      actions.setEmbedError({ key: organizationId, reportName, error: undefined });
      if (!silent) {
        actions.setEmbedLoading({ key: organizationId, reportName, loading: true });
      }

      try {
        const customReportQuery = customOverviewDashboard
          ? `?customOverviewDashboard=${encodeURIComponent(customOverviewDashboard)}`
          : '';

        const data = await injections.apiService.get<any>(
          `/api/powerbi/organizations/${organizationId}${customReportQuery}`,
        );
        actions.setEmbed({
          key: organizationId,
          reportName,
          data,
        });
      } catch (error) {
        actions.setEmbedError({ key: organizationId, reportName, error: undefined });
      } finally {
        if (!silent) {
          actions.setEmbedLoading({ key: organizationId, reportName, loading: false });
        }
      }
    },
  ),
  fetchOrganizationQueuesEmbed: thunk(
    async (actions, { organizationId, silent }, { injections }) => {
      const reportName = '_queues';

      actions.setEmbedError({ key: organizationId, reportName, error: undefined });

      if (!silent) {
        actions.setEmbedLoading({ key: organizationId, reportName, loading: true });
      }

      try {
        const data = await injections.apiService.get<any>(
          `/api/powerbi/queues/${organizationId}`,
        );
        actions.setEmbed({
          key: organizationId,
          reportName,
          data,
        });
      } catch (error) {
        actions.setEmbedError({ key: organizationId, reportName, error: undefined });
      } finally {
        if (!silent) {
          actions.setEmbedLoading({ key: organizationId, reportName, loading: false });
        }
      }
    },
  ),
  fetchGridAnalyticsOverviewReport: thunk(async (actions, { silent }, { injections }) => {
    const key = KEY_GRID_ANALYTICS_OVERVIEW;
    const reportName = 'grid-sysadmin-overview';

    actions.setEmbedError({ key, reportName, error: undefined });

    if (!silent) {
      actions.setEmbedLoading({ key, reportName, loading: true });
    }

    try {
      const data = await injections.apiService.get<any>(`/api/powerbi/token`, {
        reportKey: 'LnV41985xz', // Hard-coded for now
      });

      actions.setEmbed({
        key,
        reportName,
        data,
      });
    } catch (error) {
      actions.setEmbedError({ key, reportName, error: undefined });
    } finally {
      if (!silent) {
        actions.setEmbedLoading({ key, reportName, loading: false });
      }
    }
  }),
  fetchInstallationReport: thunk(
    async (actions, { organisationId, appId, commonAppId, silent }, { injections }) => {
      const reportName = 'grid-installation-overview';
      const key = appId;

      actions.setEmbedError({ key, reportName, error: undefined });
      if (!silent) {
        actions.setEmbedLoading({ key, reportName, loading: true });
      }

      try {
        const data = await injections.apiService.get<any>(
          `/api/powerbi/organizations/${organisationId}/apps/installations/${commonAppId}`,
        );

        actions.setEmbed({
          key,
          reportName,
          data,
        });
      } catch (error) {
        actions.setEmbedError({ key, reportName, error: undefined });
      } finally {
        if (!silent) {
          actions.setEmbedLoading({ key, reportName, loading: false });
        }
      }
    },
  ),
  fetchTenantReport: thunk(
    async (actions, { organisationId, silent }, { injections }) => {
      const reportName = 'grid-tenant-overview';
      const key = organisationId;

      actions.setEmbedError({ key, reportName, error: undefined });
      if (!silent) {
        actions.setEmbedLoading({ key, reportName, loading: true });
      }

      try {
        const data = await injections.apiService.get<any>(
          `/api/powerbi/tenants/${organisationId}`,
        );

        actions.setEmbed({
          key,
          reportName,
          data,
        });
      } catch (error) {
        actions.setEmbedError({ key, reportName, error: undefined });
      } finally {
        if (!silent) {
          actions.setEmbedLoading({ key, reportName, loading: false });
        }
      }
    },
  ),
  fetchSpacesReport: thunk(async (actions, { spaceId, silent }, { injections }) => {
    const reportName = 'grid-space-overview';
    const key = spaceId;

    actions.setEmbedError({ key, reportName, error: undefined });
    if (!silent) {
      actions.setEmbedLoading({ key, reportName, loading: true });
    }

    try {
      const data = await injections.apiService.get<any>(`/api/powerbi/spaces/${spaceId}`);

      actions.setEmbed({
        key,
        reportName,
        data,
      });
    } catch (error) {
      actions.setEmbedError({ key, reportName, error: undefined });
    } finally {
      if (!silent) {
        actions.setEmbedLoading({ key, reportName, loading: false });
      }
    }
  }),
};

export default analyticsModel;
