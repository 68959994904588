import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import SearchBar from '../search-bar/search-bar.component';
import usePaginationQueryParams from '../use-pagination-query-params';
import { PaginationQueryParams } from '../use-pagination-query-params/use-pagination-query-params';
import { usePagination } from '../pagination';
import { SortCriteria, SortOrderType } from '../../../store/types/sort';

interface PaginatedListSearchProps {
  searchPlaceholder: string;
  sortCriteria?: SortCriteria[];
  defaultSortField?: string;
  defaultSortOrder?: SortOrderType;
  minSearchStringLength?: number;
}

interface PaginatedListSearchQueryParams extends PaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrderType;
}

const PaginatedListSearch = (props: PaginatedListSearchProps) => {
  const {
    searchPlaceholder,
    sortCriteria,
    defaultSortField,
    defaultSortOrder,
    minSearchStringLength = 0
  } = props;

  const [paginationQueryParams, setPaginationQueryParams] =
    usePaginationQueryParams<PaginatedListSearchQueryParams>({
      sortField: defaultSortField,
      sortOrder: defaultSortOrder,
    });

  const { defaultPage } = usePagination();

  const handleSearch = useCallback(
    (searchTerm) => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page: defaultPage,
          ...{ search: searchTerm || undefined },
        };
      });
    },
    [setPaginationQueryParams, defaultPage],
  );

  const handleSortUpdate = useCallback(
    (sortField, sortOrder) => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page: defaultPage,
          sortField: sortField.id,
          sortOrder: sortOrder.id,
        };
      });
    },
    [setPaginationQueryParams, defaultPage],
  );

  return (
    <SearchBarWrapper className="search-bar">
      <SearchBar
        searchInputProps={{
          placeholder: searchPlaceholder,
          onSearch: handleSearch,
          defaultValue: paginationQueryParams.search,
          minLength: minSearchStringLength > 0 ? minSearchStringLength : undefined
        }}
        sortProps={{
          sortCriteria,
          defaultCriteria: paginationQueryParams.sortField,
          defaultOrder: paginationQueryParams.sortOrder,
          onChange: handleSortUpdate,
        }}
      />
    </SearchBarWrapper>
  );
};

export default PaginatedListSearch;

const SearchBarWrapper = styled.div`
  width: 100%;
`
