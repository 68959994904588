import { useQuery } from 'react-query';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { DataResidencyEnum } from '../../types/organisation';
import {PhyhubDeviceListParamsFilters} from "../../../services/phyhub/types/phyhub-device-list-params.interface";
import {usePhyhubService} from "../../../app/common/phyhub-service-provider/phyhub-service.provider";

export interface PhyhubDevicesListQueryParams {
  tenantId: string;
  page: number;
  limit: number;
  filters?: PhyhubDeviceListParamsFilters,
}

const usePhyhubDevicesList = (
  phyhubDevicesListParams: PhyhubDevicesListQueryParams,
) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesListKeys(phyhubDevicesListParams),
    () =>
      phyhubService.listDevices({
        tenantId: phyhubDevicesListParams.tenantId,
        page: phyhubDevicesListParams.page,
        limit: phyhubDevicesListParams.limit,
        filters: phyhubDevicesListParams.filters,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesList;
