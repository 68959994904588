import styled from '@emotion/styled';
import React from 'react';
import ImgBrandShape from '../../../assets/images/brand-shape.png';
import ImgOmboriGridLogo from '../../../assets/images/phygrid_logo_light.svg';
import { ApiError } from '../../../services/api/api-error';
import Loader from '../../common/loader/loader-component';

interface OnboardingPanelLayoutProps {
  loading?: boolean;
  error?: ApiError | JSX.Element | null;
  children: React.ReactNode;
}

const OnboardingPanelLayout = (props: OnboardingPanelLayoutProps) => {
  const { loading, error, children } = props;

  return (
    <Container>
      <Content>
        <Loader loading={loading} error={error} opacity={0.3}>
          <FormContainer>
            <Masthead>
              <Logo src={ImgOmboriGridLogo} alt="Phygrid" />
            </Masthead>
            {loading ? <LoaderPlaceholder /> : children}
          </FormContainer>
        </Loader>
      </Content>
    </Container>
  );
};

const LoaderPlaceholder = styled.div`
  height: 100vh;
  background: #fff;
`;

const FormContainer = styled.div`
  background: #fff;
  max-width: 768px;
  max-height: 100%;
  width: 100%;
  padding: 24px 24px 40px 24px;
  overflow-y: auto;
  overflow-x: hidden;

  h1 {
    font-size: 20px;
    margin: 16px 0;
    font-weight: bold;
  }
`;

const Content = styled.div`
  position: fixed;
  height: inherit;
  max-width: 768px;
  width: 100%;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #777;
  background: #1d1921 url(${ImgBrandShape}) 0 0 no-repeat;
  background-size: 250%;
  overflow: hidden;
`;

const Masthead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const Logo = styled.img`
  display: block;
  height: 48px;
  margin: auto;
  filter: invert(1);
`;

export default OnboardingPanelLayout;
