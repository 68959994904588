import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import { requiredAll } from '../../organisations/organisation-details/queue-details/queue-setup/validate-form';
import { composeValidators, Validator } from './validator';
import InputWrap from './input-wrap';
import { ChangeEvent } from '../../../types';
import trim from 'lodash/trim';
import styled from '@emotion/styled';

export type ValidatorType = string | number;

export interface InputProps<T = string> extends AntdInputProps {
  name: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  validators?: Validator<T>[];
  dataTestId?: string;
}

const Input = <T,>(props: InputProps<T>) => {
  const {
    name,
    className,
    isRequired = false,
    label,
    validators = [],
    defaultValue,
    dataTestId,
    type,
    ...restInputProps
  } = props;

  const inputValidators = [...(isRequired ? [requiredAll] : []), ...validators];

  const getFieldValue = useCallback(
    (field: HTMLInputElement, performTrim = false) => {
      const { value, valueAsNumber } = field;

      if (type && type === 'number') {
        // isNaN to check when user removes value from the number input (browser turns it into NaN)
        return isNaN(valueAsNumber) ? value : valueAsNumber;
      }

      return performTrim ? trim(value) : value;
    },
    [type],
  );

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      onFieldChange: (event: React.ChangeEvent<unknown>) => void,
    ): void => {
      onFieldChange(({
        target: { value: getFieldValue(event.target) },
      } as unknown) as ChangeEvent<string>);
    },
    [getFieldValue],
  );

  const handleOnBlur = useCallback(
    (
      event: React.FocusEvent<HTMLInputElement>,
      onFieldChange: (event: React.ChangeEvent<unknown>) => void,
    ): void => {
      onFieldChange(({
        target: { value: getFieldValue(event.target, true) },
      } as unknown) as ChangeEvent<string>);
    },
    [getFieldValue],
  );

  const handleKeyDown = useCallback(
    (
      event: React.KeyboardEvent<HTMLInputElement>,
      onFieldChange: (event: React.ChangeEvent<any>) => void,
    ): void => {
      if (event.key === 'Enter') {
        onFieldChange(({
          target: { value: getFieldValue(event.currentTarget, true) },
        } as unknown) as ChangeEvent<string>);
      }
    },
    [getFieldValue],
  );

  return (
    <>
      <Field name={name} validate={composeValidators(inputValidators)}>
        {({ input, meta }) => {
          const { value, onChange, ...restFormInput } = input;
          const isError = (!!meta.error || !!meta.submitError) && meta.touched;

          return (
            <InputWrap
              className={className}
              label={label}
              isRequired={isRequired}
              error={isError ? meta.error || meta.submitError : undefined}
            >
              <StyledInput
                {...restInputProps}
                {...restFormInput}
                type={type}
                defaultValue={defaultValue || value}
                value={value}
                onChange={(event) => handleChange(event, onChange)}
                onBlur={(event) => handleOnBlur(event, onChange)}
                onKeyDown={(event) => handleKeyDown(event, onChange)}
                data-testid={dataTestId}
              />
            </InputWrap>
          );
        }}
      </Field>
    </>
  );
};

const StyledInput = styled(AntdInput)`
  &[readonly] {
    background-color: #f5f6facc;
  }
`;

export default Input;
