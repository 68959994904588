import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs } from 'antd';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header from '../../../common/app-layout/header/header.component';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import ActivePlansContainer from './active-plans/active-plans.container';
import PricingContainer from './pricing/pricing.container';
import usePermissions from '../../../../utils/auth/use-permissions';

type PlansProps = RouteComponentProps<{ organisationId: string }>;

const Plans = ({ match, history, location }: PlansProps) => {
  const { organisationId } = match.params;
  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);

  const onTabChange = useCallback(
    (key: string) => {
      history.push(key);
    },
    [history],
  );

  return (
    <ErrorBoundary>
      <HeaderStyled title={<span>{t('plans.title')}</span>}>
        <Row>
          <Col>
            <TabBarStyled
              onChange={onTabChange}
              activeKey={location.pathname}
              lang={i18n.language}
            >
              <Tabs.TabPane
                tab={<span>{t('plans.overview')}</span>}
                key={`${match.url}/overview`}
              />
              {isAllowed(permissionKeys.organizations.viewSkus) && (
                <Tabs.TabPane
                  tab={<span>{t('plans.pricing')}</span>}
                  key={`${match.url}/pricing`}
                />
              )}
            </TabBarStyled>
          </Col>
        </Row>
      </HeaderStyled>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/overview`} />}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(organisationId, permissionKeys.plans.viewAll)}
          path={`${match.path}/overview`}
          component={ActivePlansContainer}
        />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.organizations.viewSkus,
          )}
          path={`${match.path}/pricing`}
          component={PricingContainer}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const HeaderStyled = styled(Header)`
  padding-bottom: 0px;
`;

const TabBarStyled = styled(TabBar)`
  
`;

export default Plans;
