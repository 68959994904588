import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Icon, Typography, Row, Col, Button } from 'antd';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Device from '../../../../../store/types/device';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import OrganisationApp from '../../../../../store/types/organisation-app';
import CreateDeviceModal, {
  DeviceFormValues,
} from './create-device-modal/create-device-modal.component';
import { DeviceCreateParams } from '../../../../../store/models/app-devices/app-devices.model';
import Environment from '../../../../../store/types/environment';
import sortDevices from '../../../../../utils/sort-devices';
import DevicesListItem from './devices-list-item/devices-list-item.component';

const { Title } = Typography;

interface DevicesProps
  extends RouteComponentProps<{ appId: string; organisationId: string }> {
  devices: Device[];
  loaded: boolean;
  fetchDevices: (params: { appName: string; silent?: boolean }) => void;
  lastUpdated?: Date;
  app: OrganisationApp | null;
  createDevice: (params: DeviceCreateParams) => Promise<void>;
  fetchEnvironments: (params: { organizationId: string }) => void;
  environments: Environment[];
  canCreate: boolean;
}

const DevicesGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 310px);
  justify-content: center;
`;

const FiltersRow = styled(Row)`
  padding: 0 16px 16px;
`;

const Devices = (props: DevicesProps) => {
  const {
    devices,
    loaded,
    fetchDevices,
    lastUpdated,
    app,
    createDevice,
    environments,
    fetchEnvironments,
    canCreate,
  } = props;
  const {
    match: {
      params: { organisationId },
    },
  } = props;
  const { t } = useTranslation();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);

  const handleCreateClick = useCallback(() => {
    setIsCreateModalVisible(true);
  }, [setIsCreateModalVisible]);

  const handleCreateModalClose = useCallback(() => {
    setIsCreateModalVisible(false);
  }, [setIsCreateModalVisible]);

  const handleCreateModalSubmit = useCallback(
    async (values: DeviceFormValues) => {
      if (app) {
        await createDevice({
          ...values,
          appName: app.appName,
        });
        handleCreateModalClose();
      }
    },
    [handleCreateModalClose, createDevice, app],
  );

  useEffect(() => {
    if (app && !isCreateModalVisible) {
      const interval = setInterval(() => {
        fetchDevices({ appName: app.appName, silent: true });
      }, 40 * 1000);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [fetchDevices, isCreateModalVisible, app]);

  useEffect(() => {
    if (app) {
      fetchDevices({ appName: app.appName, silent: false });
    }
  }, [fetchDevices, app]);

  useEffect(() => {
    fetchEnvironments({ organizationId: organisationId });
  }, [fetchEnvironments, organisationId]);

  if (!app) {
    return null;
  }

  return (
    <>
      {!loaded && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      {loaded && !devices.length && (
        <Overlay>
          <Title level={4}>
            {t('noDeviceFound')} <Icon type="frown" />
          </Title>
        </Overlay>
      )}
      <FiltersRow type="flex" justify="end">
        {canCreate && (
          <Col>
            <Button size="large" icon="plus" onClick={handleCreateClick}>
              {t('createDevice')}
            </Button>
          </Col>
        )}
      </FiltersRow>
      {loaded && !!devices.length && (
        <DevicesGrid>
          {sortDevices(devices).map((device) => (
            <Link
              title={device.note || ''}
              key={device.id}
              to={`/organisations/${device.organizationId}/operations/devices/v2/${device.uuid}`}
            >
              <DevicesListItem lastUpdated={lastUpdated} device={device} />
            </Link>
          ))}
        </DevicesGrid>
      )}
      <CreateDeviceModal
        environments={environments}
        visible={isCreateModalVisible}
        onClose={handleCreateModalClose}
        onSubmit={handleCreateModalSubmit}
      />
    </>
  );
};

export default Devices;
