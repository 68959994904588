import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon, Table } from 'antd';
import { get } from 'lodash';
import { ColumnProps } from 'antd/es/table';
import { v4 as uuidV4 } from 'uuid';
import { rgba } from 'polished';
import { useHistory } from 'react-router';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import UniversalDevice from '../../../../../store/types/universal-device';
import OverridesStatus, { IconType, ThemeType } from './overrides-status';
import NetworkStatus, { NetworkStatusType } from './network-status';
import hardDriveIcon from '../../../../../assets/images/hard-drive.svg';
import DeviceStatusEnum from '../../../../../store/types/device-status.enum';
import MeatBallsMenu from './meat-balls-menu';
import SettingsConfigurationType from '../../../../../store/types/settings-configuration';
import OrganisationApp from '../../../../../store/types/organisation-app';

interface DataRow {
  key: string;
  icon: React.ReactNode;
  name: string;
  overrides: React.ReactNode;
  build: string;
  status: React.ReactNode;
  type: string;
}

const routes = {
  spaceSettings: (organisationId: string, appId: string, spaceId: string) =>
    [
      '',
      'organisations',
      organisationId,
      'apps',
      'installations',
      appId,
      'settings-configuration',
      SettingsConfigurationType.SPACE,
      spaceId,
    ].join('/'),
  deviceSettings: (organisationId: string, appId: string, deviceId: string) =>
    [
      '',
      'organisations',
      organisationId,
      'apps',
      'installations',
      appId,
      'settings-configuration',
      SettingsConfigurationType.DEVICE,
      deviceId,
    ].join('/'),
};

const getNetworkStatus = (device: UniversalDevice): NetworkStatusType => {
  switch (device.status) {
    case DeviceStatusEnum.OK:
      return NetworkStatusType.Online;
    case DeviceStatusEnum.FAILING:
      return NetworkStatusType.Failing;
    case DeviceStatusEnum.OFFLINE:
      return NetworkStatusType.Offline;
    default:
      return NetworkStatusType.Offline;
  }
};

const Container = styled.div`
  .ant-table-placeholder {
    border-bottom: 0;
    border-radius: 8px;
  }

  .ant-table-thead tr th {
    font-size: 12px;
    text-transform: uppercase;
    background-color: transparent;
    border-bottom: 0;
  }

  .ant-table tr .icon {
    width: 4%;
  }

  .ant-table tr .location-name {
    width: 24%;
  }

  .ant-table tr .overrides {
    width: 15%;
  }

  .ant-table tr .build {
    width: 34%;
  }

  .ant-table tr .status {
    width: 12%;
  }

  .ant-table tr .meat-balls {
    width: 11%;
  }

  .ant-table-thead tr th,
  .ant-table-tbody tr td {
    padding: 10px 5px;
  }

  .ant-table-tbody .ant-table-row-space td {
    background-color: #f5f5fa !important;
  }

  .ant-table-tbody .ant-table-row-device td {
    background-color: #ffffff !important;
  }

  .ant-table-tbody tr td:first-of-type {
    border-left: 1px solid #dddddd;
  }

  .ant-table-tbody tr td:last-of-type {
    border-right: 1px solid #dddddd;
  }

  .ant-table-tbody tr:last-of-type > td {
    border-bottom: 1px solid #dddddd;
  }

  .ant-table-tbody tr:first-of-type td {
    border-top: 1px solid #dddddd;
  }

  .ant-table-tbody .ant-table-row-space td:hover {
    background-color: #f5f5fa !important;
  }

  .ant-table-tbody .ant-table-row-device td:hover {
    background-color: #ffffff !important;
  }

  .ant-table-tbody tr:first-of-type td:first-of-type {
    border-top-left-radius: 8px;
  }

  .ant-table-tbody tr:first-of-type td:last-of-type {
    border-top-right-radius: 8px;
  }

  .ant-table-tbody tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
  }

  .ant-table-tbody tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
  }

  .ant-table-tbody .ant-table-row-space .location-name {
    font-size: 16px;
    font-weight: 500;
    color: #111111;
  }

  .ant-table-tbody .ant-table-row-device .location-name {
    font-size: 14px;
    color: #111111;
  }

  .ant-table-tbody .ant-table-row .build {
    font-size: 14px;
    color: #676973;
  }

  .ant-table-body {
    overflow-x: auto !important;
  }
`;

const SpaceIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: #e40c86;
  background-color: ${rgba('#E40C86', 0.08)};
  border-radius: 50%;

  svg {
    display: block;
    max-width: 14px;
  }
`;

const SpaceIcon = () => (
  <SpaceIconContainer>
    <Icon type="environment" />
  </SpaceIconContainer>
);

const DeviceIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  color: #5f06e4;
  background-color: ${rgba('#5F06E4', 0.08)};
  border-radius: 50%;
`;

const HardDriveImage = styled.img`
  width: 14px;
  filter: invert(18%) sepia(89%) saturate(7496%) hue-rotate(266deg) brightness(83%)
    contrast(119%);
`;

const countLeaves = (obj: Object): number => {
  if (obj === null || typeof obj !== "object" || Array.isArray(obj)) {
    return 1;
  }

  return Object.values(obj).map(countLeaves).reduce((a: number, b) => a + b, 0);
}

const DeviceIcon = () => (
  <DeviceIconContainer>
    <HardDriveImage src={hardDriveIcon} />
  </DeviceIconContainer>
);

interface LocationTableProps {
  organisationId: string;
  app: OrganisationApp;
  space: OrganisationSpace;
  spaceDevices: UniversalDevice[];
  hasHeader?: boolean;
}

const LocationTable: React.FC<LocationTableProps> = ({
  organisationId,
  app,
  space,
  spaceDevices,
  hasHeader,
}) => {
  const history = useHistory();

  const columns: ColumnProps<DataRow>[] = useMemo(
    () => [
      {
        title: '',
        key: 'icon',
        align: 'center' as 'center',
        className: 'icon',
        render: ({ icon }: { icon: React.ReactNode }) => {
          return <>{icon}</>;
        },
      },
      {
        title: 'Location / Device',
        key: 'name',
        align: 'left' as 'left',
        className: 'location-name',
        render: ({ name }: { name: string }) => {
          return <div>{name}</div>;
        },
      },
      {
        title: 'Overrides',
        key: 'overrides',
        align: 'left' as 'left',
        className: 'overrides',
        render: ({ overrides }: { overrides: React.ReactNode }) => {
          return <>{overrides}</>;
        },
      },
      {
        title: 'Build',
        key: 'build',
        align: 'left' as 'left',
        className: 'build',
        render: ({ build }: { build: string }) => {
          return <>{build}</>;
        },
      },
      {
        title: 'Status',
        key: 'status',
        align: 'left' as 'left',
        className: 'status',
        render: ({ status }: { status: React.ReactNode }) => {
          return <>{status}</>;
        },
      },
      {
        title: '',
        key: 'meatBalls',
        align: 'center' as 'center',
        className: 'meat-balls',
        render: (dataRow: DataRow) => {
          const url =
            dataRow.type === 'space'
              ? routes.spaceSettings(organisationId, app.id, space.id)
              : routes.deviceSettings(organisationId, app.id, dataRow.key);

          const onEdit = () => history.push(url);

          return <MeatBallsMenu onEdit={onEdit} organisationId={organisationId} />;
        },
      },
    ],
    [history, organisationId, app, space],
  );

  const dataSource = useMemo(() => {
    const totalGlobalCount = countLeaves(get(
      app,
      ['settings', 'provider', 'app', 'gridApp', 'settings'],
      {},
    ));

    const spaceDataSource: DataRow[] = [
      {
        key: uuidV4(),
        icon: <SpaceIcon />,
        name: space.displayName,
        overrides: (
          <OverridesStatus
            totalCount={totalGlobalCount}
            changedCount={((app.spaceSettingsOverriding || {})[space.id] || []).length}
            themeType={ThemeType.Primary}
            iconType={IconType.Location}
          />
        ),
        build: '',
        status: null,
        type: 'space',
      },
    ];

    const devicesDataSource = spaceDevices.map((device) => {
      const dataRow: DataRow = {
        key: device.uuid,
        icon: <DeviceIcon />,
        name: device.deviceName,
        overrides: (
          <OverridesStatus
            totalCount={totalGlobalCount}
            changedCount={((app.deviceSettingsOverriding || {})[device.id] || []).length}
            themeType={ThemeType.Secondary}
            iconType={IconType.Device}
          />
        ),
        build: device.build,
        status: <NetworkStatus status={getNetworkStatus(device)} />,
        type: 'device',
      };

      return dataRow;
    });

    return [...spaceDataSource, ...devicesDataSource];
  }, [space.displayName, spaceDevices, app, space.id]);

  return (
    <Container>
      <Table<DataRow>
        key={space.id}
        rowClassName={(record) => `ant-table-row-${record.type}`}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        showHeader={hasHeader}
        scroll={{ x: 600 }}
      />
    </Container>
  );
};

export default LocationTable;
