export enum PhyhubDeviceStatusEnum {
  OFFLINE = 'Offline',
  /**
   * @deprecated Use `ONLINE` instead
   */
  OK = 'Ok',
  ONLINE = 'Online',
  // TODO: Add new types on the Phyhub side
  FAILING = 'Failing',
}
