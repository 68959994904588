import React, { FC, memo } from 'react';
import { Icon, Popover } from 'antd';
import styled from '@emotion/styled';
import PhyhubDevicesTableSettingsActions from './phyhub-devices-table-settings-actions/phyhub-devices-table-settings-actions.component';

interface PhyhubDevicesSettingsCellProps {
  deviceId: string;
  deviceScreenshotUrl: string | null;
  onDeleteClick: () => void;
}

const PhyhubDevicesSettingsCell: FC<PhyhubDevicesSettingsCellProps> = (props) => {
  const { deviceId, deviceScreenshotUrl } = props;

  return (
    <Wrapper>
      {deviceScreenshotUrl ? (
        <Popover
          key={`${deviceId}-screenshot`}
          content={<ScreenshotImage alt="" src={deviceScreenshotUrl} />}
          overlayStyle={{
            maxWidth: '250px',
            maxHeight: '200px',
          }}
        >
          <WithScreenshotIcon type="eye" />
        </Popover>
      ) : (
        <WithoutScreenShotIcon type="eye-invisible" />
      )}
      <PhyhubDevicesTableSettingsActions
        deviceId={deviceId}
        onDeleteClick={props.onDeleteClick}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const ScreenshotImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const WithScreenshotIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.85);
`;

const WithoutScreenShotIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.45);
`;

export default PhyhubDevicesSettingsCell;
