import * as React from 'react';
import { Typography } from 'antd';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

const { Title } = Typography;

interface HeaderProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  headerTitleTestId?: string;
}

const StyledHeader = styled.header`
  // background: rgb(245, 245, 250);
  // box-shadow: inset 0 -1px 0 0 #eaecf4, 0 2px 24px 0 rgba(0, 0, 0, 0.02);
  // padding: 40px;
  padding: 20px 40px 0 40px;

  .ant-tabs-bar {
     border-bottom: 1px solid rgba(0,0,0,0.06) !important;
  }
`;

const HeaderTitle = styled(Title)`
  font-size: 24px !important;
  margin-bottom: 0 !important;
`;

const titleSuffix = 'Phygrid';
const Header = ({ title, children, className, headerTitleTestId }: HeaderProps) => {
  return (
    <StyledHeader className={className}>
      <Helmet>
        <title>
          {title && typeof title === 'string' ? `${title} - ${titleSuffix}` : titleSuffix}
        </title>
      </Helmet>
      <HeaderTitle data-testid={headerTitleTestId}>{title}</HeaderTitle>
      {children}
    </StyledHeader>
  );
};

export default Header;