import React, { useCallback, useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import Instruction from '../../../../../common/instruction/instruction.component';
import IntelNUCLogo from '../../../../../../assets/images/intel-nuc-logo.svg';
import TizenLogo from '../../../../../../assets/images/tizen-logo.png';
import AndroidLogo from '../../../../../../assets/images/android-logo.png';
import GenericBrowserLogo from '../../../../../../assets/images/cross-browser-logo.svg';
import Win10Logo from '../../../../../../assets/images/win-10-logo.png';
import MacOSLogo from '../../../../../../assets/images/macOS-logo.png';
import GiadaLogo from '../../../../../../assets/images/giada-logo.svg';
import { getGenericBrowserUrl } from '../../../../../../utils/env';

import SetupDeviceUniversalForm, {
  DeviceOrPlatformTypeEnum,
} from './setup-device-universal-form/setup-device-universal-form.component';

type ConnectDeviceUniversal = Omit<
  React.ComponentProps<typeof SetupDeviceUniversalForm>,
  'onSelectedDeviceTypeChange'
>;

export default function SetupDeviceUniversal(props: ConnectDeviceUniversal) {
  const { t } = useTranslation();
  const [selectedDeviceType, setSelectedDeviceType] = useState('');

  // Pre-loading logo
  useEffect(() => {
    [
      IntelNUCLogo,
      TizenLogo,
      Win10Logo,
      MacOSLogo,
      AndroidLogo,
      GenericBrowserLogo,
      GiadaLogo,
    ].forEach((imgSrc) => {
      const img = new Image();
      img.src = imgSrc;
    });
  }, []);

  const instructions: {
    [deviceType: string]: {
      title: string;
      logo: string;
      steps: string[];
    };
  } = useMemo(() => {
    const title = t('instructions');
    const getStepsByKey = (key: string, params?: Record<string, string>) =>
      t(key, { ...params, returnObjects: true }) as string[];

    return {
      [DeviceOrPlatformTypeEnum.INTEL]: {
        title,
        logo: IntelNUCLogo,
        steps: getStepsByKey('intelSetupInstruction'),
      },
      [DeviceOrPlatformTypeEnum.GIADA_DN74]: {
        title,
        logo: GiadaLogo,
        steps: getStepsByKey('giadaDN74SetupInstruction'),
      },
      [DeviceOrPlatformTypeEnum.TIZEN]: {
        title,
        logo: TizenLogo,
        steps: getStepsByKey('tizenSetupInstruction'),
      },
      [DeviceOrPlatformTypeEnum.PHYGRID]: {
        title,
        logo: IntelNUCLogo,
        steps: [],
      },
      // [DeviceOrPlatformTypeEnum.WINDOWS]: {
      //   title,
      //   logo: Win10Logo,
      //   steps: getStepsByKey('windowsSetupInstruction'),
      // },
      // [DeviceOrPlatformTypeEnum.MACOS]: {
      //   title,
      //   logo: MacOSLogo,
      //   steps: getStepsByKey('macOsSetupInstruction'),
      // },
      // [DeviceOrPlatformTypeEnum.ANDROID]: {
      //   title,
      //   logo: AndroidLogo,
      //   steps: getStepsByKey('androidSetupInstruction'),
      // },
      [DeviceOrPlatformTypeEnum.ANDROID_UNMANAGED]: {
        title,
        logo: AndroidLogo,
        steps: getStepsByKey('androidUnmanagedSetupInstruction'),
      },
      [DeviceOrPlatformTypeEnum.BROWSER]: {
        title,
        logo: GenericBrowserLogo,
        steps: getStepsByKey(
          'genericBrowserSetupInstruction',
          { genericBrowserUrl: getGenericBrowserUrl() || '' }
        ),
      },
    };
  }, [t]);

  const onSelectedDeviceTypeChange = useCallback(
    (deviceType: DeviceOrPlatformTypeEnum) => {
      setSelectedDeviceType(deviceType);
    },
    [],
  );

  return (
    <Content>
      <DeviceFormWrapper>
        <SetupDeviceUniversalForm
          {...props}
          onSelectedDeviceTypeChange={onSelectedDeviceTypeChange}
        />
      </DeviceFormWrapper>
      <InstructionWrapper>
        {instructions[selectedDeviceType] && (
          <Instruction {...instructions[selectedDeviceType]} />
        )}
      </InstructionWrapper>
    </Content>
  );
}

const Content = styled(Row)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  background: #ffffff;

  @media (max-width: 940px) {
    flex-direction: column;
    overflow-y: scroll;
  }

  @media (min-width: 940px) {
    flex-direction: row;
    overflow-y: inherit;
  }
`;

const DeviceFormWrapper = styled(Col)`
  flex: 0 0 50%;
  padding: 12px 32px 24px 32px;
  min-height: auto;

  @media (max-width: 940px) {
    padding-bottom: 0px;
  }

  .ant-card-bordered {
    border: none;

    &:hover {
      border: none;
    }
  }

  .ant-card-body {
    background: #ffffff;
    padding: 0px;
  }
`;

const InstructionWrapper = styled(DeviceFormWrapper)`
  flex: 0 0 50%;
  margin: 0px;
  border-radius: 0px;
  padding: 0px;

  @media (max-width: 940px) {
    margin-top: 28px;
  }

  background: #f5f5fa;
`;
