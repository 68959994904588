import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Modal, Tree, Icon, message, Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import UniversalDevice from '../../../../../../store/types/universal-device';
import {
  useDeviceTunnelLinksByDeviceId,
  useUpdateDeviceTunnelLink,
  useCreateDeviceTunnelLink,
} from '../../../../../common/use-device-tunnel-link';
import SchemaForm, { SchemaFormRef } from '../../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import { DeviceTunnelLink } from '../../../../../../store/types/device-tunnel-link';
import { useDeleteDeviceTunnelLink } from '../../../../../common/use-device-tunnel-link';
import useDeleteModal from '../../../../../common/use-delete-modal';
import { ipAddressPattern } from '../../../../../../utils/validation/ip-address';
import { getApiUrl } from '../../../../../../utils/env';

interface TreeNodeNormal {
  title: string | React.ReactNode;
  key: string;
  children?: TreeNodeNormal[];
  [prop: string]: any;
}

interface DeviceTunnelLinkWithChildren extends DeviceTunnelLink {
  children?: DeviceTunnelLinkWithChildren[];
}

interface UniversalDeviceDetailsAssociatedTunnelLinksProps {
  device: UniversalDevice;
  organisationId: string;
}

interface ActionComponentProps {
  deviceTunnelLink: DeviceTunnelLink;
  organizationId: string;
  deviceId: string;
  onEdit: (params: DeviceTunnelLink) => void;
}

const ActionComponent = ({
  deviceTunnelLink,
  organizationId,
  deviceId,
  onEdit,
}: ActionComponentProps) => {
  const { t } = useTranslation();
  const [showDeleteModal] = useDeleteModal();

  const deleteDeviceTunnelLinkQuery = useDeleteDeviceTunnelLink({
    organizationId,
    deviceId,
    deviceTunnelLinkId: deviceTunnelLink.id,
  });

  const handleDelete = useCallback(() => {
    showDeleteModal(
      t('confirmDeleteDeviceTunnelLink'),
      t('areYouSureYouWantToDeleteDeviceTunnelLink', { deviceTunnelLink: deviceTunnelLink.displayName }),
      '',
      async () => {
        try {
          await deleteDeviceTunnelLinkQuery.mutateAsync();
          message.success(`${t('successfullyDeletedDeviceTunnelLink')}`);
        } catch {
          message.error(t('failedToDeleteDeviceTunnelLink'));
        }
      },
    );
  }, [deleteDeviceTunnelLinkQuery, showDeleteModal, t, deviceTunnelLink.displayName]);

  return (
    <Dropdown
      placement="bottomLeft"
      overlay={
        <Menu>
          <Menu.Item key={`${deviceTunnelLink.id}-edit`}>
            <EditButton type="link" icon="edit" size="small" onClick={() => onEdit(deviceTunnelLink)}>
              {t('edit')}
            </EditButton>
          </Menu.Item>
          <Menu.Item key={`${deviceTunnelLink.id}-remove`}>
            <RemoveButton
              type="link"
              icon="delete"
              size="small"
              value={deviceTunnelLink}
              onClick={handleDelete}
            >
              {t('delete')}
            </RemoveButton>
          </Menu.Item>
          <Menu.Item key={`${deviceTunnelLink.id}-link`}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${getApiUrl()}/api/device-tunnel-link/connect/${deviceTunnelLink.id}`}
            >
              <EditButton type="link" icon="link" size="small">
                {t('connect')}
              </EditButton>
            </a>
          </Menu.Item>
        </Menu>
      }
    >
      <Icon type="small-dash" />
    </Dropdown>
  );
};

const UniversalDeviceDetailsAssociatedTunnelLinks = ({
  device,
  organisationId,
}: UniversalDeviceDetailsAssociatedTunnelLinksProps) => {
  const [showForm, setShowForm] = useState(false);
  const formElement = useRef<SchemaFormRef>(null);
  const deviceId = device.parentDevice && device.parentDevice.id ? device.parentDevice.id : device.id;
  const deviceTunnelLinksState = useDeviceTunnelLinksByDeviceId(deviceId);

  const deviceTunnelLinkUpdate = useUpdateDeviceTunnelLink();
  const deviceTunnelLinkCreate = useCreateDeviceTunnelLink();

  const { t } = useTranslation();

  const handleCreateAndUpdate = useCallback(async (deviceTunnelLink: DeviceTunnelLink) => {
    const requiredInfo = {
      organizationId: organisationId,
      deviceId,
    };

    if (deviceTunnelLink.id) {
      await deviceTunnelLinkUpdate.mutateAsync({
        ...requiredInfo,
        ...deviceTunnelLink,
        ...(deviceTunnelLink.parentTunnelLink ? {
          parentTunnelLink: deviceTunnelLink.parentTunnelLink
        } : {
          parentTunnelLink: undefined
        })
      });
    } else {
      await deviceTunnelLinkCreate.mutateAsync({
        ...requiredInfo,
        ...deviceTunnelLink,
        ...(deviceTunnelLink.parentTunnelLink ? {
          parentTunnelLink: deviceTunnelLink.parentTunnelLink
        } : {
          parentTunnelLink: undefined
        })
      });
    } 
    setShowForm(false);
  }, [deviceTunnelLinkUpdate, deviceTunnelLinkCreate, organisationId, deviceId]);

  const [formData, , , , handleFormChange, handleFormSubmit] = useSchemaForm<
    any
  >(handleCreateAndUpdate, t('deviceTunnelLinkSaved'), t('errorSavingDeviceTunnelLink'));

  useEffect(() => {
    if (!showForm) {
      handleFormChange(undefined);
    }
  }, [showForm, handleFormChange])

  const handleSubmit = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, []);

  const handleFormClose = useCallback(() => {
    setShowForm(false);
  }, []);

  const handleFormOpen = useCallback(() => {
    setShowForm(true);
  }, []);

  const handleEditClick = useCallback((values: DeviceTunnelLink) => {
    handleFormChange(values);
    setShowForm(true);
  }, [handleFormChange]);

  const { deviceTunnelLinks, defualtExpandedKeys } = useMemo(() => {
    if (deviceTunnelLinksState.data) {
      return {
        deviceTunnelLinks: deviceTunnelLinksState.data,
        defualtExpandedKeys: deviceTunnelLinksState.data.map((d) => d.id),
      }
    }

    return {
      deviceTunnelLinks: [],
      defualtExpandedKeys: [],
    };
  }, [deviceTunnelLinksState]);

  const formSchema: any = useMemo(() => {
    const parentOptions = formData && formData.id
      ? deviceTunnelLinks.filter(tunnelLink => tunnelLink.id !== formData.id)
      : deviceTunnelLinks;

    const parentOptionEnums = parentOptions.map(tunnelLink => tunnelLink.id);
    const parentOptionEnumNames = parentOptions.map(tunnelLink => `${tunnelLink.displayName} (${tunnelLink.ip})`);
    const hasOptions = parentOptions.length > 0;

    const schema = {
      type: 'object',
      properties: {
        displayName: {
          title: t('displayName'),
          type: 'string',
          minLength: 1,
        },
        ip: {
          title: t('ipAddress'),
          type: 'string',
          minLength: 1,
          pattern: ipAddressPattern,
        },
        port: {
          title: t('port'),
          type: 'number',
          default: 80,
          min: 1,
        },
        parentTunnelLink: {
          title: t('parentTunnelLink'),
          type: 'string',
          enum: hasOptions ? parentOptionEnums : [""],
          enumNames: hasOptions ? parentOptionEnumNames : [""],
        },
      },
      required: ['displayName', 'ip', 'port'],
    };

    return schema;
  }, [ t, deviceTunnelLinks, formData ]);

  const treeData = useMemo(() => {
    const dataMap = new Map(deviceTunnelLinks.map(item => [
      item.id,
      { ...item, children: [] as DeviceTunnelLinkWithChildren[] }
    ]));
    const rootNodes: DeviceTunnelLinkWithChildren[] = [];

    deviceTunnelLinks.forEach(item => {
      const node = dataMap.get(item.id) as DeviceTunnelLinkWithChildren;
      if (item.parentTunnelLink) {
        const parent = dataMap.get(item.parentTunnelLink) as DeviceTunnelLinkWithChildren;
        if (parent) {
          if (!parent.children) {
            parent.children = [];
          }

          parent.children.push(node);
        } else {
          rootNodes.push(node);
        }
      } else {
        rootNodes.push(node);
      }
    });

    const mapDataToTreeNode = (data: DeviceTunnelLinkWithChildren[]): any[] => {
      return data.map(item => ({
        title: (
          <Item>
            <LinkLabel>{`${item.displayName} (${item.ip}:${item.port})`}</LinkLabel>
            <ActionComponent
              deviceTunnelLink={item as any}
              onEdit={handleEditClick}
              organizationId={organisationId}
              deviceId={deviceId}
            />
          </Item>
        ),
        key: item.id,
        selectable: false,
        children: item.children && item.children.length ? mapDataToTreeNode(item.children) : [],
      }));
    };

    return mapDataToTreeNode(rootNodes);
  }, [deviceTunnelLinks, deviceId, handleEditClick, organisationId]);

  return (
    <Container>
      <Title>{t('tunnelLinks')}</Title>
      {deviceTunnelLinksState.isLoading && <p>Loading</p>}
      {deviceTunnelLinksState.isError && <p>Error</p>}
      {deviceTunnelLinksState.isSuccess && (
        <TunnelLinkTree
          showLine
          defaultExpandedKeys={defualtExpandedKeys}
          onSelect={() => null}
          treeData={treeData}
        />
      )}
      <StyledButton onClick={handleFormOpen} icon={'plus'}>
        {t('addTunnelLink')}
      </StyledButton>
      <Modal
        destroyOnClose
        onOk={handleSubmit}
        title={t('deviceTunnelLink')}
        visible={showForm}
        onCancel={handleFormClose}
      >
        <SchemaForm
          schema={formSchema}
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
          ref={formElement}
          data={formData}
          liveValidate={false}
        />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 54px;
  padding: 0px 24px 0px 16px;
  background: #F5F5F5;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #dedfee
`;

const TunnelLinkTree = styled(Tree)`
  padding: 24px 32px 8px 32px;

  & .ant-tree-treenode {
    cursor: pointer;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EditButton = styled(Button)`
  color: #689bc4;
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: #689bc4;
  }
` as any;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  text-transform: capitalize;
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

const LinkLabel = styled.p`
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border: none;
  margin-bottom: 12px;
  box-shadow: none !important;
` as any;

export default UniversalDeviceDetailsAssociatedTunnelLinks;
