import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import {usePhyhubService} from "../../../app/common/phyhub-service-provider/phyhub-service.provider";

export interface PhyhubDevicesDeleteMutationParams {
  tenantId: string;
  deviceId: string;
}

const usePhyhubDevicesDelete = () => {
  const phyhubService = usePhyhubService();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, deviceId }: PhyhubDevicesDeleteMutationParams) =>
      phyhubService.deleteDevice({
        deviceId,
        tenantId,
      }),
    {
      onSuccess: async (_, params) => {
        await queryClient.invalidateQueries([
          phyhubDevicesQueryKeys.queryPrefix(),
          'list',
            'tenantId',
            params.tenantId,
        ]);
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default usePhyhubDevicesDelete;
