import React, { useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../../../common/pagination';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../../common/use-pagination-query-params/use-pagination-query-params';
import useTagsList from '../../../../../store/hooks/content-tags/use-tags-list';
import { ColumnProps } from 'antd/lib/table';
import { Icon } from '../../../../common/schema-form/common';
import styled from '@emotion/styled';
import Overlay from '../../../../common/overlay';
import { Button, Card, Col, Row, Typography } from 'antd';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import moment from 'moment/moment';
import OrganisationMediaTagsListActionsComponent from './organisation-media-tags-list-actions.component';
import ContentTag from '../../../../../store/types/content-tag';
import Header from '../../../../common/app-layout/header';
import usePermissions from '../../../../../utils/auth/use-permissions';
import permissionKeys from '../../../../../utils/auth/permissions';

const { Title } = Typography;

interface OrganizationMediaTagsProps
  extends RouteComponentProps<{ organisationId: string }> {}

interface OrganizationMediaTagsListPaginationParams extends PaginationQueryParams {}

const formatDate = (dateToFormat: string | undefined, format: string = 'DD/MM/YYYY') => {
  if (dateToFormat === undefined || !moment(dateToFormat).isValid()) {
    return 'N/A';
  }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.tz(dateToFormat, userTimezone).format(format);
};

const OrganizationMediaTagsListComponent = (props: OrganizationMediaTagsProps) => {
  const { match } = props;
  const { t } = useTranslation();
  const { organisationId } = match.params;

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const { isAllowed } = usePermissions(organisationId);

  const { createTag, updateTag, deleteTag } = useMemo(
    () => ({
      createTag: isAllowed(permissionKeys.contentTags.create),
      updateTag: isAllowed(permissionKeys.contentTags.update),
      deleteTag: isAllowed(permissionKeys.contentTags.remove),
    }),
    [isAllowed],
  );

  const [paginationQueryParams] = usePaginationQueryParams<
    OrganizationMediaTagsListPaginationParams
  >({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const {
    data: tagsData,
    isLoading: isTagsLoading,
    isFetching: isTagsFetching,
    isError: isTagsListError,
  } = useTagsList(
    organisationId,
    paginationQueryParams.limit,
    paginationQueryParams.page,
  );

  const columns = useMemo<ColumnProps<ContentTag>[]>(() => {
    return [
      {
        title: t('contents.name'),
        key: 'organization-media-tag-name',
        width: '30%',
        render: (_, record) => <CapitalizeText>{record.name}</CapitalizeText>,
      },
      {
        title: t('contents.description'),
        key: 'organization-media-tag-description',
        width: '30%',
        render: (_, record) => record.description,
      },
      {
        title: t('contents.slug'),
        key: 'organization-media-tag-slug',
        width: '20%',
        render: (_, record) => <LowerCaseText>{record.slug}</LowerCaseText>,
      },
      {
        title: t('contents.updatedAt'),
        key: 'organization-media-tag-updated-at',
        width: '15%',
        render: (_, record) => (
          <MutedText>{formatDate(record.updatedAt, 'DD-MM-YYYY hh:mm')}</MutedText>
        ),
      },
      {
        title: <Icon type="setting" />,
        key: 'organization-media-tag-actions',
        width: '5%',
        align: 'center',
        render: (_, record) =>
          updateTag && (
            <OrganisationMediaTagsListActionsComponent
              organizationId={organisationId}
              tag={record}
            />
          ),
      },
    ];
  }, [t, organisationId]);

  return (
    <>
      <Header
        tabTitle={t('contents.tags')}
        title={t('contents.tags')}
        icon={<Icon type="tags-o" color="white" />}
      />
      <Overlay>
        {isTagsListError && (
          <Row>
            <ErrorCard>
              <ErrorIcon type="frown" />
              <Message level={1}>{t('somethingWentWrong')}</Message>
            </ErrorCard>
          </Row>
        )}
        {!isTagsListError && (
          <Row>
            {createTag && (
              <Col>
                <Link to={`${match.url}/create`}>
                  <ButtonWrap>
                    <Button icon="plus" type="primary" size="large">
                      {t('contents.createTag')}
                    </Button>
                  </ButtonWrap>
                </Link>
              </Col>
            )}

            <Col>
              <PaginatedListTable<ContentTag>
                data={tagsData!}
                columns={columns}
                isDataLoading={isTagsLoading || isTagsFetching}
              />
            </Col>
          </Row>
        )}
      </Overlay>
    </>
  );
};

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

const LowerCaseText = styled.span`
  text-transform: lowercase;
`;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
`;

const ErrorIcon = styled(Icon)`
  font-size: 64px;
  margin-bottom: 16px;
`;

const Message = styled(Title)`
  margin-top: 12px;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 40px;

  @media screen and (max-width: 991px) {
    margin-right: 20px;
  }
`;

const MutedText = styled.p`
  color: #6c757d !important;
`;

export default OrganizationMediaTagsListComponent;
