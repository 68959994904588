import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { PhyhubDeviceListParams } from './types/phyhub-device-list-params.interface';
import { PhyhubDeviceParams } from './types/phyhub-device-get-params.interface';
import { PaginatedResponse } from './types/paginated-response.interface';
import { PhyhubDevice } from './types/phyhub-device.interface';
import { PhyhubDeviceGetReportParams } from './types/phyhub-device-get-report-params.interface';
import { PhyhubDeviceBreakdownReport } from './types/phyhub-device-breakdown-report.interface';
import { PhyhubDeviceStatusesBreakdown } from './types/phyhub-device-statuses-breakdown.interface';
import { PhyhubDeviceTypeStatusBreakdown } from './types/phyhub-device-type-status-breakdown.interface';
import { PhyhubDeviceTypeEnum } from './enums/phyhub-device-type.enum';
import { PhyhubDeviceStatusEnum } from './enums/phyhub-device-status.enum';
import { PhyhubDeviceUpdatePayload } from './types/phyhub-device-update-body.inteface';

export class PhyhubService {
  private readonly apiClient: AxiosInstance;

  constructor(baseUrl: string) {
    this.apiClient = axios.create({
      baseURL: baseUrl,
    });
  }

  public async getDevice(params: PhyhubDeviceParams): Promise<PhyhubDevice> {
    const { deviceId, tenantId } = params;

    const response = await this.apiClient.get(
      `/api/v1/admin/tenant/${tenantId}/devices/${deviceId}`,
    );

    return response.data.data;
  }

  public getDevicesReport(
    params: PhyhubDeviceGetReportParams,
  ): Promise<PhyhubDeviceBreakdownReport> {
    console.log({ getDevicesReportParams: params });

    // TODO: Implement real data requesting logic
    const breakdownByType: PhyhubDeviceTypeStatusBreakdown[] = [
      {
        type: PhyhubDeviceTypeEnum.GENERAL_BROWSER,
        total: 123,
        statuses: [
          { type: PhyhubDeviceStatusEnum.ONLINE, count: 96 },
          { type: PhyhubDeviceStatusEnum.FAILING, count: 2 },
          { type: PhyhubDeviceStatusEnum.OFFLINE, count: 25 },
        ],
      },
      {
        type: PhyhubDeviceTypeEnum.PHY_OS,
        total: 10,
        statuses: [
          { type: PhyhubDeviceStatusEnum.ONLINE, count: 4 },
          { type: PhyhubDeviceStatusEnum.FAILING, count: 2 },
          { type: PhyhubDeviceStatusEnum.OFFLINE, count: 4 },
        ],
      },
      {
        type: PhyhubDeviceTypeEnum.GRID_OS,
        total: 15,
        statuses: [
          { type: PhyhubDeviceStatusEnum.ONLINE, count: 12 },
          { type: PhyhubDeviceStatusEnum.FAILING, count: 1 },
          { type: PhyhubDeviceStatusEnum.OFFLINE, count: 2 },
        ],
      },
    ];

    const summary: PhyhubDeviceStatusesBreakdown = {
      total: 148,
      statuses: [
        { type: PhyhubDeviceStatusEnum.ONLINE, count: 112 },
        { type: PhyhubDeviceStatusEnum.FAILING, count: 5 },
        { type: PhyhubDeviceStatusEnum.OFFLINE, count: 31 },
      ],
    };

    return Promise.resolve({
      summary,
      byType: breakdownByType,
    });
  }

  public async deleteDevice(params: PhyhubDeviceParams): Promise<void> {
    const { deviceId, tenantId } = params;

    await this.apiClient.delete(`/api/v1/admin/tenant/${tenantId}/devices/${deviceId}`);
  }

  public async listDevices(
    params: PhyhubDeviceListParams,
  ): Promise<PaginatedResponse<PhyhubDevice>> {
    const { limit, page, tenantId, filters } = params;

    const filtersQueryString = filters ? qs.stringify({ filters }, { arrayFormat: 'repeat', encode: false }) : undefined;
    const paginationQueryString = qs.stringify({ page, limit });
    const queryString = [paginationQueryString, filtersQueryString].filter(value => value != null).join('&');

    const response = await this.apiClient.get(
      `/api/v1/admin/tenant/${tenantId}/devices?${queryString}`,
    );

    return response.data.data;
  }

  public async updateDevice(
    params: PhyhubDeviceParams,
    body: PhyhubDeviceUpdatePayload,
  ): Promise<PhyhubDevice> {
    const { deviceId, tenantId } = params;

    const response = await this.apiClient.put(
      `/api/v1/admin/tenant/${tenantId}/devices/${deviceId}`,
      body,
    );

    return response.data.data;
  }
}
