import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Edit } from 'react-feather';
import Overlay from '../../../common/overlay';
import { Subheader } from '../../../common/app-layout/header';
import { SortCriteria, SortOrderType } from '../../../../store/types/sort';
import usePaginationQueryParams from '../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../common/use-pagination-query-params/use-pagination-query-params';
import usePlansList from '../../../../store/hooks/plans/use-plans-list';
import LocalizedField from '../../../../store/types/localized-field';
import PaginatedListTable from '../../../common/paginated-list/paginatied-list-table';
import PaginatedListSearch from '../../../common/paginated-list/paginated-list-search';
import { usePagination } from '../../../common/pagination';
import Plan from '../../../../store/types/plan';
import { Icon } from '../../../common/schema-form/common';

const formatLocalizedField = (data: LocalizedField | undefined, defaultStr = 'N/A') => {
  return data && data.en ? data.en : defaultStr;
};

interface PlansListPaginationParams extends PaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrderType;
}

interface PlanListProps extends RouteComponentProps {}

const PlansList = (props: PlanListProps) => {
  const { match } = props;

  const { t } = useTranslation();

  const defaultSortField = 'sku';
  const defaultSortOrder = 'asc';

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams<PlansListPaginationParams>({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
  });

  const {
    data: plansData,
    isLoading: isPlansLoading,
    isFetching: isPlansFetching,
  } = usePlansList(
    paginationQueryParams.page,
    paginationQueryParams.limit,
    paginationQueryParams.sortField,
    paginationQueryParams.sortOrder,
    paginationQueryParams.search,
  );

  const columns = useMemo<ColumnProps<Plan>[]>(() => {
    return [
      {
        title: t('plans.list.type'),
        key: 'plan-type',
        width: '10%',
        render: (_, record) => <CapitalizeText>{record.type}</CapitalizeText>,
      },
      {
        title: t('plans.list.sku'),
        key: 'plan-sku',
        width: '20%',
        render: (_, record) => record.sku,
      },
      {
        title: t('plans.list.name'),
        key: 'plan-name',
        width: '35%',
        render: (_, record) => (
          <Link to={`${match.path}/${record.id}`}>
            {formatLocalizedField(record.name)}
          </Link>
        ),
      },
      {
        title: t('plans.list.availability'),
        key: 'plan-availability',
        width: '15%',
        render: (_, record) => <CapitalizeText>{record.availability}</CapitalizeText>,
      },
      {
        title: t('plans.list.isDefault'),
        key: 'plan-isDefault',
        width: '15',
        render: (_, record) => (record.isDefault ? 'Yes' : 'No'),
      },
      {
        title: <Icon type="setting" />,
        key: 'plans-settings',
        width: '10%',
        align: 'center',
        render: (_, record) => {
          return (
            <Link to={`${match.path}/${record.id}`}>
              <Icon component={() => <Edit />} />
            </Link>
          );
        },
      },
    ];
  }, [t, match.path]);

  const sortCriteria = useMemo<SortCriteria[]>(() => {
    return [
      {
        id: 'type',
        label: t('plans.list.type'),
        width: '15%',
        type: 'string',
      },
      {
        id: 'sku',
        label: t('plans.list.sku'),
        width: '15%',
        type: 'string',
      },
      {
        id: 'name',
        label: t('plans.list.name'),
        width: '40%',
        type: 'string',
      },
      {
        id: 'isDefault',
        label: t('plans.list.isDefault'),
        width: '15%',
        type: 'string',
      },
    ];
  }, [t]);

  return (
    <>
      <Overlay>
        <Subheader
          variant="dropshadow"
          components={[
            <PaginatedListSearch
              key="plans-search-bar"
              searchPlaceholder={t('plans.list.searchPlaceholder')}
              sortCriteria={sortCriteria}
              defaultSortField={defaultSortField}
              defaultSortOrder={defaultSortOrder}
            />,
          ]}
        />
        <ButtonWrap>
          <Link to={`${match.url}/add`}>
            <Button icon="plus" type="primary" size="large">
              {t('plans.list.addPlan')}
            </Button>
          </Link>
        </ButtonWrap>
        <PaginatedListTable<Plan>
          data={plansData!}
          columns={columns}
          isDataLoading={isPlansLoading || isPlansFetching}
        />
      </Overlay>
    </>
  );
};

export const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 40px;

  @media screen and (max-width: 991px) {
    margin-right: 20px;
  }
`;

export default PlansList;
