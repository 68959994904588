import {
  Avatar,
  Tag,
  Button,
  Typography,
  List,
  Popconfirm,
  Divider,
  Dropdown,
  Menu,
  Modal,
  message,
  Icon,
} from 'antd';
import dayjs, { ConfigType } from 'dayjs';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { History } from 'history';
import Browser from '../../../../store/types/browser';
import Environment from '../../../../store/types/environment';
import Space from '../../../../store/types/organisation-space';
import safeRelativeTime from '../../../../utils/date/safe-relative-time';
import BrowserStatusEnum from '../../../../store/types/browser-status.enum';
import OrganisationApp from '../../../../store/types/organisation-app';

const { Text } = Typography;

/* eslint-disable */
const tagsColors: { [tagKey: string]: string } = {
  environment: 'green',
  provisioned: 'green',
  unprovisioned: 'red',
  spaces: 'darkblue',
};
/* eslint-enable */

interface BrowserListItemProps {
  browser: Browser;
  spaces?: {
    [spaceId: string]: Space;
  };
  apps?: {
    [organisationName: string]: OrganisationApp[];
  };
  environments?: {
    [organizationId: string]: {
      [environmentName: string]: Environment;
    };
  };
  canDelete: boolean;
  canUpdate: boolean;
  onDelete: (browserId: string) => void;
  onUpdate: (browserId: string) => void;
  onConnect: (browserId: string) => void;
  moveBrowser?: (params: { appId: string; browserId: string }) => Promise<void>;
  history?: History<any>;
}

const backgroundStatusColor: { [status: string]: string } = {
  [BrowserStatusEnum.OK]: '#f0fcf3',
  [BrowserStatusEnum.OFFLINE]: '#fff1f0',
};

const Title = styled.div`
  font-weight: bold;
  padding-top: 9px;
`;

const ItemContainer = styled(List.Item)<{ status: BrowserStatusEnum }>`
  padding: 12px 24px;
  background-color: ${({ status }) =>
    backgroundStatusColor[status] || backgroundStatusColor[BrowserStatusEnum.OFFLINE]};

  &:first-of-type {
    border-radius: 4px 4px 0px 0px;
  }

  &:last-of-type {
    border-radius: 0px 0px 4px 4px;
  }
`;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
  }
` as any;

const ActionButton = styled(Button)`
  color: #689bc4;
  &:hover,
  &:active,
  &:focus {
    color: #689bc4;
  }
` as any;

const ChevronDown = styled(Icon)`
  margin-top: 4px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`;

const LinkTag = styled(Tag)`
  margin: 4px 0;

  @media only screen and (max-width: 576px) {
    white-space: pre-wrap;
  }
`;

const InfoRow = styled.div`
  padding: 4px 0px 0px 0px;
  flex-direction: row;
`;

const Label = styled(Text)`
  padding-top: 5px;

  @media only screen and (max-width: 576px) {
    white-space: pre-wrap;
    word-break: break-all;
  }
`;

const BrowserListItem = ({
  apps = {},
  browser,
  spaces,
  environments,
  onDelete,
  onUpdate,
  onConnect,
  canDelete,
  canUpdate,
  moveBrowser,
  history,
}: BrowserListItemProps) => {
  const { t } = useTranslation();
  const lastSeen = browser.lastSeenAt ? dayjs(browser.lastSeenAt) : null;
  const baseTime: ConfigType = new Date();
  const relativeLastSeenTime = useMemo(
    () => (lastSeen ? safeRelativeTime(lastSeen, baseTime) : 'Unknown'),
    [lastSeen, baseTime],
  );

  const provisionLabel = useMemo(
    () => (browser.isProvisioned ? 'Provisioned' : 'Unprovisioned'),
    [browser],
  );
  const environmentLabel = useMemo(() => {
    const env =
      environments && environments[browser.organizationId]
        ? environments[browser.organizationId][browser.env]
        : { displayName: browser.env.toUpperCase() };
    return env ? env.displayName : t('unknown');
  }, [environments, browser.organizationId, browser.env, t]);

  const browserSpaces = browser.spaces || [];

  const renderBrowserDetails = useMemo(() => {
    return (
      <InfoContainer>
        {browser.uuid && <Label type="secondary">UUID: {browser.uuid}</Label>}
        {browser.url && <Label type="secondary">URL: {browser.url}</Label>}
        {browser.notes && (
          <Label type="secondary">{`${t('notes')}: ${browser.notes}`}</Label>
        )}
        {!!browserSpaces.length && spaces && (
          <InfoRow>
            {t('spaces')}:{' '}
            {browserSpaces.map((spaceId, browserIndex) => (
              <React.Fragment key={`browser_space_${spaceId}`}>
                {!!browserIndex && <Divider type="vertical" />}
                <LinkTag color={tagsColors.spaces}>
                  {spaces[spaceId] ? spaces[spaceId].displayName : ''}
                </LinkTag>
              </React.Fragment>
            ))}
          </InfoRow>
        )}
        <InfoRow>
          {environmentLabel && (
            <LinkTag color={tagsColors.environment}>{environmentLabel}</LinkTag>
          )}
          <Divider type="vertical" />
          <LinkTag color={tagsColors[provisionLabel.toLowerCase()]}>
            {provisionLabel}
          </LinkTag>
        </InfoRow>
        <InfoRow>{`${t('lastSeen')}: ${relativeLastSeenTime}`}</InfoRow>
      </InfoContainer>
    );
  }, [
    browser,
    environmentLabel,
    provisionLabel,
    t,
    browserSpaces,
    relativeLastSeenTime,
    spaces,
  ]);

  const handleMove = useCallback(
    async (app: OrganisationApp) => {
      if (!moveBrowser) return;

      try {
        await moveBrowser({ appId: app.id, browserId: browser.id });
        message.success(t('browserMoved'));
        if (history) {
          history.push(
            `/organisations/${app.organizationId}/apps/installations/${app.id}/browsers`,
          );
        }
      } catch (e) {
        message.error(t('browserMoveOperationFailed'));
      }
    },
    [browser.id, history, moveBrowser, t],
  );

  const handleMovePress = useCallback(
    (app: OrganisationApp) => {
      if (browser) {
        Modal.confirm({
          title: t('confirmAction'),
          content: (
            <span>
              {t('areYouSureYouWantToMoveTheScreen')}{' '}
              <strong>{browser.displayName}</strong> {t('to')}{' '}
              <strong>{app.appName}</strong>?
            </span>
          ),
          width: '30%',
          onOk: () => {
            return handleMove(app);
          },
          onCancel: () => {},
        });
      }
    },
    [browser, handleMove, t],
  );

  const renderAppsMenu = useMemo(() => {
    const organisationNames = Object.keys(apps || {});
    if (!organisationNames.length) {
      return null;
    }

    const renderAppMenuOption = (app: OrganisationApp) => {
      return (
        <Menu.Item onClick={() => handleMovePress(app)} key={app.id}>
          {app.displayName}
        </Menu.Item>
      );
    };

    if (organisationNames.length === 1) {
      return (
        <Menu>
          {apps[organisationNames[0]]
            .filter((app) => app.id !== browser.appId)
            .map((app) => renderAppMenuOption(app))}
        </Menu>
      );
    }

    return (
      <Menu>
        {organisationNames.map((organisationName) => {
          const organisationApps = apps[organisationName].filter(
            (app) => app.id !== browser.appId,
          );

          return organisationApps.length ? (
            <Menu.SubMenu title={organisationName} key={organisationName}>
              {organisationApps.map((app) => renderAppMenuOption(app))}
            </Menu.SubMenu>
          ) : null;
        })}
      </Menu>
    );
  }, [apps, browser.appId, handleMovePress]);

  const actionButtons = useMemo(() => {
    const buttons: any = [];

    if (canDelete) {
      buttons.push(
        <Popconfirm
          title={t('areYouSureYouWantToDelete')}
          onConfirm={() => onDelete(browser.id)}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <RemoveButton type="link" icon="delete" size="small">
            {t('delete')}
          </RemoveButton>
        </Popconfirm>,
      );
    }

    if (canUpdate) {
      buttons.push(
        <ActionButton
          type="link"
          icon="edit"
          size="small"
          onClick={() => onUpdate(browser.id)}
        >
          {t('edit')}
        </ActionButton>,
      );
      buttons.push(
        <ActionButton
          type="link"
          icon="api"
          size="small"
          onClick={() => onConnect(browser.id)}
        >
          {t('connect')}
        </ActionButton>,
      );
      buttons.push(
        <Dropdown trigger={['click']} overlay={renderAppsMenu}>
          <ActionButton type="link" size="small">
            {t('move')}
            <ChevronDown type="down" />
          </ActionButton>
        </Dropdown>,
      );
    }

    return buttons;
  }, [
    canDelete,
    canUpdate,
    t,
    onDelete,
    browser.id,
    renderAppsMenu,
    onUpdate,
    onConnect,
  ]);

  return (
    <ItemContainer status={browser.status} actions={[...actionButtons]}>
      <List.Item.Meta
        title={
          <Link
            title="test"
            to={`/organisations/${browser.organizationId}/apps/installations/${browser.appId}/browsers`}
          >
            <Title>{browser.displayName}</Title>
          </Link>
        }
        avatar={<Avatar icon="global" />}
        description={renderBrowserDetails}
      />
    </ItemContainer>
  );
};

export default BrowserListItem;
