import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header, { Subheader } from '../../../common/app-layout/header';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import usePermissions from '../../../../utils/auth/use-permissions';
import { Icon } from '../../../common/schema-form/common';
import OrganisationUserRolesListContainer from '../user-roles-list/user-roles-list.container';
import UsersList from './users-list/users-list.component';
import OrganizationUserCreateFormComponent from './users-list/user-forms/organization-user-create-form.component';
import OrganizationUserEditFormComponent from './users-list/user-forms/organization-user-edit-form.component';

type UserManagementProps = RouteComponentProps<{ organisationId: string }>;

const UserManagement = ({ match, history, location }: UserManagementProps) => {
  const { organisationId } = match.params;

  const { t, i18n } = useTranslation();

  const { isAllowed } = usePermissions(match.params.organisationId);

  const getOrganisationPermissionPath = useCallback(
    (permissionKey: string) => getPermissionPath(organisationId, permissionKey),
    [organisationId],
  );

  const onTabChange = useCallback(
    (key: string) => {
      history.push(key);
    },
    [history],
  );

  return (
    <ErrorBoundary>
      <Header
        tabTitle={t('userManagement.userManagementHeading')}
        title={t('userManagement.userManagementHeading')}
        icon={<Icon type="user" size={20} color="#fff" />}
        isBordered={false}
      />
      <SubheaderStyled
        components={[
          <TabBar
            key="userManagement"
            activeKey={location.pathname}
            onChange={onTabChange}
            lang={i18n.language}
          >
            {isAllowed(permissionKeys.users.viewAll) && (
              <Tabs.TabPane
                key={`${match.url}/users`}
                tab={
                  <span>
                    <Icon type="user" />
                    {t('users')}
                  </span>
                }
              />
            )}
            {isAllowed(permissionKeys.userRoles.viewAll) && (
              <Tabs.TabPane
                key={`${match.url}/user-roles`}
                tab={
                  <span>
                    <Icon type="lock" />
                    {t('userRoles')}
                  </span>
                }
              />
            )}
          </TabBar>,
        ]}
      />
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/users`} />}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getOrganisationPermissionPath(permissionKeys.userRoles.viewAll)}
          path={`${match.path}/user-roles`}
          component={OrganisationUserRolesListContainer}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getOrganisationPermissionPath(permissionKeys.users.viewAll)}
          path={`${match.path}/users`}
          component={UsersList}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getOrganisationPermissionPath(permissionKeys.users.create)}
          path={`${match.path}/users/create`}
          component={OrganizationUserCreateFormComponent}
        />
        <ProtectedRouteContainer
          exact
          permissionPath={getPermissionPath(organisationId, permissionKeys.alerts.update)}
          path={`${match.path}/users/:userId`}
          component={OrganizationUserEditFormComponent}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const SubheaderStyled = styled(Subheader)`
  min-height: unset !important;
`;

export default UserManagement;
