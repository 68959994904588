import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import Header, { Subheader } from '../../../common/app-layout/header';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import { Icon } from '../../../common/schema-form/common';
import sparkleIcon from '../../../../assets/icons/sparkle.svg';
import StoreAIAssistants from './store-ai-assistants/store-ai-assistants.component';
import StoreAIPlayground from './store-ai-playground';
import permissionKeys, { getPermissionPath } from '../../../../utils/auth/permissions';
import usePermissions from '../../../../utils/auth/use-permissions';

enum StoreAIMenuKeysEnum {
  ASSISTANTS = 'assistants',
  PLAYGROUND = 'playground',
}

const StoreAIMenuTabs = {
  [StoreAIMenuKeysEnum.ASSISTANTS]: {
    path: '/assistants',
  },
  [StoreAIMenuKeysEnum.PLAYGROUND]: {
    path: '/playground',
  },
};

interface StoreAIProps extends RouteComponentProps<{ organisationId: string }> {}

const StoreAI = (props: StoreAIProps) => {
  const { match, history, location } = props;

  const { organisationId: tenantId } = match.params;

  const [tabKey, setTabKey] = useState<StoreAIMenuKeysEnum>(
    StoreAIMenuKeysEnum.ASSISTANTS,
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const segment = location.pathname.replace(match.url, '').split('/')[1];

    const segmentValue = Object.values(StoreAIMenuKeysEnum)
      .map(String)
      .includes(segment)
      ? (segment as StoreAIMenuKeysEnum)
      : StoreAIMenuKeysEnum.ASSISTANTS;

    setTabKey(segmentValue);
  }, [match, location]);

  const handleTabChange = useCallback(
    (key: string) => {
      const menuKey = key as StoreAIMenuKeysEnum;
      setTabKey(menuKey);

      const path = StoreAIMenuTabs[menuKey].path;
      history.push(`${match.url}${path}`);
    },
    [history, match],
  );

  const { isAllowed } = usePermissions(tenantId);

  const hasPlaygroundAccess = useMemo(
    (): boolean => isAllowed(permissionKeys.storeAIAssistants.run),
    [isAllowed],
  );

  return (
    <>
      <Header
        tabTitle={t('storeAI.title')}
        title={t('storeAI.title')}
        icon={<Icon src={sparkleIcon} size={20} />}
        isBordered={false}
      />
      <SubheaderStyled
        components={[
          <TabBar
            key="tab-menu"
            onChange={handleTabChange}
            activeKey={tabKey}
            lang={i18n.language}
          >
            <Tabs.TabPane
              tab={
                <span>
                  <Icon type="robot" />
                  {t('storeAI.assistants')}
                </span>
              }
              key={StoreAIMenuKeysEnum.ASSISTANTS}
            />
            {hasPlaygroundAccess && (
              <Tabs.TabPane
                tab={
                  <span>
                    <Icon type="message" />
                    {t('storeAI.playground')}
                  </span>
                }
                key={StoreAIMenuKeysEnum.PLAYGROUND}
              />
            )}
          </TabBar>,
        ]}
      />
      <StoreAIContainer>
        <ErrorBoundary>
          <Switch>
            <ProtectedRouteContainer
              permissionPath={getPermissionPath(
                tenantId,
                permissionKeys.storeAIAssistants.viewAll,
              )}
              path={`${match.path}/assistants`}
              component={StoreAIAssistants}
            />
            {hasPlaygroundAccess && (
              <ProtectedRouteContainer
                exact
                permissionPath={getPermissionPath(
                  tenantId,
                  permissionKeys.storeAIAssistants.run,
                )}
                path={`${match.path}/playground`}
                component={StoreAIPlayground}
              />
            )}
            <ProtectedRouteContainer
              path={match.path}
              permissionPath={getPermissionPath(
                tenantId,
                permissionKeys.storeAIAssistants.viewAll,
              )}
              render={() => <Redirect to={`${match.url}/assistants`} />}
            />
          </Switch>
        </ErrorBoundary>
      </StoreAIContainer>
    </>
  );
};

const StoreAIContainer = styled.div`
  position: relative;
  padding: 24px 0px 12px 0px;
  height: 100%;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const SubheaderStyled = styled(Subheader)`
  display: block;
  min-height: unset !important;
  padding-bottom: 0;
`;

export default StoreAI;
