import styled from '@emotion/styled';
import { Button } from 'antd';
import { ButtonType } from '../../../types';

export const BlockContainer = styled.div`
  position: relative;
  padding: 0;

  @media screen and (min-width: 992px) {
    padding: 0 80px;
  }
`;

// as statement is used because of problems with styled components typing
export const BackButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

export const StateWrapper = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const BlockWrapper = styled.div`
  position: relative;
`;
