import React, { useCallback, useEffect, useMemo } from 'react';
import { Tabs } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import TabBar from '../../tab-bar/tab-bar.component';
import Gridapp from '../../../../store/types/gridapp';
import GridappSettingsContainer from './gridapp-settings/gridapp-settings.container';
import GridappInfoContainer from './gridapp-info/gridapp-info.container';
import GridappOverview from './gridapp-overview';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import GridappVersionsContainer from './gridapp-versions/gridapp-versions.container';
import GridappInstallationsContainer from './gridapp-installations/gridapp-installations.container';
import usePermissions from '../../../../utils/auth/use-permissions';
import { permissionKeys } from '../../../../utils/auth/permissions';
import { useAnalyticsParams } from '../../use-analytics-params';

interface AppDetailsProps
  extends RouteComponentProps<{ gridappId: string; organisationId: string }> {
  gridapp: Gridapp | null;
  fetchGridapp: (params: { gridappId: string; organizationId: string }) => void;
  loaded: boolean;
}

const AppDetailsTabBar = styled(TabBar)`
  margin-right: 10px;
  text-align: right;
`;

const AppsLibraryDetails = (props: AppDetailsProps) => {
  const { fetchGridapp, gridapp, match, location, history } = props;
  const { t, i18n } = useTranslation();
  const { isAllowed, isSysAdmin } = usePermissions(match.params.organisationId);

  const fetchApp = useCallback(async () => {
    await fetchGridapp({
      gridappId: match.params.gridappId,
      organizationId: match.params.organisationId,
    });
  }, [fetchGridapp, match.params.gridappId, match.params.organisationId]);

  useEffect(() => {
    fetchApp();
  }, [fetchApp]);

  const { organisationId } = match.params;

  const { isGridAppOwner, isAllowedViewVersions } = useMemo(() => {
    return {
      isGridAppOwner:
        (organisationId && gridapp && gridapp.organizationId === organisationId) ||
        isSysAdmin,
      isAllowedViewVersions:
        isAllowed(permissionKeys.gridApps.viewAllVersions) || isSysAdmin,
    };
  }, [organisationId, gridapp, isSysAdmin, isAllowed]);

  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    // having app here as dep to enforce tab bar rerender
    // TODO: look into tab bar issues (animation is messed up when children change, so it is memoized)
    // eslint-disable-next-line
    [history, gridapp],
  );

  const { analyticsQueryParams } = useAnalyticsParams();

  if (!gridapp) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <div className="content-body">
      <AppDetailsTabBar
        onChange={handleTabChange}
        activeKey={location.pathname}
        lang={i18n.language}
        type="card"
      >
        <Tabs.TabPane tab={t('appInformation')} key={`${match.url}/info`} />
        {isGridAppOwner && (
          <Tabs.TabPane
            tab={t('overview')}
            key={`${match.url}/overview${analyticsQueryParams}`}
          />
        )}
        {isGridAppOwner && (
          <Tabs.TabPane tab={t('settings')} key={`${match.url}/settings`} />
        )}
        {isSysAdmin && (
          <Tabs.TabPane tab={t('installations')} key={`${match.url}/installations`} />
        )}
        {isAllowedViewVersions && (
          <Tabs.TabPane tab={t('versions')} key={`${match.url}/versions`} />
        )}
      </AppDetailsTabBar>
      <Switch>
        <Route
          path={match.path}
          exact
          render={() => <Redirect to={`${match.url}/info`} />}
        />
        <Route path={`${match.path}/info`} component={GridappInfoContainer} />
        {isGridAppOwner && (
          <Route path={`${match.path}/overview`} component={GridappOverview} />
        )}
        {isGridAppOwner && (
          <Route path={`${match.path}/settings`} component={GridappSettingsContainer} />
        )}
        {isSysAdmin && (
          <Route
            path={`${match.path}/installations`}
            component={GridappInstallationsContainer}
          />
        )}
        {isAllowedViewVersions && (
          <Route
            path={`${match.path}/versions`}
            render={() => (
              <GridappVersionsContainer
                match={match}
                location={location}
                history={history}
                canDelete={isGridAppOwner}
                canModifyReleaseNotes={isGridAppOwner}
                canViewReleaseNotes
              />
            )}
          />
        )}
        <Route path="*" render={() => <Redirect to={`${match.url}/info`} />} />
      </Switch>
    </div>
  );
};

export default AppsLibraryDetails;
