import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Table } from 'antd';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import Pagination, { usePagination } from '../pagination';
import usePaginationQueryParams from '../use-pagination-query-params';
import { PaginationQueryParams } from '../use-pagination-query-params/use-pagination-query-params';
import { PaginationCollection } from '../../../store/types/pagination';
import { loadingIcon } from '../spinner/spinner.component';

interface PaginatedListTableProps<T> {
  data: PaginationCollection<T>;
  columns: ColumnProps<T>[];
  isDataLoading: boolean;
  rowSelection?: TableRowSelection<T>;
}

const PaginatedListTable = <T,>(props: PaginatedListTableProps<T>) => {
  const { data, columns, isDataLoading, rowSelection } = props;

  const {
    page,
    pageSize,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const [paginationQueryParams, setPaginationQueryParams] = usePaginationQueryParams<
    PaginationQueryParams
  >({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const handlePageChange = useCallback(
    (page) => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page,
        };
      });
    },
    [setPaginationQueryParams],
  );

  const handlePaginationSizeChange = useCallback(
    (_, pageSize) => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page: defaultPage,
          limit: pageSize,
        };
      });
    },
    [setPaginationQueryParams, defaultPage],
  );

  return (
    <>
      <TableWrap>
        <Table
          rowKey="id"
          dataSource={data && data.docs ? data.docs : []}
          columns={columns}
          scroll={{ x: true }}
          pagination={false}
          size="small"
          loading={{
            indicator: loadingIcon,
            spinning: isDataLoading,
          }}
          rowSelection={rowSelection}
        />
        <Pagination
          align="right"
          showSizeChanger
          onShowSizeChange={handlePaginationSizeChange}
          current={paginationQueryParams.page}
          pageSize={paginationQueryParams.limit}
          total={data ? data.totalDocs : 0}
          onChange={handlePageChange}
          pageSizes={defaultPageSizeOption}
        />
      </TableWrap>
    </>
  );
};

export const TableWrap = styled.div`
  position: relative;
  // padding: 30px 80px 40px;
  padding: 20px 40px 20px 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }

  .ant-table-thead > tr > th {
    background: #f0f0f0 !important;
    height: 56px;
    margin-left: 20px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding: 8px 20px !important;
  }
`;

export default PaginatedListTable;
