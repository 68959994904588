import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import {
  getFieldNamesWithError,
  scrollIntoView,
} from '../../../../../common/react-final-form';

export interface PhyhubDeviceDetailsFormValues {
  deviceId: string;
  deviceSerial: string;
  displayName: string;
  env: string;
  spaceId: string;
  externalId: string | null;
  notes: string | null;
}

interface PhyhubDeviceDetailsFormRenderProps {
  isSubmitting: boolean;
}

interface PhyhubDeviceDetailsScreenFormProps {
  initialFormValues: PhyhubDeviceDetailsFormValues;
  onFormSubmit: (values: PhyhubDeviceDetailsFormValues) => Promise<void>;
  children: (renderProps: PhyhubDeviceDetailsFormRenderProps) => React.ReactNode;
}

const PhyhubDeviceDetailsScreenForm: FC<PhyhubDeviceDetailsScreenFormProps> = (props) => {
  const { children, initialFormValues, onFormSubmit } = props;

  const handleFormSubmit = useCallback(
    async (values: object) => {
      // 'as' statement is used because of lack of type safety in React Final Form
      await onFormSubmit(values as PhyhubDeviceDetailsFormValues);
    },
    [onFormSubmit],
  );

  return (
    <Form
      initialValues={initialFormValues}
      onSubmit={handleFormSubmit}
      render={({
        submitting: isSubmitting,
        invalid: isFormInvalid,
        handleSubmit,
        errors,
      }) => (
        // TODO: Put submit handler in a separate function if necessary
        <form
          onSubmit={(event) => {
            handleSubmit(event);

            const errorFieldNames = getFieldNamesWithError(errors);

            if (!isFormInvalid || !errorFieldNames.length) {
              return;
            }

            scrollIntoView(errorFieldNames[0]);
          }}
        >
          {children({ isSubmitting })}
        </form>
      )}
    />
  );
};

export default PhyhubDeviceDetailsScreenForm;
