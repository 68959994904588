import React, { useCallback } from 'react';
import { Icon, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import { ApiError } from '../../../services/api/api-error';
import DevicesUniversalList from '../universal-devices/devices-universal-list/devices-universal-list.component';
import UniversalDevice from '../../../store/types/universal-device';
import { PaginationProps, PaginationSearchParam } from '../../../store/types/pagination';
import DevicesReport from '../devices-report/devices-report';
import OrganisationApp from '../../../store/types/organisation-app';
import OrganisationSpace from '../../../store/types/organisation-space';
import SearchBar from '../search-bar/search-bar.component';
import { ActiveFilter } from '../../../store/types/filters';
import usePagination from '../pagination/hook/use-pagination';
import useFilterOptions from '../use-filter-options/use-filter-options';
import PanelCard from '../panel-card/panel-card.component';

interface DevicesListProps
  extends RouteComponentProps<{ organisationId: string; spaceId: string }>,
    PaginationProps {
  apps: OrganisationApp[];
  devices: UniversalDevice[];
  loaded: boolean;
  isLoading?: boolean;
  fetchDevices: ({
    organizationId,
    silent,
    searchParam,
    page,
    pageSize,
  }: {
    organizationId: string;
    silent?: boolean;
    searchParam?: PaginationSearchParam;
    page: number;
    pageSize: number;
  }) => Promise<void>;
  lastUpdated?: Date;
  error: ApiError | null;
  spaces: OrganisationSpace[];
  spaceId?: string;
  hideDevicesReport?: boolean;
  showBackButton?: boolean;
}

const DevicesList = (props: DevicesListProps) => {
  const {
    apps,
    devices,
    loaded,
    isPaginationLoading,
    fetchDevices,
    lastUpdated,
    error,
    pagination,
    spaces,
    spaceId,
    hideDevicesReport = false,
    match: {
      params: { organisationId: organizationId },
    },
    history,
    showBackButton,
  } = props;

  const { t } = useTranslation();
  const { pageSize: pageSizeNum, defaultPage } = usePagination();
  const { filterOptions } = useFilterOptions({ apps, spaces });

  const fetchOrganisationDevices = useCallback(
    async ({
      silent,
      searchParam,
      page,
      pageSize,
    }: {
      silent: boolean;
      searchParam?: PaginationSearchParam;
      page: number;
      pageSize: number;
    }) => {
      await fetchDevices({
        organizationId,
        silent,
        searchParam: spaceId ? { ...searchParam, spaceId } : searchParam,
        page,
        pageSize,
      });
    },
    [organizationId, fetchDevices, spaceId],
  );

  const handleSearch = useCallback(
    (value: string): void => {
      const val = value.trim();
      const searchParam = {
        deviceName: val,
        deviceSerial: val,
      };

      const pageLimit = (pagination && pagination.limit) || pageSizeNum;

      fetchOrganisationDevices({
        silent: true,
        searchParam: pagination ? { ...pagination.param, ...searchParam } : undefined,
        page: defaultPage,
        pageSize: pageLimit,
      });
    },
    [defaultPage, fetchOrganisationDevices, pageSizeNum, pagination],
  );

  const handleFilterChange = useCallback(
    (filters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null): void => {
      const filterTypeIds = groupBy(filters, 'typeId');
      const mappedFilterParams = map(filterTypeIds, (value, id) => {
        return { [id]: Object.keys(keyBy(value, 'valueId')) };
      });

      if (
        lastRemovedFilter &&
        !Object.keys(filterTypeIds).includes(lastRemovedFilter.typeId)
      ) {
        const id = lastRemovedFilter.typeId;
        mappedFilterParams.push({ [id]: [] });
      }

      fetchOrganisationDevices({
        silent: true,
        searchParam: pagination
          ? Object.assign(pagination.param || {}, ...mappedFilterParams)
          : undefined,
        page: defaultPage,
        pageSize: pageSizeNum,
      });
    },
    [defaultPage, fetchOrganisationDevices, pageSizeNum, pagination],
  );

  return (
    <>
      {loaded && (
        <SearchBarWrapContainer>
          {showBackButton && (
            <GoBackButton onClick={() => history.goBack()}>
              <Icon type="arrow-left" />
            </GoBackButton>
          )}
          <SearchBar
            searchInputProps={{
              placeholder: t('searchDevicePlaceholder'),
              onSearch: handleSearch,
            }}
            searchInputFilterProps={{
              filterOptions,
              onChange: handleFilterChange,
            }}
          />
        </SearchBarWrapContainer>
      )}
      <DevicesContainer>
        {!hideDevicesReport && (
          <DevicesReport tenantId={organizationId} spaceId={spaceId} />
        )}
        <DevicesUniversalList
          apps={apps}
          organizationId={organizationId}
          error={error}
          lastUpdated={lastUpdated}
          devices={devices}
          loaded={loaded}
          fetchDevices={fetchOrganisationDevices}
          isPaginationLoading={isPaginationLoading}
          pagination={pagination}
        />
      </DevicesContainer>
    </>
  );
};

const SearchBarWrapContainer = styled(PanelCard)`
  position: sticky;
  border-radius: 0px;
  padding-right: 30px;
  top: 0;
  z-index: 1;
  border-top: none;
  background: none;
  border: 0;
  border-bottom: 1px solid #ddd;

  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > div {
      border: 0;
      padding-bottom: 0;
    }
  }
`;

const GoBackButton = styled(Button)`
  color: unset;
  padding: 0px 10px 0px 20px;
  border-right: 1px solid #f2f2fa;
  margin-right: 10px;
  border: none;
  background: 0;
` as any;

const DevicesContainer = styled.div`
  padding: 10px 40px;
  position: relative;
  flex: 1;
`;

export default DevicesList;
