import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Collapse, Modal } from 'antd';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDevicesPreviewTable from '../../phyhub-devices-preview-table/phyhub-devices-preview-table.component';

interface PhyhubDevicesBaseActionModalProps {
  isVisible: boolean;
  cancelText: string;
  okText: string;
  title: string;
  affectedDevices: PhyhubDevice[];
  skipperDevices: PhyhubDevice[];
  onCancel: () => void;
  onOk: (deviceIds: string[]) => void;
}

const PhyhubDevicesBaseActionModal: FC<PhyhubDevicesBaseActionModalProps> = (props) => {
  const {
    isVisible,
    cancelText,
    okText,
    title,
    affectedDevices,
    skipperDevices,
    children,
    onOk,
  } = props;

  const { t } = useTranslation();

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedDeviceIds(affectedDevices.map((device) => device.id));
  }, [affectedDevices]);

  const handleOkClick = useCallback(() => {
    onOk(selectedDeviceIds);
  }, [selectedDeviceIds, onOk]);

  const handleRowSelectionChange = useCallback((selectedRowKeys: string[] | number[]) => {
    const mappedRowKeys: string[] = [];

    selectedRowKeys.forEach((key: string | number) => {
      mappedRowKeys.push(key.toString());
    });

    setSelectedDeviceIds(mappedRowKeys);
  }, []);

  return (
    <Modal
      visible={isVisible}
      title={title}
      cancelText={cancelText}
      okText={okText}
      onCancel={props.onCancel}
      onOk={handleOkClick}
    >
      {!!children && <ModalContent>{children}</ModalContent>}
      <Collapse>
        {affectedDevices.length > 0 && (
          <Collapse.Panel
            key="upgraded"
            header={t('phyhubDevices.label.affectedDevices', {
              count: affectedDevices.length,
            })}
          >
            <PhyhubDevicesPreviewTable
              devices={affectedDevices}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedDeviceIds,
                onChange: handleRowSelectionChange,
              }}
            />
          </Collapse.Panel>
        )}
        {skipperDevices.length > 0 && (
          <Collapse.Panel
            key="skipped"
            header={t('phyhubDevices.label.skippedDevices', {
              count: skipperDevices.length,
            })}
          >
            <PhyhubDevicesPreviewTable devices={skipperDevices} />
          </Collapse.Panel>
        )}
      </Collapse>
    </Modal>
  );
};

const ModalContent = styled.div`
  position: relative;
  margin: 0 0 20px;
`;

export default PhyhubDevicesBaseActionModal;
