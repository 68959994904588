import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import OrganisationApp from '../../../../store/types/organisation-app';
import getInstallationAppIcon from '../../../../utils/installations/get-installation-app-icon-url';

interface PhyhubDeviceInstallationsListProps {
  tenantId: string;
  installations: OrganisationApp[];
}

const PhyhubDeviceInstallationsList: FC<PhyhubDeviceInstallationsListProps> = (props) => {
  const { tenantId, installations } = props;

  const { t } = useTranslation();

  const columns = useMemo<ColumnProps<OrganisationApp>[]>(
    () => [
      {
        key: 'installationTypeImage',
        title: t('installation'),
        align: 'left',
        render: (_, installation) => (
          <Link
            key={`link-${installation.id}`}
            to={`/organisations/${tenantId}/apps/${installation.id}`}
          >
            <StyledImage alt="" src={getInstallationAppIcon(installation)} />
            {installation.displayName}
          </Link>
        ),
      },
      {
        key: 'installationSlug',
        title: t('installationName'),
        dataIndex: 'displayName',
      },
    ],
    [t],
  );

  return (
    <Table<OrganisationApp>
      columns={columns}
      dataSource={installations}
      pagination={false}
    />
  );
};

const IMAGE_SIZE = '32px';

const StyledImage = styled.img`
  margin: 0 4px 0 0;
  width: ${IMAGE_SIZE};
  height: ${IMAGE_SIZE};
`;

export default PhyhubDeviceInstallationsList;
