import React, { FC, useMemo } from 'react';
import useInstallations from '../../../../../common/use-installation';
import { StateWrapper } from '../../../../../common/phyhub-devices/phyhub-devices-common.components';
import ErrorView from '../../../../../common/error-view/error-view.component';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import Overlay from '../../../../../common/overlay';
import { Spinner } from '../../../../../common/spinner';
import PhyhubDeviceDetailsScreenInstallations from './phyhub-device-details-screen-installations-component';

interface PhyhubDeviceDetailsScreenInstallationsContainerProps {
  tenantId: string;
  deviceInstallationIds: string[];
}

const PhyhubDeviceDetailsScreenInstallationsContainer: FC<PhyhubDeviceDetailsScreenInstallationsContainerProps> = (
  props,
) => {
  const { tenantId, deviceInstallationIds } = props;

  const {
    isFetching: isInstallationsFetching,
    isLoading: isInstallationsLoading,
    isError: isInstallationsError,
    data: installations,
  } = useInstallations(tenantId);

  const deviceInstallations = useMemo<OrganisationApp[]>(() => {
    if (!installations) {
      return [];
    }

    return installations.filter((installation) =>
      deviceInstallationIds.includes(installation.id),
    );
  }, [deviceInstallationIds, installations]);

  if (isInstallationsFetching || isInstallationsLoading) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isInstallationsError) {
    return (
      <StateWrapper>
        <ErrorView />
      </StateWrapper>
    );
  }

  return (
    <PhyhubDeviceDetailsScreenInstallations
      tenantId={tenantId}
      deviceInstallations={deviceInstallations}
    />
  );
};

export default PhyhubDeviceDetailsScreenInstallationsContainer;
