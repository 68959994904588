import { State } from 'easy-peasy';
import appModel, { AppModel } from './app/app.model';
import authModel, { AuthModel } from './auth/auth.model';
import organisationAppsModel, {
  OrganisationAppsModel,
} from './organisation-apps/organisation-apps.model';
import schemaModel, { SchemaModel } from './schema/schema-model';
import organisationMediaModel, {
  OrganisationMediaModel,
} from './organisation-media/organisation-media.model';
import appBuildsModel, { AppBuildsModel } from './app-builds/app-builds.model';
import organisationDevicesModel, {
  OrganisationDevicesModel,
} from './organisation-devices/organisation-devices.model';
import releasesModel, { ReleasesModel } from './releases/releases.model';
import providersModel, { ProvidersModel } from './providers/providers.model';
import environmentsModel, { EnvironmentsModel } from './environments/environments.model';
import appBrowsersModel, { AppBrowsersModel } from './app-browsers/app-browsers.model';
import deviceDetailsModel, {
  DeviceDetailsModel,
} from './device-details/device-details.model';
import analyticsModel, { AnalyticsModel } from './analytics/analytics.model';
import deviceEnvVariablesModel, {
  DeviceEnvVariablesModel,
} from './device-env-variables/device-env-variables.model';
import classicAppEnvVariablesModel, {
  ClassicAppEnvVariablesModel,
} from './app-env-variables/classic-app-env-variables/app-env-variables.model';
import azureAppEnvVariablesModel, {
  AzureAppEnvVariablesModel,
} from './app-env-variables/azure-app-env-variables/app-env-variables.model';
import appDevicesModel, { AppDevicesModel } from './app-devices/app-devices.model';
import azureDeviceDetailsModel, {
  AzureDeviceDetailsModel,
} from './azure-device-details/azure-device-details.model';
import azureDeviceEnvVariablesModel, {
  AzureDeviceEnvVariablesModel,
} from './azure-device-env-variables/azure-device-env-variables.model';
import usersModel, { UsersModel } from './users/users.model';
import userRolesModel, { UserRolesModel } from './user-roles/user-roles.model';
import userRolePermissionsMatrixModel, {
  UserRolePermissionsMatrixModel,
} from './user-roles/user-role-permissions-matrix.model';
import organisationsModel, {
  OrganisationsModel,
} from './organisations/organisations.model';
import appsModel, { AppsModel } from './apps/apps.model';
import actionLogsModel, { ActionsLogsModel } from './action-logs/actions-logs.model';
import teamsModel, { TeamsModel } from './teams/teams.model';
import queuesModel, { QueuesModel } from './queues/queues.model';
import organisationSpacesModel, {
  OrganisationSpacesModel,
} from './organisation-spaces/organization-spaces.model';
import organisationCustomShortUrlModel, {
  OrganisationCustomShortUrlModel,
} from './organisation-custom-short-urls/organisation-custom-short-urls.model';
import organisationBrowsersModel, {
  OrganisationBrowsersModel,
} from './organisation-browsers/organisation-browsers.model';
import organisationAppsLibraryModel, {
  OrganisationAppsLibraryModel,
} from './organisation-apps-library/organisation-apps-library.model';
import organisationAppsLibraryBuildsModel, {
  OrganisationAppsLibraryBuildsModel,
} from './organisation-apps-library-builds/organisation-apps-library-builds.model';
import appMobileEndpointModel, {
  AppMobileEndpointModel,
} from './app-mobile-endpoints/app-mobile-endpoints.model';
import organisationMobileEndpointsModel, {
  OrganisationMobileEndpointsModel,
} from './organisation-mobile-endpoints/organisation-mobile-endpoints.model';
import tokensModel, { TokensModel } from './tokens/tokens.model';
import modulesModel, { ModulesModel } from './modules/modules.model';
import moduleVersionsModel, {
  ModuleVersionsModel,
} from './module-versions/module-versions.model';
import deviceModulesModel, {
  DeviceModulesModel,
} from './device-modules/device-modules.model';
import appDevicesUniversalModel, {
  AppDevicesUniversalModel,
} from './app-devices-universal/app-devices-universal.model';
import appV3DevicesUniversalModel, {
  AppV3DevicesUniversalModel,
} from './app-devices-universal/app-v3-devices-universal.model';
import universalDeviceDetailsModel, {
  UniversalDeviceDetailsModel,
} from './universal-device-details/universal-device-details.model';
import organisationInstallationGroupsModel, {
  OrganisationInstallationGroupsModel,
} from './organisation-installation-groups/organisation-installation-groups.model';
import federatedDomainModel, {
  FederatedDomainModel,
} from './federated-domains/federated-domains.model';
import appDevicesAndroidModel, {
  AppDevicesAndroidModel,
} from './app-devices-android/app-devices-android.model';
import subscriptionsModel, {
  SubscriptionsModel,
} from './subscriptions/subscription.model';
import organizationPlansModel, {
  OrganizationPlansModel,
} from './organization-plans/organization-plans.model';
import onboardingModel, { OnboardingModel } from './onboarding/onboarding.model';
import enterpriseAgreementsModel, {
  EnterpriseAgreementsModel,
} from './enterprise-agreements/enterprise-agreements.model';
import organizationAccountBalanceModel, {
  OrganizationAccountBalanceModel,
} from './organization-account-balance/organization-account-balance.model';
import organisationUsersModel, {
  OrganisationUsersModel,
} from './organisation-users/organisation-users.model';
import organisationPricingModel, {
  OrganisationPricingModel,
} from './organisation-pricing/organisation-pricing.model';
import contactsModel, { ContactsModel } from './contacts/contacts.model';
import sessionModel, { SessionModel } from './sessions/session.model';
import organisationTransactionsModel, {
  OrganisationTransactionsModel,
} from './organisation-transactions/organisation-transactions.model';
import organisationInvoicesModel, {
  OrganisationInvoicesModel,
} from './organisation-invoices/organisation-transactions.model';
import gridProductsModel, {
  GridProductsModel,
} from './grid-products/grid-products.model';
import gridProductTypesModel, {
  GridProductTypesModel,
} from './grid-product-types/grid-product-types.model';
import organisationMarketplaceSolutionsModel, {
  OrganisationMarketplaceSolutionsModel,
} from './organisation-marketplace-solutions/organisation-marketplace-solutions.model';
import organisationProfessionalServicesUnitsModel, {
  OrganisationProfessionalServicesUnitsModel,
} from './organisation-professional-services-units/organisation-professional-services-units.model';
import organisationLegacyWindowsDevicesModel, {
  OrganisationLegacyWindowsDevicesModel,
} from './organisation-devices/organisation-legacy-windows-devices.model';
import organisationBrandingModel, {
  OrganisationBrandingModel,
} from './organisation-branding/organisation-branding.model';

export type RootState = State<RootModel>;

export interface RootModel {
  organisationApps: OrganisationAppsModel;
  organisationMedia: OrganisationMediaModel;
  organisationDevices: OrganisationDevicesModel;
  organisationLegacyWindowsDevices: OrganisationLegacyWindowsDevicesModel;
  organisationSpaces: OrganisationSpacesModel;
  organisationBrowsers: OrganisationBrowsersModel;
  organisationMobileEndpoints: OrganisationMobileEndpointsModel;
  app: AppModel;
  auth: AuthModel;
  classicAppEnvVariables: ClassicAppEnvVariablesModel;
  azureAppEnvVariables: AzureAppEnvVariablesModel;
  schema: SchemaModel;
  appBuilds: AppBuildsModel;
  releases: ReleasesModel;
  providers: ProvidersModel;
  environments: EnvironmentsModel;
  deviceDetails: DeviceDetailsModel;
  azureDeviceDetails: AzureDeviceDetailsModel;
  deviceEnvVariables: DeviceEnvVariablesModel;
  azureDeviceEnvVariables: AzureDeviceEnvVariablesModel;
  analytics: AnalyticsModel;
  appDevices: AppDevicesModel;
  appBrowsers: AppBrowsersModel;
  appMobileEndpoints: AppMobileEndpointModel;
  users: UsersModel;
  userRoles: UserRolesModel;
  userRolePermissionsMatrix: UserRolePermissionsMatrixModel;
  organisations: OrganisationsModel;
  apps: AppsModel;
  actionLogs: ActionsLogsModel;
  teams: TeamsModel;
  queues: QueuesModel;
  organisationAppsLibrary: OrganisationAppsLibraryModel;
  organisationAppsLibraryBuilds: OrganisationAppsLibraryBuildsModel;
  tokens: TokensModel;
  organisationCustomShortUrls: OrganisationCustomShortUrlModel;
  modules: ModulesModel;
  moduleVersions: ModuleVersionsModel;
  deviceModules: DeviceModulesModel;
  appDevicesUniversal: AppDevicesUniversalModel;
  appV3DevicesUniversal: AppV3DevicesUniversalModel;
  appDevicesAndroid: AppDevicesAndroidModel;
  universalDeviceDetails: UniversalDeviceDetailsModel;
  organisationInstallationGroups: OrganisationInstallationGroupsModel;
  federatedDomains: FederatedDomainModel;
  subscriptions: SubscriptionsModel;
  organizationPlans: OrganizationPlansModel;
  organizationAccountBalance: OrganizationAccountBalanceModel;
  onboarding: OnboardingModel;
  enterpriseAgreements: EnterpriseAgreementsModel;
  organisationUsers: OrganisationUsersModel;
  organisationPricing: OrganisationPricingModel;
  contacts: ContactsModel;
  sessions: SessionModel;
  organisationTransactions: OrganisationTransactionsModel;
  organisationInvoices: OrganisationInvoicesModel;
  organisationProfessionalServicesUnits: OrganisationProfessionalServicesUnitsModel;
  gridProducts: GridProductsModel;
  gridProductTypes: GridProductTypesModel;
  marketplaceSolutions: OrganisationMarketplaceSolutionsModel;
  organisationBranding: OrganisationBrandingModel;
}

const rootModel = {
  organisationApps: organisationAppsModel,
  organisationMedia: organisationMediaModel,
  organisationDevices: organisationDevicesModel,
  organisationLegacyWindowsDevices: organisationLegacyWindowsDevicesModel,
  organisationSpaces: organisationSpacesModel,
  organisationBrowsers: organisationBrowsersModel,
  organisationMobileEndpoints: organisationMobileEndpointsModel,
  app: appModel,
  classicAppEnvVariables: classicAppEnvVariablesModel,
  azureAppEnvVariables: azureAppEnvVariablesModel,
  auth: authModel,
  schema: schemaModel,
  appBuilds: appBuildsModel,
  releases: releasesModel,
  providers: providersModel,
  environments: environmentsModel,
  deviceDetails: deviceDetailsModel,
  azureDeviceDetails: azureDeviceDetailsModel,
  analytics: analyticsModel,
  deviceEnvVariables: deviceEnvVariablesModel,
  azureDeviceEnvVariables: azureDeviceEnvVariablesModel,
  appDevices: appDevicesModel,
  appBrowsers: appBrowsersModel,
  appMobileEndpoints: appMobileEndpointModel,
  users: usersModel,
  userRoles: userRolesModel,
  userRolePermissionsMatrix: userRolePermissionsMatrixModel,
  organisations: organisationsModel,
  apps: appsModel,
  actionLogs: actionLogsModel,
  teams: teamsModel,
  queues: queuesModel,
  organisationAppsLibrary: organisationAppsLibraryModel,
  organisationAppsLibraryBuilds: organisationAppsLibraryBuildsModel,
  tokens: tokensModel,
  organisationCustomShortUrls: organisationCustomShortUrlModel,
  modules: modulesModel,
  moduleVersions: moduleVersionsModel,
  deviceModules: deviceModulesModel,
  appDevicesUniversal: appDevicesUniversalModel,
  appV3DevicesUniversal: appV3DevicesUniversalModel,
  appDevicesAndroid: appDevicesAndroidModel,
  universalDeviceDetails: universalDeviceDetailsModel,
  organisationInstallationGroups: organisationInstallationGroupsModel,
  federatedDomains: federatedDomainModel,
  subscriptions: subscriptionsModel,
  organizationPlans: organizationPlansModel,
  organizationAccountBalance: organizationAccountBalanceModel,
  onboarding: onboardingModel,
  enterpriseAgreements: enterpriseAgreementsModel,
  organisationUsers: organisationUsersModel,
  organisationPricing: organisationPricingModel,
  contacts: contactsModel,
  sessions: sessionModel,
  organisationTransactions: organisationTransactionsModel,
  organisationInvoices: organisationInvoicesModel,
  organisationProfessionalServicesUnits: organisationProfessionalServicesUnitsModel,
  gridProducts: gridProductsModel,
  gridProductTypes: gridProductTypesModel,
  marketplaceSolutions: organisationMarketplaceSolutionsModel,
  organisationBranding: organisationBrandingModel,
};

export default rootModel;
