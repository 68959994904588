import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { SortCriteria, SortOrder } from '../../../store/types/sort';
import FilterItem, { ActiveFilter } from '../../../store/types/filters';
import PhyhubDevicesTableContainer from './phyhub-devices-list/phyhub-devices-table/phyhub-devices-table.container';
import { BlockWrapper, StateWrapper } from './phyhub-devices-common.components';
import Header from '../app-layout/header/header.component';
import PhyhubDevicesSummaryContainer from './phyhub-devices-list/phyhub-devices-summary/phyhub-devices-summary.container';
import PhyhubDevicesSearchAndSort from './phyhub-devices-list/phyhub-devices-search-and-sort/phyhub-devices-search-and-sort.component';
import useEnvironments from '../use-environments/use-environments';
import Overlay from '../overlay';
import { Spinner } from '../spinner';
import ErrorView from '../error-view/error-view.component';
import {PhyhubDeviceListParamsFilters} from "../../../services/phyhub/types/phyhub-device-list-params.interface";

interface PhyhubDevicesContainerProps {
  titleKey?: string;
  tenantId: string;
  filterOptions: FilterItem[];
  sortCriteriaOptions: SortCriteria[];
  presetFilters?: PhyhubDeviceListParamsFilters,
}

const PhyhubDevicesContainer: FC<PhyhubDevicesContainerProps> = (props) => {
  const {
    titleKey,
    tenantId,
    filterOptions,
    sortCriteriaOptions,
      presetFilters
  } = props;

  const { t } = useTranslation();

  const {
    isError: isEnvironmentsError,
    isLoading: isEnvironmentsLoading,
    isSuccess: isEnvironmentsSuccess,
    data: environmentsCollection,
  } = useEnvironments(tenantId);

  const handleCriteriaChange = useCallback((criteria: SortCriteria, order: SortOrder) => {
    // TODO: Implement criteria and order change handling
    console.log('Handle criteria change: ', { criteria, order });
  }, []);

  // TODO: Implement filters change handling
  const handleFiltersChange = useCallback((filters: ActiveFilter[]) => {
    console.log('Handle filters change: ', { filters });
  }, []);

  // TODO: Implement search handling
  const handleSearch = useCallback((phrase: string) => {
    console.log('Handle search: ', { phrase });
  }, []);

  if (isEnvironmentsLoading) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isEnvironmentsError || !isEnvironmentsSuccess || !environmentsCollection) {
    return (
      <StateWrapper>
        <ErrorView />
      </StateWrapper>
    );
  }

  return (
    <>
      {titleKey && <Header title={t(titleKey)} />}
      <SearchAndSortWrapper>
        <PhyhubDevicesSearchAndSort
          filterOptions={filterOptions}
          sortCriteriaOptions={sortCriteriaOptions}
          onCriteriaChange={handleCriteriaChange}
          onFiltersChange={handleFiltersChange}
          onSearch={handleSearch}
        />
      </SearchAndSortWrapper>
      <BlockWrapper>
        <PhyhubDevicesSummaryContainer
          tenantId={tenantId}
        />
      </BlockWrapper>
      <BlockWrapper>
        <PhyhubDevicesTableContainer
          tenantId={tenantId}
          environments={environmentsCollection}
          filters={presetFilters}
        />
      </BlockWrapper>
    </>
  );
};

const SearchAndSortWrapper = styled.div`
  position: relative;
  margin: 20px 0;
`;

export default PhyhubDevicesContainer;
