import React, { useMemo } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLanguageHelper from '../../../../utils/language/use-language-helper';
import User from '../../../../store/types/user';
import logo from '../../../../assets/images/logo-only.svg';
import permissionKeys from '../../../../utils/auth/permissions';
import usePermissions from '../../../../utils/auth/use-permissions';

interface SidebarProps {
  user: User | null;
  logout: () => void;
  switchLanguage: (lang: string) => void;
}

const GridLogo = styled.img`
  height: 0.9em;
  margin: 0 -1px 0 9px;
`;

const UserMenuMobileView = styled.div`
  float: right;
  @media only screen and (min-width: 992px) {
    display: none;
  }
  .ant-avatar {
    color: #ffffff;
    background-color: #0058a5;
    margin: -5px auto auto 5px;
    vertical-align: middle;

    @media only screen and (max-width: 340px) {
      margin: -5px auto auto 0px;
    }
    .ant-avatar-string {
      margin-left: 0px;
      font-size: 13px;
    }
  }
`;

const UserMenuNonMobileView = styled.div`
  float: right;
  .ant-avatar {
    color: #ffffff;
    background-color: #0058a5;
    margin: -5px auto auto 5px;
    vertical-align: middle;
    .ant-avatar-string {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const DropdownUser = styled(Dropdown)`
  background-color: #202020;
  padding-right: 0px;
  border-left: solid 1px black;
`;

const DropdownLanguage = styled(Dropdown)`
  background-color: #202020;

  padding-right: 10px;
  .anticon {
    margin-left: 5px;
  }

  @media only screen and (max-width: 340px) {
    padding-right: 5px;
  }
`;

const ButtonStyled = styled.button`
  height: 55px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s;
  :hover,
  :focus {
    background-color: rgba(255, 255, 255, 0.04);
  }
`;

const LanguageButton = styled(ButtonStyled)`
  > i {
    vertical-align: middle;
    margin: 0px auto auto 5px;
  }
`;

const Email = styled.span`
  margin-left: 8px;
`;

const LanguageMenuItem = styled(Menu.Item)`
  position: relative;
  display: flex;
  justify-content: center;
`;

const IconStyled = styled(Icon)`
  margin: auto 8px;
`;

const UserMenuComponent = ({ user, logout, switchLanguage }: SidebarProps) => {
  const { activeLanguage, supportedLanguages } = useLanguageHelper();
  const { t } = useTranslation();
  const { isAllowed, isSysAdmin } = usePermissions('grid');

  const menu = useMemo(
    () => (
      <Menu theme="dark">
        {isSysAdmin && (
          <Menu.Item key="grid">
            <Link to="/grid">
              <GridLogo src={logo} /> &nbsp; {t('adminTools')}
            </Link>
          </Menu.Item>
        )}
        {isAllowed(permissionKeys.grid.manageSales) && (
          <Menu.Item key="sales">
            <Link to="/sales">
              <IconStyled type="shopping-cart" />
              {t('salesTools')}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logout" onClick={logout}>
          <IconStyled type="logout" />
          {t('logout')}
        </Menu.Item>
      </Menu>
    ),
    [isSysAdmin, isAllowed, t, logout],
  );

  const languageMenu = useMemo(
    () => (
      <Menu theme="dark">
        {supportedLanguages.map(
          (lang) =>
            activeLanguage !== lang && (
              <LanguageMenuItem
                key={`languages_${lang}`}
                onClick={() => switchLanguage(lang)}
              >
                {lang}
              </LanguageMenuItem>
            ),
        )}
      </Menu>
    ),
    [supportedLanguages, activeLanguage, switchLanguage],
  );

  return (
    user && (
      <>
        <UserMenuNonMobileView>
          {/* 
          
            I've disabled this for now, it crashes the console

          <DropdownLanguage
            trigger={['click']}
            placement="bottomCenter"
            overlay={languageMenu}
          >
            <LanguageButton>
              {activeLanguage}
              <Icon type="global" />
            </LanguageButton>
          </DropdownLanguage> */}
          <DropdownUser
            theme="dark"
            trigger={['click']}
            placement="bottomCenter"
            overlay={menu}
          >
            <ButtonStyled>
              <Email>{user.email}</Email>
              <IconStyled type="down" />
            </ButtonStyled>
          </DropdownUser>
        </UserMenuNonMobileView>
        <UserMenuMobileView>
          {/* 
          
            I've disabled this for now, it crashes console

          <DropdownLanguage
            trigger={['click']}
            placement="bottomCenter"
            overlay={languageMenu}
          >
            <LanguageButton>
              {activeLanguage}
              <Icon type="global" />
            </LanguageButton>
          </DropdownLanguage> */}
          <DropdownUser
            theme="dark"
            trigger={['click']}
            placement="bottomCenter"
            overlay={menu}
          >
            <ButtonStyled>
              <IconStyled type="down" />
            </ButtonStyled>
          </DropdownUser>
        </UserMenuMobileView>
      </>
    )
  );
};

export default UserMenuComponent;
