import {
  WeeklySchedule,
  WeeklyScheduleFormFields,
  WeeklyTime,
  WeeklyTimeFormFields,
} from '../../../../../store/types/organisation-space';

// weeklySchedule.weeklyTime is of form { 0: { from, to, isOpen }, ...., 6: { from, to. isOpen } }
// we cannot use deep nested objects with numbers as keys as it's prohibited in react-final-form,
// so we need to convert them to a form it can understand
// i.e. weeklySchedule.weeklyTime.0.to -> weeklySchedule.weeklyTime.DAY-0.to
// TODO: write a script to convert weeklySchedule.weeklyTime.SUNDAY etc.
export const transformWeeklyScheduleForFormFields = (
  weeklySchedule: WeeklySchedule | undefined,
): WeeklyScheduleFormFields => {
  // weeklyTime defaults to an empty object if not provided
  const weeklyTime = (weeklySchedule && weeklySchedule.weeklyTime) || {};
  const weeklyTimeForFormFields: WeeklyTimeFormFields = {};

  Object.keys(weeklyTime).forEach((key) => {
    const fieldName = prepareWeeklyTimeFieldNameForFormField(key);
    weeklyTimeForFormFields[fieldName] = weeklyTime[key as keyof WeeklyTime];
  });

  return { weeklyTime: weeklyTimeForFormFields };
};

export const transformWeeklyScheduleForFormSubmission = (
  weeklySchedule: WeeklyScheduleFormFields,
): WeeklySchedule => {
  const { weeklyTime: weeklyTimeFormFields } = weeklySchedule;
  const weeklyTimeForFormSubmission: WeeklyTime = {};

  Object.keys(weeklyTimeFormFields!).forEach((key) => {
    const fieldName = prepareWeeklyTimeFieldNameForFormSubmission(key);
    weeklyTimeForFormSubmission[fieldName] = weeklyTimeFormFields![
      key as keyof WeeklyTimeFormFields
    ];
  });

  return { weeklyTime: weeklyTimeForFormSubmission };
};

const KEY_IDENTIFIER = 'DAYS-';

// converts '0' -> 'DAYS-0' so that react-final-form could understand
export const prepareWeeklyTimeFieldNameForFormField = (day: string) => {
  return `${KEY_IDENTIFIER}${day}` as keyof WeeklyTimeFormFields;
};

// converts 'DAYS-' to '0' so that it could be send to backend
export const prepareWeeklyTimeFieldNameForFormSubmission = (key: string) => {
  return key.replace(KEY_IDENTIFIER, '') as keyof WeeklyTime;
};
