import React, { FC } from 'react';
import styled from '@emotion/styled';
import PhyhubDeviceStatusCircle from '../phyhub-device-status/phyhub-device-status-circle/phyhub-devices-status-circle.component';
import usePhyhubDeviceStatusColor from '../phyhub-device-status/hooks/use-phyhub-device-status-color';
import { PhyhubDeviceArchitectureEnum } from '../../../../services/phyhub/enums/phyhub-device-architecture.enum';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';

interface PhyhubDeviceTypeProps {
  arch: PhyhubDeviceArchitectureEnum;
  osName: string;
  status: PhyhubDeviceStatusEnum;
}

const PhyhubDeviceType: FC<PhyhubDeviceTypeProps> = (props) => {
  const { arch, osName, status } = props;

  const statusColor = usePhyhubDeviceStatusColor(status);

  return (
    <Container>
      <Column>
        <PhyhubDeviceStatusCircle statusColor={statusColor} />
      </Column>
      <Column>{osName}</Column>
      <Column>{arch}</Column>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 6px 134px 120px;
  grid-column-gap: 8px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
`;

export default PhyhubDeviceType;
