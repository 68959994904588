import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from '@emotion/styled';
import { Button, Col, Dropdown, Menu, message, Modal, Row, Tabs } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Device from '../../../../../store/types/device';
import Header from '../../../../common/app-layout/header/header.component';
import Spinner from '../../../../common/spinner/spinner.component';
import Overlay from '../../../../common/overlay/overlay.component';
import DeviceStatus from '../../../../common/device-status/device-status.component';
import OrganisationApp from '../../../../../store/types/organisation-app';
import DeviceStatusEnum from '../../../../../store/types/device-status.enum';
import TabBar from '../../../../common/tab-bar/tab-bar.component';
import SettingsContainer from './settings/settings.container';
import ProtectedRouteContainer from '../../../../common/protected-route/protected-route.container';
import { getPermissionPath, permissionKeys } from '../../../../../utils/auth/permissions';
import usePermissions from '../../../../../utils/auth/use-permissions';
import EnvVariablesContainer from './env-variables/env-variables.container';
import Space from '../../../../../store/types/organisation-space';

const DeviceDetailsHeader = styled(Header)`
  padding-bottom: 0;
`;

const DeviceDetailsHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
`;

const ActionButton = styled(Button)`
  margin-left: 8px;
` as any;

const AppLink = styled(Link)`
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 400;
  &:hover,
  &:active,
  &:focus {
    color: rgba(0, 0, 0, 0.6) !important;
  }
`;

interface DeviceDetailsProps
  extends RouteComponentProps<{ deviceUuid: string; organisationId: string }> {
  device: Device | null;
  spaces: Space[];
  lastUpdated: Date;
  fetchDevice: (params: { deviceUuid: string; silent?: boolean }) => void;
  fetchSpaces: (params: { organizationId: string; silent?: boolean }) => void;
  loaded: boolean;
  apps: {
    [organisationName: string]: OrganisationApp[];
  };
  restartApp: (params: { deviceUuid: string }) => Promise<void>;
  rebootDevice: (params: { deviceUuid: string }) => Promise<void>;
  moveDevice: (params: { appName: string; deviceUuid: string }) => Promise<void>;
}

const DeviceDetails = (props: DeviceDetailsProps) => {
  const {
    device,
    fetchDevice,
    fetchSpaces,
    loaded,
    lastUpdated,
    apps,
    restartApp,
    rebootDevice,
    moveDevice,
    match: {
      params: { deviceUuid, organisationId },
    },
    match,
    location,
    history,
  } = props;
  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(match.params.organisationId);
  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    [history],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDevice({ deviceUuid, silent: true });
    }, 20 * 1000);

    fetchDevice({ deviceUuid });

    return () => {
      clearInterval(interval);
    };
  }, [deviceUuid, fetchDevice]);

  useEffect(() => {
    if (organisationId) fetchSpaces({ organizationId: organisationId, silent: true });
  }, [organisationId, fetchSpaces]);

  const handleRestartPress = useCallback(async () => {
    try {
      message.success(t('appRestartRequested'));
      await restartApp({ deviceUuid });
    } catch {
      message.error(t('appRestartRequestFailed'));
    }
  }, [restartApp, deviceUuid, t]);

  const handleReboot = useCallback(async () => {
    try {
      await rebootDevice({ deviceUuid });
      message.success(t('deviceRebootRequested'));
    } catch {
      message.error(t('deviceRebootRequestFailed'));
    }
  }, [rebootDevice, deviceUuid, t]);

  const handleMove = useCallback(
    async (appName: string) => {
      try {
        await moveDevice({ appName, deviceUuid });
        message.success(t('deviceMoved'));
        fetchDevice({ deviceUuid });
      } catch {
        message.error(t('deviceMoveOperationFailed'));
      }
    },
    [moveDevice, fetchDevice, deviceUuid, t],
  );

  const handleMovePress = useCallback(
    (appName: string) => {
      if (device) {
        Modal.confirm({
          title: 'Confirm action',
          content: (
            <span>
              {t('areYouSureYouWantToMoveTheDevice')} <strong>{device.deviceName}</strong>{' '}
              {t('from')} <strong>{device.appName}</strong> {t('to')}{' '}
              <strong>{appName}</strong>?
            </span>
          ),
          width: '30%',
          onOk: () => {
            return handleMove(appName);
          },
          onCancel: () => {},
        });
      }
    },
    [device, handleMove, t],
  );

  const handleRebootPress = useCallback(() => {
    if (device) {
      Modal.confirm({
        title: t('confirmAction'),
        content: (
          <span>
            {t('areYouSureYouWantToRebootDevice')} <strong>{device.deviceName}</strong>?
          </span>
        ),
        width: '30%',
        onOk: () => {
          return handleReboot();
        },
        onCancel: () => {},
      });
    }
  }, [device, handleReboot, t]);

  const renderAppsMenu = useMemo(() => {
    const organisationNames = Object.keys(apps);
    if (!organisationNames.length || !device) {
      return null;
    }

    const renderAppMenuOption = (app: OrganisationApp) => {
      if (app.internalAppNames && app.internalAppNames.length) {
        return (
          <Menu.SubMenu title={app.displayName} key={app.id}>
            {app.internalAppNames.map((appName) => {
              return (
                <Menu.Item onClick={() => handleMovePress(appName)} key={appName}>
                  {appName}
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item onClick={() => handleMovePress(app.appName)} key={app.id}>
          {app.displayName}
        </Menu.Item>
      );
    };

    if (organisationNames.length === 1) {
      return (
        <Menu>
          {apps[organisationNames[0]]
            .filter((app) => app.appName !== device.appName)
            .map((app) => renderAppMenuOption(app))}
        </Menu>
      );
    }

    return (
      <Menu>
        {organisationNames.map((organisationName) => {
          const organisationApps = apps[organisationName].filter(
            (app) => app.appName !== device.appName,
          );

          return organisationApps.length ? (
            <Menu.SubMenu title={organisationName} key={organisationName}>
              {organisationApps.map((app) => renderAppMenuOption(app))}
            </Menu.SubMenu>
          ) : null;
        })}
      </Menu>
    );
  }, [apps, device, handleMovePress]);

  const appsList = useMemo(() => {
    return apps
      ? Object.keys(apps).reduce(
          (result: OrganisationApp[], organisationName: string) => [
            ...result,
            ...apps[organisationName],
          ],
          [],
        )
      : [];
  }, [apps]);

  const renderTitle = useCallback(() => {
    if (!device) {
      return null;
    }

    if (device.appName) {
      const deviceApp = appsList.find((app) => app.appName === device.appName);

      if (deviceApp) {
        return (
          <>
            <AppLink
              to={`/organisations/${organisationId}/apps/installations/${deviceApp.id}`}
            >
              {deviceApp.appName}
            </AppLink>
            /{device.deviceName}
          </>
        );
      }
    }

    return device.deviceName;
  }, [device, appsList, organisationId]);

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (!device) {
    return null;
  }

  const deviceActionButtonsDisabled = device.status === DeviceStatusEnum.OFFLINE;
  const moveDisabled =
    appsList.filter((app) => app.appName !== device.appName).length < 1;

  return (
    <>
      <DeviceDetailsHeader title={renderTitle()}>
        <DeviceDetailsHeaderContent>
          <DeviceStatus lastUpdated={lastUpdated} size="large" device={device} />
          <ActionButtonsWrapper>
            {isAllowed(permissionKeys.devices.move) && (
              <Dropdown
                disabled={moveDisabled}
                trigger={['click']}
                overlay={renderAppsMenu}
              >
                <ActionButton disabled={moveDisabled} size="large" icon="swap">
                  {t('move')}
                </ActionButton>
              </Dropdown>
            )}
            {isAllowed(permissionKeys.devices.restart) && (
              <ActionButton
                disabled={deviceActionButtonsDisabled}
                size="large"
                icon="reload"
                onClick={handleRestartPress}
              >
                {t('restartDevice')}
              </ActionButton>
            )}
            {isAllowed(permissionKeys.devices.reboot) && (
              <ActionButton
                onClick={handleRebootPress}
                disabled={deviceActionButtonsDisabled}
                size="large"
                icon="poweroff"
                type="danger"
              >
                {t('rebootDevice')}
              </ActionButton>
            )}
          </ActionButtonsWrapper>
        </DeviceDetailsHeaderContent>
        <Row>
          <Col>
            <TabBar
              onChange={handleTabChange}
              activeKey={location.pathname}
              lang={i18n.language}
            >
              <Tabs.TabPane tab={t('settings')} key={`${match.url}/settings`} />

              {isAllowed(permissionKeys.devices.viewAllDeviceVariables) && (
                <Tabs.TabPane
                  tab={t('environmentVariables')}
                  key={`${match.url}/env-variables`}
                />
              )}
            </TabBar>
          </Col>
        </Row>
      </DeviceDetailsHeader>
      <div className="content-body">
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            render={() => <Redirect to={`${match.url}/settings`} />}
          />
          <ProtectedRouteContainer
            loginOnly
            path={`${match.path}/settings`}
            component={SettingsContainer}
          />
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.devices.viewAllDeviceVariables,
            )}
            path={`${match.path}/env-variables`}
            component={EnvVariablesContainer}
          />
        </Switch>
      </div>
    </>
  );
};

export default DeviceDetails;
