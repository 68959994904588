import diff from 'microdiff';
import DeviceTypeEnum from './device-type.enum';

export default interface OrganisationApp {
  appName: string;
  createdAt: string;
  displayName: string;
  id: string;
  externalId?: string;
  organizationId: string;
  provider: string;
  release: string;
  type: string;
  architecture: string;
  updatedAt: string;
  internalAppNames?: string[];
  balena?: any;
  settings: {
    electron?: any;
    provider?: {
      app?: any;
      web?: any;
    };
  };
  spaceSettingsOverriding?: {
    [spaceId: string]: ReturnType<typeof diff>;
  };
  deviceSettingsOverriding?: {
    [deviceId: string]: ReturnType<typeof diff>;
  };
  isPublished: boolean;
  deviceType: DeviceTypeEnum;
  supportedLanguages?: string[];
  defaultLanguage?: string;
  multiLanguageSupport: boolean;
  installationGroupId?: string;
  sku?: string;
  autoBuild?: boolean;
  appContainerGridappId?: string;
  enableLayoutV2: boolean;
}

export enum InstallationSettingsLevel {
  GLOBAL = 'global',
  SPACE = 'space',
  DEVICE = 'device'
}
