import { PhyhubDevicesListQueryParams } from './use-phyhub-devices-list';
import { PhyhubDevicesGetQueryParams } from './use-phyhub-devices-get';
import { PhyhubDevicesGetReportQueryParams } from './use-phyhub-devices-get-report';

const phyhubDevicesQueryKeys = {
  queryPrefix: () => {
    return 'phyhub-devices';
  },
  getPhyhubDevicesListKeys: (
    phyhubDevicesListQueryParams: PhyhubDevicesListQueryParams,
  ) => [
    phyhubDevicesQueryKeys.queryPrefix(),
    'list',
    'tenantId',
    phyhubDevicesListQueryParams.tenantId,
    'page',
    phyhubDevicesListQueryParams.page,
    'limit',
    phyhubDevicesListQueryParams.limit,
      'filters',
      JSON.stringify(phyhubDevicesListQueryParams.filters || {})
  ],
  getPhyhubDevicesGetKeys: (phyhubDevicesGetQueryParams: PhyhubDevicesGetQueryParams) => [
    phyhubDevicesQueryKeys.queryPrefix(),
    'get',
    'tenantId',
    phyhubDevicesGetQueryParams.tenantId,
    'deviceId',
    phyhubDevicesGetQueryParams.deviceId,
  ],
  getPhyhubDevicesReportKeys: (
    phyhubDevicesGetReportQueryParams: PhyhubDevicesGetReportQueryParams,
  ) => [
    phyhubDevicesQueryKeys.queryPrefix(),
    'get',
    'tenantId',
    phyhubDevicesGetReportQueryParams.tenantId,
  ],
};

export default phyhubDevicesQueryKeys;
