import React, { useCallback, useEffect, useMemo } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import AppsLibraryList from '../../../../common/apps-library/gridapp-list/gridapp-list.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import OrganisationApp from '../../../../../store/types/organisation-app';
import GridappTypeEnum from '../../../../../store/types/gridapp-type.enum';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';
import {
  GridappSpecialGroupTypes,
  GridappAccess,
  GroupedGridapps,
} from '../../../../../store/types/gridapp';
import useMarketplaceGridapps from '../../../../common/use-marketplace-gridapps/use-marketplace-gridapps';

type AppsLibraryListContainerProps = Pick<
  React.ComponentProps<typeof AppsLibraryList>,
  'match' | 'history' | 'location'
>;

const deviceTypes: { [s: string]: string } = {
  [GridappTypeEnum.MOBILE]: DeviceTypeEnum.MOBILE_WPA,
  [GridappTypeEnum.IOT_MODULE]: DeviceTypeEnum.IOT,
};

const AppsLibraryListContainer = (props: AppsLibraryListContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
    history,
  } = props;

  const { t } = useTranslation();

  const gridappsState = useMarketplaceGridapps(organisationId);

  const { gridapps, gridappsLoading, gridappsError } = useMemo(
    () => ({
      gridapps: gridappsState.data ? gridappsState.data : [],
      gridappsLoading: gridappsState.isLoading,
      gridappsError: gridappsState.isError,
    }),
    [gridappsState],
  );

  const { createApp, fetchReleases, fetchApps } = useActions<RootModel>((actions) => ({
    fetchReleases: actions.releases.fetch,
    createApp: (data: Partial<OrganisationApp>) =>
      actions.apps.create({ data, key: organisationId }),
    fetchApps: actions.organisationApps.fetch,
  }));

  const { releases } = useStore(
    (state: RootState) => ({
      releases: state.releases.data,
    }),
    [organisationId],
  );

  useEffect(() => {
    fetchReleases({ deviceType: DeviceTypeEnum.WPA });
  }, [fetchReleases]);

  const installGridapp = useCallback(
    async ({
      id,
      displayName,
      type,
      installationGroupId,
    }: {
      id: string;
      type: GridappTypeEnum;
      displayName: string;
      installationGroupId: string;
    }) => {
      const deviceType = deviceTypes[type] || DeviceTypeEnum.WPA;
      const appId = await createApp({
        displayName,
        architecture: 'x64',
        deviceType,
        organizationId: organisationId,
        provider: 'app-container',
        release: releases[0],
        autoBuild: true,
        appContainerGridappId: id,
        installationGroupId,
      });
      await fetchApps({ organizationId: organisationId, silent: true });

      let redirectLocation;
      if ([GridappTypeEnum.IOT_MODULE, GridappTypeEnum.SCREEN].includes(type)) {
        redirectLocation = `/organisations/${organisationId}/apps/installations/${appId}/devices-universal`;
      } else {
        redirectLocation = `/organisations/${organisationId}/apps/installations/${appId}/builds`;
      }

      history.push(redirectLocation);
    },
    [createApp, fetchApps, history, organisationId, releases],
  );

  const groupedGridapps = useMemo(() => {
    if (gridappsLoading) return [];

    const appsAccessGroups: GroupedGridapps[] = [
      {
        type: GridappSpecialGroupTypes.SPECIAL_OFFER_APPS,
        title: t('specialOffers'),
        apps: [],
      },
      {
        type: GridappSpecialGroupTypes.ALL_APPS,
        title: t('allApps'),
        apps: [],
      },
    ];

    gridapps.forEach((app) => {
      const specialGroupIndex = appsAccessGroups.findIndex(
        (group) => group.type === GridappSpecialGroupTypes.SPECIAL_OFFER_APPS,
      );
      const allAppsGroupIndex = appsAccessGroups.findIndex(
        (group) => group.type === GridappSpecialGroupTypes.ALL_APPS,
      );
      let groupIndex = allAppsGroupIndex;

      if (app.access === GridappAccess.SPECIFIC_TENANTS) {
        groupIndex = specialGroupIndex;
      }

      appsAccessGroups[groupIndex].apps.push(app);
    });

    return appsAccessGroups;
  }, [gridapps, gridappsLoading, t]);

  return (
    <AppsLibraryList
      {...props}
      isError={!!gridappsError}
      isLoading={gridappsLoading}
      groupedGridapps={groupedGridapps}
      installGridapp={installGridapp}
    />
  );
};

export default AppsLibraryListContainer;
