import React, { FC, useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from 'easy-peasy';
import PhyhubDevicesContainer from '../../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import { RootState } from '../../../../../store/models/root.model';
import styled from '@emotion/styled';
import { Button } from 'antd';
import ConnectSetupDeviceUniversalModal from '../devices-universal/connect-setup-device-universal-modal/connect-setup-device-universal-modal.component';
import OrganisationApp from '../../../../../store/types/organisation-app';
import phyhubDevicesQueryKeys from '../../../../../store/hooks/phyhub-devices/phyhub-devices-query-keys';
import { useQueryClient } from 'react-query';
import { PhyhubDeviceListParamsFiltersOperatorEnum } from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';

interface AppPhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string; appId: string }> {}

const InstallationPhyhubDevicesContainer: FC<AppPhyhubDevicesContainerProps> = (
  props,
) => {
  const {
    match: {
      // TODO: Rename organisationId to organizationId to match the API
      params: { appId, organisationId: tenantId },
    },
  } = props;

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [isConnectOrSetupModalVisible, setIsConnectOrSetupModalVisible] = useState(false);

  const handleConnectOrSetupModalOpen = useCallback(() => {
    setIsConnectOrSetupModalVisible(true);
  }, []);

  const handleConnectOrSetupModalClose = useCallback(async () => {
    setIsConnectOrSetupModalVisible(false);
    // KP 2024-12-07: This is temporary logic before we create dedicated Phyhub connect or create device modal
    await queryClient.invalidateQueries([
      phyhubDevicesQueryKeys.queryPrefix(),
      'list',
      'tenantId',
      tenantId,
    ]);
  }, [queryClient, tenantId]);

  const { filterOptions } = usePhyhubDeviceFilters({ installations: [], spaces: [] });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const { app } = useStore<
    RootState,
    {
      app: OrganisationApp | null;
    }
  >((state) => {
    const organisationApp =
      (state.organisationApps.data &&
        state.organisationApps.data[tenantId] &&
        state.organisationApps.data[tenantId][appId]) ||
      null;

    return {
      app: organisationApp,
    };
  });

  const presetFilters = {
    installationIds: {
      op: PhyhubDeviceListParamsFiltersOperatorEnum.IN,
      val: [appId],
    },
  };

  return (
    <>
      <Wrapper>
        <ConnectDeviceButton
          size="large"
          icon="api"
          onClick={handleConnectOrSetupModalOpen}
          data-testid="phyhub-devices-connect-device-button"
        >
          {t('connectDevice')}
        </ConnectDeviceButton>
      </Wrapper>
      <PhyhubDevicesContainer
        tenantId={tenantId}
        filterOptions={filterOptions}
        sortCriteriaOptions={sortCriteriaOptions}
        presetFilters={presetFilters}
      />
      <ConnectSetupDeviceUniversalModal
        {...props}
        isVisible={isConnectOrSetupModalVisible}
        onClose={handleConnectOrSetupModalClose}
        app={app}
      />
    </>
  );
};

const ConnectDeviceButton = styled(Button)`` as any;

const Wrapper = styled.div`
  position: relative;
  padding: 20px 40px;
  margin-bottom: -20px;
  display: flex;
  justify-content: flex-end;
`;

export default InstallationPhyhubDevicesContainer;
