import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { PhyhubDeviceBreakdownReport } from '../../../../../services/phyhub/types/phyhub-device-breakdown-report.interface';
import PhyhubDeviceStatusesBar, {
  ProgressSize,
} from '../../phyhub-device-statuses-bar/phyhub-device-statuses-bar.component';
import PhyhubDevicesSummaryTableContent from './phyhub-devices-summary-table-content/phyhub-devices-summary-table-content.component';
import PhyhubDevicesSummaryTableHeader from './phyhub-devices-summary-table-header/phyhub-devices-summary-table-header.component';

interface PhyhubDevicesSummaryProps {
  report: PhyhubDeviceBreakdownReport;
}

const PhyhubDevicesSummary: FC<PhyhubDevicesSummaryProps> = (props) => {
  const { report } = props;

  const { t } = useTranslation();

  const [isDeviceListVisible, setDeviceListVisible] = useState<boolean>(false);

  const handleDeviceListToggle = useCallback(() => {
    setDeviceListVisible((prevIsDeviceListVisible) => !prevIsDeviceListVisible);
  }, []);

  return (
    <Wrapper>
      <PhyhubDevicesSummaryTableHeader
        isToggleOpen={isDeviceListVisible}
        summaryBreakdown={report.summary}
        onToggleClick={handleDeviceListToggle}
      />
      {isDeviceListVisible ? (
        <PhyhubDevicesSummaryTableContent typeStatusBreakdowns={report.byType} />
      ) : (
        <PhyhubDeviceStatusesBar
          size={ProgressSize.DEFAULT}
          statusesBreakdown={report.summary}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 10px 40px;
`;

export default PhyhubDevicesSummary;
