import React, { useEffect, useCallback, useState, useMemo } from 'react';
import ApiService from '../../../services/api/api.service';
import { DeviceStatusHistory, Status } from './history';
import { getApiUrl } from '../../../utils/env';
import Header from '../../common/app-layout/header/header.component';
import { Table, Input, Tag, Icon, Row, Col, Card, Typography, Tooltip } from 'antd';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import Loader from '../../common/loader/loader-component';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const { Title } = Typography;

const api = new ApiService(getApiUrl());

interface OSSummary {
  total: number;
  stable: number;
  offline: number;
  unstable: number;
  reliability: number;
}

interface Summary {
  total: number;
  stable: number;
  offline: number;
  unstable: number;
  reliability: number;
  osSummary: { [key: string]: OSSummary };
}

const getAllDevices = async () => {
  const devices: [any] = await api.get('/api/edge/devices/all', {
    fields: [
      'name',
      'orgId',
      'type',
      'status',
      'statusLog',
      'serial',
      'env',
      'tag',
      'telemetry.agent.os.os',
      'telemetry.agent.os.arch',
      'telemetry.agent.expires',
      'telemetry.agent.provisionExpires',
    ].join(),
  });

  function countOccurrences(string: string, char: string) {
    // Escape special characters in the char
    const escapedChar = char.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedChar, 'g');
    const matches = string.match(regex);
    return matches ? matches.length : 0;
  }

  const SIX_HOURS_MS = 6 * 60 * 60 * 1000;

  return devices.map((device) => {
    // + : The device was OK (online)
    // - : The device was offline
    // a : The device's agent was not available
    // _ : Placeholder indicating no state change (ignored in rendering).
    // e : The edge component was not available
    // S : The device did not capture screenshots
    // s : The screen was not available
    // h : The hub was not available
    // r : The device did not reboot
    const statusLog = `${device.statusLog.log}`.replace(/[_]/g, '');
    const lastStatusChar = statusLog.charAt(statusLog.length - 1);
    const lastStatusTime = new Date(device.statusLog.updateTime).getTime();
    const currentTime = new Date().getTime();

    // Check for presence of only online and offline statuses
    const onlineStatuses = countOccurrences(statusLog, '+');
    const offlineStatuses = countOccurrences(statusLog, '-');
    const otherStatuses = statusLog.replace(/[+-]/g, '').length;

    if (onlineStatuses >= 1 && offlineStatuses <= 2 && otherStatuses <= 3) {
      device.customStatus = 'stable';
    } else if (onlineStatuses + offlineStatuses + otherStatuses > 1) {
      device.customStatus = 'unstable';
    } else {
      device.customStatus = 'offline';
    }
    // Check if the last status was offline and it's been more than 6 hours
    if (lastStatusChar === '-' && currentTime - lastStatusTime > SIX_HOURS_MS) {
      device.customStatus = 'offline';
    }

    // console.log({
    //   device: device.name,
    //   status: device.customStatus,
    //   onlineStatuses,
    //   offlineStatuses,
    //   otherStatuses,
    //   statusLog,
    //   originalLoc: device.statusLog.log
    // });

    return device;
  });
};

const timeFormat = (time: string) => {
  try {
    const stamp = new Date(time).getTime();
    if (stamp < 0) return '';
    const diff = Math.round((new Date().getTime() - stamp) / 60000);
    if (diff <= 5) return '';
    if (diff < 60) return `${diff} min`;
    if (diff < 60 * 30) return `${Math.round(diff / 60)}h`;
    return `${Math.round(diff / (24 * 60))}d`;
  } catch (e) {
    return '';
  }
};

function List({
  data,
  onReload,
  loading,
}: {
  data: any[];
  onReload: any;
  loading: boolean;
}) {
  const [filter, setFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    setFilter(window.localStorage.getItem('search') || '');
  }, [setFilter]);

  useEffect(() => {
    const storedFilter = window.localStorage.getItem('search') || '';
    setFilter(storedFilter);
    setInputValue(storedFilter);
  }, [setFilter]);

  const debounceSetFilter = useMemo(
    () =>
      debounce((value: string) => {
        setFilter(value);
        window.localStorage.setItem('search', value);
      }, 2000),
    [],
  ); // Adjust the delay (in milliseconds) as needed

  const onChange = useCallback(
    (e: any) => {
      setInputValue(e.target.value);
      debounceSetFilter(e.target.value);
    },
    [debounceSetFilter],
  );

  const info = useMemo(
    () =>
      data.filter(
        ({ name, serial, customStatus }) =>
          (name.includes(filter) || (serial && serial.includes(filter))) &&
          (statusFilter === 'all' || customStatus === statusFilter),
      ),
    [data, filter, statusFilter],
  );

  const summarizedData: Summary = useMemo(() => summarizeDevices(info), [info]);

  const extractNumber = (str: any) => parseInt(`${str}`.replace(/[^0-9]^/g, ''), 10) || 0;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend' as any,
      render: (text: any, r: any) => (
        <>
          {r.orgId && r.uuid ? (
            <Link to={`/organisations/${r.orgId}/operations/devices/v3/${r.uuid}/screen`}>
              {text}
            </Link>
          ) : (
            text
          )}
          {r.env === 'prod' ? null : (
            <Tag color="blue" style={{ marginLeft: 8 }}>
              {r.env}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => (a.status || '').localeCompare(b.status || ''),
      render: (text: any, r: any) => {
        // console.log({r})
        return (
          <>
            <DeviceStatusHistory
              history={r.statusLog.log}
              updateTime={r.statusLog.updateTime}
              small
              narrow
            />
            &nbsp;
            <Status status={r.status} />
            <br />
            <span style={{ color: 'lightgray' }}>
              {timeFormat(r.statusLog.updateTime)}
            </span>
            &nbsp;
            <span style={{ color: 'lightgray' }}>{r.customStatus}</span>
          </>
        );
      },
    },
    {
      title: 'Serial',
      dataIndex: 'serial',
      key: 'serial',
      sorter: (a: any, b: any) => (a.serial || '').localeCompare(b.serial || ''),
    },
    {
      title: 'OS',
      dataIndex: 'telemetry.agent.os.os',
      key: 'telemetry.agent.os.os',
      sorter: (a: any, b: any) =>
        (a['telemetry.agent.os.os'] || '').localeCompare(
          b['telemetry.agent.os.os'] || '',
        ),
      render: (text: any) => text,
    },
    {
      title: 'Architecture',
      dataIndex: 'telemetry.agent.os.arch',
      key: 'telemetry.agent.os.arch',
      sorter: (a: any, b: any) =>
        (a['telemetry.agent.os.arch'] || '').localeCompare(
          b['telemetry.agent.os.arch'] || '',
        ),
      render: (text: any) => text,
    },
    {
      title: 'CA valid',
      dataIndex: 'telemetry.agent.expires',
      render: (text: any) => (text ? extractNumber(text) : null),
      sorter: (a: any, b: any) => {
        const va = extractNumber(a['telemetry.agent.expires'])
          ? extractNumber(a['telemetry.agent.expires'])
          : 9999999999999;
        const vb = extractNumber(b['telemetry.agent.expires'])
          ? extractNumber(b['telemetry.agent.expires'])
          : 9999999999999;
        return va - vb;
      },
      key: 'telemetry.agent.expires',
    },
    {
      title: 'ID valid',
      dataIndex: 'telemetry.agent.provisionExpires',
      key: 'telemetry.agent.provisionExpires',
      render: (text: any) => (text ? extractNumber(text) : null),
      sorter: (a: any, b: any) => {
        const va = extractNumber(a['telemetry.agent.provisionExpires'])
          ? extractNumber(a['telemetry.agent.provisionExpires'])
          : 9999999999999;
        const vb = extractNumber(b['telemetry.agent.provisionExpires'])
          ? extractNumber(b['telemetry.agent.provisionExpires'])
          : 9999999999999;
        return va - vb;
      },
    },
  ];

  const osSummaryColumns = [
    {
      title: 'OS',
      dataIndex: 'os',
      key: 'os',
      sorter: (a: any, b: any) => a.os.localeCompare(b.os),
    },
    {
      title: () => (
        <Tooltip title={t('deviceMonitoring.totalToolTip')}>
          {t('deviceMonitoring.total')}
        </Tooltip>
      ),
      dataIndex: 'total',
      key: 'total',
      sorter: (a: any, b: any) => a.total - b.total,
    },
    {
      title: () => (
        <Tooltip title={t('deviceMonitoring.stableTooltip')}>
          {t('deviceMonitoring.stable')}
        </Tooltip>
      ),
      dataIndex: 'stable',
      key: 'stable',
      sorter: (a: any, b: any) => a.stable - b.stable,
    },
    {
      title: () => (
        <Tooltip title={t('deviceMonitoring.unstableTooltip')}>
          {t('deviceMonitoring.unstable')}
        </Tooltip>
      ),
      dataIndex: 'unstable',
      key: 'unstable',
      sorter: (a: any, b: any) => a.unstable - b.unstable,
    },
    {
      title: () => (
        <Tooltip title={t('deviceMonitoring.offlineTooltip')}>
          {t('deviceMonitoring.offline')}
        </Tooltip>
      ),
      dataIndex: 'offline',
      key: 'offline',
      sorter: (a: any, b: any) => a.offline - b.offline,
    },
    {
      title: () => (
        <Tooltip title={t('deviceMonitoring.reliableTooltip')}>
          {t('deviceMonitoring.reliability')}
        </Tooltip>
      ),
      dataIndex: 'reliability',
      key: 'reliability',
      sorter: (a: any, b: any) => a.reliability - b.reliability,
    },
  ];

  return (
    <>
      <Input.Search
        style={{ width: 400, marginBottom: '1em' }}
        placeholder={t('gridDeviceSearchPlaceholder')}
        value={inputValue}
        onChange={onChange}
        onSearch={onReload}
        enterButton={<Icon type={loading ? 'loading' : 'search'} />}
      />
      <Row
        gutter={16}
        style={{ marginBottom: '1em', display: 'flex', flexWrap: 'nowrap' }}
      >
        <Col style={{ flex: 1 }}>
          <Card
            title={'Total'}
            bordered={true}
            headStyle={statusFilter === 'all' ? activeCardHeaderStyle : {}}
            onClick={() => setStatusFilter('all')}
          >
            {summarizedData.total}
          </Card>
        </Col>
        <Col style={{ flex: 1 }}>
          <Card
            title={'Stable'}
            bordered={false}
            headStyle={statusFilter === 'stable' ? activeCardHeaderStyle : {}}
            onClick={() => setStatusFilter('stable')}
          >
            {summarizedData.stable}
          </Card>
        </Col>
        <Col style={{ flex: 1 }}>
          <Card
            title={'Unstable'}
            bordered={false}
            headStyle={statusFilter === 'unstable' ? activeCardHeaderStyle : {}}
            onClick={() => setStatusFilter('unstable')}
          >
            {summarizedData.unstable}
          </Card>
        </Col>
        <Col style={{ flex: 1 }}>
          <Card
            title={'Offline'}
            bordered={false}
            headStyle={statusFilter === 'offline' ? activeCardHeaderStyle : {}}
            onClick={() => setStatusFilter('offline')}
          >
            {summarizedData.offline}
          </Card>
        </Col>
        <Col style={{ flex: 1 }}>
          <Card
            title={'Reliability'}
            bordered={false}
            headStyle={statusFilter === 'reliability' ? activeCardHeaderStyle : {}}
            onClick={() => setStatusFilter('reliability')}
          >
            {summarizedData.reliability}
          </Card>
        </Col>
      </Row>

      <Table
        title={() => 'OS Summary'}
        columns={osSummaryColumns}
        dataSource={Object.keys(summarizedData.osSummary).map((os) => ({
          key: os,
          os: os,
          total: summarizedData.osSummary[os].total,
          stable: summarizedData.osSummary[os].stable,
          unstable: summarizedData.osSummary[os].unstable,
          offline: summarizedData.osSummary[os].offline,
          reliability: summarizedData.osSummary[os].reliability,
        }))}
        pagination={false}
      />
      <Table
        size="small"
        columns={columns}
        dataSource={info}
        pagination={false}
        bodyStyle={{ margin: 0 }}
      />
    </>
  );
}

const summarizeDevices = (devices: any[]): Summary => {
  let stable = 0;
  let offline = 0;
  let unstable = 0;
  const osSummary: { [key: string]: OSSummary } = {};

  devices.forEach((device) => {
    const status: 'stable' | 'offline' | 'unstable' = device.customStatus;
    if (status === 'offline') {
      offline += 1;
    } else if (status === 'stable') {
      stable += 1;
    } else {
      unstable += 1;
    }
    const os = device['telemetry.agent.os.os'] || device.type;
    const key = `${os}-${device['telemetry.agent.os.arch']}`;

    if (!osSummary[key]) {
      osSummary[key] = { total: 0, stable: 0, offline: 0, unstable: 0, reliability: 0 };
    }
    osSummary[key][status] += 1;
  });

  Object.keys(osSummary).forEach((key) => {
    const os = osSummary[key];
    osSummary[key].total = os.stable + os.offline + os.unstable;
    osSummary[key].reliability =
      Math.round((osSummary[key].stable / osSummary[key].total) * 100) || 0;
  });

  // Convert osSummary to an array and sort it
  const sortedOsSummary = Object.entries(osSummary)
    .sort((a, b) => b[1].total - a[1].total)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const total = devices.length;
  const reliability = Math.round((stable / total) * 100) || 0;
  return { total, stable, offline, unstable, reliability, osSummary: sortedOsSummary };
};

const DeviceMonitoring = () => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const onReload = useCallback(async () => {
    try {
      setLoading(true);
      const devices: any[] = await getAllDevices();
      devices.forEach((device) => {
        device['telemetry.agent.os.os'] = device['telemetry.agent.os.os'] || device.type;
      });
      devices.sort((a, b) => a.name.localeCompare(b.name));
      setData(devices);
      setLoading(false);
    } catch (err) {
      setError((err as Error).toString());
    }
  }, [setError, setLoading, setData]);

  useEffect(() => {
    onReload();
  }, [onReload]);

  if (error)
    return (
      <Row>
        <ErrorCard>
          <ErrorIcon type="frown" />
          <Message level={1}>{t('somethingWentWrong')}</Message>
        </ErrorCard>
      </Row>
    );

  return (
    <>
      <Loader loading={loading}>
        <Header title="Devices" />
        <div className="content-body">
          <List data={data} onReload={onReload} loading={loading} />
        </div>
      </Loader>
    </>
  );
};

const ErrorIcon = styled(Icon)`
  font-size: 64px;
  margin-bottom: 16px;
`;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
`;

const activeCardHeaderStyle = {
  backgroundColor: '#2364AA',
  color: '#ffffff',
}; // Customize the header color and text color

const Message = styled(Title)`
  margin-top: 12px;
`;

export default DeviceMonitoring;
