import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Alert, Collapse, Modal } from 'antd';
import moment from 'moment';
import semver from 'semver';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDevicesPreviewTable from '../../phyhub-devices-preview-table/phyhub-devices-preview-table.component';
import PhyhubDevicesUpdateOsControls from './phyhub-devices-update-os-controls/phyhub-devices-update-os-controls.component';
import { PhyhubDeviceVersion } from '../../../../../services/phyhub/types/phyhub-device-version.interface';

interface PhyhubDevicesUpdateOsModalProps {
  isVisible: boolean;
  devices: PhyhubDevice[];
  defaultTargetVersion: string;
  availableVersions: PhyhubDeviceVersion[];
  onCancel: () => void;
  onUpdateOsConfirm: (
    deviceIds: string[],
    plannedTimestamp: moment.Moment,
    targetVersion: string,
  ) => void;
}

const PhyhubDevicesUpdateOsModal: FC<PhyhubDevicesUpdateOsModalProps> = (props) => {
  const {
    isVisible,
    defaultTargetVersion,
    devices,
    availableVersions,
    onUpdateOsConfirm,
  } = props;

  const { t } = useTranslation();

  const [plannedTimestamp, setPlannedTimestamp] = useState<moment.Moment>(moment());

  const [targetVersion, setTargetVersion] = useState<string>(defaultTargetVersion);

  const devicesForUpgrade = useMemo<PhyhubDevice[]>(
    () =>
      devices.filter(
        (device) => device.os && semver.gt(targetVersion, device.os.version),
      ),
    [targetVersion, devices],
  );

  const devicesForDowngrade = useMemo<PhyhubDevice[]>(
    () =>
      devices.filter(
        (device) => device.os && semver.lt(targetVersion, device.os.version),
      ),
    [targetVersion, devices],
  );

  const devicesForSkip = useMemo<PhyhubDevice[]>(
    () =>
      devices.filter(
        (device) => !device.os || semver.eq(targetVersion, device.os.version),
      ),
    [targetVersion, devices],
  );

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

  useEffect(() => {
    setSelectedDeviceIds(devicesForUpgrade.map((device) => device.id));
  }, [devicesForUpgrade]);

  const handlePlannedTimestampChange = useCallback((value: moment.Moment) => {
    setPlannedTimestamp(value);
  }, []);

  const handleTargetVersionChange = useCallback((value: string) => {
    setTargetVersion(value);
  }, []);

  const handleOk = useCallback(() => {
    onUpdateOsConfirm(selectedDeviceIds, plannedTimestamp, targetVersion);
  }, [plannedTimestamp, targetVersion, selectedDeviceIds, onUpdateOsConfirm]);

  const handleRowSelectionChange = useCallback((selectedRowKeys: string[] | number[]) => {
    const mappedRowKeys: string[] = [];

    selectedRowKeys.forEach((key: string | number) => {
      mappedRowKeys.push(key.toString());
    });

    setSelectedDeviceIds(mappedRowKeys);
  }, []);

  return (
    <Modal
      visible={isVisible}
      title={t('phyhubDevices.modal.updateOs.title')}
      cancelText={t('phyhubDevices.modal.updateOs.cancelText')}
      okText={t('phyhubDevices.modal.updateOs.okText')}
      onCancel={props.onCancel}
      onOk={handleOk}
    >
      <PhyhubDevicesUpdateOsControls
        targetVersion={targetVersion}
        plannedTimestamp={plannedTimestamp}
        availableVersions={availableVersions}
        onPlannedTimestampChange={handlePlannedTimestampChange}
        onTargetVersionChange={handleTargetVersionChange}
      />
      <AlertWrapper>
        <Alert
          showIcon
          type="info"
          message={t('phyhubDevices.modal.updateOs.alertMessage')}
        />
      </AlertWrapper>
      <Collapse>
        {devicesForUpgrade.length > 0 && (
          <Collapse.Panel
            key="upgraded"
            header={t('phyhubDevices.label.devicesWillBeUpgraded', {
              count: devicesForUpgrade.length,
            })}
          >
            <PhyhubDevicesPreviewTable
              devices={devicesForUpgrade}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedDeviceIds,
                onChange: handleRowSelectionChange,
              }}
            />
          </Collapse.Panel>
        )}
        {devicesForDowngrade.length > 0 && (
          <Collapse.Panel
            key="downgraded"
            header={t('phyhubDevices.label.devicesWillBeDowngraded', {
              count: devicesForDowngrade.length,
            })}
          >
            <PhyhubDevicesPreviewTable devices={devicesForDowngrade} />
          </Collapse.Panel>
        )}
        {devicesForSkip.length > 0 && (
          <Collapse.Panel
            key="skipped"
            header={t('phyhubDevices.label.devicesWillBeSkipped', {
              count: devicesForSkip.length,
            })}
          >
            <PhyhubDevicesPreviewTable devices={devicesForSkip} />
          </Collapse.Panel>
        )}
      </Collapse>
    </Modal>
  );
};

const AlertWrapper = styled.div`
  position: relative;
  margin: 0 0 20px;
`;

export default PhyhubDevicesUpdateOsModal;
