import { PhyhubDeviceArchitectureEnum } from '../../services/phyhub/enums/phyhub-device-architecture.enum';
import { PhyhubDeviceStatusEnum } from '../../services/phyhub/enums/phyhub-device-status.enum';
import { PhyhubDeviceOsTypeEnum } from '../../services/phyhub/enums/phyhub-device-os-type.enum';

export enum PhyhubDeviceTwinTypeEnum {
  DEVICE = 'Device',
  SCREEN = 'Screen',
  EDGE = 'Edge',
  CLOUD = 'Cloud',
  WEB = 'Web',
}

// TODO: Move constants to other directory if necessary

// Maps Phyhub device architecture enum to display name translation key
export const PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP: Record<
  PhyhubDeviceArchitectureEnum,
  string
> = {
  [PhyhubDeviceArchitectureEnum.ARM_64]: 'phyhubDeviceArchitectureOption.arm64',
  [PhyhubDeviceArchitectureEnum.ARM_V7]: 'phyhubDeviceArchitectureOption.armv7',
  [PhyhubDeviceArchitectureEnum.X86_64]: 'phyhubDeviceArchitectureOption.x86_64',
};

export const PHYHUB_TWIN_DEVICE_TYPE_MAP: Record<PhyhubDeviceTwinTypeEnum, string> = {
  [PhyhubDeviceTwinTypeEnum.DEVICE]: 'phyhubDeviceOption.device',
  [PhyhubDeviceTwinTypeEnum.SCREEN]: 'phyhubDeviceOption.screen',
  [PhyhubDeviceTwinTypeEnum.EDGE]: 'phyhubDeviceOption.edge',
  [PhyhubDeviceTwinTypeEnum.CLOUD]: 'phyhubDeviceOption.cloud',
  [PhyhubDeviceTwinTypeEnum.WEB]: 'phyhubDeviceOption.web',
};

export const PHYHUB_DEVICE_STATUS_TRANSLATION_MAP: Record<
  PhyhubDeviceStatusEnum,
  string
> = {
  [PhyhubDeviceStatusEnum.ONLINE]: 'phyhubDevices.status.online',
  [PhyhubDeviceStatusEnum.OK]: 'phyhubDevices.status.online',
  [PhyhubDeviceStatusEnum.FAILING]: 'phyhubDevices.status.failing',
  [PhyhubDeviceStatusEnum.OFFLINE]: 'phyhubDevices.status.offline',
};

export const PHYHUB_DEVICE_OS_TYPE_TRANSLATION_MAP: Record<
  PhyhubDeviceOsTypeEnum,
  string
> = {
  [PhyhubDeviceOsTypeEnum.GRID_OS]: 'phyhubDevices.osType.gridOs',
  [PhyhubDeviceOsTypeEnum.PHY_OS]: 'phyhubDevices.osType.phyOs',
};
