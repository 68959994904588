import React, { useMemo, useState, useCallback } from 'react';
import { Icon, Layout, Drawer } from 'antd';
import styled from '@emotion/styled';
import User from '../../../../store/types/user';

interface SidebarProps {
  children: React.ReactNode;
  user: User | null;
  logout: () => void;
  switchLanguage: (lang: string) => void;
  header?: React.ReactNode;
}

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  /* @media (max-width: 990px) {
    display: none;
  } */
`;

// Enable scroll & Apply thin scrollbar to avoid width distortion
const SidebarContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media only screen {
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #7d7d7d;
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip: padding-box;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #5e5e5e;
      border: 2px solid transparent;
      background-clip: padding-box;
    }
  }
`;

const MenuButton = styled.button`
  position: absolute;
  z-index: 1;
  right: -48px;
  top: 7px;
  height: 40px;
  width: 40px;
  border: 0;
  outline: 0;
  cursor: pointer;
  color: #fff;
  background-color: #202020;
  transition: background-color 0.3s;
  :hover {
    background-color: #292929;
  }

  @keyframes cssAnimation {
    to {
      visibility: visible;
    }
  }
  animation: cssAnimation 0s 0.15s forwards;
  visibility: hidden;
`;

const CloseButton = styled(MenuButton)`
  right: -45px;
  top: 85px;
  height: 45px;
  width: 45px;
  border-radius: 0 9px 9px 0;

  @keyframes cssAnimation {
    to {
      visibility: visible;
    }
  }
  animation: cssAnimation 0s 0.15s forwards;
  visibility: hidden;

  > .anticon {
    position: relative;
    left: -3px;
  }
`;

const toggleHeaderTitle = (show: boolean) => {
  const headerTitle = document.getElementById('header-title');
  if (headerTitle) headerTitle.style.display = show ? 'inline-block' : 'none';
};

const SidebarComponent = ({ children, header }: SidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isMobileView, setIsMobileView] = useState<boolean>(false);

  const handleCollapse = useCallback((state: boolean) => {
    setIsCollapsed(state);
    toggleHeaderTitle(state);
  }, []);

  const handleBreakpoint = useCallback((broken: boolean) => {
    setIsCollapsed(broken);
    setIsMobileView(broken);
  }, []);

  const collapseButtons = useMemo(() => {
    if (!isMobileView) return null;
    if (isMobileView && isCollapsed)
      return (
        <MenuButton
          onClick={() => {
            handleCollapse(false);
          }}
        >
          <Icon type="menu" />
        </MenuButton>
      );
    return (
      <CloseButton
        onClick={() => {
          handleCollapse(true);
        }}
      >
        <Icon type="left" />
      </CloseButton>
    );
  }, [isMobileView, isCollapsed, handleCollapse]);

  return (
    <Layout.Sider
      theme="dark"
      width={isMobileView ? 0 : 320}
      breakpoint="lg"
      collapsed={isCollapsed}
      collapsedWidth={0}
      trigger={null}
      onBreakpoint={(broken) => {
        handleBreakpoint(broken);
        toggleHeaderTitle(broken);
      }}
    >
      <SidebarWrapper>
        {header}
        {collapseButtons}
        {!isMobileView && <SidebarContent>{children}</SidebarContent>}
      </SidebarWrapper>
      {isMobileView && (
        <DrawerMenu
          visible={!isCollapsed}
          placement="left"
          width={320}
          closable={false}
          onClose={() => {
            handleCollapse(true);
          }}
        >
          <SidebarWrapper>
            {header}
            {collapseButtons}
            <SidebarContent>{children}</SidebarContent>
          </SidebarWrapper>
        </DrawerMenu>
      )}
    </Layout.Sider>
  );
};

const DrawerMenu = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px;
    height: 100%;
  }

  .ant-drawer-mask {
    opacity: 0.25 !important;
    animation: none !important;
  }
`;

export default SidebarComponent;
