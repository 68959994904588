import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortCriteria } from '../../../../../../store/types/sort';
import { PhyhubDevice } from '../../../../../../services/phyhub/types/phyhub-device.interface';

export type PhyhubDeviceSortCriteriaType = SortCriteria & {
  id: keyof PhyhubDevice;
};

interface UsePhyhubDeviceSorts {
  sortCriteriaOptions: SortCriteria[];
}

export default function usePhyhubDeviceSortOptions(): UsePhyhubDeviceSorts {
  const { t } = useTranslation();

  const sortCriteriaOptions = useMemo<PhyhubDeviceSortCriteriaType[]>(() => {
    return [
      {
        id: 'createdAt',
        label: t('sortDevice.dateAdded'),
        type: 'date',
      },
      {
        id: 'updatedAt',
        label: t('sortDevice.lastUpdated'),
        type: 'date',
      },
      {
        id: 'displayName',
        label: t('sortDevice.deviceName'),
        type: 'string',
      },
    ];
  }, [t]);

  return {
    sortCriteriaOptions,
  };
}
