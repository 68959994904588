import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css, Global } from '@emotion/core';
import 'react-phone-number-input/style.css';
import {
  Alert,
  Checkbox as CheckboxAntd,
  Form as FormAntd,
  Icon,
  Input as InputAntd,
  Select,
} from 'antd';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ReactCountryFlag from 'react-country-flag';
import { RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/es/form/Form';
import classNames from 'classnames';
import { SelectValue } from 'antd/lib/select';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';
import { useQueryString } from '../../common/use-query-string-param/use-query-string-param';
import { getSortedCountries } from '../../../utils/countries';
import User from '../../../store/types/user';
import Organisation from '../../../store/types/organisation';
import ErrorView from '../../common/error-view/error-view.component';
import { guessClientCountry } from '../../../utils/client-location';
import AppButton from '../../common/app-button/app-button.component';
import VoucherDataType, {
  VoucherType as VoucherTypeEnum,
} from '../../../store/types/voucher';
import Voucher from '../voucher/voucher.component';
import OnboardingInfo from '../../../store/types/onboarding-info';
import OnboardingEnterpriseAgreementDetails from './enterprise-agreement/enterprise-agreement-details.component';
import BillingAddress from './component/billing-address.component';
import { ApiError } from '../../../services/api/api-error';
import { passwordRegExp } from '../../../utils/validation/password';
import OnboardingPanelLayout from '../onboarding-panel-layout/onboarding-panel-layout.component';
import Address from '../../../store/types/address';
import {
  getDataResidencyInfo,
  getDataResidencyByCountry,
} from '../../../utils/data-residency';

const FormItem = FormAntd.Item;

type OnboardingRegistrationComponentProps = {
  loading: boolean;
  error: ApiError | null;
  user: User | null;
  signup: (params: any) => Promise<Organisation>;
  validateVatId: (params: any) => Promise<any>;
  validateReferralCode: (params: {
    code: string;
    countryCode?: string;
  }) => Promise<{ valid: boolean; voucher?: VoucherDataType }>;
  fetchOnboarding: (params: { token: string; countryCode?: string }) => Promise<void>;
  onboardingInfo: OnboardingInfo | null;
} & RouteComponentProps &
  FormComponentProps;

const countries = getSortedCountries();

const passwordPartRegex = {
  capitalLetter: /[A-Z]/,
  specialCharacter: /[#?!@$.%^&*-]/,
};

interface SignupFormValues {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  vatId?: string;
  password?: string;
  tosConsent: boolean;
  marketingConsent: boolean;
  country: string;
  dataResidency: string;
  tenantDisplayName: string;
  billingAddress?: Address;
}

const countryFlagStyle = {
  width: '24px',
  height: '16px',
  backgroundSize: 'cover',
  borderWidth: '2px',
  borderColor: 'blue',
  marginRight: '8px',
};

const mapFormValuesToSignupFormValues = (formValues: SignupFormValues) => ({
  ...formValues,
  ...(formValues.billingAddress && {
    billingAddress: { ...formValues.billingAddress, country: formValues.country },
  }),
});

const OnboardingRegistrationComponent = (props: OnboardingRegistrationComponentProps) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    hasCapitalLetter: false,
    hasSpecialCharacter: false,
    hasAtleast10Characters: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorKey, setErrorKey] = useState<{ key: string; eventId?: string }>();

  /*
  const [isTosModalVisible, setIsTosModalVisible] = useState(false);
*/
  const [codeVoucher, setCodeVoucher] = useState<VoucherDataType | undefined>(undefined);

  const queryStringObject = useQueryString();
  const params = {
    token: queryStringObject.t as string,
    origin: queryStringObject.o as string,
  };

  const { t } = useTranslation();
  const {
    loading,
    error,
    user,
    fetchOnboarding,
    onboardingInfo,
    signup,
    history,
    form: formAntd,
    validateVatId,
    validateReferralCode,
  } = props;
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    isFieldTouched,
    getFieldError,
    validateFields,
    getFieldValue,
  } = formAntd;
  const isLoggedIn = !!user;
  const { enterpriseAgreement = null } = onboardingInfo || {};
  const isEnterpriseAgreementForExistingTenant = enterpriseAgreement
    ? !!enterpriseAgreement.organizationId
    : false;
  const { billingAddress: agreementBillingAddress = undefined } =
    enterpriseAgreement || {};

  useEffect(() => {
    if (onboardingInfo) {
      if (onboardingInfo.email) {
        setFieldsValue({
          email: onboardingInfo.email,
        });
      }
      if (
        onboardingInfo.voucher &&
        onboardingInfo.voucher.type === VoucherTypeEnum.REFERRAL_CODE
      ) {
        setFieldsValue({
          referralCode: onboardingInfo.voucher.code,
        });
        validateFields(['referralCode']);
      }

      if (onboardingInfo.enterpriseAgreement) {
        const {
          organizationConfiguration,
          tenantAdminUser,
        } = onboardingInfo.enterpriseAgreement;
        const { firstName, lastName, phoneNumber, email } = tenantAdminUser;
        const { vatId = '', tenantName = '', country = '', dataResidency = '' } =
          organizationConfiguration || {};

        setFieldsValue({
          firstName,
          lastName,
          email,
          phoneNumber,
          tenantDisplayName: tenantName,
          vatId,
          ...(country && { country }),
          dataResidency: dataResidency || getDataResidencyByCountry(country),
        });

        if (vatId) {
          validateFields(['vatId']);
        }
      }
    }
  }, [onboardingInfo, setFieldsValue, validateFields]);

  useEffect(() => {
    async function fetchData() {
      await guessClientCountry().then(async (country) => {
        setFieldsValue({
          country,
        });
        if (params.token) {
          await fetchOnboarding({ token: params.token, countryCode: country });
        }
      });
    }
    fetchData();
  }, [setFieldsValue, fetchOnboarding, params.token]);

  const handleFormSubmit = useCallback(
    async (ev: React.SyntheticEvent) => {
      ev.preventDefault();
      validateFieldsAndScroll(
        { scroll: { offsetTop: 100 } },
        async (err: any, values: SignupFormValues) => {
          setErrorKey(undefined);

          if (!err) {
            try {
              setIsSubmitting(true);
              const { id } = await signup({
                ...mapFormValuesToSignupFormValues(values),
                token: params.token,
                tosConsent: values.tosConsent || false,
                marketingConsent: values.marketingConsent || false,
              });
              if (onboardingInfo && onboardingInfo.deviceSerial) {
                // new device new account scenario
                history.replace(
                  `/organisations/${id}/device-setup?deviceSerial=${onboardingInfo.deviceSerial}`,
                );
              } else {
                history.replace(`/organisations/${id}`);
              }
            } catch (e) {
              Sentry.withScope((scope) => {
                const enterpriseAgreementId = get(
                  onboardingInfo,
                  'enterpriseAgreement.id',
                );
                const deviceSerial = get(onboardingInfo, 'deviceSerial');
                const email = get(onboardingInfo, 'email');

                scope.setTags({
                  ...(enterpriseAgreementId ? { enterpriseAgreementId } : {}),
                  ...(deviceSerial ? { deviceSerial } : {}),
                });
                scope.setExtras({
                  ...(email ? { email } : {}),
                  error: e.response.data.errors,
                });

                const newErr = new Error(e.response.data.message);
                newErr.name = 'Onboarding Sign-up Error';
                const eventId = Sentry.captureException(newErr);
                setErrorKey({
                  key: 'accountRegistration.signupErrorDescription',
                  eventId,
                });
              });
            } finally {
              setIsSubmitting(false);
            }
          } else {
            setErrorKey({ key: 'accountRegistration.signupValidationError' });
          }
        },
      );
    },
    [validateFieldsAndScroll, signup, params.token, onboardingInfo, history],
  );

  const handleConfirmBlur = useCallback((e) => {
    const { value } = e.target;
    setConfirmDirty((prevVal) => prevVal || !!value);
  }, []);

  const compareToFirstPassword = useCallback(
    (rule, value, callback) => {
      const { form } = props;
      if (value && value !== form.getFieldValue('password')) {
        callback('Passwords do not match');
      } else {
        callback();
      }
    },
    [props],
  );

  const validateToNextPassword = useCallback(
    (rule, value = '', callback) => {
      const { form } = props;
      setPasswordRequirements({
        hasCapitalLetter: value.match(passwordPartRegex.capitalLetter),
        hasSpecialCharacter: value.match(passwordPartRegex.specialCharacter),
        hasAtleast10Characters: value.length > 9,
      });

      if (value && confirmDirty) {
        form.validateFields(['passwordConfirm'], { force: true });
      }
      callback();
    },
    [confirmDirty, props],
  );

  const validateVatNumber = useCallback(
    async (rule, value = '') => {
      if (value.length < 1) {
        return Promise.resolve();
      }

      if (value.length < 8 || value.length > 14) {
        return Promise.reject(new Error('Invalid VAT ID'));
      }

      try {
        const { valid, companyName } = await validateVatId({
          vatId: value,
          token: params.token,
        });

        if (!valid) {
          return Promise.reject(new Error('Invalid VAT ID'));
        }

        if (!isFieldTouched('tenantDisplayName') && companyName) {
          setFieldsValue({
            tenantDisplayName: companyName,
          });
        }

        const prefilledBillingName = agreementBillingAddress
          ? agreementBillingAddress.name
          : '';
        if (!isFieldTouched('billingAddress.name') && !prefilledBillingName) {
          setFieldsValue({
            billingAddress: { ...agreementBillingAddress, name: companyName },
          });
        }

        return Promise.resolve();
      } catch {
        // this call should not error, let's not block sign up
        return Promise.resolve();
      }
    },
    [
      validateVatId,
      params.token,
      isFieldTouched,
      agreementBillingAddress,
      setFieldsValue,
    ],
  );

  const validateReferralCodeVoucher = useCallback(
    async (rule, value = '') => {
      if (value.length < 1) {
        setCodeVoucher(undefined);
        return Promise.resolve();
      }

      if (value.length < 3 || value.length > 50) {
        setCodeVoucher(undefined);
        return Promise.reject(new Error('Invalid referral code'));
      }

      try {
        const { valid, voucher } = await validateReferralCode({
          code: value,
          countryCode: getFieldValue('country'),
        });
        if (!valid) {
          setCodeVoucher(undefined);
          return Promise.reject(new Error('Invalid referral code'));
        }
        if (voucher && (!codeVoucher || codeVoucher.code !== voucher.code)) {
          setCodeVoucher(voucher);
        }
        return Promise.resolve();
      } catch {
        setCodeVoucher(undefined);
        // this call should not error, let's not block sign up
        return Promise.reject(new Error('Invalid referral code'));
      }
    },
    [codeVoucher, getFieldValue, validateReferralCode],
  );

  const validatePhoneNumber = useCallback(async (rule, value = '', callback) => {
    return isValidPhoneNumber(value)
      ? callback()
      : callback(new Error('Invalid phone number'));
  }, []);

  const handleCountryChange = useCallback(
    (country: SelectValue) => {
      const dataResidency = !isFieldTouched('dataResidency')
        ? getDataResidencyByCountry(country as string)
        : undefined;

      setFieldsValue({
        ...(dataResidency && { dataResidency }),
      });
    },
    [isFieldTouched, setFieldsValue],
  );

  /*
  const handleTosModalCloseButtonClick = useCallback(() => {
    setIsTosModalVisible(false);
  }, []);
*/

  const noToken = !params.token ? <ErrorView /> : null;

  const email =
    (user && user.email) || (onboardingInfo ? onboardingInfo.email : undefined);

  const { dataResidencyOptions } = getDataResidencyInfo();

  return (
    <>
      <Global
        styles={{
          body: {
            height: 'auto',
            overflow: 'auto',
          },
          html: {
            height: 'auto',
          },
        }}
      />
      <OnboardingPanelLayout
        loading={!onboardingInfo || loading}
        error={error || noToken}
      >
        <PageTitle>{t('accountRegistration.accountSetup')}</PageTitle>
        <PageSubTitle>{email}</PageSubTitle>
        <Form onSubmit={handleFormSubmit}>
          {!isEnterpriseAgreementForExistingTenant && (
            <Section>
              <FormRow>
                <Label htmlFor="country">
                  {t('accountRegistration.country')}
                  <LabelDescription>
                    {t('accountRegistration.chooseLocation')}
                  </LabelDescription>
                </Label>
                <FormItem label="" hasFeedback>
                  {getFieldDecorator('country', {
                    initialValue: 'SE',
                  })(
                    <Select size="large" onChange={handleCountryChange}>
                      {countries.names.map((countryName, index) => (
                        <Select.Option
                          key={countries.codes[index]}
                          value={countries.codes[index]}
                        >
                          <OptionWrapper>
                            <FlagIconWrapper>
                              <ReactCountryFlag
                                svg
                                code={countries.codes[index]}
                                styleProps={countryFlagStyle}
                              />
                            </FlagIconWrapper>
                            <SelectLabel>{countryName}</SelectLabel>
                          </OptionWrapper>
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </FormRow>
              <FormRow>
                <Label htmlFor="dataResidency">
                  {t('accountRegistration.dataResidency')}
                </Label>
                <FormItem label="" hasFeedback>
                  {getFieldDecorator('dataResidency', {
                    rules: [
                      {
                        required: true,
                        message: 'Choose a data residency',
                      },
                    ],
                  })(
                    <Select size="large">
                      {dataResidencyOptions.map((dataResidency) => (
                        <Select.Option key={dataResidency} value={dataResidency}>
                          <SelectLabel>{dataResidency}</SelectLabel>
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </FormRow>
            </Section>
          )}

          <Section>
            {onboardingInfo && onboardingInfo.shouldCollectUserInfo && (
              <>
                <FormGroup>
                  <FormRow>
                    <Label htmlFor="firstName">
                      {t('accountRegistration.firstName')}
                    </Label>
                    <FormItem label="" hasFeedback>
                      {getFieldDecorator('firstName', {
                        rules: [
                          {
                            required: true,
                            message: 'Enter first name',
                          },
                          {
                            min: 2,
                            message: 'Name is too short',
                          },
                          {
                            max: 50,
                            message: 'Name is too long',
                          },
                        ],
                      })(<Input />)}
                    </FormItem>
                  </FormRow>
                  <FormRow>
                    <Label htmlFor="lastName">{t('accountRegistration.lastName')}</Label>
                    <FormItem label="" hasFeedback>
                      {getFieldDecorator('lastName', {
                        rules: [
                          {
                            required: true,
                            message: 'Enter last name',
                          },
                          {
                            min: 2,
                            message: 'Name is too short',
                          },
                          {
                            max: 50,
                            message: 'Name is too long',
                          },
                        ],
                      })(<Input />)}
                    </FormItem>
                  </FormRow>
                </FormGroup>
                <FormRow>
                  <Label htmlFor="email">{t('accountRegistration.emailAddress')}</Label>
                  <FormItem label="" hasFeedback>
                    {getFieldDecorator('email', {
                      initialValue: email || '',
                      rules: [
                        {
                          type: 'email',
                          message: 'Invalid email',
                        },
                        {
                          required: true,
                          message: 'Enter an email',
                        },
                      ],
                    })(<Input disabled={!!email} />)}
                  </FormItem>
                </FormRow>
                <FormRow>
                  <Label htmlFor="phoneNumber">
                    {t('accountRegistration.phoneNumber')}
                  </Label>
                  <FormItem label="" hasFeedback>
                    {getFieldDecorator('phoneNumber', {
                      validateFirst: true,
                      rules: [
                        {
                          required: true,
                          message: 'Enter phone number',
                        },
                        {
                          validator: validatePhoneNumber,
                        },
                      ],
                    })(
                      <PhoneNumberInput
                        hasError={!!getFieldError('phoneNumber')}
                        defaultCountry={
                          !isFieldTouched('phoneNumber')
                            ? getFieldValue('country')
                            : undefined
                        }
                      />,
                    )}
                  </FormItem>
                </FormRow>
              </>
            )}
            {!isEnterpriseAgreementForExistingTenant && (
              <>
                <FormRow>
                  <Label htmlFor="company">
                    {t('accountRegistration.companyVatId')}{' '}
                    <LabelOptional>{`(${t(
                      'accountRegistration.optional',
                    )})`}</LabelOptional>
                  </Label>
                  <FormItem label="" hasFeedback>
                    {getFieldDecorator('vatId', {
                      validateTrigger: 'onBlur',
                      rules: [
                        {
                          validator: validateVatNumber,
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </FormRow>
                <FormRow>
                  <Label htmlFor="tenantDisplayName">
                    {t('accountRegistration.tenantDisplayName')}
                  </Label>
                  <FormItem label="" hasFeedback>
                    {getFieldDecorator('tenantDisplayName', {
                      rules: [
                        {
                          required: true,
                          message: 'Enter your tenant name',
                        },
                        {
                          min: 3,
                          message: 'Name is too short',
                        },
                        {
                          max: 50,
                          message: 'Name is too long',
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </FormRow>
              </>
            )}
            {!isLoggedIn && !enterpriseAgreement && (
              <FormRow>
                <Label htmlFor="referralCode">
                  {t('accountRegistration.referralCode')}
                </Label>
                <FormItem label="" hasFeedback>
                  {getFieldDecorator('referralCode', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        validator: validateReferralCodeVoucher,
                      },
                    ],
                  })(<Input />)}
                </FormItem>
              </FormRow>
            )}
            {codeVoucher && (
              <VoucherContainer>
                <Voucher voucher={codeVoucher} />
              </VoucherContainer>
            )}
          </Section>
          {!isEnterpriseAgreementForExistingTenant && (
            <Section>
              <PageTitle>{t('address.billingAddress')}</PageTitle>
              <BillingAddress form={formAntd} billingAddress={agreementBillingAddress} />
            </Section>
          )}
          {!isLoggedIn && (
            <>
              <PageTitle>{t('accountRegistration.createPassword')}</PageTitle>
              <p>{t('accountRegistration.youPasswordMustContainAtLeast')}</p>
              <PasswordChecklist>
                <PasswordCheckItem active={passwordRequirements.hasCapitalLetter}>
                  <PasswordCheckIcon theme={undefined}>
                    <Icon type="check" />
                  </PasswordCheckIcon>{' '}
                  {t('accountRegistration.capitalLetter')}
                </PasswordCheckItem>
                <PasswordCheckItem active={passwordRequirements.hasSpecialCharacter}>
                  <PasswordCheckIcon theme={undefined}>
                    <Icon type="check" />
                  </PasswordCheckIcon>
                  {t('accountRegistration.specialSymbol')}
                </PasswordCheckItem>
                <PasswordCheckItem active={passwordRequirements.hasAtleast10Characters}>
                  <PasswordCheckIcon theme={undefined}>
                    <Icon type="check" />
                  </PasswordCheckIcon>
                  {t('accountRegistration.numberOfCharacters', { charCount: 10 })}
                </PasswordCheckItem>
              </PasswordChecklist>
              <Section>
                <FormRow>
                  <Label htmlFor="password">{t('accountRegistration.newPassword')}</Label>
                  <FormItem label="" hasFeedback>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          pattern: passwordRegExp,
                          message: 'Invalid password',
                        },
                        {
                          required: true,
                          message: 'Enter password',
                        },
                        {
                          validator: validateToNextPassword,
                        },
                      ],
                    })(<InputPassword />)}
                  </FormItem>
                </FormRow>
                <FormRow>
                  <Label htmlFor="confirmPassword">
                    {t('accountRegistration.confirmYourPassword')}
                  </Label>
                  <FormItem label="" hasFeedback>
                    {getFieldDecorator('passwordConfirm', {
                      rules: [
                        {
                          required: true,
                          message: 'Enter password confirmation',
                        },
                        {
                          validator: compareToFirstPassword,
                        },
                      ],
                    })(<InputPassword onBlur={handleConfirmBlur} />)}
                  </FormItem>
                </FormRow>
              </Section>
            </>
          )}
          {enterpriseAgreement && (
            <Section>
              <FormRow>
                <OnboardingEnterpriseAgreementDetails
                  enterpriseAgreement={enterpriseAgreement}
                />
              </FormRow>
            </Section>
          )}
          <FormRow>
            <FormItem>
              {getFieldDecorator('tosConsent', {
                valuePropName: 'checked',
                initialValue: false,
                rules: [
                  {
                    required: true,
                    transform: (value) => value || undefined,
                    type: 'boolean',
                    message: 'Consent is required',
                  },
                ],
              })(
                <Checkbox>
                  {/* eslint-disable */}
                  {enterpriseAgreement ? (
                    <Trans i18nKey="accountRegistration.termsAgreementStatementEnterprise">
                      I agree to the{' '}
                      <a
                        href="https://phygrid.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>
                      , the
                      <a
                        href="https://phygrid.com/dpa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Processing Addendum
                      </a>{' '}
                      and the{' '}
                      <a
                        href="https://phygrid.com/enterprise-agreement-terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Enterprise Agreement Terms
                      </a>{' '}
                      and any schedules or addenda thereto.
                    </Trans>
                  ) : (
                    <Trans i18nKey="accountRegistration.termsAgreementStatement">
                      I agree to the{' '}
                      <a
                        href="https://phygrid.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>{' '}
                      and the
                      <a
                        href="https://phygrid.com/dpa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Processing Addendum
                      </a>{' '}
                      and any schedules or addenda thereto.
                    </Trans>
                  )}

                  {/* eslint-enable */}
                </Checkbox>,
              )}
            </FormItem>
          </FormRow>
          {!isLoggedIn && (
            <FormRow>
              <FormItem>
                {getFieldDecorator('marketingConsent', {
                  valuePropName: 'checked',
                })(<Checkbox>{t('accountRegistration.subscribeToOmbori')}</Checkbox>)}
              </FormItem>
            </FormRow>
          )}
          <FormRow>
            {errorKey && (
              <FailedAlert
                message={t('accountRegistration.signupErrorTitle')}
                description={
                  <Trans
                    i18nKey={errorKey.key}
                    components={[
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`mailto: ${t('supportEmail')}`}
                      >
                        {{ supportEmail: t('supportEmail') }}
                        {{ eventId: errorKey.eventId || '' }}
                      </a>,
                    ]}
                  />
                }
                type="error"
                showIcon
              />
            )}
            <SignupButton loading={isSubmitting} htmlType="submit">
              <span>{t('accountRegistration.continue')}</span>
            </SignupButton>
          </FormRow>
        </Form>
      </OnboardingPanelLayout>
      {/*      <Modal
        onCancel={handleTosModalCloseButtonClick}
        style={{ top: 32 }}
        width="90vw"
        footer={null}
        visible={isTosModalVisible}
      >
        <TosContainer>
          <Tos onConfirm={handleTosModalCloseButtonClick} />
        </TosContainer>
      </Modal> */}
    </>
  );
};

/*
const TosContainer = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  h1 {
    color: #000 !important;
  }
`;
*/

const FailedAlert = styled(Alert)`
  margin-bottom: 32px;
`;

const PhoneNumberInput = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props: any, ref: React.Ref<HTMLDivElement>) => {
    const { onChange, value, hasError, defaultCountry } = props;

    return (
      <PhoneInputWrapper
        ref={ref}
        className={classNames({ 'has-error': hasError }, 'ant-input-affix-wrapper')}
      >
        <PhoneInput
          onChange={onChange}
          value={value}
          defaultCountry={defaultCountry}
          international
        />
      </PhoneInputWrapper>
    );
  },
);

const VoucherContainer = styled.div`
  position: relative;
`;

const SignupButton = styled(AppButton)`
  width: 100%;
` as any;

const PhoneInputWrapper = styled.div`
  .PhoneInputInput {
    height: 40px;
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 4px;
    padding: 0 8px;
    box-shadow: 0 0 0;
    transition: all 0.3s;
    padding-right: 24px;

    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible,
    &:checked,
    &:hover {
      border-color: #5524da;
      outline-color: #5524da;
    }
  }

  &.has-error {
    .PhoneInputInput {
      border-color: #e3343d;

      &:focus,
      &:active,
      &:focus-within,
      &:focus-visible,
      &:checked,
      &:hover {
        border-color: #e3343d;
        outline-color: #e3343d;
      }
    }
  }
`;

const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const SelectLabel = styled.div`
  text-align: flex-start;
  font-size: 14px;
`;

const FlagIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: -1px;
`;

const Form = styled(FormAntd)`
  font-size: 16px;
`;
const FormRow = styled.div`
  display: block;
  margin-top: 24px;
  width: 100%;
`;
const Section = styled.section`
  display: block;
  padding-bottom: 24px;
  border-bottom: 8px solid #f5f5fa;
`;
const FormGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const Label = styled.label`
  color: #1d1921;
  font-weight: 700;
  display: block;
  width: 100%;
  font-size: 16px;
  padding-bottom: 4px;
`;
const LabelOptional = styled.span`
  font-weight: 300;
`;
const LabelDescription = styled.span`
  display: block;
  font-weight: normal;
  color: #777;
  font-size: 14px;
`;
const Input = styled(InputAntd)`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 4px;
  padding: 0 8px;
  box-shadow: 0 0 0;

  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible,
  &:checked {
    border-color: #5524da;
    outline-color: #5524da;
  }
`;
const InputPassword = styled(InputAntd.Password)`
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0 0 0;

  span {
    border: none;
  }

  &:focus,
  &:active,
  &:focus-within,
  &:focus-visible,
  &:checked {
    border-color: #5524da !important;
    outline-color: #5524da !important;
  }
`;
const Checkbox = styled(CheckboxAntd)`
  width: 100% !important;
  font-weight: 400;
  padding-left: 32px;
  line-height: 1.5;
  .ant-checkbox {
    border: 0;
    margin-left: -32px;
    margin-right: 8px;
  }
`;

const PasswordChecklist = styled.ul`
  margin: 16px 0;
  padding: 0;
  list-style: none;
`;
const PasswordCheckIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5524da;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #fff;

  svg {
    display: none;
  }
`;
const PasswordCheckItem = styled.li<any>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  padding: 4px 0;

  ${(props) =>
    props.active &&
    css`
      ${PasswordCheckIcon} {
        background: #5524da;
      }
      svg {
        display: block;
      }
    `}
`;

const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #1d1921;
  margin-top: 40px;
`;
const PageSubTitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
  color: #1d1921;
  margin-bottom: 24px;
`;

export default FormAntd.create<OnboardingRegistrationComponentProps>()(
  OnboardingRegistrationComponent,
);
