import React, { useCallback, useMemo } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import { Icon } from '../../../../common/schema-form/common';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  useGetNetworkWhitelistRules,
  NetworkWhitelistRule,
  useDeleteNetworkWhitelistRule,
} from '../../../../common/use-network-whitelist-rules';
import { loadingIcon } from '../../../../common/spinner/spinner.component';
import Pagination, { usePagination } from '../../../../common/pagination';
import NetworkWhitelistSettings from './networking-whitelist-settings.component';
import NetworkWhitelistRuleAdd from './networking-whitelist-rule-add.component';
import NetworkWhitelistRuleEdit from './networking-whitelist-rule-edit.component';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import useHasPermissions from '../../../../../utils/auth/use-has-permissions';
import permissionKeys from '../../../../../utils/auth/permissions';

interface NetworkWhitelistRulesProps {
  tenantId: string;
}

const NetworkWhitelistRules = (props: NetworkWhitelistRulesProps) => {
  const { tenantId } = props;

  const isEditAllowed = useHasPermissions(
    tenantId,
    permissionKeys.networkWhitelistRules.update,
  );
  const isDeleteAllowed = useHasPermissions(
    tenantId,
    permissionKeys.networkWhitelistRules.remove,
  );
  const isCreateAllowed = useHasPermissions(
    tenantId,
    permissionKeys.networkWhitelistRules.create,
  );

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const [paginationQueryParams, setPaginationQueryParams] = usePaginationQueryParams({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const sortField = 'createdAt';
  const sortOrder = 'asc';

  const {
    data: networkWhitelistRulesData,
    isLoading: isLoadingNetworkWhitelistRules,
    isFetching: isFetchingNetworkWhitelistRules,
  } = useGetNetworkWhitelistRules(
    tenantId,
    paginationQueryParams.page,
    paginationQueryParams.limit,
    sortField,
    sortOrder,
  );

  const handlePaginationSizeChange = useCallback(
    (_: number, pageSize: number) => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page: defaultPage,
          limit: pageSize,
        };
      });
    },
    [defaultPage, setPaginationQueryParams],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page,
        };
      });
    },
    [setPaginationQueryParams],
  );

  const { mutateAsync: deleteWhitelistRule } = useDeleteNetworkWhitelistRule();

  const handleNetworkWhitelistRuleDelete = useCallback(
    async (networkWhitelistRuleId: string) => {
      try {
        await deleteWhitelistRule(networkWhitelistRuleId);
        message.success(t('networking.networkWhitelistRuleDeleteSuccess'));
      } catch (err) {
        message.error(t('networking.networkWhitelistRuleDeleteFailure'));
      }
    },
    [deleteWhitelistRule, t],
  );

  const columns = useMemo<ColumnProps<NetworkWhitelistRule>[]>(() => {
    const cols: ColumnProps<NetworkWhitelistRule>[] = [
      {
        title: t('environment'),
        key: 'env',
        width: '25%',
        render: (_, record) => <CapitalizedText>{record.env}</CapitalizedText>,
      },
      {
        title: t('networking.hostname'),
        key: 'hostname',
        width: '35%',
        render: (_, record) => record.hostname,
      },
      {
        title: t('lastUpdated'),
        key: 'updatedAt',
        width: '20%',
        render: (_, record) => moment(record.updatedAt).format('DD/MM/YYYY hh:mm A'),
      },
    ];

    if (isEditAllowed || isDeleteAllowed) {
      cols.push({
        title: <Icon type="setting" />,
        align: 'center',
        width: '20%',
        key: 'settings',
        render: (_, record) => {
          return (
            <>
              {isEditAllowed && (
                <NetworkWhitelistRuleEdit
                  tenantId={tenantId}
                  networkWhitelistRule={record}
                />
              )}

              {isDeleteAllowed && (
                <Popconfirm
                  title={t('areYouSureYouWantToDelete')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={() => {
                    handleNetworkWhitelistRuleDelete(record.id);
                  }}
                >
                  <Button type="link">
                    <Icon component={() => <Trash2 color="#FF4D4F" />} />
                  </Button>
                </Popconfirm>
              )}
            </>
          );
        },
      });
    }

    return cols;
  }, [handleNetworkWhitelistRuleDelete, tenantId, t, isDeleteAllowed, isEditAllowed]);

  return (
    <>
      <OptionsWrapper>
        <NetworkWhitelistSettings key="network-whitelist-settings" tenantId={tenantId} />
        {isCreateAllowed && <NetworkWhitelistRuleAdd tenantId={tenantId} />}
      </OptionsWrapper>

      <TableWrap>
        <Table
          dataSource={networkWhitelistRulesData ? networkWhitelistRulesData.docs : []}
          columns={columns}
          rowKey="id"
          pagination={false}
          scroll={{ x: true }}
          size="small"
          loading={{
            indicator: loadingIcon,
            spinning: isLoadingNetworkWhitelistRules || isFetchingNetworkWhitelistRules,
          }}
        />
        <Pagination
          align="right"
          showSizeChanger
          onShowSizeChange={handlePaginationSizeChange}
          current={paginationQueryParams.page}
          pageSize={paginationQueryParams.limit}
          total={networkWhitelistRulesData ? networkWhitelistRulesData.totalDocs : 0}
          onChange={handlePageChange}
          pageSizes={defaultPageSizeOption}
        />
      </TableWrap>
    </>
  );
};

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 40px 0 40px;
  justify-content: flex-end;
  gap: 40px;

  @media screen and (max-width: 991px) {
    padding: 20px 20px 0 20px;
  }
`;

const TableWrap = styled.div`
  position: relative;
  padding: 20px 40px 20px 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }

  .ant-table-thead > tr > th {
    background: #f0f0f0 !important;
    height: 56px;
    margin-left: 20px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-thead > tr > th:first-of-type,
  .ant-table-tbody > tr > td:first-of-type {
    padding: 8px 20px !important;
  }
`;

const CapitalizedText = styled.span`
  text-transform: capitalize;
`;

export default NetworkWhitelistRules;
