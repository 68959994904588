import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Link, RouteComponentProps } from 'react-router-dom';
import Overlay from '../../../common/overlay';
import { Subheader } from '../../../common/app-layout/header';
import { useSkus, Sku } from '../../../common/use-skus';
import { SortCriteria, SortOrderType } from '../../../../store/types/sort';
import usePaginationQueryParams from '../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../common/use-pagination-query-params/use-pagination-query-params';
import { Icon } from '../../../common/schema-form/common';
import { Edit } from 'react-feather';
import PaginatedListTable from '../../../common/paginated-list/paginatied-list-table';
import PaginatedListSearch from '../../../common/paginated-list/paginated-list-search';
import { usePagination } from '../../../common/pagination';

interface SkuListPaginationParams extends PaginationQueryParams {
  sortField?: string;
  sortOrder?: SortOrderType;
}

const SkuList = ({ match }: RouteComponentProps) => {
  const { t } = useTranslation();

  const {page, defaultPage, pageSize, defaultPageSize} = usePagination();

  const defaultSortField = 'sku';
  const defaultSortOrder = 'asc';

  const [paginationQueryParams] = usePaginationQueryParams<SkuListPaginationParams>({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
    sortField: defaultSortField,
    sortOrder: defaultSortOrder,
  });

  const { data: skuData, isLoading, isFetching } = useSkus(
    paginationQueryParams.page,
    paginationQueryParams.limit,
    paginationQueryParams.sortField,
    paginationQueryParams.sortOrder,
    paginationQueryParams.search
  );

  const columns = useMemo<ColumnProps<Sku>[]>(() => {
    return [
      {
        title: t('billingSkus.sku'),
        key: 'sku',
        width: '25%',
        render: (_, record) => (
          <Link to={`${match.url}/${record.id}`}>
            {record.sku}
          </Link>
        ),
      },
      {
        title: t('billingSkus.name'),
        key: 'name',
        width: '40%',
        render: (_, record) => record.name,
      },
      {
        title: t('billingSkus.availability'),
        key: 'availability',
        width: '10%',
        render: (_, record) => <CapitalizeText>{record.availability}</CapitalizeText>
      },
      {
        title: t('billingSkus.currentActivePrice'),
        key: 'price-information',
        width: '15%',
        render: (_, record) => {
          return record.activePrice && record.activePriceValue
            ? `${record.activePriceValue.currency} ${record.activePriceValue.valueDecimal}`
            : 'N/A';
        }
      },
      {
        title: <Icon type='setting' />,
        key: "sku-settings",
        align: "center",
        width: "15%",
        render: (_, record) => {
          return (
            <>
              <Link to={`${match.url}/${record.id}`}>
                <Icon component={() => <Edit />} />
              </Link>
            </>
          )
        }
      }
    ]
  }, [t, match.url]);

  const sortCriteria = useMemo<SortCriteria[]>(() => {
    return [
      {
        id: 'sku',
        label: t('billingSkus.sku'),
        type: 'string',
      },
      {
        id: 'name',
        label: t('billingSkus.name'),
        type: 'string',
      },
      {
        id: 'availability',
        label: t('billingSkus.availability'),
        type: 'string',
      },
    ]
  }, [t]);

  return (
    <>
    <Overlay>
      <Subheader
        components={[
          <SearchWrapper><PaginatedListSearch
          key='sku-search-bar'
          searchPlaceholder={t('billing.searchTransactions')}
          sortCriteria={sortCriteria}
          defaultSortField={defaultSortField}
          defaultSortOrder={defaultSortOrder}
        /></SearchWrapper>,
        ]}
      />
      <ButtonWrap>
        <Link to={`${match.url}/add`}>
          <Button icon="plus" type="primary" size="large">
            {t('billingSkus.addSku')}
          </Button>
        </Link>
      </ButtonWrap>
      <PaginatedListTable<Sku>
        data={skuData!}
        columns={columns}
        isDataLoading={isLoading || isFetching}
      />
    </Overlay>
    </>
  );
};

const SearchWrapper = styled.div`
  width: 100%;
  display: block;
  padding-top: 20px;
  .search-bar > div {
    border: 0;
    padding-bottom: 0;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 40px;

  @media screen and (max-width: 991px) {
    margin-right: 20px;
  }
`;


const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

export default SkuList;
