import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import Masonry from 'react-masonry-css';

interface InstallationsLayoutProps {
  children: ReactNode; // Proper type for children
}

export const InstallationsLayout: React.FC<InstallationsLayoutProps> = ({ children }) => {
  const breakpointColumns = {
    default: 3,
    1300: 2,
    700: 1,
  };

  return (
    <Wrapper>
      <Masonry
        breakpointCols={breakpointColumns}
        className="masonry-grid"
        columnClassName="masonry-column"
      >
        {children}
      </Masonry>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .masonry-grid {
    display: flex;
    margin-left: -20px;
  }
  .masonry-column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style individual children */
  .masonry-column > div {
    margin-bottom: 20px;
  }
`;
