import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Table as AntdTable, Button, Tooltip } from 'antd';
import { GridAssistant } from '@ombori/grid-llm-react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { TableType } from '../../../../../../types';
import Pagination, { usePagination } from '../../../../../common/pagination';
import usePaginationQueryParams from '../../../../../common/use-pagination-query-params';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import Overlay from '../../../../../common/overlay';
import { utcToLocalDate } from '../../../../../../utils/dayjs';
import StoreAIAssistantStatusTag from '../store-ai-assistant-status-tag.component';
import { useStoreAIAssistantsList } from '../../../../../../store/hooks/store-ai/use-store-ai-assistants-list';
import permissionKeys from '../../../../../../utils/auth/permissions';
import usePermissions from '../../../../../../utils/auth/use-permissions';
import { PaginationQueryParams } from '../../../../../common/use-pagination-query-params/use-pagination-query-params';
import StoreAssistantListItemActions from './store-ai-assistants-list-item-actions.component';
import { Icon } from '../../../../../common/schema-form/common';
import ScrollableTooltip from '../../../../../common/scrollable-tooltip.component';

interface StoreAIAssistantsListParams extends PaginationQueryParams {}

export interface StoreAIAssistantListProps
  extends RouteComponentProps<{ organisationId: string }> {}

const StoreAIAssistantList = (props: StoreAIAssistantListProps) => {
  const { match } = props;
  const {
    params: { organisationId: tenantId },
  } = match;

  const { t } = useTranslation();
  const history = useHistory();

  const {
    page,
    pageSize,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const { isAllowed } = usePermissions(tenantId);

  const [paginationQueryParams, setPaginationQueryParams] = usePaginationQueryParams<
    StoreAIAssistantsListParams
  >({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const storeAIAssistantsListState = useStoreAIAssistantsList({
    page: paginationQueryParams.page || defaultPage,
    tenantId,
    limit: paginationQueryParams.limit || defaultPageSize,
  });

  const hasActionSettingsAccess = useMemo(
    (): boolean => isAllowed(permissionKeys.storeAIAssistants.remove),
    [isAllowed],
  );

  const columns = useMemo<ColumnProps<GridAssistant>[]>(() => {
    return [
      {
        title: <StyledNameColumnTitle>{t('storeAI.name')}</StyledNameColumnTitle>,
        key: 'assistantName',
        align: 'left',
        className: 'store-ai-assistant-list-col-name',
        render: (_, item) => {
          return (
            <Tooltip title={item.name}>
              <StyledNameLink to={`${match.url}/${item.id}`}>{item.name}</StyledNameLink>
            </Tooltip>
          );
        },
      },
      {
        title: t('storeAI.status'),
        key: 'status',
        align: 'center',
        width: '100px',
        render: (_, item) => {
          return <StoreAIAssistantStatusTagStyled status={item.status} />;
        },
      },
      {
        title: t('storeAI.lastModified'),
        key: 'createdAt',
        align: 'center',
        width: '150px',
        render: (_, item) => {
          const lastModifiedAt = item.updatedAt || item.createdAt;

          return lastModifiedAt
            ? utcToLocalDate(new Date(lastModifiedAt)).format('DD/MM/YYYY HH:mm')
            : t('storeAI.unknown');
        },
      },
      {
        title: t('storeAI.instructions'),
        key: 'instructions',
        align: 'left',
        className: 'store-ai-assistant-list-col-instructions',
        render: (_, item) => {
          return (
            <ScrollableTooltip title={item.instructions}>
              <ClippedIstruction>{item.instructions}</ClippedIstruction>
            </ScrollableTooltip>
          );
        },
      },
      ...(hasActionSettingsAccess
        ? [
            {
              title: <Icon type="setting" />,
              key: 'actions',
              fixed: 'right',
              width: 64,
              align: 'center',
              render: (_, item) => {
                return <StoreAssistantListItemActions assistant={item} />;
              },
            } as ColumnProps<GridAssistant>,
          ]
        : []),
    ];
  }, [hasActionSettingsAccess, match, t]);

  const handlePageChange = useCallback(
    (page: number): void => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          page,
        };
      });
    },
    [setPaginationQueryParams],
  );

  const handlePaginationSizeChange = useCallback(
    (_: number, pageLimit: number): void => {
      setPaginationQueryParams((prevState) => {
        return {
          ...prevState,
          limit: pageLimit,
          page: defaultPage,
        };
      });
    },
    [defaultPage, setPaginationQueryParams],
  );

  const { totalCount, assistantsData } = useMemo((): { totalCount: number; assistantsData: GridAssistant[] } => {
    if (!storeAIAssistantsListState.data) {
      return {
        totalCount: 0,
        assistantsData: [],
      };
    }

    return {
      totalCount: storeAIAssistantsListState.data.totalCount,
      assistantsData: storeAIAssistantsListState.data.resources,
    };
  }, [storeAIAssistantsListState.data]);

  const isAssistantCreateAllowed = useMemo(
    (): boolean => isAllowed(permissionKeys.storeAIAssistants.create),
    [isAllowed],
  );

  const handleAddNewAssistant = useCallback((): void => {
    history.push(`${match.url}/add`);
  }, [match.url, history]);

  return (
    <>
      <Overlay
        errorOverlay={{
          error: storeAIAssistantsListState.error,
        }}
        spinnerOverlay={{
          isLoading: storeAIAssistantsListState.isLoading,
        }}
      >
        {storeAIAssistantsListState.isSuccess && (
          <StoreAIAssistantListContainer>
            {isAssistantCreateAllowed && (
              <Heading>
                <Button
                  icon="plus"
                  type="primary"
                  size="large"
                  onClick={handleAddNewAssistant}
                >
                  {t('storeAI.newAssistant')}
                </Button>
              </Heading>
            )}
            <Table
              rowKey="id"
              dataSource={assistantsData}
              columns={columns}
              pagination={false}
              size="small"
              scroll={{ x: 600 }}
            />
            <Pagination
              align="right"
              showSizeChanger
              onShowSizeChange={handlePaginationSizeChange}
              current={paginationQueryParams.page}
              pageSize={paginationQueryParams.limit}
              total={totalCount}
              onChange={handlePageChange}
              pageSizes={defaultPageSizeOption}
            />
          </StoreAIAssistantListContainer>
        )}
      </Overlay>
    </>
  );
};

const StoreAIAssistantListContainer = styled.div`
  position: relative;
  padding: 0px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;

const Table = styled(AntdTable)`
  margin-top: 20px;

  .ant-table-thead > tr > th {
    background: #F0F0F0 !important;
    height: 56px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }
  .ant-table-body {
    margin: 0 !important;
  }

  .store-ai-assistant-list-col-name {
    width: 40%;
  }

  .store-ai-assistant-list-col-instructions {
    width: 50%;
  }

  @media (max-width: 768px) {
    .store-ai-assistant-list-col-name {
      width: 250px;
    }

    .store-ai-assistant-list-col-instructions {
      width: 300px;
    }
  }
` as TableType<GridAssistant>;

const StyledNameLink = styled(Link)`
  display: block;
  padding-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }
`;

const Heading = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

const ClippedIstruction = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
`;

const StyledNameColumnTitle = styled.div`
  padding-left: 12px;
`;

const StoreAIAssistantStatusTagStyled = styled(StoreAIAssistantStatusTag)`
  margin: auto;
`;

export default StoreAIAssistantList;
