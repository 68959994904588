import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, message, Modal } from 'antd';
import moment from 'moment';
import PhyhubDevicesTable from './phyhub-devices-table.component';
import usePagination from '../../../pagination/hook/use-pagination';
import usePaginationQueryParams from '../../../use-pagination-query-params';
import usePhyhubDevicesList, {
  PhyhubDevicesListQueryParams,
} from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-list';
import { StateWrapper } from '../../phyhub-devices-common.components';
import Overlay from '../../../overlay';
import { Spinner } from '../../../spinner';
import ErrorView from '../../../error-view/error-view.component';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDevicesUpdateOsModal from '../../phyhub-devices-modals/phyhub-devices-update-os-modal/phyhub-devices-update-os-modal.component';
import { PhyhubDeviceVersion } from '../../../../../services/phyhub/types/phyhub-device-version.interface';
import Environment from '../../../../../store/types/environment';
import PhyhubDevicesUpdateEnvironmentModal from '../../phyhub-devices-modals/phyhub-devices-update-environment-modal/phyhub-devices-update-environment-modal.component';
import PhyhubDevicesRestartModal from '../../phyhub-devices-modals/phyhub-devices-restart-modal/phyhub-devices-restart-modal.component';
import PhyhubDevicesRebootModal from '../../phyhub-devices-modals/phyhub-devices-reboot-modal/phyhub-devices-restart-modal.component';
import usePhyhubDevicesDelete from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-delete';
import Message from '../../../message';
import useDeleteModal from '../../../use-delete-modal';
import { PhyhubDeviceListParamsFilters } from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';

// TODO: Replace mocked data with real data when API is ready

const MOCKED_PHYHUB_DEVICE_VERSIONS: PhyhubDeviceVersion[] = [
  { version: '2.0.0', latest: false },
  { version: '2.1.5', latest: false },
  { version: '2.2.5', latest: false },
  { version: '2.2.5', latest: false },
  { version: '2.5.0', latest: true },
];

const MOCKED_DEFAULT_PHYHUB_DEVICE_VERSION = '2.5.0';

const MOCKED_DEFAULT_ENVIRONMENT_NAME = 'prod';

interface PhyhubDevicesTableContainerProps {
  tenantId: string;
  environments: Environment[];
  filters?: PhyhubDeviceListParamsFilters;
}

const PhyhubDevicesTableContainer: FC<PhyhubDevicesTableContainerProps> = (props) => {
  const { tenantId, environments, filters } = props;

  const { t } = useTranslation();

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const phyhubDevicesListQueryParams = useMemo<PhyhubDevicesListQueryParams>(
    () => ({
      tenantId,
      limit: paginationQueryParams.limit || defaultPageSize,
      page: paginationQueryParams.page || defaultPage,
      filters,
    }),
    [tenantId, paginationQueryParams, filters],
  );

  const {
    isError: isPhyhubDevicesListError,
    isLoading: isPhyhubDevicesListLoading,
    isFetching: isPhyhubDevicesListFetching,
    isSuccess: isPhyhubDevicesListIsSuccess,
    data: phyhubDevicesCollection,
  } = usePhyhubDevicesList(phyhubDevicesListQueryParams);

  const {
    isLoading: isPhyhubDeviceDeleteLoading,
    mutateAsync: phyhubDeviceDelete,
  } = usePhyhubDevicesDelete();

  const [devicesForReboot, setDevicesForReboot] = useState<PhyhubDevice[]>([]);

  const [devicesForRestart, setDevicesForRestart] = useState<PhyhubDevice[]>([]);

  const [devicesForUpdateEnvironment, setDevicesForUpdateEnvironment] = useState<
    PhyhubDevice[]
  >([]);

  const [devicesForUpdateOs, setDevicesForUpdateOs] = useState<PhyhubDevice[]>([]);

  const [showDeleteModal] = useDeleteModal();

  const isUpdateOsAvailable = useMemo<boolean>(() => {
    const uniqueOsVersions = Array.from(
      new Set(
        devicesForUpdateOs.map((device) =>
          device.os ? `${device.os.type}_${device.os.arch}` : null,
        ),
      ).values(),
    );

    return uniqueOsVersions.length === 1 && uniqueOsVersions[0] !== null;
  }, [devicesForUpdateOs]);

  const handleDeviceDeleteConfirm = useCallback(
    (deviceId: string) => async () => {
      try {
        await phyhubDeviceDelete({ tenantId, deviceId });

        message.success(
          <Message content={t('phyhubDevices.details.message.deleteSuccess')} />,
        );
      } catch (error) {
        // TODO: Implement Phyhub errors parsing logic
        message.error(
          <Message content={t('phyhubDevices.details.message.deleteFailed')} />,
        );
      }
    },
    [tenantId, phyhubDeviceDelete],
  );

  const handleDeviceDelete = useCallback(
    async (deviceId: string) => {
      const phyhubDeviceItem = phyhubDevicesCollection
        ? phyhubDevicesCollection.docs.find((device) => device.id === deviceId)
        : null;

      if (!phyhubDeviceItem) {
        return;
      }

      showDeleteModal(
        t('confirmDeleteDevice'),
        t('areYouSureYouWantToDeleteDevice', {
          deviceName: phyhubDeviceItem.displayName,
        }),
        phyhubDeviceItem.displayName,
        handleDeviceDeleteConfirm(phyhubDeviceItem.id),
      );
    },
    [handleDeviceDeleteConfirm, phyhubDevicesCollection, showDeleteModal, t],
  );

  const handleReboot = useCallback(
    (selectedDeviceIds: string[]) => {
      const selectedDevices = phyhubDevicesCollection
        ? phyhubDevicesCollection.docs.filter((device) =>
            selectedDeviceIds.includes(device.id),
          )
        : [];

      setDevicesForReboot(selectedDevices);
    },
    [phyhubDevicesCollection],
  );

  const handleRebootConfirm = useCallback((deviceIds: string[]) => {
    const selectedDevicesForReboot = devicesForReboot.filter((device) =>
      deviceIds.includes(device.id),
    );

    // TODO: Implement device rebooting logic when API is ready
    console.log('Reboot Confirm', { selectedDevicesForReboot });

    setDevicesForReboot([]);
  }, []);

  const handleRebootReset = useCallback(() => {
    setDevicesForReboot([]);
  }, []);

  const handleRestart = useCallback(
    (selectedDeviceIds: string[]) => {
      const selectedDevices = phyhubDevicesCollection
        ? phyhubDevicesCollection.docs.filter((device) =>
            selectedDeviceIds.includes(device.id),
          )
        : [];

      setDevicesForRestart(selectedDevices);
    },
    [phyhubDevicesCollection],
  );

  const handleRestartConfirm = useCallback((deviceIds: string[]) => {
    const selectedDevicesForRestart = devicesForRestart.filter((device) =>
      deviceIds.includes(device.id),
    );

    // TODO: Implement device restarting logic when API is ready
    console.log('Restart Confirm', { selectedDevicesForRestart });

    setDevicesForRestart([]);
  }, []);

  const handleRestartReset = useCallback(() => {
    setDevicesForRestart([]);
  }, []);

  const handleUpdateEnvironment = useCallback(
    (selectedDeviceIds: string[]) => {
      const selectedDevices = phyhubDevicesCollection
        ? phyhubDevicesCollection.docs.filter((device) =>
            selectedDeviceIds.includes(device.id),
          )
        : [];

      setDevicesForUpdateEnvironment(selectedDevices);
    },
    [phyhubDevicesCollection],
  );

  const handleUpdateEnvironmentConfirm = useCallback(
    (
      deviceIds: string[],
      plannedTimestamp: moment.Moment,
      targetEnvironmentName: string,
    ) => {
      const selectedDevicesForUpdateEnvironment = devicesForUpdateEnvironment.filter(
        (device) => deviceIds.includes(device.id),
      );

      // TODO: Implement environment updating logic when API is ready
      console.log('Update Environment Confirm', {
        targetEnvironmentName,
        plannedTimestamp,
        selectedDevicesForUpdateEnvironment,
      });

      setDevicesForUpdateEnvironment([]);
    },
    [devicesForUpdateEnvironment],
  );

  const handleUpdateEnvironmentReset = useCallback(() => {
    setDevicesForUpdateEnvironment([]);
  }, []);

  const handleUpdateOs = useCallback(
    (selectedDeviceIds: string[]) => {
      const selectedDevices = phyhubDevicesCollection
        ? phyhubDevicesCollection.docs.filter((device) =>
            selectedDeviceIds.includes(device.id),
          )
        : [];

      setDevicesForUpdateOs(selectedDevices);
    },
    [phyhubDevicesCollection],
  );

  const handleUpdateOsConfirm = useCallback(
    (
      deviceIds: string[],
      plannedTimestamp: moment.Moment,
      targetDeviceVersion: string,
    ) => {
      const selectedDevicesForUpdateOs = devicesForUpdateOs.filter((device) =>
        deviceIds.includes(device.id),
      );

      // TODO: Implement device OS updating logic when API is ready
      console.log('Update OS Confirm', {
        targetDeviceVersion,
        plannedTimestamp,
        selectedDevicesForUpdateOs,
      });

      setDevicesForUpdateOs([]);
    },
    [devicesForUpdateOs],
  );

  const handleUpdateOsReset = useCallback(() => {
    setDevicesForUpdateOs([]);
  }, []);

  useEffect(() => {
    if (!!devicesForUpdateOs.length && !isUpdateOsAvailable) {
      Modal.info({
        title: t('phyhubDevices.modal.updateOsNotAvailable.title'),
        content: t('phyhubDevices.modal.updateOsNotAvailable.content'),
        okText: t('phyhubDevices.button.done'),
        onOk: handleUpdateOsReset,
      });
    }
  }, [isUpdateOsAvailable, handleUpdateOsReset, devicesForUpdateOs.length, t]);

  if (isPhyhubDevicesListLoading) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isPhyhubDevicesListError) {
    return (
      <StateWrapper>
        <ErrorView
          title={t('phyhubDevices.list.error.title')}
          content={t('phyhubDevices.list.error.subtitle')}
        />
      </StateWrapper>
    );
  }

  if (!isPhyhubDevicesListIsSuccess || !phyhubDevicesCollection) {
    return (
      <StateWrapper>
        <Empty description={t('phyhubDevices.list.emptyState')} />
      </StateWrapper>
    );
  }

  return (
    <>
      <PhyhubDevicesRebootModal
        isVisible={!!devicesForReboot.length}
        devices={devicesForReboot}
        onCancel={handleRebootReset}
        onRebootConfirm={handleRebootConfirm}
      />
      <PhyhubDevicesRestartModal
        isVisible={!!devicesForRestart.length}
        devices={devicesForRestart}
        onCancel={handleRestartReset}
        onRestartConfirm={handleRestartConfirm}
      />
      <PhyhubDevicesUpdateEnvironmentModal
        isVisible={!!devicesForUpdateEnvironment.length}
        defaultTargetEnvironmentName={MOCKED_DEFAULT_ENVIRONMENT_NAME}
        devices={devicesForUpdateEnvironment}
        environments={environments}
        onCancel={handleUpdateEnvironmentReset}
        onUpdateEnvironmentConfirm={handleUpdateEnvironmentConfirm}
      />
      <PhyhubDevicesUpdateOsModal
        isVisible={!!devicesForUpdateOs.length && isUpdateOsAvailable}
        defaultTargetVersion={MOCKED_DEFAULT_PHYHUB_DEVICE_VERSION}
        availableVersions={MOCKED_PHYHUB_DEVICE_VERSIONS}
        devices={devicesForUpdateOs}
        onCancel={handleUpdateOsReset}
        onUpdateOsConfirm={handleUpdateOsConfirm}
      />
      <PhyhubDevicesTable
        isFetching={isPhyhubDeviceDeleteLoading || isPhyhubDevicesListFetching}
        paginationCollection={phyhubDevicesCollection}
        onDeviceDelete={handleDeviceDelete}
        onReboot={handleReboot}
        onRestart={handleRestart}
        onUpdateEnvironment={handleUpdateEnvironment}
        onUpdateOs={handleUpdateOs}
      />
    </>
  );
};

export default PhyhubDevicesTableContainer;
