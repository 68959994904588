import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, message } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import ChannelForm, {
  ChannelFormApiValidationErrors,
  ChannelFormValues,
} from './channel-form-fields.component';
import { ApiError } from '../../../../../services/api/api-error';
import Message from '../../../../common/message';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import useCreateChannel from '../../../../../store/hooks/channels/use-create-channel';
import { ContentTagType } from '../../../../../store/common/content-tags/content-tag-type';

interface ChannelCreateFormProps
  extends RouteComponentProps<{ organisationId: string }> {}

const ChannelCreateForm = (props: ChannelCreateFormProps) => {
  const { t } = useTranslation();

  const {
    match: {
      params: { organisationId: tenantId },
    },
    history,
  } = props;

  const goBack = useGoBack();

  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const initialValues = useMemo<ChannelFormValues>(() => {
    return {
      name: '',
      description: '',
      tags: [{ tagType: ContentTagType.ORIENTATION, tagId: ContentTagType.ORIENTATION }],
    };
  }, []);

  const { mutateAsync: createAlertChannel } = useCreateChannel(tenantId);

  const onFormSubmit = useCallback(
    async (values: ChannelFormValues): Promise<ChannelFormApiValidationErrors> => {
      try {
        const channel = await createAlertChannel({
          ...values,
          tenantId,
        });

        message.success(<Message content={t('contents.channelAddSuccess')} />);
        history.replace(
          `/organisations/${tenantId}/content/channels/update/${channel.id}`,
        );

        return {};
      } catch (error) {
        message.error(<Message content={t('contents.channelAddFailed')} />);

        const errors = extractApiValidationErrors(error as ApiError);
        return errors;
      }
    },
    [createAlertChannel, history, tenantId, t],
  );

  return (
    <ChannelFormContainer>
      <BackToListButton type="default" icon="arrow-left" onClick={handleBack}>
        {t('back')}
      </BackToListButton>

      <ChannelForm
        tenantId={tenantId}
        title={t('contents.createChannel')}
        initialValues={initialValues}
        onFormSubmit={onFormSubmit}
      />
    </ChannelFormContainer>
  );
};

export default ChannelCreateForm;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const ChannelFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 20px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
