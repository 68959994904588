import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../../../common/search-bar/search-bar.component';
import { Button, Icon, Popconfirm, Tooltip, Typography } from 'antd';
import SchemaForm, {
  SchemaFormRef,
} from '../../../common/schema-form/schema-form.component';
import PanelCard from '../../../common/panel-card/panel-card.component';
import useSchemaForm from '../../../common/use-schema-form/use-schema-form';
import { InstallationGroupBody } from '../../../common/use-installation-groups';
import { useTranslation } from 'react-i18next';
import InstallationGroup from '../../../../store/types/installation-group';
const { Title } = Typography;

interface InstallationGroupContainerProps
  extends RouteComponentProps<{ organisationId: string; groupId: string }> {
  installationGroup: InstallationGroup;
  handleSubmit: (data: InstallationGroupBody) => Promise<void>;
  handleDelete: () => void;
  isDeletable: boolean;
}

export const InstallationGroupComponent = (props: InstallationGroupContainerProps) => {
  const {
    handleSubmit,
    history,
    isDeletable,
    installationGroup,
    handleDelete,
  } = props;

  const { t } = useTranslation();

  const formElement = useRef<SchemaFormRef>(null);

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm<any>(
    handleSubmit,
    t('installationGroup.saved'),
    t('installationGroup.savedError'),
  );

  useEffect(() => {
    formInit(installationGroup);
  }, [installationGroup, formInit]);

  const formSchema = useMemo(() => {
    const schema = {
      type: 'object',
      title: t('installationGroup.generalSectionTitle'),
      properties: {
        displayName: {
          type: 'string',
          title: t('installationGroup.displayName'),
        },
        notes: {
          type: 'string',
          title: t('installationGroup.notes'),
        },
      },
      required: ['displayName'],
    };

    return schema;
  }, [t]);

  const uiSchema = useMemo(() => {
    return {
      objectFieldTemplate: 'none',
      displayName: {
        classNames: 'fieldTitle',
      },
      notes: {
        'ui:widget': 'textarea',
        classNames: 'userNotes',
      },
    };
  }, []);

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  return (
    <Container>
      <>
        <TitleContainer>
          <IconContainer>
            <EnvironmentIconContainer>
              <EnvironmentIcon type="apartment" />
            </EnvironmentIconContainer>
          </IconContainer>
          <TextContainer>
            <TextTitleContainer>
              <TitleLabel>{installationGroup.displayName}</TitleLabel>
            </TextTitleContainer>
          </TextContainer>
        </TitleContainer>
        <SearchBarWrapContainer>
          <GoBackButtonContainer>
            <GoBackButton icon="arrow-left" onClick={() => history.goBack()} />
          </GoBackButtonContainer>
          <SearchBarStyled>
          <SearchBar
            searchInputProps={{
              placeholder: t('searchPlaceholder'),
            }}
          />
          </SearchBarStyled>
          <ButtonContainer>
            {isDeletable ? (
              <Popconfirm
                title={t('areYouSureYouWantToDelete')}
                onConfirm={handleDelete}
                okText={t('yes')}
                cancelText={t('no')}
              >
                <StyledButton type="danger" icon={'delete'}>
                  {t('installationGroup.delete')}
                </StyledButton>
              </Popconfirm>
            ) : (
              <Tooltip
                placement="topLeft"
                title={t('installationGroup.moveInstallations')}
              >
                <StyledButton type="danger" disabled={true} icon={'delete'}>
                  {t('installationGroup.delete')}
                </StyledButton>
              </Tooltip>
            )}

            <StyledButton
              type="primary"
              loading={formLoading}
              onClick={handleSave}
              disabled={!formDirty}
            >
              {t('installationGroup.saveAllChanges')}
            </StyledButton>
          </ButtonContainer>
        </SearchBarWrapContainer>
      </>
      <PageBody>
        <TabContainer>
          <GeneralSectionContainer>
            <PanelCardInstallationGroup
              title={
                <Title level={2}>{t('installationGroup.generalSectionTitle')}</Title>
              }
              bodyStyle={{ padding: 16 }}
            >
              <SchemaForm
                schema={formSchema}
                uiSchema={uiSchema}
                ref={formElement}
                onSubmit={handleFormSubmit}
                onChange={handleFormChange}
                data={formData}
                liveValidate={false}
              />
            </PanelCardInstallationGroup>
          </GeneralSectionContainer>
          <SideBarTableContainer>
            <div />
          </SideBarTableContainer>
        </TabContainer>
      </PageBody>
    </Container>
  );
};

const SearchBarStyled = styled.div`
width: 100%;
display: flex;

& > div {
  border: 0;
  padding: 0;
  width: 100%;
}
`

const Container = styled.div`
  width: auto;
  background-color: #f5f5f5;
`;

const TitleContainer = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  padding-left: 40px;
  padding-top: 20px;
`;

const IconContainer = styled.div`
  width: 56px;
  height: 56px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: center;
  align-items: center;
  gap: 0px;
  background-color: rgb(32, 32, 32);
  opacity: 1;
  border-radius: 8px;
`;

const EnvironmentIconContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  color: #fff;
`;

const EnvironmentIcon = styled(Icon)`
  width: 100%;
  cursor: auto;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 0px;
`;

const TextTitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 4px 0px 0px;
  width: 100%;
  height: min-content;
  inset: auto;
  flex: 0 0 auto;
  align-self: auto;
`;

const TitleLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #000;
  font-size: 24px;
  line-height: 1em;
`;

const GeneralSectionContainer = styled.div`
  position: relative;
  overflow: visible;
  max-width: 882px;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 24px;
`;

const PageBody = styled.div`
  position: relative;
  overflow: visible;
  min-height: 888px;
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  margin: 0px auto;
  padding: 32px;
  justify-content: center;
`;

const TabContainer = styled.div`
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 0px;

  @media only screen and (max-width: 1437px) {
    flex-direction: column;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

const SearchBarWrapContainer = styled(PanelCard)`
  position: sticky;
  border-radius: 0px;
  top: 0;
  z-index: 1;
  border-top: none;
  width: 100%;
  background: #f5f5f5;
  border: 0;
  padding: 0 40px;
  
  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.06);
  }
`;

const GoBackButton = styled(Button)`
  color: unset;
  margin-right: 10px;
  border: none;
  align-self: center;
  background: #f5f5f5;
` as any;

const GoBackButtonContainer = styled.div`
  height: 35px;
  display: flex;
  margin-right: 20px;
`;

const SideBarTableContainer = styled.div`
  display: flex;
  top: 0;
  padding: 5px;
  max-height: 100%;
  will-change: transform;

  .ant-table-row {
    border: none !important;
  }
`;

const PanelCardInstallationGroup = styled(PanelCard)`
  max-width: 755px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-left: 15px;
  min-width: 255px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
` as any;
