import { DataMatrixColumn } from '@ombori/grid-reports';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DeviceEventsByDay, DeviceSessionsByDay } from '../../../use-analytics-report';
import { Table, Button } from 'antd';
import UniversalDevice from '../../../../../store/types/universal-device';
import groupBy from 'lodash/groupBy';
import getColumnValue from './get-column-value';
import toLower from 'lodash/toLower';
import snakeCase from 'lodash/snakeCase';
import { TableRecord } from './types';
import getSortFn from './get-sort-fn';
import { getDeviceAverageOrLowestPercentage } from './get-percentage';
import { prepareDateRangeSearchParams } from '../../utils';

interface DeviceTableRecord extends TableRecord {
  deviceId: string;
}

interface DevicesTableProps {
  tenantId: string;
  columns: DataMatrixColumn[];
  devices: UniversalDevice[];
  eventsData: DeviceEventsByDay[];
  sessionsData: DeviceSessionsByDay[];
  dateFrom: string;
  dateTo: string;
}

const DevicesTable: React.FC<DevicesTableProps> = ({
  tenantId,
  devices,
  columns,
  eventsData,
  sessionsData,
  dateFrom,
  dateTo,
}) => {
  const { t } = useTranslation();

  const groupedEventsData = useMemo(
    () => groupBy(eventsData, (dataItem) => dataItem.deviceId),
    [eventsData],
  );

  const groupedSessionsData = useMemo(
    () => groupBy(sessionsData, (dataItem) => dataItem.deviceId),
    [sessionsData],
  );

  const data: DeviceTableRecord[] = devices
    .map((device) => {
      const eventsData = groupedEventsData[device.uuid] || [];

      const sessionsData = groupedSessionsData[device.uuid] || [];

      return { device, eventsData, sessionsData };
    })
    .filter(
      ({ eventsData, sessionsData }) => eventsData.length > 0 || sessionsData.length > 0,
    )
    .map(({ device, eventsData, sessionsData }) => {
      const result = columns
        .map((column) => {
          const columnValue = getColumnValue<DeviceEventsByDay>({
            column,
            eventsData: eventsData,
            sessionData: sessionsData,
            getEventsAvgValue: getDeviceAverageOrLowestPercentage,
            getEventsLowestValue: getDeviceAverageOrLowestPercentage,
          });

          return { [toLower(snakeCase(column.title))]: columnValue };
        })
        .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue }), {});

      return {
        key: device.uuid,
        device: device.deviceName,
        deviceId: device.uuid,
        ...result,
      };
    });

  const tableColumns = columns.map((column) => {
    const sortFn = getSortFn(column);

    return {
      title: column.title,
      dataIndex: toLower(snakeCase(column.title)),
      key: toLower(snakeCase(column.title)),
      sorter: sortFn,
    };
  });

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const allColumns = [
    {
      title: t('device'),
      dataIndex: 'device',
      key: 'device',
      render: (text: string, record: DeviceTableRecord) => {
        return (
          <Button type="link">
            <Link
              to={{
                pathname: `/organisations/${tenantId}/operations/devices/v3/${record.deviceId}/overview`,
                search: `${searchParams}`,
              }}
            >
              {text}
            </Link>
          </Button>
        );
      },
    },
    ...tableColumns,
  ];

  return <Table columns={allColumns} dataSource={data} size="small" />;
};

export default DevicesTable;
