import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Environment from '../../../../../store/types/environment';
import PhyhubDevicesBaseActionModal from '../phyhub-devices-base-action-modal/phyhub-devices-base-action-modal.component';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDevicesUpdateEnvironmentControls from './phyhub-devices-update-environment-controls/phyhub-devices-update-environment-controls.component';

interface PhyhubDevicesUpdateEnvironmentModalProps {
  isVisible: boolean;
  defaultTargetEnvironmentName: string;
  devices: PhyhubDevice[];
  environments: Environment[];
  onCancel: () => void;
  onUpdateEnvironmentConfirm: (
    deviceIds: string[],
    plannedTimestamp: moment.Moment,
    targetEnvironmentName: string,
  ) => void;
}

const PhyhubDevicesUpdateEnvironmentModal: FC<PhyhubDevicesUpdateEnvironmentModalProps> = (
  props,
) => {
  const {
    isVisible,
    defaultTargetEnvironmentName,
    devices,
    environments,
    onUpdateEnvironmentConfirm,
  } = props;

  const { t } = useTranslation();

  const [plannedTimestamp, setPlannedTimestamp] = useState<moment.Moment>(moment());

  const [targetEnvironmentName, setTargetEnvironmentName] = useState<string>(
    defaultTargetEnvironmentName,
  );

  const handlePlannedTimestampChange = useCallback((value: moment.Moment) => {
    setPlannedTimestamp(value);
  }, []);

  const handleTargetEnvironmentChange = useCallback((value: string) => {
    setTargetEnvironmentName(value);
  }, []);

  const handleOk = useCallback(
    (selectedDeviceIds: string[]) => {
      onUpdateEnvironmentConfirm(
        selectedDeviceIds,
        plannedTimestamp,
        targetEnvironmentName,
      );
    },
    [onUpdateEnvironmentConfirm, plannedTimestamp, targetEnvironmentName],
  );

  const affectedDevices = useMemo(
    () => devices.filter((device) => device.env !== targetEnvironmentName),
    [targetEnvironmentName, devices],
  );

  const skipperDevices = useMemo(
    () => devices.filter((device) => device.env === targetEnvironmentName),
    [targetEnvironmentName, devices],
  );

  return (
    <PhyhubDevicesBaseActionModal
      isVisible={isVisible}
      cancelText={t('phyhubDevices.modal.updateEnvironment.cancelText')}
      okText={t('phyhubDevices.modal.updateEnvironment.okText')}
      title={t('phyhubDevices.modal.updateEnvironment.title')}
      affectedDevices={affectedDevices}
      skipperDevices={skipperDevices}
      onCancel={props.onCancel}
      onOk={handleOk}
    >
      <PhyhubDevicesUpdateEnvironmentControls
        plannedTimestamp={plannedTimestamp}
        targetEnvironmentName={targetEnvironmentName}
        availableEnvironments={environments}
        onPlannedTimestampChange={handlePlannedTimestampChange}
        onTargetEnvironmentChange={handleTargetEnvironmentChange}
      />
    </PhyhubDevicesBaseActionModal>
  );
};

export default PhyhubDevicesUpdateEnvironmentModal;
