import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Row, Table as AntdTable } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { IntegrationStatusTag, PanelCard } from '../common';
import { TableType } from '../../../../../../types';
import Pagination, { usePagination } from '../../../../../common/pagination';
import { IntegrationJobHistory } from '../types';
import { useIntegrationJobHistories } from '../hooks';
import { DataResidencyEnum } from '../../../../../../store/types/organisation';
import Overlay from '../../../../../common/overlay/overlay-v2.component';
import { utcToLocalDate } from '../../../../../../utils/dayjs';

interface JobHistoryListProps {
  tenantId: string;
  dataResidency: DataResidencyEnum;
  integrationId: string;
}

const IntegrationJobHistoryList = (props: JobHistoryListProps) => {
  const { tenantId, dataResidency, integrationId } = props;

  const paginationContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const {
    data: integrationJobHistory,
    isSuccess,
    isLoading: isInitialLoading,
    isFetched,
    isFetching,
    isRefetching,
    error,
  } = useIntegrationJobHistories({
    tenantId,
    dataResidency,
    integrationId,
  });

  const isDataInvalidating = isFetching && isFetched;
  const currentPage = page ? page : defaultPage;
  const currentPageSize = pageSize ? pageSize : defaultPageSize;
  const totalCount = isSuccess && integrationJobHistory ? integrationJobHistory.data.totalItems : 0;

  const handlePageChange = useCallback((pageNum: number) => setPage(pageNum), [setPage]);

  const handlePaginationSizeChange = useCallback(
    (page: number, pageLimit: number) => {
      setPage(page);
      setPageSize(pageLimit);
    },
    [setPage, setPageSize],
  );

  const columns = useMemo<ColumnProps<IntegrationJobHistory>[]>(() => {
    return [
      {
        title: <Date>{t('date')}</Date>,
        key: 'date',
        align: 'left',
        render: (_, record) => <Date>{utcToLocalDate(record.createdAt).format('ddd DD MMMM, YYYY')}</Date>,
      },
      {
        title: t('startTime'),
        key: 'startTime',
        align: 'left',
        render: (_, record) => {
          // TO DO: Align with back-end. I think we should capture when the job actually started and not base it on the cron expression
          //   Also, schedule is not in the response. Value here is temporary.
          return utcToLocalDate(record.createdAt).format('hh:mm A');
        },
      },
      {
        title: t('duration'),
        key: 'duration',
        align: 'left',
        render: (_, record) => {
          return record.duration;
        },
      },
      {
        title: t('jobStatus'),
        key: 'status',
        align: 'center',
        render: (_, record) => <IntegrationStatusTag status={record.status} />,
      },
    ];
  }, [t]);

  return (
    <PanelCardStyled>
      <Row>
        <Title>{t('history')}</Title>
      </Row>
      <Overlay
        errorOverlay={{
          error,
          layout: 'filled',
        }}
      >
        <div ref={paginationContainerRef}>
          <Table
            rowKey="id"
            dataSource={integrationJobHistory ? integrationJobHistory.data.items : []}
            columns={columns}
            pagination={false}
            size="small"
          />
          <Pagination
            parentContainerRef={paginationContainerRef}
            isLoading={
              !isDataInvalidating && (isFetching || isRefetching || isInitialLoading)
            }
            align="right"
            showSizeChanger
            onShowSizeChange={handlePaginationSizeChange}
            current={currentPage}
            pageSize={currentPageSize}
            total={totalCount}
            onChange={handlePageChange}
            pageSizes={defaultPageSizeOption}
          />
        </div>
      </Overlay>
    </PanelCardStyled>
  );
};

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  padding: 20px 20px 10px 20px;
`;

const Table = styled(AntdTable)`
  .ant-table {
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
  }
  .ant-table-thead > tr > th {
    background: #F0F0F0 !important;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }
  .ant-table-body {
    margin: 0 !important;
  }
` as TableType<IntegrationJobHistory>;

const PanelCardStyled = styled(PanelCard)`
  padding: 0;
`;

const Date = styled.span`
  padding-left: 13px;
`;

export default React.memo(IntegrationJobHistoryList);
