import { TFunction } from 'i18next';
import pick from 'lodash/pick';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {
  EnterpriseAgreement,
  EnterpriseAgreementStatusEnum,
} from '../store/types/enterprise-agreement';
import { colorInverse } from './color';
import { getGridIntegerPrice } from './currency';
import { getApiUrl } from './env';

export enum ButtonActionsEnum {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
  SEND_INVITE = 'send_invite',
  RESEND_INVITE = 'resend_invite',
  DELETE = 'delete',
  DOWNLOAD_PDF = 'download_pdf',
}

export const getStatusColor = (
  status: EnterpriseAgreementStatusEnum,
): {
  background: string;
  foreground: string;
} => {
  const color = {
    background: '#dddddd',
    foreground: '#444444',
  };

  switch (status) {
    case EnterpriseAgreementStatusEnum.DRAFT:
      color.background = '#dddddd';
      color.foreground = colorInverse('#dddddd');
      return color;
    case EnterpriseAgreementStatusEnum.INVITE_SENT:
      color.background = '#108ee9';
      color.foreground = colorInverse('#108ee9');
      return color;
    case EnterpriseAgreementStatusEnum.PENDING:
      color.background = '#ffc107';
      color.foreground = colorInverse('#ffc107');
      return color;
    case EnterpriseAgreementStatusEnum.ACTIVE:
      color.background = '#00ae48';
      color.foreground = colorInverse('#00ae48');
      return color;
    case EnterpriseAgreementStatusEnum.INACTIVE:
      color.background = '#dddddd';
      color.foreground = colorInverse('#dddddd');
      return color;
    default:
      color.background = '#dddddd';
      color.foreground = colorInverse('#dddddd');
      return color;
  }
};

export const convertEmailToInitials = (str?: string): string => {
  if (!str) {
    return '';
  }

  const email = str.substring(0, str.lastIndexOf('@')) || str;

  const initials = email.includes('.')
    ? `${email.split('.')[0][0] || ''}${email.split('.')[1][0] || ''}`
    : `${email[0] || ''}${email[1] || ''}`;

  return initials.toUpperCase();
};

export const convertEmailToName = (str: string): string => {
  if (!str) {
    return '';
  }

  const name = str.substring(0, str.lastIndexOf('@')) || str;
  return name
    .replace(/\.|-|_/g, ' ')
    .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
};

export const getProcessingTip = (
  action: ButtonActionsEnum | undefined,
  t: TFunction,
): string | undefined => {
  switch (action) {
    case ButtonActionsEnum.CREATE:
      return t('enterpriseAgreement.creating');
    case ButtonActionsEnum.EDIT:
      return t('enterpriseAgreement.updating');
    case ButtonActionsEnum.SEND_INVITE:
      return t('enterpriseAgreement.sendingInvite');
    case ButtonActionsEnum.RESEND_INVITE:
      return t('enterpriseAgreement.resendingInvite');
    case ButtonActionsEnum.DELETE:
      return t('enterpriseAgreement.deleting');
    default:
  }
};

export interface ActionButtonsConfig {
  delete: boolean;
  sendInvite: boolean;
  resendInvite: boolean;
  create: boolean;
  edit: boolean;
  ok: boolean;
}

export const getButtonsConfig = (
  status: EnterpriseAgreementStatusEnum | ButtonActionsEnum.CREATE,
): ActionButtonsConfig => {
  const buttonsConfig = {
    delete: true,
    sendInvite: false,
    resendInvite: false,
    create: false,
    edit: false,
    ok: true,
  };

  if (status === ButtonActionsEnum.CREATE) {
    buttonsConfig.delete = false;
    buttonsConfig.create = true;
    buttonsConfig.ok = false;
    return buttonsConfig;
  }

  // TO DO: Workflow not yet final
  switch (status) {
    case EnterpriseAgreementStatusEnum.DRAFT:
      buttonsConfig.sendInvite = true;
      buttonsConfig.edit = true;
      buttonsConfig.ok = false;
      return buttonsConfig;
    case EnterpriseAgreementStatusEnum.INVITE_SENT:
      buttonsConfig.resendInvite = true;
      buttonsConfig.edit = true;
      buttonsConfig.ok = false;
      return buttonsConfig;
    case EnterpriseAgreementStatusEnum.ACTIVE:
    case EnterpriseAgreementStatusEnum.INACTIVE:
      buttonsConfig.delete = false;
      return buttonsConfig;
    default:
      return buttonsConfig;
  }
};

export const getFieldReadOnlyState = (
  status: EnterpriseAgreementStatusEnum | ButtonActionsEnum | undefined,
): boolean => {
  // TO DO: Workflow not yet final
  switch (status) {
    case EnterpriseAgreementStatusEnum.DRAFT:
    case EnterpriseAgreementStatusEnum.INVITE_SENT:
    case ButtonActionsEnum.CREATE:
      return false;
    default:
      return true;
  }
};

export const getFormattedAgreementCoverage = ({
  durationMonths,
  startDate,
  status,
  format = 'YYYY-MM-DD',
}: {
  durationMonths: number;
  startDate?: Date;
  status: EnterpriseAgreementStatusEnum;
  format?: string;
}): { fromDate: string; toDate: string } => {
  const startDateOfAgreement = [
    EnterpriseAgreementStatusEnum.ACTIVE,
    EnterpriseAgreementStatusEnum.INACTIVE,
  ].includes(status)
    ? startDate
    : new Date();

  return {
    fromDate: moment(startDateOfAgreement).format(format),
    toDate: moment(startDateOfAgreement)
      .add(durationMonths, 'M')
      .format(format),
  };
};

export const mapAgreementToFormValues = (agreement: EnterpriseAgreement) => ({
  ...agreement,
  plans: {
    tenantPlanId: agreement.plans.tenantPlanId.id,
    supportPlanId: agreement.plans.supportPlanId.id,
  },
  committedConsumption: agreement.committedConsumption
    ? agreement.committedConsumption.valueDecimal
    : undefined,
  extraCredits: agreement.extraCredits ? agreement.extraCredits.valueDecimal : undefined,
  extraCreditsValidDurationMonths: agreement.extraCredits
    ? agreement.extraCreditsValidDurationMonths
    : undefined,
  discountConfig: agreement.discountConfig ? agreement.discountConfig : undefined,
});

const mapDiscountConfigFormValuesToAgreement = (
  discountConfig: EnterpriseAgreement['discountConfig'],
) => {
  const { saasRevenue, professionalServices } = discountConfig || {
    saasRevenue: { discountPercent: 0, discountPercentValue: 0 },
    professionalServices: { discountPercent: 0, discountPercentValue: 0 },
  };  

  return {
    saasRevenue: { ...saasRevenue, discountPercentValue: +(saasRevenue.discountPercentValue).toFixed(2) },
    professionalServices: {
      ...professionalServices,
      discountPercentValue: +(professionalServices.discountPercentValue).toFixed(2),
    },
  };
};

export const mapFormValuesToAgreement = (
  formValues: any,
): Partial<EnterpriseAgreement> => {
  const agreement = {
    ...formValues,
    committedConsumption: formValues.committedConsumption
      ? {
          currency: formValues.organizationConfiguration.currency,
          value: getGridIntegerPrice(formValues.committedConsumption),
        }
      : undefined,
    extraCredits: formValues.extraCredits
      ? {
          currency: formValues.organizationConfiguration.currency,
          value: getGridIntegerPrice(formValues.extraCredits),
        }
      : undefined,
    extraCreditsValidDurationMonths: formValues.extraCredits
      ? formValues.extraCreditsValidDurationMonths
      : undefined,
    billingAddress: {
      ...(formValues.billingAddress || {}),
      country: formValues.organizationConfiguration.country,
    },
    discountConfig: formValues.discountConfig ? mapDiscountConfigFormValuesToAgreement(formValues.discountConfig) : undefined,
  };

  const pickFields = [
    'name',
    'durationMonths',
    'committedConsumption',
    'extraCredits',
    'extraCreditsValidDurationMonths',
    'discountConfig',
    'plans',
    'organizationId',
    'organizationConfiguration',
    'billingAddress',
    'tenantAdminUser',
    'customMessages',
  ];

  const agreementCopy = cloneDeep(agreement);

  if (agreementCopy.organizationId) {
    unset(agreementCopy, 'organizationConfiguration.currency');
    unset(agreementCopy, 'organizationConfiguration.dataResidency');
  }

  return pick(agreementCopy, pickFields) as Partial<EnterpriseAgreement>;
};

export const getEnterpriseAgreementDocumentUrl = (enterpriseAgreementId: string) => {
  return `${getApiUrl()}/api/enterprise-agreements/${enterpriseAgreementId}/document`;
}
