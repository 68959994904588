import React, { useState, useCallback, useEffect } from 'react';
import { Redirect, Switch, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import styled from '@emotion/styled';
import TabBar from '../../common/tab-bar/tab-bar.component';
import Header, { Subheader } from '../../common/app-layout/header';
import { Icon } from '../../common/schema-form/common';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../common/protected-route/protected-route.container';
import SkuList from './sku/sku-list.component';
import SkuCreateForm from './sku/sku-create-form';
import SkuEditForm from './sku/sku-edit-form';
import PlansList from './plan/plan-list.component';
import PlanCreateForm from './plan/plan-create-form.component';
import PlanEditForm from './plan/plan-edit-form.component';

interface BillingProps extends RouteComponentProps{};

enum MenuKeysEnum {
  SKUS = 'skus',
  PLANS = 'plans',
}

const MenuTabs = {
  [MenuKeysEnum.SKUS]: {
    path: '/skus'
  },
  [MenuKeysEnum.PLANS]: {
    path: '/plans'
  }
}

const Billing = (props: BillingProps) => {
  const { history, location, match } = props;

  const { t, i18n } = useTranslation();

  const [tabKey, setTabKey] = useState<MenuKeysEnum>(MenuKeysEnum.SKUS);

  useEffect(() => {
    const segment = location.pathname.replace(match.url, '').split('/')[1];
    setTabKey(segment as MenuKeysEnum);
  }, [location, match]);

  const onTabChange = useCallback(
    (key: string) => {
      const menuKey = key as MenuKeysEnum;
      setTabKey(menuKey);

      const path = MenuTabs[menuKey].path;
      history.push(`${match.url}${path}`);
    },
    [history, match]
  );

  return (
    <>
      <Header
        tabTitle={t('billing.title')}
        title={t('billing.title')}
        icon={<Icon type='credit-card' color='#fff' />}
        isBordered={false}
      />
      <SubheaderStyled
        components={[
          <TabBar
            key="billing"
            activeKey={tabKey}
            onChange={onTabChange}
            lang={i18n.language}
          >
            <Tabs.TabPane
              key={MenuKeysEnum.SKUS}
              tab={
                <span>
                  {t('sku')}
                </span>
              }
            />
            <Tabs.TabPane
              key={MenuKeysEnum.PLANS}
              tab={
                <span>
                  {t('plans.list.title')}
                </span>
              }
            />
          </TabBar>
        ]}
      />
      <ErrorBoundary>
        <Switch>
          <ProtectedRouteContainer
            exact
            path={`${match.path}/skus`}
            render={(routeProps) => (
              <SkuList {...routeProps} />
            )}
          />
          <ProtectedRouteContainer
            exact
            path={`${match.path}/skus/add`}
            component={SkuCreateForm}
          />
          <ProtectedRouteContainer
            exact
            path={`${match.path}/skus/:skuId`}
            component={SkuEditForm}
          />
          <ProtectedRouteContainer
            exact
            path={`${match.path}/plans`}
            component={PlansList}
          />
          <ProtectedRouteContainer
            exact
            path={`${match.path}/plans/add`}
            component={PlanCreateForm}
          />
          <ProtectedRouteContainer
            exact
            path={`${match.path}/plans/:planId`}
            component={PlanEditForm}
          />
          <ProtectedRouteContainer
            exact
            path={match.path}
            render={() => <Redirect to={`${match.url}/skus`} />}
          />
        </Switch>
      </ErrorBoundary>
    </>
  )
}

const SubheaderStyled = styled(Subheader)`
  min-height: unset !important;
`;

export default Billing;
