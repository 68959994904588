import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { AlertRule } from '../../../../../store/types/alerts';
import moment from 'moment';
import usePagination from '../../../../common/pagination/hook/use-pagination';
import { getSeverity, getStatus } from '../../../../../utils/alerts';
import usePermissions from '../../../../../utils/auth/use-permissions';
import permissionKeys from '../../../../../utils/auth/permissions';
import { Icon } from '../../../../common/schema-form/common';
import {
  useAlertRules,
  useDeleteAlertRuleById,
} from '../../../../../store/hooks/alert-rules';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import { ColumnProps } from 'antd/lib/table';
import { Edit, Trash2 } from 'react-feather';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';

interface ActionRulesViewProps extends RouteComponentProps<{ organisationId: string }> {}

const ActionRulesView = (props: ActionRulesViewProps) => {
  const {
    match: {
      url,
      params: { organisationId },
    },
  } = props;

  const [alertRuleToDelete, setAlertRuleToDelete] = useState<string>();

  const history = useHistory();

  const { t } = useTranslation();

  const { isAllowed } = usePermissions(organisationId);

  const { createAlert, updateAlert, deleteAlert, showAlert } = useMemo(
    () => ({
      createAlert: isAllowed(permissionKeys.alerts.create),
      updateAlert: isAllowed(permissionKeys.alerts.update),
      deleteAlert: isAllowed(permissionKeys.alerts.remove),
      showAlert: isAllowed(permissionKeys.alerts.viewAll),
    }),
    [isAllowed],
  );

  const { page, pageSize, defaultPage, defaultPageSize } = usePagination();

  const handleCreateRule = useCallback(() => {
    history.push(`${url}/create`);
  }, [history, url]);

  const handleEditRule = useCallback(
    (id: string) => {
      history.push(`${url}/update/${id}`);
    },
    [history, url],
  );

  const [paginationQueryParams] = usePaginationQueryParams({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const {
    data: alertRulesData,
    isLoading,
    isRefetching: isRefetchingAlertRules,
    isFetching,
    refetch: refetchAlertRules,
  } = useAlertRules({
    tenantId: organisationId,
    page: paginationQueryParams.page,
    limit: paginationQueryParams.limit,
  });

  const { mutateAsync, isLoading: isDeleteAlertRuleLoading } = useDeleteAlertRuleById(
    organisationId,
  );

  const handleDeleteAlertRule = useCallback(
    async (id: string) => setAlertRuleToDelete(id),
    [],
  );

  useEffect(() => {
    const deleteAlertRule = async () => {
      try {
        if (alertRuleToDelete) {
          await mutateAsync(alertRuleToDelete);
          await refetchAlertRules();

          message.success(t('alertRules.successDeletingData'));
        }
      } catch {
        message.error(t('alertRules.errorDeletingData'));
      } finally {
        setAlertRuleToDelete(undefined);
      }
    };

    deleteAlertRule();
  }, [alertRuleToDelete, refetchAlertRules, mutateAsync, t]);

  const isDeleting = isDeleteAlertRuleLoading || isRefetchingAlertRules;

  const columns: ColumnProps<AlertRule>[] = useMemo(
    () => [
      {
        title: t('alertRules.alertRule'),
        key: 'alertRule',
        render: (data: AlertRule) =>
          updateAlert ? (
            <Link to={`${url}/update/${data.id}`}>{data.displayName}</Link>
          ) : (
            <>{data.displayName}</>
          ),
      },

      {
        title: t('alertRules.isEnabled'),
        key: 'status',
        render: (data: AlertRule) => {
          return getStatus(data.enabled, t);
        },
      },
      {
        title: t('alertRules.severity'),
        key: 'severity',
        render: (data: AlertRule) => {
          return getSeverity(data.severity, t);
        },
      },
      {
        title: t('alertRules.lastUpdateAt'),
        key: 'lastUpdateAt',
        render: (data: AlertRule) => {
          return moment(data.updatedAt).format('DD/MM/YYYY hh:mm A');
        },
      },
      {
        title: <Icon type="setting" />,
        key: 'settings',
        fixed: 'right',
        width: 50,
        align: 'center',
        render: (data: AlertRule) => {
          const deleteIcon =
            alertRuleToDelete === data.id && isDeleting ? (
              <Icon type="loading" />
            ) : (
              <Icon component={() => <Trash2 color="#FF4D4F" />} />
            );

          return (
            <ActionContainer>
              {updateAlert && (
                <Icon
                  component={() => <Edit />}
                  onClick={() => handleEditRule(data.id)}
                />
              )}
              {deleteAlert && (
                <Popconfirm
                  title={t('actionGroups.areYouSureYouWantToDeleteThisAlertRule')}
                  onConfirm={() => handleDeleteAlertRule(data.id)}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  {deleteIcon}
                </Popconfirm>
              )}
            </ActionContainer>
          );
        },
      },
    ],
    [
      t,
      url,
      handleEditRule,
      deleteAlert,
      handleDeleteAlertRule,
      updateAlert,
      isDeleting,
      alertRuleToDelete,
    ],
  );

  return (
    <ActionRulesViewContainer>
      {createAlert && (
        <ButtonWrapper>
          <Button type="primary" size="large" icon="plus" onClick={handleCreateRule}>
            {t('alertRules.createRule')}
          </Button>
        </ButtonWrapper>
      )}
      {showAlert && (
        <PaginatedListTable<AlertRule>
          data={alertRulesData!}
          columns={columns}
          isDataLoading={isLoading || isFetching}
        />
      )}
    </ActionRulesViewContainer>
  );
};

const ActionRulesViewContainer = styled.div`
  position: relative;
  flex: 1;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 40px;
` as any;

const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #689bc4;
`;

export default ActionRulesView;
