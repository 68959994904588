import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PhyhubDeviceStatusColorEnum } from '../phyhub-device-status';

interface PhyhubDeviceStatusCircleProps {
  statusColor: PhyhubDeviceStatusColorEnum;
}

const PhyhubDeviceStatusCircle: FC<PhyhubDeviceStatusCircleProps> = (props) => {
  const { statusColor } = props;

  return <PhyhubDevStatusCircle statusColor={statusColor} />;
};

const CIRCLE_SIZE = '6px';

const PhyhubDevStatusCircle = styled.span<{ statusColor: PhyhubDeviceStatusColorEnum }>`
  display: inline-block;
  width: ${CIRCLE_SIZE};
  height: ${CIRCLE_SIZE};
  border-radius: 50%;
  background-color: ${(props) => props.statusColor};
`;

export default PhyhubDeviceStatusCircle;
