import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from 'easy-peasy';
import PhyhubDevicesContainer from '../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import { RootState } from '../../../../store/models/root.model';
import Organisation from '../../../../store/types/organisation';
import ErrorView from '../../../common/error-view/error-view.component';
import { StateWrapper } from '../../../common/phyhub-devices/phyhub-devices-common.components';

interface OrganisationPhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string }> {}

const OrganisationPhyhubDevicesContainer: FC<OrganisationPhyhubDevicesContainerProps> = (
  props,
) => {
  const {
    match: {
      // TODO: Rename organisationId to organizationId to match the API
      params: { organisationId: tenantId },
    },
  } = props;

  // TODO: Implement installations and spaces requesting from the API
  const { filterOptions } = usePhyhubDeviceFilters({ installations: [], spaces: [] });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const { tenant } = useStore<
    RootState,
    {
      tenant: Organisation | null;
    }
  >((state) => {
    return {
      tenant: state.organisations.data && state.organisations.data[tenantId],
    };
  });

  return (
    <PhyhubDevicesContainer
      tenantId={tenantId}
      filterOptions={filterOptions}
      sortCriteriaOptions={sortCriteriaOptions}
      titleKey={'devices'}
    />
  );
};

export default OrganisationPhyhubDevicesContainer;
