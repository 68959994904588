import { useMemo } from 'react';
import { PhyhubDeviceStatusColorEnum } from '../phyhub-device-status';
import { PhyhubDeviceStatusEnum } from '../../../../../services/phyhub/enums/phyhub-device-status.enum';

export default function usePhyhubDeviceStatusColor(
  status: PhyhubDeviceStatusEnum,
): PhyhubDeviceStatusColorEnum {
  return useMemo<PhyhubDeviceStatusColorEnum>(() => {
    switch (status) {
      case PhyhubDeviceStatusEnum.OK:
      case PhyhubDeviceStatusEnum.ONLINE: {
        return PhyhubDeviceStatusColorEnum.OK;
      }

      case PhyhubDeviceStatusEnum.OFFLINE: {
        return PhyhubDeviceStatusColorEnum.ERROR;
      }

      default: {
        return PhyhubDeviceStatusColorEnum.WARNING;
      }
    }
  }, [status]);
}
