import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { Button, message } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { get } from 'lodash';
import { AjvError, FieldProps, FormValidation } from 'react-jsonschema-form';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../common/schema-form/schema-form.component';
import useSchemaForm from '../../../../../common/use-schema-form/use-schema-form';
import User from '../../../../../../store/types/user';
import UniversalDevice from '../../../../../../store/types/universal-device';
import Environment from '../../../../../../store/types/environment';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import DeviceDetailsInfo from '../../common/device-details-info/device-details-info.component';
import DeviceDetailsScreenshot from '../../common/device-details-screenshot/device-details-screenshot.component';
import { UniversalDeviceUpdateParams } from '../../../../../../store/models/universal-device-details/universal-device-details.model';
import UniversalDeviceType from '../../../../../../store/types/universal-device-type.enum';
import generateTimeOptions from '../../../../../../utils/generate-time-options';
import OrganizationSpace from '../../../../../../store/types/organisation-space';
import Overlay from '../../../../../common/overlay/overlay.component';
import Spinner from '../../../../../common/spinner/spinner.component';
import DeviceNameInputValidation, {
  DeviceNameInputValidationRef,
} from '../../../../../common/schema-form/internal-widgets/device-name-input-validation/device-name-input-validation.component';
import UniversalDeviceDetailsAssociatedInstallations from './universal-device-details-associated-installations.component';
import UniversalDeviceDetailsTunnelLinks from './universal-device-details-tunnel-links.component';
import { ContentTagType } from '../../../../../../store/common/content-tags/content-tag-type';
import { useActiveTenantAllSpaces } from '../../../../../common/spaces-provider/spaces-provider.component';

interface UniversalDeviceDetailsScreenProps
  extends RouteComponentProps<{ organisationId: string; deviceUuid: string }> {
  canManageDeviceSettings: boolean;
  device: UniversalDevice | null;
  updateDevice: (device: UniversalDeviceUpdateParams) => Promise<void>;
  user: User | null;
  fetchEnvironments: (params: { organizationId: string }) => void;
  environments: Environment[];
  apps: {
    [organisationName: string]: OrganisationApp[];
  };
}

const UniversalDeviceDetailsScreen = (props: UniversalDeviceDetailsScreenProps) => {
  const { t } = useTranslation();
  const {
    canManageDeviceSettings,
    device,
    updateDevice,
    user,
    fetchEnvironments,
    environments,
    apps,
  } = props;
  const formElement = useRef<SchemaFormRef>(null);
  const {
    match: {
      params: { organisationId },
    },
  } = props;

  const refInputValidation = useRef() as React.RefObject<DeviceNameInputValidationRef>;

  const handleError = () => {
    message.error(t('thereAreErrorsInTheContentForm'));
  };

  const handleSave = useCallback(() => {
    if (formElement.current) {
      formElement.current.submit();
    }
  }, [formElement]);

  const handleSubmit = useCallback(
    async (data: any) => {
      if (device) {
        await updateDevice({
          uuid: device.id,
          displayName: data.displayName,
          env: data.env,
          spaces: [data.space],
          notes: data.notes,
          isProvisioned: data.isProvisioned,
          deviceMonitored: data.deviceMonitored,
          externalId: data.externalId,
          deviceExpectedAvailability: {
            ...data.deviceExpectedAvailability,
            days: data.deviceExpectedAvailability.days,
          },
          extras: data.extras,
          tags: data.tags && data.tags.length ? data.tags : [],
        });
      }
    },
    [updateDevice, device],
  );

  useEffect(() => {
    fetchEnvironments({ organizationId: organisationId });
  }, [fetchEnvironments, organisationId]);

  const { spaces } = useActiveTenantAllSpaces();

  const [
    formData,
    formLoading,
    formDirty,
    formInit,
    handleFormChange,
    handleFormSubmit,
  ] = useSchemaForm(
    handleSubmit,
    t('deviceSettingsSaved'),
    t('errorSavingDeviceSettings'),
  );

  useEffect(() => {
    if (device && !Object.keys(formData).length) {
      // @ts-ignore
      formInit({
        displayName: device.deviceName,
        env: device.env,
        isProvisioned: device.deviceProvisioned,
        notes: device.notes,
        externalId: device.externalId,
        deviceMonitored: device.deviceMonitored || false, // for existing non-iot devices
        deviceExpectedAvailability: device.deviceExpectedAvailability || {},
        extras: (device.parentDevice && device.parentDevice.extras) || {},
        space: (device.spaces || []).length ? device.spaces[0] : undefined,
        tags: device.tags && device.tags.length ? device.tags : [],
      });
    }
  }, [device, formInit, formData]);

  useEffect(() => {
    if (
      device &&
      device.deviceName === formData.displayName &&
      !formDirty &&
      !formLoading &&
      refInputValidation.current
    ) {
      refInputValidation.current.setDefaultValue(device.deviceName);
    }
  }, [device, formData, formLoading, formDirty, refInputValidation]);

  const browserUISchema = useMemo(() => {
    return {
      objectFieldTemplate: 'none',
      deviceExpectedAvailability: {
        days: {
          'ui:widget': 'checkboxes',
          'ui:options': {
            inline: true,
          },
        },
      },
      displayName: {
        'ui:widget': (widgetProps: FieldProps) => {
          return <DeviceNameInputValidation ref={refInputValidation} {...widgetProps} />;
        },
      },
      tags: {
        items: {
          'ui:field': 'contentTagPicker',
        }
      }
    };
  }, [refInputValidation]);

  const deviceType = get(device, 'parentDevice.type');

  const browserFormSchema = useMemo(() => {
    const monitorTimeOptions = generateTimeOptions([0]);
    const rebootTimeOptions = generateTimeOptions([0, 30]);

    const isAndroid = deviceType === UniversalDeviceType.ANDROID;

    const parentSpaces = spaces.filter((space) => !space.parentSpaceId);

    return {
      definitions: {
        time: {
          type: 'string',
          enum: monitorTimeOptions,
        },
        rebootTime: {
          type: 'string',
          enum: rebootTimeOptions,
        },
      },
      type: 'object',
      properties: {
        displayName: {
          type: 'string',
          minLength: 1,
          title: t('displayName'),
          pattern: '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$',
        },
        env: {
          type: 'string',
          title: t('environment'),
          enum: environments.map((env) => env.environmentName),
          enumNames: environments.map((env) => env.displayName),
          readOnly: environments.length < 1,
        },
        space: {
          title: t('space'),
          type: 'string',
          enum: ['', ...parentSpaces.map((space) => space.id)],
          enumNames: ['', ...parentSpaces.map((space) => space.displayName)],
          default: parentSpaces.length ? parentSpaces[0].id : '',
          readOnly: parentSpaces.length < 1,
        },
        externalId: {
          type: 'string',
          title: t('externalId'),
        },
        notes: {
          type: 'string',
          title: t('notes'),
        },
        isProvisioned: {
          type: 'boolean',
          title: t('isProvisioned'),
        },
        ...(isAndroid && {
          extras: {
            type: 'object',
            title: t('deviceConfiguration'),
            properties: {
              rebootSchedule: {
                $ref: '#/definitions/rebootTime',
                title: t('deviceRebootSchedule'),
              },
            },
          },
        }),
        deviceMonitored: {
          type: 'boolean',
          title: t('deviceIsMonitored'),
        },
        tags: {
          type: 'array',
          title: t('deviceContentTags'),
          uniqueItems: true,
          items: {
            type: 'object',
            title: '',
            properties: {
              tagType: {
                type: 'string',
                default: ContentTagType.CONTENT_TAG,
              },
              tagId: {
                type: 'string',
              },
              values: {
                type: 'array',
                items: {
                  type: "string",
                }
              }
            },
            required: ["tagId", "values"],
          },
        }
      },
      dependencies: {
        deviceMonitored: {
          oneOf: [
            {
              properties: {
                deviceMonitored: {
                  enum: [true],
                },
                deviceExpectedAvailability: {
                  type: 'object',
                  title: t('deviceExpectedAvailability'),
                  properties: {
                    days: {
                      title: t('days'),
                      type: 'array',
                      items: {
                        type: 'number',
                        oneOf: [
                          {
                            type: 'number',
                            enum: [1],
                            title: t('monday'),
                          },
                          {
                            type: 'number',
                            enum: [2],
                            title: t('tuesday'),
                          },
                          {
                            type: 'number',
                            enum: [3],
                            title: t('wednesday'),
                          },
                          {
                            type: 'number',
                            enum: [4],
                            title: t('thursday'),
                          },
                          {
                            type: 'number',
                            enum: [5],
                            title: t('friday'),
                          },
                          {
                            type: 'number',
                            enum: [6],
                            title: t('saturday'),
                          },
                          {
                            type: 'number',
                            enum: [0],
                            title: t('sunday'),
                          },
                        ],
                      },
                      default: [1, 2, 3, 4, 5, 6, 0],
                      uniqueItems: true,
                    },
                    start: {
                      title: 'Start (UTC)',
                      $ref: '#/definitions/time',
                    },
                    stop: {
                      title: 'Stop (UTC)',
                      $ref: '#/definitions/time',
                    },
                  },
                  dependencies: {
                    start: ['stop'],
                    stop: ['start'],
                  },
                },
              },
            },
            {
              properties: {
                deviceMonitored: {
                  enum: [false],
                },
              },
            },
          ],
        },
      },
      required: ['displayName', 'isProvisioned', 'env', 'space'],
    };
  }, [t, environments, spaces, deviceType]);

  const handleFormValidation = useCallback(
    (value: any, formError: FormValidation) => {
      const elem = refInputValidation.current && refInputValidation.current.input;

      const validationMessage = elem && (elem.props as any)['data-error-message'];
      const isValidatingDeviceName = elem && (elem.props as any)['data-validating'];

      if (validationMessage) {
        formError.displayName.addError(validationMessage);
      }

      if (isValidatingDeviceName) {
        formError.displayName.addError(t('validating'));
      }

      return formError;
    },
    [refInputValidation, t],
  );

  const transformErrors = useCallback(
    (errors: AjvError[]) => {
      return errors.map((error) => {
        if (error.property === '.displayName' && error.name === 'pattern') {
          error.message = t('mustBeInSmallLettersAndKebabCase');
          error.params = { ...error.params, isCustomMessage: true };
        }

        return error;
      });
    },
    [t],
  );

  if (!device) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <Container>
      <ScreenAssociatedContainer>
        <PanelCard bodyStyle={{ padding: "1em 0 0 0" }}>
          {device && (
            <div style={{ paddingBottom: '.5em' }}>
              <SchemaForm
                uiSchema={{ objectFieldTemplate: 'none' }}
                data={device.parentDevice && device.parentDevice.id ? device.parentDevice : { id: device.id }}
                schema={{
                  type: 'object',
                  properties: {
                    id: {
                      type: "string",
                      title: t('deviceId'),
                      readOnly: true,
                    },
                    deviceSerial: {
                      type: "string",
                      title: t('deviceSerial'),
                      readOnly: true,
                    },
                  }
                }}
                formContext={{ organisationId, user }}
              />
            </div>
          )}
          <SchemaForm
            ref={formElement}
            uiSchema={browserUISchema}
            data={formData}
            onChange={handleFormChange}
            schema={browserFormSchema}
            onSubmit={handleFormSubmit}
            onError={handleError}
            formContext={{ organisationId, user }}
            customFormValidation={handleFormValidation}
            transformErrors={transformErrors}
          />
        </PanelCard>
        <InstallationsContainer>
          <PanelCard bodyStyle={{ padding: 0 }}>
            <UniversalDeviceDetailsAssociatedInstallations
              apps={apps}
              organisationId={organisationId}
              device={device}
            />
          </PanelCard>
        </InstallationsContainer>
        {deviceType === UniversalDeviceType.IOTHUB && canManageDeviceSettings && (
          <InstallationsContainer>
            <PanelCard bodyStyle={{ padding: 0 }}>
              <UniversalDeviceDetailsTunnelLinks
                organisationId={organisationId}
                device={device}
              />
            </PanelCard>
          </InstallationsContainer>
        )}
      </ScreenAssociatedContainer>
      <DeviceDetailsContainer>
        <PanelCard>
          <DeviceDetailsScreenshot device={device} />
          <DeviceDetailsInfo device={device} />
          {canManageDeviceSettings && (
            <Button
              loading={formLoading}
              size="large"
              block
              type="primary"
              onClick={handleSave}
              disabled={!formDirty}
            >
              {t('saveChanges')}
            </Button>
          )}
        </PanelCard>
      </DeviceDetailsContainer>
    </Container >
  );
};

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  gap: 32px;
  height: min-content;
  overflow: visible;
  padding: 0px;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 992px) {
    flex-flow: column nowrap;
    display: block;
  }
`;

const ScreenAssociatedContainer = styled.div`
  & label{
    font-weight: bold;
  }
  align-items: flex-start;
  flex: 0.62 0 0px;
  flex-flow: column nowrap;
  gap: 32px;
  overflow: visible;
  padding: 0px;

  form input[type='checkbox'] {
    margin: 2px 0 0 -20px;
  }
`;

const DeviceDetailsContainer = styled.div`
  place-content: flex-start;
  align-items: flex-start;
  flex: 0.38 0 0px;
  flex-flow: column nowrap;
  gap: 24px;
  overflow: visible;
  padding: 0px;

  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    width: 100%;
    display: block;
  }
`;

const InstallationsContainer = styled.div`
  margin-top: 40px;

  .ant-table {
    border: 0;
  }

  @media (max-width: 768px) {
    overflow: hidden;

    .ant-table-thead > tr > th {
      padding: 3px;
    }
  }
`;

export default UniversalDeviceDetailsScreen;
