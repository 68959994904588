import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import PhyhubDevicesContainer from '../../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import { PhyhubDeviceListParamsFiltersOperatorEnum } from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';

interface SpacePhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string; spaceId: string }> {}

const SpacePhyhubDevicesContainer: FC<SpacePhyhubDevicesContainerProps> = (props) => {
  const {
    match: {
      params: { spaceId, organisationId: tenantId },
    },
  } = props;

  const { t } = useTranslation();

  const { filterOptions } = usePhyhubDeviceFilters({ installations: [], spaces: [] });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const presetFilters = {
    spaceIds: {
      op: PhyhubDeviceListParamsFiltersOperatorEnum.IN,
      val: [spaceId],
    },
  };

  return (
    <PhyhubDevicesContainer
      tenantId={tenantId}
      filterOptions={filterOptions}
      sortCriteriaOptions={sortCriteriaOptions}
      presetFilters={presetFilters}
    />
  );
};

export default SpacePhyhubDevicesContainer;
