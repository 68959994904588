
export enum PhyhubDeviceListParamsFiltersOperatorEnum {
  IN = 'in',
}

export interface PhyhubDeviceListParamsFilters {
  [key: string]: {
    op: PhyhubDeviceListParamsFiltersOperatorEnum,
    val: string[],
  }
}

export interface PhyhubDeviceListParams {
  tenantId: string;
  limit: number;
  page: number;
  filters?: PhyhubDeviceListParamsFilters,
}
