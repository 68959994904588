import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { Icon, Tabs } from 'antd';
import { Bell, Zap } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header, { Subheader } from '../../../common/app-layout/header';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import ActionRulesView from './action-rules/action-rules-view.component';
import ActionGroupsView from './action-groups/action-groups-view.component';
import ActionGroupsUpdate from './action-groups/action-groups-update.component';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import AlertRuleCreateForm from './alert-rule/alert-rule-create-form.component';
import AlertRuleEditForm from './alert-rule/alert-rule-edit-form.component';

interface AlertsProps extends RouteComponentProps<{ organisationId: string }> {}

enum MenuKeysEnum {
  ALERT_RULE = 'alert-rules',
  ACTION_GROUP = 'action-groups',
}

const MenuTabs = {
  [MenuKeysEnum.ALERT_RULE]: {
    path: '/alert-rules',
  },
  [MenuKeysEnum.ACTION_GROUP]: {
    path: '/action-groups',
  },
};

const Alerts = (props: AlertsProps) => {
  const { location, match, history } = props;
  const { organisationId } = match.params;

  const [tabKey, setTabKey] = useState<MenuKeysEnum>(MenuKeysEnum.ALERT_RULE);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const segment = location.pathname.replace(match.url, '').split('/')[1];

    setTabKey(segment as MenuKeysEnum);
  }, [match, location]);

  const onTabChange = useCallback(
    (key: string) => {
      const menuKey = key as MenuKeysEnum;
      setTabKey(menuKey);

      const path = MenuTabs[menuKey].path;
      history.push(`${match.url}${path}`);
    },
    [history, match],
  );

  return (
    <>
      <Header
        tabTitle={t('alerts.title')}
        title={t('alerts.title')}
        icon={<Bell size={20} color="#fff" />}
        isBordered={false}
      />
      <SubheaderStyled
        components={[
          <TabBar
            key="search-bar"
            onChange={onTabChange}
            activeKey={tabKey}
            lang={i18n.language}
          >
            <Tabs.TabPane
              tab={
                <span>
                  <Icon component={() => <Bell size={16} />} />
                  {t('alertRules.title')}
                </span>
              }
              key={MenuKeysEnum.ALERT_RULE}
            />
            <Tabs.TabPane
              tab={
                <span>
                  <Icon component={() => <Zap size={16} />} />
                  {t('actionGroups.title')}
                </span>
              }
              key={MenuKeysEnum.ACTION_GROUP}
            />
          </TabBar>,
        ]}
      />
      <ErrorBoundary>
        <Switch>
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.viewAll,
            )}
            path={`${match.path}/alert-rules`}
            component={ActionRulesView}
          />
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.create,
            )}
            path={`${match.path}/alert-rules/create`}
            component={AlertRuleCreateForm}
          />
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.update,
            )}
            path={`${match.path}/alert-rules/update/:alertRuleId`}
            component={AlertRuleEditForm}
          />

          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.viewAll,
            )}
            path={`${match.path}/action-groups`}
            component={ActionGroupsView}
          />
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.create,
            )}
            path={`${match.path}/action-groups/create`}
            component={ActionGroupsUpdate}
          />
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.update,
            )}
            path={`${match.path}/action-groups/update/:groupId`}
            component={ActionGroupsUpdate}
          />
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.alerts.viewAll,
            )}
            path={match.path}
            render={() => <Redirect to={`${match.url}/alert-rules`} />}
          />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

const SubheaderStyled = styled(Subheader)`
  min-height: unset !important;
`;

export default Alerts;
