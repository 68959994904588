import React, { useCallback } from 'react';
import { FieldProps } from 'react-jsonschema-form';
import { Dropdown, Icon, Menu, Popover, Button } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import QueueItem from './queue-item';
import { endpoints, webUrls } from '../../../../../utils/queue-urls';
import useOrganizationQueues from '../../../use-organization-queues';
import Spinner from '../../../spinner/spinner.component';

const QueuePicker = ({
  schema: { title },
  formData = {},
  onChange,
  formContext,
}: FieldProps) => {
  const { history, organisationId } = formContext;
  const { t } = useTranslation();
  const organizationQueuesState = useOrganizationQueues(organisationId);

  const queues = organizationQueuesState.data || [];

  const selectedQueue = queues.find((queue) => queue.id === formData.queueId);

  const onClick = useCallback(() => {
    if (!selectedQueue) return;

    history.push(
      `/organisations/${selectedQueue.organizationId}/apps/installations/${selectedQueue.id}/settings`,
    );
  }, [history, selectedQueue]);

  const onMenuSelect = useCallback(
    (item: ClickParam) => {
      const found = queues.find((queue: any) => queue.id === item.key);
      if (found) {
        onChange({
          queueId: found.id,
          organization: found.organization,
          queueEndpoint: endpoints[found.region],
          webUrl: webUrls[found.region],
          ref: 'queue',
          name: found.title,
        });
      }
    },
    [onChange, queues],
  );

  const menu = (
    <Menu>
      {queues.map((queue) => (
        <Menu.Item key={queue.id} onClick={onMenuSelect}>
          <QueueItem queue={queue} small />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Container>
      <div>
        <label className="control-label">{title}*</label>
      </div>

      {organizationQueuesState.isLoading && <Spinner />}

      {organizationQueuesState.isSuccess && (
        <>
          <Button.Group size="large">
            <Dropdown overlay={menu}>
              <Button type={!selectedQueue ? 'danger' : 'default'}>
                {selectedQueue && <QueueItem queue={selectedQueue} />}

                {!selectedQueue && <>{t('queueNotSelected')}&ensp;</>}

                <Chevron type="down" />
              </Button>
            </Dropdown>
          </Button.Group>

          {selectedQueue && (
            <Popover content={t('queueOpenSettings')}>
              <Button onClick={onClick} type="link">
                <Icon type="link" />
              </Button>
            </Popover>
          )}
        </>
      )}

      {organizationQueuesState.isError && (
        <Warning>{t('errorDuringFetchingQueues')}</Warning>
      )}
    </Container>
  );
};

const Chevron = styled(Icon)`
  position: absolute;
  right: 6px;
  top: 14px;
`;

const Container = styled.div`
  margin-bottom: 16px;
`;

const Warning = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

export default QueuePicker;
