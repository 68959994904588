import React, { useCallback, useMemo } from 'react';
import {
  getProductPrice,
  GridProduct,
  IsoLanguageIds,
  ProductTags,
} from '@ombori/grid-products/dist';
import { get, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps } from 'react-final-form';
import Select, { ValueType } from 'react-select';
import { Select as SelectAnt, Button, Tabs, AutoComplete } from 'antd';
import moment from 'moment';
import { FieldArray } from 'react-final-form-arrays';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import toLower from 'lodash/toLower';
import { FormTextInput, Label } from '../queue-details/queue-setup/styled-block';
import {
  required as requiredValidation,
  requiredAll as requiredAllValidation,
} from '../queue-details/queue-setup/validate-form';
import { ValueOfGridProduct } from './grid-products-details/prepare-data-before-sending';
import PanelCard from '../../../common/panel-card/panel-card.component';
import Environment from '../../../../store/types/environment';
import HeaderComponent from '../../../common/app-layout/header/header.component';
import routes from './routes';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import Spinner from '../../../common/spinner/spinner.component';
import { ShoppingBag } from 'react-feather';
import MultiLanguageLabelComponent from '../../../common/schema-form/widgets/multi-language/multi-language-label.component';
import { TFunction } from 'i18next';
import { TagsInput } from 'react-tag-input-component';
import TextArea from 'antd/lib/input/TextArea';
import { LanguageOption } from './language-picker';
import { SelectValue } from 'antd/lib/select';
import {
  GridProductsFormValues,
  SetValueType,
} from './grid-products-details/grid-products-details.component';
import { MutableState } from 'final-form';
import { ButtonType, SelectType } from '../../../../types';
import { appendQueryParams } from '../../../../utils/url';
import { DEFAULT_PRODUCTS_ENVIRONMENT } from './utils';
import usePaginationQueryParams from '../../../common/use-pagination-query-params';
import { GridProductsPaginationQueryParams } from './types';
import usePermissions from '../../../../utils/auth/use-permissions';
import permissionKeys from '../../../../utils/auth/permissions';

const isoLanguageIdName = 'isoLanguageId';

const getValidationObjByFlag = (required: boolean) => {
  return required ? { validate: requiredValidation } : {};
};

const getValidateAllObjByFlag = (required: boolean) => {
  return required ? { validate: requiredAllValidation } : {};
};

export const getFirstSelectableImage = (product: GridProduct) => {
  const catalogWithImage = product.catalogPageLocationProduct.find((catalogPage) =>
    catalogPage.catalogType.includes('image/'),
  );
  const image = (catalogWithImage && catalogWithImage.catalogPageLocationProduct) || '';
  return { image, productId: (catalogWithImage && catalogWithImage.productId) || '' };
};

export const defaultLng = 'en-US' as IsoLanguageIds;

export const getFirstAvailablePriceLabel = (product: GridProduct) => {
  const priceList = product.productPriceList[0];
  if (!priceList) {
    return 'Unavailable';
  }

  const price = getProductPrice(priceList, defaultLng as IsoLanguageIds);
  return price.label;
};

export type Direction = 'rtl' | 'ltr';

export const getDirection = (language: string): Direction => {
  if (`${toLower(language)}`.startsWith('ar')) {
    return 'rtl';
  }

  return 'ltr';
};

const getProductGroupIdInput = ({
  t,
  disabled = true,
  name,
}: {
  t: TFunction;
  disabled?: boolean;
  name?: string;
}) => {
  const fieldName = name ? `${name}.productGroupId` : 'productGroupId';
  return (
    <Field name={fieldName} {...getValidationObjByFlag(true)}>
      {({ input, meta }) => (
        <FormTextInputStyled
          input={input}
          meta={meta}
          label={t('gridProducts.productProductGroupId')}
          required
          type="text"
          disabled={disabled}
        />
      )}
    </Field>
  );
};

const SelectAdapter = ({
  input,
  isRtl,
  dataCy,
  options,
  ...rest
}: FieldRenderProps<any> & {
  isRtl: boolean;
  dataCy: string;
  defaultValue: { value: string; label: string };
  options: { value: string; label: string }[];
}) => {
  const value = input.value.value
    ? input.value
    : options.find((option) => option.value === input.value);

  return (
    <Select
      {...(input as any)}
      {...rest}
      options={options}
      isSearchable
      isRtl={isRtl}
      value={value}
      classNamePrefix={`react-select-${dataCy}`}
      onChange={(newValue: ValueType<{ value: string }>) => {
        // make it in a compatable format
        const valueSafe =
          newValue && get(newValue, 'value') ? get(newValue, 'value') : null;
        if (valueSafe) {
          input.onChange({ target: { value: valueSafe } } as any);
        }
      }}
    />
  );
};

const getSimpleSelect = ({
  label,
  name,
  items,
  isRtl,
  dataCy,
}: {
  label: string;
  name: string;
  items: { value: string; label: string }[];
  isRtl?: boolean;
  dataCy?: string;
  // default value will be taken from the input
}) => {
  const fieldName = name;

  return (
    <SimpleSelect>
      {!!label && (
        <>
          <Label>{label}</Label>
          <br />
        </>
      )}

      <Field
        name={fieldName}
        component={SelectAdapter as any}
        options={items}
        isRtl={isRtl}
        isClearable={false}
        dataCy={dataCy}
      />
    </SimpleSelect>
  );
};

const MultiSelectAdapter = ({
  input,
  isRtl,
  dataCy,
  options,
  additionalOnChange,
  ...rest
}: FieldRenderProps<any> & {
  isRtl: boolean;
  dataCy: string;
  defaultValue: { value: string; label: string }[];
  options: { value: string; label: string }[];
  additionalOnChange?: (newValues: string[]) => void;
}) => {
  const value = input.value
    .map((currValue: string) => {
      const optionItem = options && options.find((option) => option.value === currValue);

      if (!optionItem) {
        return null;
      }

      return optionItem;
    })
    .filter((item: any) => !!item);

  return (
    <Select
      {...(input as any)}
      {...rest}
      isMulti
      isSearchable
      options={options}
      isRtl={isRtl}
      value={value}
      classNamePrefix={`react-select-${dataCy}`}
      onChange={(newValue: ValueType<{ value: string }>[]) => {
        const valueSafe = newValue ? newValue.map((item) => get(item, 'value')) : [];

        if (valueSafe) {
          input.onChange({ target: { value: valueSafe } } as any);
        }

        if (additionalOnChange) {
          additionalOnChange(valueSafe);
        }
      }}
      styles={{
        multiValue: (styles) => {
          return {
            ...styles,
            backgroundColor: '#edf2f7',
          };
        },
        multiValueLabel: (styles) => ({
          ...styles,
          fontSize: '14px',
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          ':hover': {
            backgroundColor: 'none',
            cursor: 'pointer',
            color: '#e53e3e',
          },
          svg: {
            width: '20px',
            height: '20px',
          },
        }),
      }}
    />
  );
};

const getMultiSelect = ({
  label,
  name,
  items,
  isRtl,
  dataCy,
  additionalOnChange,
}: {
  label: string;
  name: string;
  items: { value: string; label: string }[];
  isRtl?: boolean;
  dataCy?: string;
  additionalOnChange?: (newValues: string[]) => void;
  // default value will be taken from the input
}) => {
  const fieldName = name;

  return (
    <div>
      {!!label && (
        <>
          <Label>{label}</Label>
          <br />
        </>
      )}

      <Field
        name={fieldName}
        component={MultiSelectAdapter as any}
        options={items}
        isRtl={isRtl}
        isClearable={false}
        dataCy={dataCy}
        additionalOnChange={additionalOnChange}
      />
    </div>
  );
};

const getSupportedLanguagePicker = ({
  t,
  name,
  supportedLanguages,
}: {
  t: TFunction;
  name: string;
  supportedLanguages: IsoLanguageIds[];
}) => (
  <Field name={`${name}.${isoLanguageIdName}`}>
    {({ input }) => (
      <div>
        <div>Language</div>
        <SelectAntLanguagePicker
          showSearch
          value={input.value}
          placeholder={t('gridProducts.productLanguagePickerPlaceholder')}
          onChange={(newValue) => {
            input.onChange({ target: { value: newValue } } as any);
          }}
        >
          {supportedLanguages.map((option) => (
            <SelectAnt.Option key={option}>
              <LanguageOption option={option} hideFullLabel />
            </SelectAnt.Option>
          ))}
        </SelectAntLanguagePicker>
      </div>
    )}
  </Field>
);

const SelectAntLanguagePicker = styled(SelectAnt)`
  width: 120px;
  .ant-select-selection {
    height: 36px;
  }
  .ant-select-selection-selected-value {
    height: 36px;

    div:first-of-type {
      margin: 0px;
    }
  }
`;

const getSimpleInput = ({
  fieldName,
  label,
  description,
  required = false,
  type = 'text',
  disabled = false,
  min,
  max,
  dataTestId,
}: {
  fieldName: string;
  required?: boolean;
  label: string;
  description?: string;
  type?: 'text' | 'number';
  disabled?: boolean;
  min?: string;
  max?: string;
  dataTestId?: string;
}) => {
  const customParser =
    type === 'number'
      ? {
          parse: (value: string) => {
            if (value) {
              return parseInt(value, 10);
            }
            return '';
          },
        }
      : {};

  return (
    <Field name={fieldName} {...customParser} {...getValidationObjByFlag(required)}>
      {({ input, meta }) => (
        <FormTextInputStyled
          input={input}
          meta={meta}
          label={label}
          description={description || undefined}
          type={type}
          required={required}
          disabled={disabled}
          {...(type === 'number' && { min })}
          {...(type === 'number' && { max })}
          dataTestId={dataTestId}
        />
      )}
    </Field>
  );
};

const getPriceInput = ({
  fieldName,
  label,
  description,
  required = false,
  disabled = false,
  min = '0',
  max,
  dataTestId,
}: {
  fieldName: string;
  required?: boolean;
  label: string;
  description?: string;
  disabled?: boolean;
  min?: string;
  max?: string;
  dataTestId?: string;
}) => {
  const customParser = (value: string) => {
    if (value == null || value.trim() === '') {
      return undefined;
    }
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? undefined : parsedValue;
  };

  return (
    <Field name={fieldName} parse={customParser} {...getValidationObjByFlag(required)}>
      {({ input, meta }) => (
        <FormTextInputStyled
          input={input}
          meta={meta}
          label={label}
          description={description || undefined}
          type="number"
          required={required}
          disabled={disabled}
          min={min}
          max={max}
          dataTestId={dataTestId}
          step="0.01"
        />
      )}
    </Field>
  );
};

const getSimpleAutoComplete = ({
  fieldName,
  label,
  maxWidth = '-webkit-fill-available',
  required = false,
  disabled = false,
  options = [],
}: {
  fieldName: string;
  options: string[];
  maxWidth?: string | number;
  required?: boolean;
  label: string;
  description?: string;
  disabled?: boolean;
}) => {
  return (
    <div key={`${fieldName}-autocomplete`} style={{ maxWidth }}>
      <Label>
        {label}
        {required ? '*' : ''}
      </Label>
      <Field name={fieldName} {...getValidationObjByFlag(required)}>
        {({ input }) => (
          <StyledAutoComplete
            value={input.value}
            dataSource={options}
            disabled={disabled}
            onChange={(value: SelectValue) =>
              input.onChange({ target: { value } } as any)
            }
          />
        )}
      </Field>
    </div>
  );
};

const getSimpleTagsInput = ({
  fieldName,
  label,
  maxWidth = '-webkit-fill-available',
  required = false,
  placeholder = '',
  dataTestId,
}: {
  fieldName: string;
  maxWidth?: string | number;
  required?: boolean;
  label?: string;
  description?: string;
  disabled?: boolean;
  placeholder?: string;
  dataTestId?: string;
}) => {
  return (
    <TagsContainer
      key={`${fieldName}-tags`}
      style={{ maxWidth }}
      data-testid={dataTestId}
    >
      {label && (
        <Label>
          {label}
          {required ? '*' : ''}
        </Label>
      )}
      <Field name={fieldName} {...getValidationObjByFlag(required)}>
        {({ input }) => (
          <TagsInput
            value={input.value || []}
            onChange={(tags: string[]) => {
              input.onChange({ target: { value: tags } } as any);
            }}
            name={fieldName}
            placeHolder={placeholder}
          />
        )}
      </Field>
    </TagsContainer>
  );
};

const getVariantOptionTagsInput = ({
  fieldName,
  label,
  maxWidth = '-webkit-fill-available',
  required = false,
  setValue,
  placeholder = '',
  currentFormValues,
  customOnChange,
  customOnChangeFieldName,
  dataTestId,
}: {
  fieldName: string;
  currentFormValues: GridProductsFormValues;
  setValue: SetValueType;
  maxWidth?: string | number;
  required?: boolean;
  label?: string;
  description?: string;
  disabled?: boolean;
  placeholder?: string;
  customOnChange: (params: {
    optionFieldName: string;
    newFieldValues: string[];
    setValue: any;
    currentFormValues: GridProductsFormValues;
  }) => void;
  customOnChangeFieldName: string;
  dataTestId?: string;
}) => {
  return (
    <TagsContainer
      key={`${fieldName}-tags`}
      style={{ maxWidth }}
      data-testid={dataTestId}
    >
      {label && (
        <Label>
          {label}
          {required ? '*' : ''}
        </Label>
      )}
      <Field name={fieldName} {...getValidationObjByFlag(required)}>
        {({ input }) => (
          <TagsInput
            value={input.value || []}
            onChange={(tags: string[]) => {
              customOnChange({
                optionFieldName: customOnChangeFieldName,
                newFieldValues: tags,
                setValue,
                currentFormValues,
              });
            }}
            name={fieldName}
            placeHolder={placeholder}
          />
        )}
      </Field>
    </TagsContainer>
  );
};

const getMediaByFieldValue = ({
  fieldName,
  mediaType,
}: {
  fieldName: string;
  mediaType: string;
}) => {
  return (
    <div key={`media-${fieldName}`}>
      <Field name={fieldName}>
        {({ input }) => {
          if (mediaType.includes('video')) {
            return <PreviewVideo src={input.value} />;
          }

          return <PreviewImage src={input.value} alt={'preview'} />;
        }}
      </Field>
    </div>
  );
};

// Specific only for "productTags" field
// Note: Apply tags to all selected language to limit display in UI
const getProductTagsFieldInput = ({
  label,
  description,
  placeholder,
  required = false,
  supportedLanguages,
  dataTestId,
}: {
  label: string;
  placeholder: string;
  supportedLanguages: IsoLanguageIds[];
  description?: string;
  required?: boolean;
  dataTestId?: string;
}) => {
  return (
    <TagsContainer data-testid={dataTestId}>
      <Label>{label}</Label>
      <Description>{description}</Description>
      <Field name={'productTags'} {...getValidationObjByFlag(required)}>
        {({ input }) => {
          const value: string[] = input.value
            ? input.value.reduce(
                (acc: string[], curr: ProductTags) => uniq(acc.concat(curr.productTags)),
                [],
              )
            : [];

          return (
            <TagsInput
              value={value}
              onChange={(tags: string[]) => {
                const tagsByLanguages: ProductTags[] = supportedLanguages.map(
                  (currLanguage) => ({
                    isoLanguageId: currLanguage,
                    productTags: tags,
                  }),
                );

                input.onChange({ target: { value: tagsByLanguages } } as any);
              }}
              name="productTags"
              placeHolder={placeholder}
            />
          );
        }}
      </Field>
    </TagsContainer>
  );
};

const TagsContainer = styled.div`
  width: -webkit-fill-available;
  input.rti--input {
    min-width: fit-content;
  }
`;

function getMultiLanguageArrayInput<T>({
  arrayFieldName,
  inputFieldName,
  label,
  description,
  required = false,
  supportedLanguages = [],
  setValue,
  defaultValues = {} as T,
  t,
  type,
}: {
  arrayFieldName: string;
  inputFieldName: string;
  supportedLanguages: IsoLanguageIds[];
  required?: boolean;
  label: string;
  description?: string;
  type?: 'text' | 'textarea';
  disabled?: boolean;
  setValue: SetValueType;
  defaultValues: T;
  t: TFunction;
}) {
  type TranslationItem = T & { isoLanguageId: IsoLanguageIds };

  const isTextArea = type === 'textarea';

  return (
    <Field name={arrayFieldName} {...getValidationObjByFlag(required)}>
      {({ input }) => {
        const getLanguageValue = (language: IsoLanguageIds) => {
          const currLanguageValue =
            input.value &&
            input.value.find((item: TranslationItem) => item.isoLanguageId === language);

          if (!currLanguageValue) {
            return '';
          }

          return currLanguageValue[inputFieldName];
        };
        const handleLanguageToValueChange = (
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          language: IsoLanguageIds,
        ) => {
          const newValues: MutableState[] = supportedLanguages.map((currLanguage) => {
            const currData =
              input.value &&
              input.value.find(
                (item: TranslationItem) => item.isoLanguageId === currLanguage,
              );

            if (!currData) {
              return {
                ...defaultValues,
                isoLanguageId: currLanguage,
              };
            }
            if (currLanguage !== language) {
              return currData;
            }

            return {
              ...currData,
              isoLanguageId: currLanguage,
              [inputFieldName]: e.target.value,
            };
          });

          setValue(arrayFieldName, newValues);
        };

        return (
          <>
            <div>
              {label} {required && <span className="required">*</span>}
            </div>

            <div>{description || undefined}</div>

            {supportedLanguages.length === 0 && (
              <div>{t('gridProducts.pleaseSelectLanguage')}</div>
            )}

            {supportedLanguages.map((language) => {
              const fieldValue = getLanguageValue(language);

              return (
                <ContentWrapper key={`${arrayFieldName}-${inputFieldName}-${language}`}>
                  <LanguageLabelWrapper>
                    <MultiLanguageLabelComponent language={language} />
                  </LanguageLabelWrapper>

                  {isTextArea && (
                    <TextArea
                      rows={3}
                      value={fieldValue}
                      onChange={(e) => handleLanguageToValueChange(e, language)}
                      placeholder=""
                    />
                  )}

                  {!isTextArea && (
                    <LanguageTextInput
                      type="text"
                      value={fieldValue}
                      onChange={(e) => handleLanguageToValueChange(e, language)}
                      placeholder=""
                      theme={''}
                    />
                  )}
                </ContentWrapper>
              );
            })}
          </>
        );
      }}
    </Field>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LanguageLabelWrapper = styled.div`
  display: flex;
  width: 124px;
`;

const LanguageTextInput = styled.input`
  display: block;
  width: 100%;
  height: 40px;
  padding: 2px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin-left: 8px;
  ${({ disabled }) => `
    background-color: ${disabled ? '#f2f2f2' : '#fff'};
  `}
`;

const getDatePicker = ({
  fieldName,
  t,
  customLabel,
}: {
  t: TFunction;
  fieldName: string;
  customLabel?: string;
}) => {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => {
        const converted = input.value
          ? moment(input.value).format('YYYY-MM-DD')
          : undefined;

        const label = customLabel || t(`gridProducts.product${fieldName}`);

        return (
          <FormTextInput
            input={{
              ...input,
              value: converted,
            }}
            meta={meta}
            label={label}
            type="date"
          />
        );
      }}
    </Field>
  );
};

const getExtendableArraySectionWrapper = ({
  t,
  push,
  lng,
}: {
  t: TFunction;
  push: (name: string, defaultValue: ValueOfGridProduct) => void;
  lng?: IsoLanguageIds;
}) => ({
  onItemRemove = () => {},
  mainPropertyName,
  defaultValue,
  content,
  required = false,
  addButtonLabel,
  hideTitle = false,
  hideActionButtons = false,
}: {
  onItemRemove?: () => void;
  mainPropertyName: string;
  defaultValue?: ValueOfGridProduct;
  content: (name: string, index: number) => any;
  required?: boolean;
  addButtonLabel?: string;
  hideTitle?: boolean;
  hideActionButtons?: boolean;
}) => {
  return (
    <>
      {!hideTitle && (
        <CardLabel>
          {t(`gridProducts.product${mainPropertyName}Title`)}
          {required ? '*' : ''}
        </CardLabel>
      )}
      <FieldArray name={mainPropertyName} {...getValidateAllObjByFlag(required)}>
        {({ fields }) =>
          fields.map((name, index) => {
            return (
              <RowWrapper key={`${mainPropertyName}-${name}`}>
                <Section>{content(name, index)}</Section>
                {!hideActionButtons && (
                  <ArrayDeleteButton
                    onClick={() => {
                      fields.remove(index);
                      onItemRemove();
                    }}
                    size="default"
                    disabled={required && index === 0}
                    icon="delete"
                    data-cy={`${mainPropertyName}-${index}-minus-section-button`}
                    type="link"
                  />
                )}
              </RowWrapper>
            );
          })
        }
      </FieldArray>

      {!hideActionButtons && (
        <Button
          size="default"
          icon="plus"
          type="link"
          onClick={() => {
            push(mainPropertyName, defaultValue);
          }}
          data-cy={`${mainPropertyName}-section-add-new`}
        >
          {addButtonLabel || t('add')}
        </Button>
      )}
    </>
  );
};

const RowWrapper = styled.div`
  display: flex;
`;

const ArrayDeleteButton = styled(Button)`` as any;

const FormTextInputStyled = styled(FormTextInput)`
  width: 100%;

  input {
    height: 36px;
  }

  padding-bottom: 0px;
`;

const Section = styled(PanelCard)`
  margin: 0px 0px 8px;
  width: 100%;
`;

const EnvDropdown = ({
  env,
  envs,
  onChange,
  disabled = false,
}: {
  env: string;
  envs: Environment[];
  onChange: (value: string) => void;
  disabled?: boolean;
}) => {
  return (
    <EnvDropdownSearch showSearch disabled={disabled} value={env} onChange={onChange}>
      {envs.map(({ environmentName, displayName }) => (
        <SelectAnt.Option key={environmentName} value={environmentName}>
          {displayName}
        </SelectAnt.Option>
      ))}
    </EnvDropdownSearch>
  );
};

const EnvDropdownSearch = styled(SelectAnt)`
  margin-right: 8px;
  div:first-of-type {
    height: 100%;
    min-height: 42px;
  }

  .ant-select-selection-selected-value {
    padding-top: 4px;
    padding-right: 4px;
  }
` as SelectType<string>;

const HeaderContainer = styled(HeaderComponent)`
  padding-bottom: 0;
`;

const HeaderTitle = styled.div``;

const TabBarStyled = styled(TabBar)``;

const TabPane = styled(Tabs.TabPane)<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive
      ? `
      font-weight: 500;
      color: #2364aa;
      // border-bottom: 10px solid;
       `
      : ''}
`;

const MainHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 15px;
  background-color: #f5f5f5;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  gap: 12px;
  padding: 0px 0px 16px 0px;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  place-content: center;
  align-items: center;
  gap: 0px;
  background-color: rgb(32, 32, 32);
  opacity: 1;
  border-radius: 8px;
`;

const ProductsIconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  color: #fff;
`;

const ProductsIcon = styled(ShoppingBag)`
  width: 100%;
  cursor: auto;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: center flex-start;
  align-items: center;
  gap: 0px;
`;

const TextTitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: min-content;
  inset: auto;
  flex: 0 0 auto;
  align-self: auto;
`;

const PageLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

const TitleLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #000;
  font-size: 24px;
  line-height: 1em;
`;

const SubtitleLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  color: #000;
  font-size: 20px;
  line-height: 1em;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const AddNewButton = styled(Button)`
  align-self: center;
  margin-bottom: 4px;
` as any;

interface MainHeaderProps {
  match: RouteComponentProps<{ organisationId: string }>['match'];
}

const MainHeader: React.FC<MainHeaderProps> = ({ match }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const {
    params: { organisationId: tenantId },
  } = match;

  const [queryParams] = usePaginationQueryParams<GridProductsPaginationQueryParams>({
    env: DEFAULT_PRODUCTS_ENVIRONMENT,
  });

  const { env } = queryParams;
  const { isAllowed } = usePermissions(tenantId);

  const isOverviewActiveTab = useMemo(() => {
    return location.pathname === routes.productsOverviewPath(tenantId);
  }, [location.pathname, tenantId]);

  const isTypesActiveTab = useMemo(() => {
    return location.pathname === routes.productsTypesPath(tenantId);
  }, [location.pathname, tenantId]);

  const isUploadActiveTab = useMemo(() => {
    return location.pathname === routes.uploadProductsPath(tenantId);
  }, [location.pathname, tenantId]);

  const isIntegrationsActiveTab = useMemo(() => {
    return location.pathname === routes.settingsPath(tenantId);
  }, [location.pathname, tenantId]);

  const handleTabChange = useCallback(
    (path: string) => {
      const updatedPath = appendQueryParams(path, {
        env,
      });

      history.push(updatedPath);
    },
    [env, history],
  );

  const handleAddNewProductClick = useCallback(() => {
    const path = appendQueryParams(routes.newProductPath(tenantId), {
      env,
    });

    history.push(path);
  }, [env, history, tenantId]);

  const handleNewCategoryClick = useCallback(() => {
    const path = appendQueryParams(routes.newProductTypePath(tenantId), {
      env,
    });

    history.push(path);
  }, [env, history, tenantId]);

  return (
    <MainHeaderContainer>
      <TitleContainer>
        <IconContainer>
          <ProductsIconContainer>
            <ProductsIcon />
          </ProductsIconContainer>
        </IconContainer>
        <TextContainer>
          <TextTitleContainer>
            <TitleLabel>{t('gridProducts.title')}</TitleLabel>
          </TextTitleContainer>
        </TextContainer>
      </TitleContainer>
      <ActionsContainer>
        <TabBarStyled
          onChange={handleTabChange}
          activeKey={location.pathname}
          lang={i18n.language}
          data-testid="grid-products-tabs"
        >
          <TabPane
            tab={t('gridProducts.titleProductsOverview')}
            key={routes.productsOverviewPath(tenantId)}
            isActive={isOverviewActiveTab}
          />
          <TabPane
            tab={t('gridProducts.titleCategory')}
            key={routes.productsTypesPath(tenantId)}
            isActive={isTypesActiveTab}
          />
          {isAllowed(permissionKeys.gridProducts.create) && (
            <TabPane
              tab={t('gridProducts.titleImport')}
              key={routes.uploadProductsPath(tenantId)}
              isActive={isUploadActiveTab}
            />
          )}

          {isAllowed(permissionKeys.integrations.viewAll) && (
            <TabPane
              tab={t('Integrations')}
              isActive={isIntegrationsActiveTab}
              key={routes.settingsPath(tenantId)}
            />
          )}
        </TabBarStyled>

        {isOverviewActiveTab && isAllowed(permissionKeys.gridProducts.create) && (
          <AddNewButton
            icon="plus"
            type="primary"
            onClick={handleAddNewProductClick}
            data-testid="grid-products-new-product-button"
          >
            {t('gridProducts.newProduct')}
          </AddNewButton>
        )}

        {isTypesActiveTab && isAllowed(permissionKeys.gridProducts.create) && (
          <AddNewButton
            icon="plus"
            type="primary"
            size="small"
            onClick={handleNewCategoryClick}
            data-testid="grid-products-new-category-button"
          >
            {t('gridProducts.newCategory')}
          </AddNewButton>
        )}
      </ActionsContainer>
    </MainHeaderContainer>
  );
};

interface SubHeaderProps {
  pageName: string;
  subtitle: string;
  children: React.ReactNode;
}

const SubHeader: React.FC<SubHeaderProps> = ({ children, pageName, subtitle }) => {
  return (
    <MainHeaderContainer>
      <TitleContainer>
        <IconContainer>
          <ProductsIconContainer>
            <ProductsIcon />
          </ProductsIconContainer>
        </IconContainer>
        <TextContainer>
          <TextTitleContainer>
            <PageLabel>{pageName}</PageLabel>
            <SubtitleLabel>{subtitle}</SubtitleLabel>
          </TextTitleContainer>
        </TextContainer>
      </TitleContainer>
      {children}
    </MainHeaderContainer>
  );
};

interface CountInfoProps {
  count: number;
  label: string;
  isLoading?: boolean;
}

const CountInfo: React.FC<CountInfoProps> = ({ count, label, isLoading }) => {
  return (
    <Count>
      <ProductsSmallIconContainer>
        <ProductsIcon />
      </ProductsSmallIconContainer>

      <CountLabel>
        {isLoading && <Spinner size={16} />}
        {!isLoading && `${count} ${label}`}
      </CountLabel>
    </Count>
  );
};

const Count = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProductsSmallIconContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
  color: #111111;
`;

const CountLabel = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-left: 8px;
  color: #111111;
`;

const StyledPanelCard = styled(PanelCard)`
  margin-bottom: 16px;
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
`;

const BackButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0 16px 0 0;
  border-right: 1px solid #cacaca;
` as any;

const DeleteButton = styled(Button)`
  box-shadow: none;
  background: inherit;
` as ButtonType;

const CardLabel = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-size: 12px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  .ant-input {
    height: 36px;
  }
`;

const PreviewImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
`;

const PreviewVideo = styled.video`
  width: 60px;
  height: 60px;
  object-fit: cover;
`;

const SimpleSelect = styled.div`
  width: 100%;
`;

export {
  getExtendableArraySectionWrapper,
  getProductGroupIdInput,
  getDatePicker,
  getSimpleInput,
  getPriceInput,
  getSimpleAutoComplete,
  getSimpleSelect,
  getSimpleTagsInput,
  getMultiSelect,
  getValidationObjByFlag,
  getMultiLanguageArrayInput,
  getProductTagsFieldInput,
  getSupportedLanguagePicker,
  getMediaByFieldValue,
  getVariantOptionTagsInput,
  Section,
  EnvDropdown,
  MainHeader,
  SubHeader,
  HeaderContainer,
  HeaderTitle,
  TabBarStyled,
  TabPane,
  CountInfo,
  StyledPanelCard,
  HeaderActions,
  BackButton,
  DeleteButton,
  CardLabel,
};
