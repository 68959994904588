import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Col, Icon, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from '../../../../common/app-layout/header/header.component';
import TabBar from '../../../../common/tab-bar/tab-bar.component';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import ProtectedRouteContainer from '../../../../common/protected-route/protected-route.container';
import { getPermissionPath, permissionKeys } from '../../../../../utils/auth/permissions';
import AppsLibrary from '../../apps-library/apps-library.component';
import usePermissions from '../../../../../utils/auth/use-permissions';
import PublicAppsContainer from './public-apps/public-apps.container';

type MarketplaceBrowseProps = RouteComponentProps<{ organisationId: string }>;

const MarketplaceBrowse = ({ match, history, location }: MarketplaceBrowseProps) => {
  const { t, i18n } = useTranslation();
  const {
    params: { organisationId },
  } = match;
  const onTabChange = useCallback(
    (key: string) => {
      history.push(key);
    },
    [history],
  );

  const { isAllowed } = usePermissions(match.params.organisationId);
  const hasMarketplaceAccess = isAllowed(permissionKeys.marketplace.viewAll);

  return (
    <>
      <MarketplaceHeader
        title={
          <span>
            <Icon type="shopping-cart" /> Marketplace
          </span>
        }
      >
        <Row>
          <Col>
            <MarketplaceTabBar
              onChange={onTabChange}
              activeKey={location.pathname}
              lang={i18n.language}
            >
              {hasMarketplaceAccess && (
                <Tabs.TabPane
                  tab={t('marketplacePublicApps')}
                  key={`${match.url}/public-apps`}
                />
              )}
              {hasMarketplaceAccess && (
                <Tabs.TabPane
                  tab={t('marketplaceAppsLibrary')}
                  key={`${match.url}/apps-library`}
                />
              )}
            </MarketplaceTabBar>
          </Col>
        </Row>
      </MarketplaceHeader>
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path={match.path}
            render={() =>
              hasMarketplaceAccess ? (
                <Redirect to={`${match.url}/public-apps`} />
              ) : (
                <Redirect to={`${match.url}/apps-library`} />
              )
            }
          />
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.marketplace.viewAll,
            )}
            path={`${match.path}/public-apps`}
            component={PublicAppsContainer}
          />
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.marketplace.viewAll,
            )}
            path={`${match.path}/apps-library`}
            component={AppsLibrary}
          />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

const MarketplaceHeader = styled(Header)`
  padding-bottom: 0;
`;

const MarketplaceTabBar = styled(TabBar)`
  
`;

export default MarketplaceBrowse;
