import styled from '@emotion/styled';

export const GridBase = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;

export const TableCell = styled.div<{ align?: 'center' | 'left' | 'right' }>`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: ${({ align = 'left' }) => {
    switch (align) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};
  align-items: center;
`;
