import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  author?: string;
  hoverable?: boolean;
  onInstall?: (a: any) => void;
  url?: string;
  title?: string;
  description?: string;
  screenshot?: string;
}

const Wrapper = ({ children, to }: { children: any; to: any }) =>
  to ? (
    <Link style={{ flex: 1 }} to={to}>
      {children}
    </Link>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

const MarketplaceItem = ({
  author,
  hoverable = false,
  url,
  title,
  description,
  screenshot,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
      hoverable={hoverable}
    >
      <Wrapper to={url}>
        <Header>
          <AppImage>
            <img src={screenshot} alt="Solutions powered by Phygrid" />
          </AppImage>
          <AppCard>
            <AppCardInfo>
              <AppTitleWrapper>
                <p>{title}</p>
              </AppTitleWrapper>
              {author ? `${t('appBy', { author })}` : null}
            </AppCardInfo>
          </AppCard>
        </Header>
        <Body>{description}</Body>
      </Wrapper>
    </Container>
  );
};

const AppImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
  overflow: hidden;

  img {
    transition: all 0.1s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Container = styled(Card)`
  margin-bottom: 16px;
  overflow-y: hidden;
  cursor: auto;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 24%);
  border: 0;
  padding: 0;
  transition: all 0.1s ease;

  &:hover {
    box-shadow: 0px 10px 20px rgb(0 0 0 / 24%);

    ${AppImage} {
      img {
        transform: scale(1.1);
        transition: all 0.1s ease;
      }
    }
  }

  .ant-card-body {
    padding: 0;
    height: 100%;
  }
`;

const Header = styled.div`
  display: block;
  flex: 0;
  color: #676973;
`;

const Body = styled.div`
  flex: 1;
  overflow-y: hidden;
  text-overflow: ellipsis;
  color: #676973;
  padding: 0 16px 16px 16px;
`;

const AppCard = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  padding: 8px 16px;
`;

const AppCardInfo = styled.div`
  color: #676973;
`;

const AppTitleWrapper = styled.div`
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #111;
  font-weight: bold;
  font-size: 16px;
`;

export default MarketplaceItem;
