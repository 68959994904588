/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { FieldProps, FormValidation } from 'react-jsonschema-form';
import { Button, Col, Icon, List, message, Modal, Row, Spin } from 'antd';
import { cloneDeep, get, has, isEqual, merge } from 'lodash';
import Header from '../../common/app-layout/header/header.component';
import { ApiError } from '../../../services/api/api-error';
import { EnterpriseAgreement } from '../../../store/types/enterprise-agreement';
import Plan from '../../../store/types/plan';
import { PlanTypeEnum } from '../../../store/types/organisation-plan';
import Organization, { BillingCurrencyEnum } from '../../../store/types/organisation';
import SelectSearch, {
  arrayToNameIdEnums,
  SelectSearchRef,
} from '../../common/schema-form/widgets/select-search/select-search.component';
import VatIdInput, { VatIdInputRef } from '../../common/schema-form/fields/vat-id-input/vat-id-input.component';
import ValidationStatusEnum from '../../../store/types/validation-status-enum';
import PhoneNumberInput, {
  isValidPhoneNumberInput,
} from '../../common/schema-form/widgets/phone-number-input/phone-number-input.component';
import CrudModal from '../../common/crud-list/crud-modal/crud-modal.component';
import { KEY_ENTERPRISE_AGREEMENTS } from '../../../store/models/enterprise-agreements/enterprise-agreements.model';
import EnterpriseAgreementItem, { StatusTag } from './enterprise-agreement-item.component';
import {
  ActionButtonsConfig,
  ButtonActionsEnum,
  getButtonsConfig, getEnterpriseAgreementDocumentUrl,
  getFieldReadOnlyState,
  getFormattedAgreementCoverage,
  getProcessingTip,
  getStatusColor,
  mapAgreementToFormValues,
  mapFormValuesToAgreement,
} from '../../../utils/enterprise-agreement';
import { StickyColumn } from '../../organisations/organisation-details/queue-details/queue-setup/styled-block';
import EnterpriseAgreementListFilter from './filter/enterprise-agreement-list-filter.container';
import Loader from '../../common/loader/loader-component';
import { EuropeanUnionCountries, getSortedCountries } from '../../../utils/countries';
import User from '../../../store/types/user';
import { getDataResidencyInfo as getDataResidencyOptions } from '../../../utils/data-residency';

interface EnterpriseAgreementListProps {
  fetchEnterpriseAgreements: ({
    params,
    silent,
  }: {
    params?: { [key: string]: string };
    silent?: boolean;
  }) => Promise<void>;
  createEnterpriseAgreement: ({
    data,
    key,
  }: {
    data: Partial<EnterpriseAgreement>;
    key: string;
  }) => Promise<void>;
  updateEnterpriseAgreement: ({
    data,
    key,
    id,
  }: {
    data: Partial<EnterpriseAgreement>;
    key: string;
    id?: string;
  }) => Promise<void>;
  deleteEnterpriseAgreement: ({ key, id }: { key: string; id?: string }) => Promise<void>;
  sendEnterpriseAgreementInvite: ({
    enterpriseAgreementId,
    silent,
  }: {
    enterpriseAgreementId: string;
    silent?: boolean;
  }) => Promise<void>;
  fetchFilters: ({
    params,
    silent,
  }: {
    params?: { [key: string]: string };
    silent?: boolean;
  }) => Promise<void>;
  fetchOrganizations: () => Promise<void>;
  enterpriseAgreements: EnterpriseAgreement[];
  plans: Plan[];
  organizations: Organization[];
  loading: boolean;
  error: ApiError | null;
  organisationUsers: (key: string) => User[];
  fetchOrganisationUsers: (params: { organizationId: string }) => Promise<void>;
}

const EnterpriseAgreementList = (props: EnterpriseAgreementListProps) => {
  const {
    fetchEnterpriseAgreements,
    createEnterpriseAgreement,
    updateEnterpriseAgreement,
    deleteEnterpriseAgreement,
    sendEnterpriseAgreementInvite,
    fetchFilters,
    fetchOrganizations,
    enterpriseAgreements,
    plans,
    organizations,
    loading = false,
    error,
    fetchOrganisationUsers,
    organisationUsers,
  } = props;
  const { t } = useTranslation();
  const hasMounted = useRef<boolean>(false);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedAgreement, setSelectedAgreement] = useState<EnterpriseAgreement>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [formInitialValues, setFormInitialValues] = useState<any>({});

  const vatIdInputRef = useRef<VatIdInputRef>({
    validate: () => {},
    reset: () => {},
  });
  const vatIdDataRef = useRef<{
    isValidating: boolean;
    errors?: string[] | undefined;
    enableValidation?: boolean;
  }>({ isValidating: false, errors: undefined, enableValidation: false });

  const countrySelectSearchRef = useRef<SelectSearchRef>({
    setValue: () => {},
  });
  const existingTenantDataRef = useRef<{ validate: boolean }>({ validate: false });

  const isEditMode = !!selectedAgreement;

  useEffect(() => {
    setFormInitialValues(
      selectedAgreement ? mapAgreementToFormValues(selectedAgreement) : {},
    );
  }, [selectedAgreement]);

  const crudModalRef: any = useRef();
  const submitModalButtonRef: any = useRef();

  const actionButtonRef = useRef<ButtonActionsEnum>();
  const [actionButton, setActionButton] = useState<ButtonActionsEnum>();

  const organizationsEnum = useMemo(() => {
    return arrayToNameIdEnums({
      arr: organizations,
      idKey: 'id',
      nameKey: 'displayName',
    });
  }, [organizations]);

  const fieldSchema = useMemo(() => {
    const status =
      (formInitialValues ? formInitialValues.status : ButtonActionsEnum.CREATE) ||
      ButtonActionsEnum.CREATE;

    return buildFieldSchema({
      formInitialValues,
      isEditMode,
      t,
      plans,
      organizationsEnum,
      organisationUsers,
      readOnly: getFieldReadOnlyState(status),
      countries: getSortedCountries(),
    });
  }, [formInitialValues, isEditMode, t, plans, organizationsEnum, organisationUsers]);

  const metaSchema = useMemo(() => {
    const customMetaSchema = {
      organizationConfiguration: {
        country: {
          'ui:widget': (widgetProps: FieldProps) => {
            return (
              <SelectSearch
                {...widgetProps}
                innerRef={countrySelectSearchRef}
                onSelectedValue={(key: string) => {
                  vatIdDataRef.current.enableValidation = has(
                    EuropeanUnionCountries,
                    key,
                  );

                  if (vatIdDataRef.current.enableValidation) {
                    vatIdInputRef.current.validate();
                    return;
                  }

                  vatIdInputRef.current.reset();
                }}
              />
            );
          },
        },
        vatId: {
          'ui:field': (fieldProps: FieldProps) => {
            return (
              <VatIdInput
                {...fieldProps}
                ref={vatIdInputRef}
                enableValidation={vatIdDataRef.current.enableValidation}
                onValidation={(
                  status: ValidationStatusEnum,
                  errors?: string[] | undefined,
                ) => {
                  const isValidating = status === ValidationStatusEnum.VALIDATING;

                  vatIdDataRef.current.isValidating = isValidating;
                  vatIdDataRef.current.errors = errors;
                }}
              />
            );
          },
        },
      },
    };

    return buildMetaSchema(customMetaSchema);
  }, [vatIdDataRef]);

  useEffect(() => {
    fetchEnterpriseAgreements({});
    fetchOrganizations();
    hasMounted.current = true;
  }, [fetchEnterpriseAgreements, fetchOrganizations]);

  const { statusDesc, statusColor, buttonsConfig } = useMemo(() => {
    const status = selectedAgreement ? selectedAgreement.status : null;

    return {
      statusDesc: status && status.replace('_', ' '),
      statusColor: status && getStatusColor(status),
      buttonsConfig: getButtonsConfig(status || ButtonActionsEnum.CREATE),
    };
  }, [selectedAgreement]);

  const processingTip = useMemo(() => getProcessingTip(actionButton, t), [
    actionButton,
    t,
  ]);

  const handleModalOpen = useCallback((agreement?: EnterpriseAgreement) => {
    if (agreement) {
      const { durationMonths, startDate, status, organizationConfiguration } = agreement;
      const { country = '' } = organizationConfiguration || {};

      const { fromDate, toDate } = getFormattedAgreementCoverage({
        durationMonths,
        startDate: startDate as Date,
        status,
      });

      agreement.startDate = fromDate;
      agreement.endDate = toDate;

      vatIdDataRef.current.enableValidation = country in EuropeanUnionCountries;
      setSelectedAgreement(agreement);
    }
    setIsModalVisible(true);
  }, []);

  const handleModalFormDelete = useCallback(
    (id: string) => {
      const modal = Modal.confirm({
        title: t('enterpriseAgreement.deleteConfirmation'),
        content: (
          <ModalContent>
            {t('enterpriseAgreement.areYouSureYouWantToDelete')}
          </ModalContent>
        ),
        okText: t('yes'),
        cancelText: t('no'),
        className: 'modal-content-m-0',
        onOk: async () => {
          modal.update({ cancelButtonProps: { disabled: true } });
          actionButtonRef.current = ButtonActionsEnum.DELETE;
          setActionButton(ButtonActionsEnum.DELETE);

          try {
            await deleteEnterpriseAgreement({ id, key: KEY_ENTERPRISE_AGREEMENTS });
            message.success(t('enterpriseAgreement.successfullyDeleted'));
            modal.destroy();
            setIsModalVisible(false);
            setSelectedAgreement(undefined);
          } catch (err) {
            message.error(t('enterpriseAgreement.failedToDelete'));
          } finally {
            modal.update({ cancelButtonProps: { disabled: false } });
            actionButtonRef.current = undefined;
            setActionButton(undefined);
            fetchFilters({ silent: true });
          }
        },
        onCancel: () => {},
      });
    },
    [t, deleteEnterpriseAgreement, fetchFilters],
  );

  const handleModalActionClick = useCallback(
    (action: ButtonActionsEnum) => {
      if (action === ButtonActionsEnum.VIEW) {
        setIsModalVisible(false);
        setSelectedAgreement(undefined);
        return;
      }

      if (action === ButtonActionsEnum.DELETE && selectedAgreement) {
        handleModalFormDelete(selectedAgreement.id);
        return;
      }

      if (crudModalRef.current) {
        actionButtonRef.current = action;
        crudModalRef.current.submit();
      }
    },
    [handleModalFormDelete, selectedAgreement],
  );

  const handleModalFormClose = useCallback(() => {
    setIsModalVisible(false);
    setIsFormTouched(false);
    setSelectedAgreement(undefined);
    setFormInitialValues({});

    vatIdDataRef.current = {
      isValidating: false,
      errors: undefined,
      enableValidation: false,
    };
  }, []);

  const handleModalFormSubmit = useCallback(
    async (formData: Partial<EnterpriseAgreement>) => {
      try {
        if (!actionButtonRef.current) {
          submitModalButtonRef.current.buttonNode.click();
        }

        setActionButton(actionButtonRef.current);

        switch (actionButtonRef.current) {
          case ButtonActionsEnum.CREATE:
            await createEnterpriseAgreement({
              data: mapFormValuesToAgreement(formData),
              key: KEY_ENTERPRISE_AGREEMENTS,
            });
            message.success(t('enterpriseAgreement.successfullyCreated'));
            handleModalFormClose();
            fetchFilters({ silent: true });
            break;
          case ButtonActionsEnum.EDIT:
            await updateEnterpriseAgreement({
              id: formData.id,
              data: mapFormValuesToAgreement(formData),
              key: KEY_ENTERPRISE_AGREEMENTS,
            });
            message.success(t('enterpriseAgreement.successfullyUpdated'));
            handleModalFormClose();
            fetchFilters({ silent: true });
            break;
          case ButtonActionsEnum.SEND_INVITE:
          case ButtonActionsEnum.RESEND_INVITE:
            await sendEnterpriseAgreementInvite({
              enterpriseAgreementId: formData.id as string,
            });
            message.success(t('enterpriseAgreement.successfullySentInvite'));
            break;
          default:
        }
      } catch (err) {
        switch (actionButtonRef.current) {
          case ButtonActionsEnum.CREATE:
            message.error(t('enterpriseAgreement.failedToCreate'));
            break;
          case ButtonActionsEnum.EDIT:
            message.error(t('enterpriseAgreement.failedToUpdate'));
            break;
          case ButtonActionsEnum.SEND_INVITE:
            message.error(t('enterpriseAgreement.failedToSendInvite'));
            break;
          default:
        }

        setFormInitialValues(formData);
        setIsFormTouched(true);
      }

      actionButtonRef.current = undefined;
      setActionButton(undefined);
    },
    [
      createEnterpriseAgreement,
      t,
      handleModalFormClose,
      updateEnterpriseAgreement,
      sendEnterpriseAgreementInvite,
      fetchFilters,
    ],
  );

  const handleModalFormChange = useCallback(
    (values: Partial<EnterpriseAgreement>) => {
      const formValues = cloneDeep(values);
      // TO DO: Check why Form schema is returning these objects even if they were not changed
      if (
        formValues.organizationConfiguration &&
        !Object.keys(formValues.organizationConfiguration).length
      ) {
        delete formValues.organizationConfiguration;
      }
      if (formValues.customMessages && !Object.keys(formValues.customMessages).length) {
        delete formValues.customMessages;
      }

      // Not so efficient, but limited to schema-form capability
      if (!isEqual(formInitialValues, formValues)) {
        setIsFormTouched(true);
      } else {
        setIsFormTouched(false);
      }

      if (existingTenantDataRef.current.validate) {
        const country = get(formValues, 'organizationConfiguration.country');
        const vatId = get(formValues, 'organizationConfiguration.vatId');

        countrySelectSearchRef.current.setValue(country);
        vatIdDataRef.current.enableValidation = has(EuropeanUnionCountries, country);

        if (vatIdDataRef.current.enableValidation && vatId) {
          vatIdInputRef.current.validate(vatId);
        } else {
          vatIdInputRef.current.reset();
        }

        existingTenantDataRef.current.validate = false;
      }

      if (
        formInitialValues &&
        formValues.organizationId &&
        formInitialValues.organizationId !== formValues.organizationId
      ) {
        const organization = organizations.find(
          (org) => org.id === formValues.organizationId,
        );
        if (organization) {
          const { dataResidencyOptions } = getDataResidencyOptions();
          const { billingAddress } = organization;

          fetchOrganisationUsers({ organizationId: formValues.organizationId });

          setFormInitialValues({
            ...formValues,
            organizationConfiguration: {
              currency: organization.currency,
              country: organization.country,
              ...(organization.vatId ? { vatId: organization.vatId } : {}),
              tenantName: organization.displayName,
              ...(organization.dataResidency &&
              dataResidencyOptions.includes(organization.dataResidency)
                ? { dataResidency: organization.dataResidency }
                : {}),
            },
            plans: {
              tenantPlanId: organization.planSubscriptions.tenant.current.planId,
              supportPlanId: organization.planSubscriptions.support.current.planId,
            },
            billingAddress: {
              name: billingAddress ? billingAddress.name : '',
              addressLine1: billingAddress ? billingAddress.addressLine1 : '',
              city: billingAddress ? billingAddress.city : '',
              country: billingAddress ? billingAddress.country : '',
              postal: billingAddress ? billingAddress.postal : '',
              // optional fields - only include them when they have values
              ...(billingAddress && billingAddress.addressLine2 ? { addressLine2: billingAddress.addressLine2 } : {}),
              ...(billingAddress && billingAddress.state ?  {state: billingAddress.state} : {}),
            },
          });

          existingTenantDataRef.current.validate = true;
        }
      }
    },
    [fetchOrganisationUsers, formInitialValues, organizations],
  );

  const handleModalFormError = () => {
    setActionButton(undefined);
    actionButtonRef.current = undefined;
  };

  const handleModalFormValidation = useCallback(
    (value: Partial<EnterpriseAgreement>, formError: FormValidation) => {
      const {
        committedConsumption,
        extraCredits,
        tenantAdminUser,
        organizationConfiguration,
      } = value;
      const { phoneNumber = '' } = tenantAdminUser || {};
      const { currency = null } = organizationConfiguration || {};

      if (phoneNumber && !isValidPhoneNumberInput({ value: phoneNumber })) {
        // @ts-ignore
        formError.tenantAdminUser.phoneNumber.addError(t('invalidPhoneNumber'));
      }

      if (committedConsumption && !currency) {
        // @ts-ignore
        formError.committedConsumption.addError(t('currencyRequired'));
        // @ts-ignore
        formError.organizationConfiguration.currency.addError(t('currencyRequired'));
      }

      if (extraCredits && !currency) {
        // @ts-ignore
        formError.extraCredits.addError(t('currencyRequired'));

        if (!committedConsumption) {
          // @ts-ignore
          formError.organizationConfiguration.currency.addError(t('currencyRequired'));
        }
      }

      if (vatIdDataRef.current.errors) {
        vatIdDataRef.current.errors.forEach((err) => {
          // @ts-ignore
          formError.organizationConfiguration.vatId.addError(err);
        });
      }

      if (vatIdDataRef.current.isValidating) {
        // @ts-ignore
        formError.organizationConfiguration.vatId.addError('');
      }

      return formError;
    },
    [t],
  );

  const handleApplyFilter = useCallback(
    async (values: { [key: string]: string }) => {
      try {
        setIsFiltering(true);
        await fetchEnterpriseAgreements({ params: values, silent: true });
      } catch (err) {
        message.error('Failed to filter list');
      } finally {
        setIsFiltering(false);
      }
    },
    [fetchEnterpriseAgreements],
  );

  return (
    <>
      <Header title={t('enterpriseAgreement.title')} />
      <div className="content-body">
        <Loader loading={loading} error={error} hasMounted={hasMounted.current}>
          <ListActionRow type="flex" justify="end">
            <Col>
              <Button size="large" icon="plus" onClick={() => handleModalOpen()}>
                {t('create')}
              </Button>
            </Col>
          </ListActionRow>
          <Row gutter={{ md: 20, xl: 40 }}>
            <Col md={24} xl={15}>
              <Spin
                tip={`${t('loading')}...`}
                spinning={isFiltering}
                indicator={<Icon type="loading" spin />}
              >
                <ListStyled
                  itemLayout="vertical"
                  bordered
                  size="large"
                  dataSource={enterpriseAgreements}
                  renderItem={(agreement: EnterpriseAgreement) => (
                    <EnterpriseAgreementItem
                      agreement={agreement}
                      onClick={handleModalOpen}
                    />
                  )}
                />
              </Spin>
            </Col>
            <StickyColumn md={24} xl={9}>
              <EnterpriseAgreementListFilter
                isFiltering={isFiltering}
                onSubmit={handleApplyFilter}
              />
            </StickyColumn>
          </Row>
        </Loader>
      </div>
      <CrudModal
        ref={crudModalRef}
        title={
          <ModalTitle>
            <div>{t('enterpriseAgreement.modalTitle')}</div>
            {statusColor && (
              <StatusTag
                color={statusColor.background}
                textcolor={statusColor.foreground}
              >
                {statusDesc}
              </StatusTag>
            )}
          </ModalTitle>
        }
        schema={fieldSchema}
        metaSchema={metaSchema}
        initialValues={formInitialValues}
        onSubmit={handleModalFormSubmit}
        onChange={handleModalFormChange}
        onClose={handleModalFormClose}
        onError={handleModalFormError}
        customFormValidation={handleModalFormValidation}
        visible={isModalVisible}
        externalFormChange
        width={700}
        showMessage={false}
        processingTip={processingTip}
        footer={getModalFooterButtons({
          t,
          ref: submitModalButtonRef,
          buttonsConfig,
          actionButton: actionButtonRef.current,
          isFormTouched,
          onClick: handleModalActionClick,
          selectedAgreement,
        })}
      />
    </>
  );
};

const getModalFooterButtons = ({
  t,
  ref,
  actionButton,
  buttonsConfig,
  isFormTouched = false,
  onClick,
                                 selectedAgreement,
}: {
  t: TFunction;
  ref: any;
  actionButton?: ButtonActionsEnum;
  buttonsConfig?: ActionButtonsConfig;
  isFormTouched?: boolean;
  onClick?: (buttonAction: ButtonActionsEnum) => void;
  selectedAgreement: EnterpriseAgreement | undefined,
}): JSX.Element[] => {
  const {
    sendInvite = false,
    resendInvite = false,
    create = false,
    edit = false,
    ok = false,
    delete: remove = false,
  } = buttonsConfig || {};
  const buttons: JSX.Element[] = [];

  //  TODO KP: we will re-do this entire form, current implementation is too hard to maintain
    if (selectedAgreement && selectedAgreement.id) {
      const downloadButton = (
        <DownloadDocumentButton
          key={ButtonActionsEnum.DOWNLOAD_PDF}
          href={`${getEnterpriseAgreementDocumentUrl(selectedAgreement.id)}`}
          target="_blank"
          type="default"
          disabled={isFormTouched}
        >
          {<Icon type="file" />}
          {t('enterpriseAgreement.download')}
        </DownloadDocumentButton>
      );
      buttons.push(downloadButton);
    }

  if (remove) {
    const deleteButton = (
      <DeleteButton
        key={ButtonActionsEnum.DELETE}
        className="btn-danger"
        onClick={() => onClick && onClick(ButtonActionsEnum.DELETE)}
        loading={ButtonActionsEnum.DELETE === actionButton}
      >
        {ButtonActionsEnum.DELETE !== actionButton && <Icon type="delete" />}
        {t('deleteAlt')}
      </DeleteButton>
    );
    buttons.push(deleteButton);
  }

  if (ok) {
    const okButton = (
      <Button
        key={ButtonActionsEnum.VIEW}
        type="primary"
        onClick={() => onClick && onClick(ButtonActionsEnum.VIEW)}
      >
        {t('ok')}
      </Button>
    );
    buttons.push(okButton);
  }

  if (sendInvite) {
    const sendInviteButton = (
      <Button
        key={ButtonActionsEnum.SEND_INVITE}
        type="default"
        onClick={() => onClick && onClick(ButtonActionsEnum.SEND_INVITE)}
        loading={ButtonActionsEnum.SEND_INVITE === actionButton}
        disabled={isFormTouched}
      >
        {ButtonActionsEnum.SEND_INVITE !== actionButton && <Icon type="mail" />}
        {t('enterpriseAgreement.sendInvite')}
      </Button>
    );
    buttons.push(sendInviteButton);
  }

  if (resendInvite) {
    const resendInviteButton = (
      <Button
        key={ButtonActionsEnum.RESEND_INVITE}
        type="default"
        onClick={() => onClick && onClick(ButtonActionsEnum.RESEND_INVITE)}
        loading={ButtonActionsEnum.RESEND_INVITE === actionButton}
        disabled={isFormTouched}
      >
        {ButtonActionsEnum.RESEND_INVITE !== actionButton && <Icon type="mail" />}
        {t('enterpriseAgreement.resendInvite')}
      </Button>
    );
    buttons.push(resendInviteButton);
  }

  if (edit) {
    const editButton = (
      <Button
        ref={ref}
        key={ButtonActionsEnum.EDIT}
        type="primary"
        onClick={() => onClick && onClick(ButtonActionsEnum.EDIT)}
        loading={ButtonActionsEnum.EDIT === actionButton}
        disabled={!isFormTouched}
      >
        {ButtonActionsEnum.EDIT !== actionButton && <Icon type="save" />}
        {t('update')}
      </Button>
    );
    buttons.push(editButton);
  }

  if (create) {
    const createButton = (
      <Button
        ref={ref}
        key={ButtonActionsEnum.CREATE}
        type="primary"
        onClick={() => onClick && onClick(ButtonActionsEnum.CREATE)}
        loading={ButtonActionsEnum.CREATE === actionButton}
        htmlType="submit"
      >
        {ButtonActionsEnum.CREATE !== actionButton && <Icon type="save" />}
        {t('create')}
      </Button>
    );
    buttons.push(createButton);
  }

  return buttons;
};

const DownloadDocumentButton = styled(Button)`
  + button {
    margin-left: 8px;
  }
` as any;

const ModalContent = styled.div`
  margin-top: 20px;
`;

const ModalTitle = styled.div`
  > div {
    display: inline-grid;
    margin-right: 10px;
  }
`;

const ListStyled = styled(List)`
  margin-bottom: 20px;
` as any;

const ListActionRow = styled(Row)`
  margin-bottom: 16px;
`;

const DeleteButton = styled(Button)`
  float: left;
` as any;

const buildFieldSchema = ({
  formInitialValues,
  isEditMode,
  t,
  plans,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  organizationsEnum,
  organisationUsers,
  readOnly,
  countries,
}: {
  formInitialValues: Partial<EnterpriseAgreement>;
  isEditMode: boolean;
  t: TFunction;
  plans: Plan[];
  organizationsEnum: {
    ids: string[];
    names: string[];
  };
  organisationUsers: any;
  readOnly: boolean;
  countries: { names: string[]; codes: string[] };
}) => {
  const tenantPlans = (plans || []).filter((plan) => plan.type === PlanTypeEnum.TENANT);
  const supportPlans = (plans || []).filter((plan) => plan.type === PlanTypeEnum.SUPPORT);
  const { names: countryNames, codes: countryCodes } = countries;

  const isCreatingAgreementForExistingTenant =
    !!formInitialValues.organizationId && !isEditMode;
  const organisationUserEmails = formInitialValues.organizationId
    ? organisationUsers(formInitialValues.organizationId).map((user: User) => user.email)
    : [];

  const uniqueEmails = Array.from(new Set(organisationUserEmails));

  const { dataResidencyOptions } = getDataResidencyOptions();

  const fieldProperties = {
    name: {
      type: 'string',
      title: t('name'),
      maxLength: 100,
    },
    durationMonths: {
      type: 'number',
      title: t('enterpriseAgreement.durationMonths'),
      enum: [3, 6, 12, 24, 36],
      default: 12,
    },
    ...(readOnly && {
      startDate: {
        type: 'string',
        title: t('startDate'),
        format: 'date',
      },
      endDate: {
        type: 'string',
        title: t('endDate'),
        format: 'date',
      },
    }),
    organizationId: {
      type: 'string',
      title: t('enterpriseAgreement.tenant'),
      enum: organizationsEnum.ids,
      enumNames: organizationsEnum.names,
      readOnly: isEditMode,
    },
    organizationConfiguration: {
      type: 'object',
      title: t('enterpriseAgreement.tenantInfo'),
      properties: {
        ...(readOnly && {
          tenantName: {
            type: 'string',
            title: t('organization'),
          },
        }),
        country: {
          type: 'string',
          title: t('country'),
          enum: countryCodes,
          enumNames: countryNames,
        },
        currency: {
          type: 'string',
          title: t('currency'),
          enum: Object.keys(BillingCurrencyEnum),
          readOnly:
            isCreatingAgreementForExistingTenant || !!formInitialValues.organizationId,
        },
        dataResidency: {
          type: 'string',
          title: t('dataResidency'),
          enum: dataResidencyOptions,
          readOnly:
            isCreatingAgreementForExistingTenant || !!formInitialValues.organizationId,
        },
        vatId: {
          type: 'string',
          title: t('enterpriseAgreement.vatId'),
          minLength: 8,
          maxLength: 14,
        },
      },
      required: ['country', 'currency'],
    },
    // TO DO: Fix. Cannot validate 100-, 90-
    committedConsumption: {
      type: 'number',
      title: t('enterpriseAgreement.committedConsumption'),
      minimum: 1,
    },
    extraCredits: {
      type: 'number',
      title: t('enterpriseAgreement.extraCredits'),
      minimum: 1,
    },
    extraCreditsValidDurationMonths: {
      type: 'number',
      title: t('enterpriseAgreement.extraCreditsValidDuration'),
      enum: [1, 3, 6, 12],
    },
    discountConfig: {
      type: 'object',
      title: 'Discount info',
      properties: {
        saasRevenue: {
          type: 'object',
          title: t('enterpriseAgreement.saasRevenue'),
          properties: {
            discountPercentValue: {
              type: 'number',
              title: t('enterpriseAgreement.discountPercent'),
              default: 0,
              minimum: 0,
              maximum: 100,
            }
          }
        },
        professionalServices: {
          type: 'object',
          title: t('enterpriseAgreement.professionalServices'),
          properties: {
            discountPercentValue: {
              type: 'number',
              title: t('enterpriseAgreement.discountPercent'),
              default: 0,
              minimum: 0,
              maximum: 100,
            }
          }
        },
      },
    },
    plans: {
      type: 'object',
      title: t('plansLabel'),
      properties: {
        tenantPlanId: {
          type: 'string',
          title: t('tenantPlanLabel'),
          enum: [...tenantPlans.map((plan) => plan.id)],
          enumNames: [...tenantPlans.map((plan) => plan.name.en)],
        },
        supportPlanId: {
          type: 'string',
          title: t('supportPlanLabel'),
          enum: [...supportPlans.map((plan) => plan.id)],
          enumNames: [...supportPlans.map((plan) => plan.name.en)],
        },
      },
      required: ['tenantPlanId', 'supportPlanId'],
    },
    billingAddress: {
      type: 'object',
      title: t('address.billingAddress'),
      properties: {
        name: {
          type: 'string',
          title: t('address.name'),
          maxLength: 200,
        },
        addressLine1: {
          type: 'string',
          title: t('address.addressLine1'),
          minLength: 2,
          maxLength: 200,
        },
        addressLine2: {
          type: 'string',
          title: t('address.addressLine2'),
          minLength: 2,
          maxLength: 200,
        },
        city: {
          type: 'string',
          title: t('address.city'),
          minLength: 2,
          maxLength: 200,
        },
        state: {
          type: 'string',
          title: `${t('address.stateProvinceRegion')}`,
          minLength: 2,
          maxLength: 200,
        },
        postal: {
          type: 'string',
          title: t('address.postalZipCode'),
          minLength: 2,
          maxLength: 20,
        },
      },
      required: ['name', 'addressLine1', 'city', 'postal'],
    },
    tenantAdminUser: isCreatingAgreementForExistingTenant ? {
      type: 'object',
      title: t('enterpriseAgreement.tenantAdmin'),
      properties: {
        email: {
          type: 'string',
          title: t('email'),
          enum: uniqueEmails.length ? uniqueEmails : [''],
        },
      },
      required: ['email']
    } : {
      type: 'object',
      title: t('enterpriseAgreement.tenantAdmin'),
      properties: {
        firstName: {
          type: 'string',
          title: t('firstName'),
          maxLength: 100,
        },
        lastName: {
          type: 'string',
          title: t('lastName'),
          maxLength: 100,
        },
        email: {
          type: 'string',
          title: t('email'),
          format: 'email',
          maxLength: 50,
        },
        phoneNumber: {
          type: 'string',
          title: t('enterpriseAgreement.phoneNumber'),
        },
      },
      required: ['email', 'firstName', 'lastName'],
    },
    customMessages: {
      type: 'object',
      title: t('enterpriseAgreement.customMessages'),
      properties: {
        signUpLandingMessage: {
          type: 'string',
          title: t('enterpriseAgreement.signUpLandingMessage'),
          maxLength: 500,
        },
        signUpCallToActionMessage: {
          type: 'string',
          title: t('enterpriseAgreement.signUpCallToActionMessage'),
          maxLength: 500,
        },
        emailInviteMessage: {
          type: 'string',
          title: t('enterpriseAgreement.emailInviteMessage'),
          maxLength: 1000,
          default:
            '<h1>Hi {{firstName}} {{lastName}}!</h1>\nWe are glad to send you your enterprise agreement.<br/><br/>\n',
        },
      },
    },
  };

  const fieldSchema = {
    type: 'object',
    properties: fieldProperties,
    readOnly,
    required: ['name', 'durationMonths', 'plans', 'tenantAdminUser'],
  };

  return fieldSchema;
};

const buildMetaSchema = (customMetaSchema: any) => {
  const metaSchema = {
    objectFieldTemplate: 'main-section',
    durationMonths: {
      'ui:widget': 'hidden',
    },
    organizationId: {
      'ui:widget': SelectSearch,
    },
    organizationConfiguration: {
      country: {
        'ui:widget': SelectSearch,
      },
    },
    tenantAdminUser: {
      phoneNumber: {
        'ui:widget': PhoneNumberInput,
      },
    },
    customMessages: {
      signUpLandingMessage: {
        'ui:widget': 'textarea',
        'ui:options': { rows: 3 },
      },
      signUpCallToActionMessage: {
        'ui:widget': 'textarea',
        'ui:options': { rows: 3 },
      },
      emailInviteMessage: {
        'ui:widget': 'textarea',
        'ui:options': { rows: 3 },
      },
    },
  };

  return merge(metaSchema, customMetaSchema);
};

export default EnterpriseAgreementList;
