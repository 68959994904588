import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import ErrorBoundary from '../../../common/error-boundary/error-boundary.component';
import Header from '../../../common/app-layout/header';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../common/schema-form/common';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import Integration from './integration';
import permissionKeys, { getPermissionPath } from '../../../../utils/auth/permissions';
// TO DO: Revert when there'll be more subpages under settings
// import { IntegrationsForm } from './integrations/components';
// import { IntegrationsFormRouteComponentProps } from './integrations/components/integrations-form.component';

interface SettingsProps extends RouteComponentProps<{ organisationId: string }> {}

// TO DO: Revert when there'll be more subpages under settings
// const PATHS = ['/integrations'];

const Settings = (props: SettingsProps) => {
  const { match } = props;
  const { organisationId } = match.params;

  const { t } = useTranslation();

  // TO DO: Revert when there'll be more subpages under settings
  /* const selectedMenuItemKeys = useMemo(() => {
    const matchedMenuPath = PATHS.map((path) =>
      matchPath(location.pathname, {
        path: `${match.path}${path}`,
      }),
    ).find((pathMatch) => !!pathMatch);

    return matchedMenuPath ? [matchedMenuPath.url] : [];
  }, [location.pathname, match.path]); */

  return (
    <ErrorBoundary>
      {/* <Header
        tabTitle={t('settings')}
        title={t('settings')}
        icon={<Icon color="#fff" type="setting" />}
      /> */}
      <SettingsContainer>
        {/* TO DO: Revert when there'll be more subpages under settings */}
        {/* <RowStyled gutter={{ md: 20, xl: 40 }}>
          <StickyColumn>
            <SidebarMenu
              id="sidebarMenu"
              mode="inline"
              selectedKeys={selectedMenuItemKeys}
            >
              <MenuItem key={`${match.url}/integrations`}>
                <Link to={`${match.url}/integrations`}>
                  <span>Integrations</span>
                </Link>
              </MenuItem>
            </SidebarMenu>
          </StickyColumn>
          <ContentColumn>
            <Switch>
              <ProtectedRouteContainer
                exact
                path={`${match.path}/integrations/add`}
                render={(routeProps) => <IntegrationsForm {...(routeProps as IntegrationsFormRouteComponentProps)} type="add" />}
              />
              <ProtectedRouteContainer
                path={`${match.path}/integrations`}
                component={Integrations}
              />
              <Redirect to={`${match.url}/integrations`} />
            </Switch>
          </ContentColumn>
        </RowStyled> */}
        <RowStyled>
          <ContentColumn>
            <Switch>
              <ProtectedRouteContainer
                permissionPath={getPermissionPath(
                  organisationId,
                  permissionKeys.integrations.viewAll,
                )}
                path={`${match.path}/integrations`}
                component={Integration}
              />
              <Redirect to={`${match.url}/integrations`} />
            </Switch>
          </ContentColumn>
        </RowStyled>
      </SettingsContainer>
    </ErrorBoundary>
  );
};

const SettingsContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: 64px 0 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const RowStyled = styled(Row)`
  @media (min-width: 1200px) {
    display: flex;
  }

  flex: 1;
`;

export const StickyColumn = styled(Col)`
  width: 100%;
  z-index: 1;

  @media (min-width: 1200px) {
    position: sticky;
    display: block;
    width: 10%;
    top: 16px;
    height: fit-content;
  }
`;

// Revert when there'll be more subpages under settings
/* const SidebarMenu = styled(Menu)`
  margin: 0 40px 0 5px;
  background: unset;
  border: none;
  display: block;

  @media (max-width: 1199px) {
    display: -webkit-box;
    overflow-x: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    width: 100%;

    margin-left: 0;

    .ant-menu-item {
      width: unset;
    }
  }

  @media (min-width: 1200px) {
    display: block;
  }
`;

const MenuItem = styled(Menu.Item)`
  height: 30px !important;

  span {
    padding: 4px;
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-menu-item {
    padding-left: 0 !important;
  }

  &.ant-menu-item-active {
    background-color: unset !important;

    span {
      background-color: rgb(221 230 235 / 30%);
      border-radius: 4px;
    }
  }

  &.ant-menu-item-selected {
    font-weight: 600;
    background-color: unset !important;

    span {
      background-color: #dde6eb;
      border-radius: 4px;
      color: #2364aa;
    }
  }

  &.ant-menu-item-selected::after {
    opacity: 0;
  }
`; */

const ContentColumn = styled(Col)`
  width: 100%;

  @media (min-width: 1200px) {
    display: block;
    width: 100%;
  }

  @media (min-width: 1400px) {
    display: block;
    width: 100%;
  }
`;

export default Settings;
