import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import useChannelById from '../../../../../store/hooks/channels/use-channel-by-id';
import useUpdateChannel from '../../../../../store/hooks/channels/use-update-channel';
import Overlay from '../../../../common/overlay/overlay.component';
import { Spinner } from '../../../../common/spinner';
import ChannelForm, {
  ChannelFormApiValidationErrors,
  ChannelFormValues,
} from './channel-form-fields.component';
import { ButtonType } from '../../../../../types';
import useGoBack from '../../../../common/use-go-back';
import { extractApiValidationErrors } from '../../../../common/react-final-form';
import { ApiError } from '../../../../../services/api/api-error';
import Message from '../../../../common/message';

interface ChannelEditFormProps extends RouteComponentProps<{ organisationId: string; channelId: string }> {}

const ChannelEditForm = (props: ChannelEditFormProps) => {
  const {
    match: {
      params: { organisationId: tenantId, channelId },
    },
  } = props;

  const { t } = useTranslation();

  const { data: channel, isLoading, isSuccess, isError } = useChannelById(tenantId, channelId);

  const initialValues = useMemo((): ChannelFormValues => {
    if (!channel) {
      return {
        name: '',
        description: '',
        tags: [],
      };
    }

    return {
      name: channel.name,
      description: channel.description || '',
      tags: channel.tags ? channel.tags : [],
    };
  }, [channel]);

  const { mutateAsync: updateChannel } = useUpdateChannel(tenantId, channelId);

  const onFormSubmit = useCallback(
    async (values: ChannelFormValues): Promise<ChannelFormApiValidationErrors> => {
      try {
        const { name, description, tags = [] } = values;

        await updateChannel({
          tenantId,
          name,
          description,
          tags,
        });

        message.success(<Message content={t('contents.channelEditSuccess')} />);

        return {};
      } catch (error) {
        message.error(<Message content={t('contents.channelEditFailed')} />);

        const errors = extractApiValidationErrors(error as ApiError);
        return errors;
      }
    },
    [t, tenantId, updateChannel],
  );

  const goBack = useGoBack();
  const handleBack = useCallback(() => {
    goBack();
  }, [goBack]);

  return (
    <>
      {isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {isError && <NotFound>{t('contents.channelFetchFailed')}</NotFound>}

      {isSuccess && (
        <ChanneleFormContainer>
          <BackToListButton type="default" icon="arrow-left" onClick={handleBack}>
            {t('back')}
          </BackToListButton>

          <ChannelForm
            title={t('contents.updateChannel')}
            channelId={channel ? channel.id : undefined}
            tenantId={tenantId}
            initialValues={initialValues}
            onFormSubmit={onFormSubmit}
          />
        </ChanneleFormContainer>
      )}
    </>
  );
};

export default ChannelEditForm;

const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BackToListButton = styled(Button)`
  height: 36px;
  font-size: 14px;
  color: #676973;
  border: none;
  margin-right: 5px;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    background-color: #f2f4f8;
  }
` as ButtonType;

const ChanneleFormContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 0 40px 20px 40px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`;
