import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import IframeView from '../iframe-view/iframe-view.component';
import Spinner from '../spinner/spinner.component';
import Overlay from '../overlay/overlay.component';

const { Title } = Typography;

const termsOfServiceUrl =
  'https://assets.ctfassets.net/gytr4w0ctk4c/5taX3CGM04LAVX76QBQouG/b69f143487784bc12e5c022fefdc51db/Phygrid___Terms_of_Service.pdf';

interface TosProps {
  onConfirm: () => void;
  className?: string;
}

export default function Tos({ onConfirm, className }: TosProps) {
  const { t } = useTranslation();
  const [showFakeLoader, setShowFakeLoader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowFakeLoader(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Container className={className}>
        <HeaderTitle>{t('termsOfService')}</HeaderTitle>
        <IframeWrapper>
          <IframeView url={termsOfServiceUrl} />
        </IframeWrapper>
        <ActionsWrapper>
          <ActionButton onClick={onConfirm} size="large" type="primary">
            {t('iAgree')}
          </ActionButton>
        </ActionsWrapper>
      </Container>
      {showFakeLoader && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
    </>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  position: absolute;
  padding: 72px 112px;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 32px 0;
  }
`;

const HeaderTitle = styled(Title)`
  align-self: center;
  color: #ffffff !important;
  margin-bottom: 40px !important;

  @media (max-width: 800px) {
    font-size: 14px !important;
    margin-bottom: 24px !important;
  }
`;

const IframeWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 812px;
  border-radius: 12px;
  overflow: hidden;
  align-self: center;

  @media (max-width: 800px) {
    width: 100%;
    border-radius: 0px;
  }
`;

const ActionsWrapper = styled.div`
  align-self: center;
  color: #ffffff !important;
  margin-top: 32px;
`;

const ActionButton = styled(Button)`
  min-width: 212px;
` as any;
