import { action, Action, thunk, Thunk } from 'easy-peasy';
import { TenantSettings } from '../../types/branding';
import { ApiError } from '../../../services/api/api-error';
import Injections from '../../injections.interface';

export const routes = {
  getTenantSettings: (tenantId: string) =>
    ['', 'api', 'tenant-settings', 'tenant', tenantId].join('/'),
  updateTenantSettings: (tenantSettingsId: string) =>
    ['', 'api', 'tenant-settings', tenantSettingsId].join('/'),
  deleteTenantSettings: (tenantSettingsId: string) =>
    ['', 'api', 'tenant-settings', tenantSettingsId].join('/'),
};

export interface OrganisationBrandingModel {
  tenantSettings: TenantSettings | null;
  error: ApiError | null;
  isLoading: boolean;

  setTenantSettings: Action<OrganisationBrandingModel, TenantSettings | null>;
  setLoading: Action<OrganisationBrandingModel, boolean>;
  setError: Action<OrganisationBrandingModel, ApiError | null>;

  fetchTenantSettings: Thunk<OrganisationBrandingModel, { tenantId: string }, Injections>;
  updateTenantSettings: Thunk<
    OrganisationBrandingModel,
    { tenantSettingsId: string; data: Partial<TenantSettings> },
    Injections
  >;
  deleteTenantSettings: Thunk<
    OrganisationBrandingModel,
    { tenantSettingsId: string },
    Injections
  >;
}

const organisationBrandingModel: OrganisationBrandingModel = {
  tenantSettings: null,
  error: null,
  isLoading: false,

  setTenantSettings: action((state, payload) => {
    state.tenantSettings = payload;
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),

  fetchTenantSettings: thunk(async (actions, { tenantId }, { injections }) => {
    actions.setLoading(true);
    actions.setError(null);
    try {
      const data = await injections.apiService.get<TenantSettings>(
        routes.getTenantSettings(tenantId),
      );
      actions.setTenantSettings(data);
    } catch (error) {
      actions.setError(error as ApiError);
    } finally {
      actions.setLoading(false);
    }
  }),

  updateTenantSettings: thunk(
    async (actions, { tenantSettingsId, data }, { injections }) => {
      actions.setLoading(true);
      actions.setError(null);
      try {
        const updatedSettings = await injections.apiService.put<TenantSettings>(
          routes.updateTenantSettings(tenantSettingsId),
          data,
        );
        actions.setTenantSettings(updatedSettings);
      } catch (error) {
        actions.setError(error as ApiError);
      } finally {
        actions.setLoading(false);
      }
    },
  ),

  deleteTenantSettings: thunk(async (actions, { tenantSettingsId }, { injections }) => {
    actions.setLoading(true);
    actions.setError(null);
    try {
      await injections.apiService.delete(routes.deleteTenantSettings(tenantSettingsId));
      actions.setTenantSettings(null);
    } catch (error) {
      actions.setError(error as ApiError);
    } finally {
      actions.setLoading(false);
    }
  }),
};

export default organisationBrandingModel;
