import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

interface PhyhubDevicesTableMassActionsProps {
  selectedDevicesCount: number;
  onUpdateOsClick: () => void;
  onUpdateEnvironmentClick: () => void;
  onRestartClick: () => void;
  onRebootClick: () => void;
  onCancelUpdatesClick: () => void;
}

const PhyhubDevicesTableMassActions: FC<PhyhubDevicesTableMassActionsProps> = (props) => {
  const { selectedDevicesCount } = props;

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>
        {t('phyhubDevices.label.withSelected', { count: selectedDevicesCount })}
      </Title>
      <Button icon="cloud-upload" type="default" onClick={props.onUpdateOsClick}>
        {t('phyhubDevices.button.updateOs')}
      </Button>
      <Button icon="cloud" type="default" onClick={props.onUpdateEnvironmentClick}>
        {t('phyhubDevices.button.setEnvironment')}
      </Button>
      <Button icon="reload" type="default" onClick={props.onRestartClick}>
        {t('phyhubDevices.button.restart')}
      </Button>
      <Button icon="poweroff" type="default" onClick={props.onRebootClick}>
        {t('phyhubDevices.button.reboot')}
      </Button>
      <Button type="danger" onClick={props.onCancelUpdatesClick}>
        {t('phyhubDevices.button.cancelUpdates')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: nowrap;
`;

export default memo(PhyhubDevicesTableMassActions);
