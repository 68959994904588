import React, { FC, memo, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Empty, message, Tabs } from 'antd';
import { History } from 'history';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import { DeviceReport } from '../../../common/overview-report';
import usePhyhubDevicesGet from '../../../../store/hooks/phyhub-devices/use-phyhub-devices-get';
import { Spinner } from '../../../common/spinner';
import Overlay from '../../../common/overlay/overlay.component';
import ErrorView from '../../../common/error-view/error-view.component';
import PhyhubDeviceHeader from '../../../common/phyhub-devices/phyhub-device-header/phyhub-device-header.component';
import { useAnalyticsParams } from '../../../common/use-analytics-params';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import {
  StateWrapper,
} from '../../../common/phyhub-devices/phyhub-devices-common.components';
import styled from '@emotion/styled';
import usePhyhubDevicesDelete from '../../../../store/hooks/phyhub-devices/use-phyhub-devices-delete';
import Message from '../../../common/message';
import useGoBack from '../../../common/use-go-back';
import useDeleteModal from '../../../common/use-delete-modal';
import PhyhubDeviceDetailsScreenContainer
  from "./phyhub-device-details-screen/phyhub-device-details-screen.container";

interface OrganisationPhyhubDeviceDetailsContainerProps {
  deviceId: string;
  matchPath: string;
  matchUrl: string;
  tenantId: string;
  history: History;
}

const OrganizationPhyhubDeviceDetailsContainer: FC<OrganisationPhyhubDeviceDetailsContainerProps> = (
  props,
) => {
  const { deviceId, matchPath, matchUrl, tenantId, history } = props;

  const { i18n, t } = useTranslation();

  const {
    isError: isPhyhubDevicesGetError,
    isFetching: isPhyhubDevicesGetFetching,
    isLoading: isPhyhubDevicesGetLoading,
    isSuccess: isPhyhubDevicesGetSuccess,
    data: phyhubDeviceItem,
  } = usePhyhubDevicesGet({ deviceId, tenantId });

  const {
    isLoading: isPhyhubDeviceDeleteLoading,
    mutateAsync: phyhubDeviceDelete,
  } = usePhyhubDevicesDelete();

  const { analyticsQueryParams } = useAnalyticsParams();

  const location = useLocation();

  const [showDeleteModal] = useDeleteModal();

  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    [history],
  );

  const goBack = useGoBack();

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await phyhubDeviceDelete({ tenantId, deviceId });

      goBack(`/organisations/${tenantId}/devices/phyhub-devices`);

      message.success(
        <Message content={t('phyhubDevices.details.message.deleteSuccess')} />,
      );
    } catch (error) {
      // TODO: Implement Phyhub errors parsing logic
      message.error(
        <Message content={t('phyhubDevices.details.message.deleteFailed')} />,
      );
    }
  }, [goBack, phyhubDeviceDelete, t, tenantId, deviceId]);

  const handleDeleteClick = useCallback(async () => {
    if (!phyhubDeviceItem) {
      return;
    }

    showDeleteModal(
      t('confirmDeleteDevice'),
      t('areYouSureYouWantToDeleteDevice', { deviceName: phyhubDeviceItem.displayName }),
      phyhubDeviceItem.displayName,
      handleDeleteConfirm,
    );
  }, [handleDeleteConfirm, phyhubDeviceItem, showDeleteModal, t]);

  if (
    isPhyhubDevicesGetLoading ||
    isPhyhubDevicesGetFetching ||
    isPhyhubDeviceDeleteLoading
  ) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isPhyhubDevicesGetError) {
    return (
      <StateWrapper>
        <ErrorView
          title={t('phyhubDevices.details.error.title')}
          content={t('phyhubDevices.details.error.subtitle')}
        />
      </StateWrapper>
    );
  }

  if (!isPhyhubDevicesGetSuccess || !phyhubDeviceItem) {
    return (
      <StateWrapper>
        <Empty description={t('phyhubDevices.details.emptyState')} />
      </StateWrapper>
    );
  }

  return (
    <>
      <PhyhubDeviceHeader
        deviceDisplayName={phyhubDeviceItem.displayName}
        deviceStatusUpdatedAt={
          phyhubDeviceItem.statusUpdatedAt || phyhubDeviceItem.updatedAt
        }
        deviceStatus={phyhubDeviceItem.status}
      >
        <HeaderBar>
          <TabBar
            onChange={handleTabChange}
            activeKey={`${location.pathname}${location.search}`}
            lang={i18n.language}
          >
            <Tabs.TabPane
              tab={t('overview')}
              key={`${matchUrl}/overview${analyticsQueryParams}`}
            />
            <Tabs.TabPane tab={t('settings')} key={`${matchUrl}/screen`} />
          </TabBar>
          <HeaderActionsBar>
            <Button icon="delete" size="large" type="danger" onClick={handleDeleteClick}>
              {t('deleteDevice')}
            </Button>
          </HeaderActionsBar>
        </HeaderBar>
      </PhyhubDeviceHeader>
      <Switch>
        <Route
          path={matchPath}
          exact
          render={() => <Redirect to={`${matchUrl}/screen`} />}
        />
        <ProtectedRouteContainer
          loginOnly
          path={`${matchPath}/overview`}
          component={DeviceReport}
        />
        <ProtectedRouteContainer
          loginOnly
          path={`${matchPath}/screen`}
          render={() => (
            <PhyhubDeviceDetailsScreenContainer
              deviceItem={phyhubDeviceItem}
            />
          )}
        />
      </Switch>
    </>
  );
};

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const HeaderActionsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export default memo(OrganizationPhyhubDeviceDetailsContainer);
