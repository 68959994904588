/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
import React from 'react';
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  author?: string;
  hoverable?: boolean;
  onInstall?: () => void;
  url?: string;
  title?: string;
  description?: string;
  coverPhoto?: string;
  onItemSelect?: (params: any) => void;
}

const Wrapper = ({ children, to }: { children: any; to: any }) =>
  to ? (
    <Link style={{ flex: 1 }} to={to}>
      {children}
    </Link>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );

const AppsLibraryListItem = ({
  author,
  hoverable = false,
  url,
  title,
  description,
  coverPhoto,
  onInstall,
}: Props) => {
  const { t } = useTranslation();
  const handleInstall = React.useCallback(() => {
    if (onInstall) {
      onInstall();
    }
  }, [onInstall]);

  return (
    <Container
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
      hoverable={hoverable}
    >
      <Wrapper to={url}>
        <Header>
          <AppImage>
            <img src={coverPhoto} alt="Solutions powered by Phygrid" />
          </AppImage>
          <AppCard>
            <AppCardInfo>
              <AppTitleWrapper>
                <p>{title}</p>
              </AppTitleWrapper>
              {author ? `${t('appBy', { author })}` : null}
            </AppCardInfo>
            <InstallButton onClick={handleInstall}>Install</InstallButton>
          </AppCard>
        </Header>
        {description && <Body>{description}</Body>}
      </Wrapper>
    </Container>
  );
};

const AppImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
  overflow: hidden;

  img {
    transition: all 0.1s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Container = styled(Card)<{ hoverable: boolean }>`
  margin-bottom: 16px;
  overflow-y: hidden;
  cursor: auto;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 24%);
  border: 0;
  padding: 0;
  transition: all 0.1s ease;

  ${({ hoverable }) =>
    hoverable
      ? `
    &:hover {
      box-shadow: 0px 10px 20px rgb(0 0 0 / 24%);

      ${AppImage} {
        img {
          transform: scale(1.1);
          transition: all 0.1s ease;
        }
      }
    }
  `
      : ''}

  .ant-card-body {
    padding: 0;
    height: 100%;
  }
`;

const Header = styled.div`
  display: block;
  flex: 0;
  color: #676973;
`;

const Body = styled.div`
  flex: 1;
  color: #676973;
  padding: 0 16px 16px 16px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const AppCard = styled.div`
  flex: 1;
  margin: 0;
  display: flex;
  padding: 16px;
  align-items: center;
  position: relative;
`;

const AppCardInfo = styled.div`
  color: #676973;
`;

const InstallButton = styled(Button)`
  position: absolute;
  right: 0;
  margin-right: 16px;
  min-width: 88px;
  min-height: 36px;
` as any;

const AppTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #111;
  font-weight: bold;
  font-size: 16px;
`;

export default AppsLibraryListItem;
