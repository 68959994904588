import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import OrganisationSpace from '../../../../../../../store/types/organisation-space';
import Environment from '../../../../../../../store/types/environment';
import {
  Input,
  Select,
  SelectProps,
  TextArea,
  validateRequired,
} from '../../../../../../common/react-final-form';

interface PhyhubDeviceDetailsFormProps {
  isSubmitting: boolean;
  environments: Environment[];
  spaces: OrganisationSpace[];
}

const PhyhubDeviceDetailsFormFields: FC<PhyhubDeviceDetailsFormProps> = (props) => {
  const { isSubmitting, environments, spaces } = props;

  const { t } = useTranslation();

  const environmentOptions = useMemo<SelectProps['options']>(
    () =>
      environments.map((env) => ({ label: env.displayName, value: env.environmentName })),
    [environments],
  );

  const spaceOptions = useMemo<SelectProps['options']>(
    () => spaces.map((space) => ({ label: space.displayName, value: space.id })),
    [spaces],
  );

  return (
    <>
      <FieldContainer>
        <Input isRequired readOnly label={t('deviceId')} name="deviceId" />
      </FieldContainer>
      <FieldContainer>
        <Input isRequired readOnly label={t('deviceSerial')} name="deviceSerial" />
      </FieldContainer>
      <FieldContainer>
        <Input
          isRequired
          label={t('displayName')}
          name="displayName"
          validators={[validateRequired(t('formValidation.required_displayName'))]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('environment')}
          name="env"
          options={environmentOptions}
          validators={[validateRequired(t('formValidation.required_env'))]}
        />
      </FieldContainer>
      <FieldContainer>
        <Select
          isRequired
          isSearchEnabled
          label={t('space')}
          name="spaceId"
          options={spaceOptions}
          validators={[validateRequired(t('formValidation.required_spaceId'))]}
        />
      </FieldContainer>
      <FieldContainer>
        <Input label={t('externalId')} name="externalId" />
      </FieldContainer>
      <FieldContainer>
        <TextArea label={t('notes')} name="notes" rows={4} />
      </FieldContainer>
    </>
  );
};

const FieldContainer = styled(Row)`
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }
`;

export default PhyhubDeviceDetailsFormFields;
