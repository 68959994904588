import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import PhyhubDeviceStatus from '../../phyhub-device-status/phyhub-device-status';
import PhyhubDevicesTableTypeCell from './phyhub-devices-table-type-cell/phyhub-devices-table-type-cell.component';
import { Icon } from '../../../schema-form/common';
import PhyhubDevicesSettingsCell from './phyhub-devices-table-settings-cell/phyhub-devices-table-settings-cell.component';
import PaginatedListTable from '../../../paginated-list/paginatied-list-table';
import { PaginationCollection } from '../../../../../store/types/pagination';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDevicesTableMassActions from './phyhub-devices-table-mass-actions/phyhub-devices-table-mass-actions.component';

interface PhyhubDevicesTableProps {
  isFetching: boolean;
  paginationCollection: PaginationCollection<PhyhubDevice>;
  onDeviceDelete: (deviceId: string) => void;
  onRestart: (selectedDevices: string[]) => void;
  onReboot: (selectedDevices: string[]) => void;
  onUpdateEnvironment: (selectedDevices: string[]) => void;
  onUpdateOs: (selectedDevices: string[]) => void;
}

const PhyhubDevicesTable: FC<PhyhubDevicesTableProps> = (props) => {
  const {
    isFetching,
    paginationCollection,
    onDeviceDelete,
    onReboot,
    onRestart,
    onUpdateEnvironment,
    onUpdateOs,
  } = props;

  const { t } = useTranslation();

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);

  const handleRowSelectionChange = useCallback((selectedRowKeys: string[] | number[]) => {
    const mappedRowKeys: string[] = [];

    selectedRowKeys.forEach((key: string | number) => {
      mappedRowKeys.push(key.toString());
    });

    setSelectedDeviceIds(mappedRowKeys);
  }, []);

  const handleRebootClick = useCallback(() => {
    onReboot(selectedDeviceIds);
  }, [selectedDeviceIds, onReboot]);

  const handleRestartClick = useCallback(() => {
    onRestart(selectedDeviceIds);
  }, [selectedDeviceIds, onRestart]);

  const handleUpdateEnvironmentClick = useCallback(() => {
    onUpdateEnvironment(selectedDeviceIds);
  }, [selectedDeviceIds, onUpdateEnvironment]);

  const handleUpdateOsClick = useCallback(() => {
    onUpdateOs(selectedDeviceIds);
  }, [selectedDeviceIds, onUpdateOs]);

  const handleCancelUpdatesClick = useCallback(() => {
    setSelectedDeviceIds([]);
  }, []);

  const handleDeleteDeviceClick = useCallback(
    (deviceId: string) => () => {
      onDeviceDelete(deviceId);
    },
    [onDeviceDelete],
  );

  const selectedDevicesCount = useMemo<number>(() => selectedDeviceIds.length, [
    selectedDeviceIds,
  ]);

  const columns = useMemo<ColumnProps<PhyhubDevice>[]>(
    (): ColumnProps<PhyhubDevice>[] => [
      {
        key: 'displayName',
        title: selectedDevicesCount ? (
          <MassActionsContainer>
            <MassActionsWrapper>
              <PhyhubDevicesTableMassActions
                selectedDevicesCount={selectedDevicesCount}
                onRebootClick={handleRebootClick}
                onRestartClick={handleRestartClick}
                onUpdateEnvironmentClick={handleUpdateEnvironmentClick}
                onUpdateOsClick={handleUpdateOsClick}
                onCancelUpdatesClick={handleCancelUpdatesClick}
              />
            </MassActionsWrapper>
          </MassActionsContainer>
        ) : (
          t('deviceName')
        ),
        render: (_, deviceItem) => (
          <Link
            to={`/organisations/${deviceItem.tenantId}/devices/phyhub-devices/${deviceItem.id}/screen`}
          >
            {deviceItem.displayName || t('phyhubDevices.label.unnamedDevice')}
          </Link>
        ),
      },
      {
        key: 'status',
        title: selectedDevicesCount ? '' : t('status'),
        render: (_, deviceItem) => <PhyhubDeviceStatus status={deviceItem.status} />,
      },
      {
        key: 'type',
        title: selectedDevicesCount ? '' : t('type'),
        render: (_, deviceItem) => (
          <PhyhubDevicesTableTypeCell status={deviceItem.status} os={deviceItem.os} />
        ),
      },
      {
        key: 'serialNumber',
        title: selectedDevicesCount ? '' : t('serialNumber'),
        dataIndex: 'deviceSerial',
      },
      {
        key: 'env',
        title: selectedDevicesCount ? '' : t('environment'),
        dataIndex: 'env',
      },
      {
        title: selectedDevicesCount ? '' : t('tags'),
        key: 'tags',
        render: () => t('notAvailable'),
      },
      {
        title: selectedDevicesCount ? '' : t('installation'),
        key: 'installation',
        render: () => t('notAvailable'),
      },
      // TODO: Add installation column data
      {
        title: selectedDevicesCount ? '' : <Icon type="setting" />,
        key: 'settings',
        fixed: 'right',
        align: 'center',
        width: 100,
        render: (_, deviceItem) => (
          <PhyhubDevicesSettingsCell
            deviceId={deviceItem.id}
            deviceScreenshotUrl={deviceItem.screenshotUrl}
            onDeleteClick={handleDeleteDeviceClick(deviceItem.id)}
          />
        ),
      },
    ],
    [selectedDevicesCount, t],
  );

  return (
    <PaginatedListTable<PhyhubDevice>
      isDataLoading={isFetching}
      columns={columns}
      data={paginationCollection}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedDeviceIds,
        onChange: handleRowSelectionChange,
      }}
    />
  );
};

const MASS_ACTIONS_BLOCK_HEIGHT = '32px';

const MassActionsContainer = styled.div`
  position: relative;
  height: ${MASS_ACTIONS_BLOCK_HEIGHT};
`;

const MassActionsWrapper = styled.div`
  position: absolute;
  height: ${MASS_ACTIONS_BLOCK_HEIGHT};
`;

export default memo(PhyhubDevicesTable);
