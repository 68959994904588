import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Col, Icon, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import LegacyWindowsDevice from '../../../store/types/legacy-windows-device';
import { ApiError } from '../../../services/api/api-error';
import DevicesUniversalListItem from '../universal-devices/devices-universal-list-item/devices-universal-list-item.component';
import Overlay from '../overlay/overlay.component';
import Spinner from '../spinner/spinner.component';
import SearchInput from '../search-input/search-input-v2.component';
import ErrorView from '../error-view/error-view.component';
import UniversalDeviceType from '../../../store/types/universal-device-type.enum';
import sortLegacyWindowsDevices from '../../../utils/sort-legacy-windows-devices';
import Pagination from '../pagination';
import usePagination from '../pagination/hook/use-pagination';
import OrganisationApp from '../../../store/types/organisation-app';

const { Title, Text } = Typography;

interface LegacyWindowsDevicesListProps {
  app?: OrganisationApp | null;
  customQuery?: string;
  enableSearch?: boolean;
  devices: LegacyWindowsDevice[];
  loaded: boolean;
  lastUpdated?: Date;
  error?: ApiError | null;
  fetchDevices?: ({ silent }: { silent: boolean }) => Promise<void>;
  onDeviceListItemClick?: (device: LegacyWindowsDevice) => void;
  renderExtraActions?: (deviceId: string) => JSX.Element;
  disableDevices?: string[];
  autoScroll?: boolean;
}

const LegacyWindowsDevicesList = (props: LegacyWindowsDevicesListProps) => {
  const {
    app,
    devices,
    customQuery = '',
    enableSearch = true,
    loaded,
    fetchDevices,
    lastUpdated,
    error,
    onDeviceListItemClick,
    renderExtraActions,
    disableDevices = [],
    autoScroll = false,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState<string>(customQuery);
  const [filteredDevices, setFilteredDevices] = useState<LegacyWindowsDevice[]>([]);

  const { t } = useTranslation();

  const { page, pageSize, setPage, setPageSize, defaultPageSizeOption } = usePagination({
    defaultPageSize: 500,
    defaultPageSizeOption: [500],
  });

  useEffect(() => {
    if (customQuery !== undefined) {
      setQuery(customQuery);
    }
  }, [customQuery]);

  useEffect(() => {
    const currentDevices = devices;

    if (query.length > 1) {
      setFilteredDevices(filterDevices(currentDevices, query));
    } else {
      setFilteredDevices(currentDevices);
    }
  }, [devices, query, setFilteredDevices]);

  useEffect(() => {
    const fetchLegacyWindowsDevices = async () => {
      if (fetchDevices) {
        await fetchDevices({ silent: false });
      }
    };

    if (containerRef && containerRef.current && autoScroll) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    fetchLegacyWindowsDevices();
  }, [autoScroll, fetchDevices]);

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
    },
    [setQuery],
  );

  const handlePaginationSizeChange = useCallback(
    (pageNum: number, pageLimit: number) => {
      setPage(1);
      setPageSize(pageLimit);
    },
    [setPage, setPageSize],
  );

  const handlePageChange = useCallback(
    (pageNum: number) => {
      setPage(pageNum);
    },
    [setPage],
  );

  const renderDeviceListItem = useCallback(
    (device: LegacyWindowsDevice, isDisabled: boolean) => {
      let link;

      if (!onDeviceListItemClick && device.organizationId) {
        if (device.type === UniversalDeviceType.SCREEN) {
          link = `/organisations/${device.organizationId}/operations/devices/v3/${device.id}`;
        } else if (device.type === UniversalDeviceType.IOTHUB) {
          link = `/organisations/${device.organizationId}/operations/devices/v2/${device.uuid}`;
        } else if (device.type === UniversalDeviceType.LEGACY_BALENA) {
          link = `/organisations/${device.organizationId}/operations/devices/${device.uuid}`;
        }
      }

      if (link) {
        return (
          <Link key={device.id} to={link}>
            <DevicesUniversalListItem lastUpdated={lastUpdated} device={device} />
          </Link>
        );
      }

      return (
        // eslint-disable-next-line
        <div
          key={device.id}
          onClick={() => {
            return onDeviceListItemClick && onDeviceListItemClick(device);
          }}
        >
          <DevicesUniversalListItem
            renderExtraActions={renderExtraActions}
            lastUpdated={lastUpdated}
            device={device}
            isDisabled={isDisabled}
          />
        </div>
      );
    },
    [lastUpdated, onDeviceListItemClick, renderExtraActions],
  );

  const sortedDevices = useMemo(() => {
    const appId = app ? app.id : '';

    const filteredOnlyLegacyWindows = filteredDevices.filter((device) => {
      const include = appId ? device.appIds.includes(appId) : true;

      return (
        include &&
        device.type === UniversalDeviceType.IOTHUB &&
        device.iotedge === false &&
        device.parentDevice === null
      );
    });

    return sortLegacyWindowsDevices(filteredOnlyLegacyWindows).map((device) => {
      const isDisabled = disableDevices.includes(device.id);
      return renderDeviceListItem(device, isDisabled);
    });
  }, [app, disableDevices, filteredDevices, renderDeviceListItem]);

  // TO DO: Add this to use usePagination for local pagination support
  const { pageIndex, pageSizeIndex } = useMemo(() => {
    const altPage = (page - 1) * pageSize;

    return {
      pageIndex: altPage,
      pageSizeIndex: altPage + pageSize,
    };
  }, [page, pageSize]);

  return (
    <Container ref={containerRef}>
      <TitleStyled level={4}>
        <WindowsIcon type="windows" />
        <TitleText>{t('legacyWindowsDevices')}</TitleText>
        <Divider />
      </TitleStyled>
      <Content>
        {!loaded && (
          <OverlayStyled>
            <Spinner />
            <LoadingTip>{t('mayTakeSomeTime')}</LoadingTip>
          </OverlayStyled>
        )}
        {error && (
          <OverlayStyled>
            <ErrorView />
          </OverlayStyled>
        )}
        {loaded && !error && !sortedDevices.length && (
          <OverlayStyled>
            <Title level={4}>
              {t('noDevicesFound')} <Icon type="frown" />
            </Title>
          </OverlayStyled>
        )}
        {loaded && !!sortedDevices.length && (
          <>
            <FiltersRow type="flex" justify="end">
              {enableSearch && (
                <Col lg={12} xs={16}>
                  <SearchInput
                    placeholder={t('searchDevicePlaceholderLabel')}
                    onSearch={handleSearch}
                  />
                </Col>
              )}
            </FiltersRow>
            <DevicesGrid>
              {sortedDevices.length &&
                sortedDevices.slice(pageIndex, pageSizeIndex).map((device) => device)}
            </DevicesGrid>
            <Pagination
              align="right"
              showSizeChanger
              onShowSizeChange={handlePaginationSizeChange}
              current={page}
              pageSize={pageSize}
              total={sortedDevices.length}
              onChange={handlePageChange}
              pageSizes={defaultPageSizeOption}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

const filterDevices = (devices: LegacyWindowsDevice[], query: string) => {
  const lowerCasedQuery = query.toLowerCase();

  return devices.filter((device) => {
    const hostName = get(device, 'properties.reported.hostname', '') as string;
    const supervisorRelease = get(
      device,
      'properties.reported.supervisorRelease',
      '',
    ) as string;

    const hasDeviceName = device.deviceName.toLowerCase().includes(lowerCasedQuery);
    const hasHostName = hostName.toLowerCase().includes(lowerCasedQuery);
    const hasDeviceSerial =
      device.deviceSerial && device.deviceSerial.toLowerCase().includes(lowerCasedQuery);
    const hasSupervisorRelease = supervisorRelease
      .toLowerCase()
      .includes(lowerCasedQuery);

    return hasDeviceName || hasHostName || hasDeviceSerial || hasSupervisorRelease;
  });
};

const Container = styled.div`
  margin-top: 50px;
`;

const TitleStyled = styled(Title)`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled.span`
  align-items: center;
  display: inline-flex;
  margin-left: 10px;
`;

const Divider = styled.hr`
  flex: 1;
  margin-left: 10px;
  border-width: 0.1px;
  border-color: #80808026;
`;

const WindowsIcon = styled(Icon)`
  font-size: 30px;
`;

const Content = styled.div`
  margin-top: 20px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const OverlayStyled = styled(Overlay)`
  position: relative;
  flex-direction: column;
`;

const LoadingTip = styled(Text)`
  margin-top: 5px;
`;

const DevicesGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 310px);
  justify-content: center;
  padding-bottom: 10px;
`;

const FiltersRow = styled(Row)`
  padding: 0 16px 16px;
  align-items: center;
`;

export default LegacyWindowsDevicesList;
