import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Descriptions } from 'antd';
import { EnterpriseAgreement } from '../../../../store/types/enterprise-agreement';
import { formatCurrencyAwareValue } from '../../../../utils/currency';
import { getTranslationFromObject } from '../../../../utils/language/language.util';
import Plan from '../../../../store/types/organisation-plan';

interface OnboardingEnterpriseAgreementDetailsProps {
  enterpriseAgreement: EnterpriseAgreement;
}

const OnboardingEnterpriseAgreementDetails = (
  props: OnboardingEnterpriseAgreementDetailsProps,
) => {
  const { enterpriseAgreement } = props;
  const {
    durationMonths,
    discountConfig,
    committedConsumption,
    plans,
    organizationConfiguration,
    extraCredits,
    extraCreditsValidDurationMonths,
  } = enterpriseAgreement;
  const { tenantPlanId, supportPlanId } = plans;
  const { currency = null } = organizationConfiguration || {};

  const { tenantPlanName, supportPlanName } = useMemo(() => {
    const { name: tenantPlan } = tenantPlanId as Plan;
    const { name: supportPlan } = supportPlanId as Plan;

    return {
      tenantPlanName: getTranslationFromObject(tenantPlan),
      supportPlanName: getTranslationFromObject(supportPlan),
    };
  }, [tenantPlanId, supportPlanId]);

  const { fromDate, toDate } = useMemo(() => {
    const nowMoment = moment();

    return {
      fromDate: nowMoment.format('DD-MM-YYYY'),
      toDate: nowMoment.add(durationMonths, 'M').format('DD-MM-YYYY'),
    };
  }, [durationMonths]);

  const committedConsumptionAmount = useMemo(() => {
    if (!committedConsumption) {
      return '';
    }

    return formatCurrencyAwareValue(committedConsumption);
  }, [committedConsumption]);

  const voucher = useMemo(() => {
    if (!extraCredits) {
      return {
        amount: '',
        validUntil: '',
      };
    }

    const nowMoment = moment();
    return {
      amount: formatCurrencyAwareValue(extraCredits),
      validUntil: nowMoment
        .add(extraCreditsValidDurationMonths || durationMonths, 'M')
        .format('DD-MM-YYYY'),
    };
  }, [extraCredits, durationMonths, extraCreditsValidDurationMonths]);

  const saasRevenueDiscountPercentValue = useMemo(() => {
    if (!discountConfig || !discountConfig.saasRevenue) {
      return 0;
    }

    return discountConfig.saasRevenue.discountPercentValue;
  }, [discountConfig]);

  const professionalServicesDiscountPercentValue = useMemo(() => {
    if (!discountConfig || !discountConfig.professionalServices) {
      return 0;
    }

    return discountConfig.professionalServices.discountPercentValue;
  }, [discountConfig]);

  const pageTitle =
    organizationConfiguration && organizationConfiguration.tenantName
      ? `Enterprise Agreement details for ${organizationConfiguration.tenantName}`
      : 'Enterprise Agreement details';

  return (
    <EnterpriseAgreementDetails title={<PageTitle>{pageTitle}</PageTitle>} column={1}>
      <Descriptions.Item label="Duration">{`${
        durationMonths ? `${durationMonths} months` : ''
      }`}</Descriptions.Item>
      <Descriptions.Item label="Start date">{fromDate}</Descriptions.Item>
      <Descriptions.Item label="End date">{toDate}</Descriptions.Item>
      {saasRevenueDiscountPercentValue && (
        <Descriptions.Item label="Saas revenue discount">
          {`${saasRevenueDiscountPercentValue}%`}
        </Descriptions.Item>
      )}
      {professionalServicesDiscountPercentValue && (
        <Descriptions.Item label="Professional services discount">
          {`${professionalServicesDiscountPercentValue}%`}
        </Descriptions.Item>
      )}
      {committedConsumptionAmount && (
        <Descriptions.Item label="Committed consumption">
          {committedConsumptionAmount}
        </Descriptions.Item>
      )}
      {voucher.amount && (
        <Descriptions.Item label="Voucher value">{voucher.amount}</Descriptions.Item>
      )}
      {voucher.validUntil && (
        <Descriptions.Item label="Voucher valid until">
          {voucher.validUntil}
        </Descriptions.Item>
      )}
      {currency && <Descriptions.Item label="Currency">{currency}</Descriptions.Item>}
      <Descriptions.Item label="Tenant plan">{tenantPlanName}</Descriptions.Item>
      <Descriptions.Item label="Support plan">{supportPlanName}</Descriptions.Item>
    </EnterpriseAgreementDetails>
  );
};

const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #1d1921;
  margin-top: 40px;
`;

const EnterpriseAgreementDetails = styled(Descriptions)`
  .ant-descriptions-item-label {
    width: 35%;
    white-space: break-spaces;
  }
  .ant-descriptions-item-label::after {
    float: right;
  }
`;

export default OnboardingEnterpriseAgreementDetails;
