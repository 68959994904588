import React from 'react';
import styled from '@emotion/styled';

interface SubheaderProps {
  className?: string;
  components?: JSX.Element[];
  variant?: 'flat' | 'dropshadow';
}

const Subheader = (props: SubheaderProps) => {
  const { className, components = [], variant = 'flat' } = props;

  return (
    <SubheaderContainer className={className} variant={variant}>
      {components.map((component) => component)}
    </SubheaderContainer>
  );
};

const SubheaderContainer = styled.div<{ variant: string }>`
  display: flex;
  align-items: center;
  min-height: 52px;
  padding: 0 40px;
  gap: 5px;

  .ant-tabs {
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.06);
  }

  @media screen and (max-width: 991px) {
    padding: 0 20px;
  }

  ${({ variant }) => {
    return variant === 'dropshadow'
      ? `
        min-height: unset;
        padding: 10px 80px;
        box-shadow: rgba(33, 35, 38, 0.3) 0px 0px 4px 1px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
        `
      : '';
  }}
`;

export default Subheader;
