import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { StyledComponent } from '@emotion/styled';
import { ComponentEnum, ComponentVersion } from '../use-device-components';
import {
  Button,
  Divider as AntdDivider,
  Typography,
  Collapse as AntdCollapse,
  message,
} from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { CollapseProps } from 'antd/lib/collapse/Collapse';
import VersionsInfo from './versions-info.component';
import { Icon } from '../schema-form/common';
import {
  useDeviceBundleUpdate,
  UseDeviceBundleUpdateProps,
} from '../use-device-components';
import Message from '../message';
import { ApiError } from '../../../services/api/api-error';
import { useRouteMatch } from 'react-router';

const { Title: AntdTitle, Text: AntdText } = Typography;

interface OutdatedDeviceComponentsContentProps {
  outdatedComponents: ComponentVersion[];
  onDeviceBundleUpdate?: (isUpdating: boolean) => void;
  canUserUpdate?: boolean;
}

const OutdatedDeviceComponentsContent = (props: OutdatedDeviceComponentsContentProps) => {
  const { outdatedComponents, onDeviceBundleUpdate = () => {}, canUserUpdate } = props;

  const [isDeviceBundlePanelVisible, setIsDeviceBundlePanelVisible] = useState<boolean>(
    false,
  );

  const { t } = useTranslation();

  const {
    params: { organisationId },
  } = useRouteMatch<{ organisationId: string }>();

  const {
    mutateAsync: updateDeviceBundle,
    isLoading: isUpdatingDeviceBundle,
    isSuccess: isUpdatingDeviceBundleSuccess,
  } = useDeviceBundleUpdate();

  const { nonDeviceBundleComponents, deviceBundle } = useMemo(() => {
    const deviceBundle = outdatedComponents.find(
      (item) => item.component === ComponentEnum.DEVICE_BUNDLE,
    );

    const nonDeviceBundleComponents = outdatedComponents.filter(
      (item) => item.component !== ComponentEnum.DEVICE_BUNDLE,
    );

    return {
      nonDeviceBundleComponents,
      deviceBundle,
    };
  }, [outdatedComponents]);

  const handleDeviceBundleUpdate = useCallback(
    async (params: UseDeviceBundleUpdateProps) => {
      try {
        onDeviceBundleUpdate(true);
        await updateDeviceBundle(params);
        message.success(
          <Message content={t('outdatedDeviceComponents.successSavingData')} />,
          10,
        );
      } catch (e) {
        message.error(
          <Message
            content={t('outdatedDeviceComponents.errorSavingData')}
            error={(e as any) as ApiError}
          />,
          10,
        );
      } finally {
        onDeviceBundleUpdate(false);
      }
    },
    [updateDeviceBundle, onDeviceBundleUpdate, t],
  );

  const handleDeviceBundleExpand = useCallback((isActive?: boolean) => {
    setIsDeviceBundlePanelVisible((prevState) => !prevState);
  }, []);

  const deviceBundleContent = useMemo(() => {
    if (!deviceBundle) {
      return null;
    }

    const {
      uuid,
      displayName,
      currentVersion,
      latestVersion,
      bundleComponents = [],
    } = deviceBundle;

    const filteredOutdatedBundleComponents = bundleComponents.filter(
      (bundleComponent) =>
        bundleComponent.currentVersion !== bundleComponent.latestVersion,
    );

    const mappedBundleComponents = filteredOutdatedBundleComponents.map(
      (bundleComponent) => {
        const { latestVersion: version, component, key } = bundleComponent;

        return {
          component,
          key,
          version,
        };
      },
    );

    return (
      <Components>
        <RowWrap>
          <Row>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <MoreLess onClick={() => handleDeviceBundleExpand(isActive)}>
                  {isActive ? t('less') : t('more')}
                </MoreLess>
              )}
              expandIconPosition="right"
              activeKey={isDeviceBundlePanelVisible ? 'panel' : undefined}
            >
              <CollapsePanel
                key="panel"
                header={
                  <VersionsInfo
                    title={displayName}
                    currentVersion={currentVersion}
                    latestVersion={latestVersion}
                  />
                }
                showArrow
                forceRender
              >
                {filteredOutdatedBundleComponents.length > 0 && (
                  <BundleComponents>
                    {filteredOutdatedBundleComponents.map((bundleComponent) => {
                      const {
                        key,
                        displayName,
                        currentVersion,
                        latestVersion,
                      } = bundleComponent;

                      return (
                        <VersionsInfo
                          key={key}
                          title={displayName}
                          currentVersion={currentVersion}
                          latestVersion={latestVersion}
                        />
                      );
                    })}
                  </BundleComponents>
                )}
              </CollapsePanel>
            </Collapse>
          </Row>
        </RowWrap>
        {!isUpdatingDeviceBundleSuccess && canUserUpdate && (
          <ButtonBundleUpdate
            type="default"
            size="large"
            onClick={() =>
              handleDeviceBundleUpdate({
                uuid,
                bundleVersion: latestVersion,
                bundleComponents: mappedBundleComponents,
              })
            }
            loading={isUpdatingDeviceBundle}
          >
            {t('update')}
          </ButtonBundleUpdate>
        )}
        {isUpdatingDeviceBundleSuccess && (
          <UpdateSuccess>
            {t('outdatedDeviceComponents.updateInitiated')}
            <Icon type="check-circle" size={24} color="#07AD48" />
          </UpdateSuccess>
        )}
      </Components>
    );
  }, [
    canUserUpdate,
    deviceBundle,
    handleDeviceBundleExpand,
    handleDeviceBundleUpdate,
    isDeviceBundlePanelVisible,
    isUpdatingDeviceBundle,
    isUpdatingDeviceBundleSuccess,
    t,
  ]);

  const handleGoToUpdatePage = useCallback(
    (componentType: ComponentEnum) => {
      let redirectUrl = '';

      const matchedComponent = outdatedComponents.find(
        (outdatedComponent) => outdatedComponent.component === componentType,
      );

      if (!matchedComponent) {
        return;
      }

      const { uuid: deviceUuid, appId, appEnableLayoutV2 = false } = matchedComponent;

      switch (componentType) {
        case ComponentEnum.APP:
          redirectUrl = `/organisations/${organisationId}/apps/installations/${appId}/${
            appEnableLayoutV2 ? 'settings-configuration/global-settings' : 'content'
          }`;
          break;
        case ComponentEnum.BOOT_SCREEN:
          redirectUrl = `/organisations/${organisationId}/apps/installations/${appId}/${
            appEnableLayoutV2 ? 'settings-configuration/global-settings' : 'settings'
          }`;
          break;
        case ComponentEnum.GRIDOS:
        case ComponentEnum.MODULE:
          redirectUrl = `/organisations/${organisationId}/operations/devices/v3/${deviceUuid}/modules`;
          break;
        default:
      }

      if (redirectUrl) {
        window.open(redirectUrl, '_blank', 'noreferrer');
      }
    },
    [outdatedComponents, organisationId],
  );

  return (
    <OutdatedComponentsContentContainer>
      {outdatedComponents.length > 1 && (
        <>
          <Components>
            <HeadingTitle size={18}>
              {t('outdatedDeviceComponents.updatesAreAvailableForYourDevice')}
            </HeadingTitle>
          </Components>
          <Divider />
        </>
      )}
      {nonDeviceBundleComponents.map((component) => {
        const {
          currentVersion,
          latestVersion,
          displayName,
          packageName,
          iconUrl,
          isAutoUpdate,
          component: componentType,
          key: componentKey,
        } = component;

        return (
          <React.Fragment key={`${componentType}-${componentKey}`}>
            <Components>
              {iconUrl && <ComponentIcon src={iconUrl} />}
              <RowWrap>
                <Row>
                  <VersionsInfo
                    title={displayName || packageName || ''}
                    currentVersion={currentVersion}
                    latestVersion={latestVersion}
                  />
                </Row>
                <Row>
                  {!isAutoUpdate && (
                    <>
                      <Icon
                        type="exclamation-circle"
                        theme="filled"
                        color="#CF1322"
                        size={14}
                      />
                      <Text>{t('outdatedDeviceComponents.requiresManualConfig')}</Text>
                    </>
                  )}
                </Row>
              </RowWrap>
              <GoToUpdatePageButton
                type="default"
                size="large"
                onClick={() => handleGoToUpdatePage(componentType)}
              >
                {t('view')}
              </GoToUpdatePageButton>
            </Components>
            <Divider />
          </React.Fragment>
        );
      })}
      {deviceBundleContent}
    </OutdatedComponentsContentContainer>
  );
};

const OutdatedComponentsContentContainer = styled.div`
  > .ant-divider:last-child {
    display: none;
  }
`;

const Components = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 25px;
  gap: 10px;
`;

const Title = styled(AntdTitle)<{ size?: number }>`
  font-size: ${({ size = 14 }) => size}px !important;
  margin-bottom: 0 !important;
`;

const HeadingTitle = styled(Title)`
  margin-right: 20px;
`;

const Text = styled(AntdText)`
  display: flex;
  gap: 5px;
  font-size: 14px !important;
`;

const Divider = styled(AntdDivider)`
  margin: 0;
`;

const RowWrap = styled.div`
  flex: 1;
  margin: 11px auto;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

type StyledButtonType = StyledComponent<
  ButtonProps & React.ClassAttributes<Button>,
  Pick<ButtonProps & React.ClassAttributes<Button>, keyof ButtonProps>,
  any
>;
const GoToUpdatePageButton = styled(Button)`
  margin: auto;
` as StyledButtonType;

const ButtonBundleUpdate = styled(Button)`
  margin: 0 auto auto auto;
` as StyledButtonType;

const ComponentIcon = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
`;

type StyledCollapseType = StyledComponent<
  CollapseProps & React.ClassAttributes<AntdCollapse>,
  Pick<CollapseProps & React.ClassAttributes<AntdCollapse>, keyof CollapseProps>,
  any
>;
const Collapse = styled(AntdCollapse)`
  width: -webkit-fill-available;
` as StyledCollapseType;

const CollapsePanel = styled(AntdCollapse.Panel)`
  > .ant-collapse-header {
    display: flex;
    cursor: default !important;
  }
`;

const BundleComponents = styled.div`
  margin-top: 10px;
  .ant-typography {
    color: #a6a6a6;
  }

  .anticon {
    color: #a6a6a6;
  }
`;

const MoreLess = styled.span`
  position: unset !important;
  order: 2 !important;
  line-height: unset !important;
  transform: unset !important;

  font-size: 10px !important;
  color: #2364aa !important;
  text-transform: lowercase !important;
  margin-bottom: -4px;
  margin-left: 5px;
  cursor: pointer;
`;

const UpdateSuccess = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #07ad48;
  margin: 7px auto auto;
`;

export default OutdatedDeviceComponentsContent;
