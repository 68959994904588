import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import PhyhubDevicesSummary from './phyhub-devices-summary.component';
import usePhyhubDevicesGetReport from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-get-report';
import { StateWrapper } from '../../phyhub-devices-common.components';
import Overlay from '../../../overlay';
import { Spinner } from '../../../spinner';
import ErrorView from '../../../error-view/error-view.component';

interface PhyhubDevicesSummaryContainerProps {
  tenantId: string;
}

const PhyhubDevicesSummaryContainer: FC<PhyhubDevicesSummaryContainerProps> = (props) => {
  const { tenantId } = props;

  const { t } = useTranslation();

  const {
    isError: isPhyhubDevicesGetReportError,
    isLoading: isPhyhubDevicesGetReportLoading,
    isSuccess: isPhyhubDevicesGetReportSuccess,
    data: phyhubDeviceBreakdownReport,
  } = usePhyhubDevicesGetReport({ tenantId });

  if (isPhyhubDevicesGetReportLoading) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isPhyhubDevicesGetReportError) {
    return (
      <StateWrapper>
        <ErrorView />
      </StateWrapper>
    );
  }

  if (!isPhyhubDevicesGetReportSuccess || !phyhubDeviceBreakdownReport) {
    return (
      <StateWrapper>
        <Empty description={t('phyhubDevices.list.emptyState')} />
      </StateWrapper>
    );
  }

  return <PhyhubDevicesSummary report={phyhubDeviceBreakdownReport} />;
};

export default PhyhubDevicesSummaryContainer;
