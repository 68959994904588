import React from 'react';
import styled from '@emotion/styled';
import { Subheader } from '../../../../common/app-layout/header';
import NetworkWhitelistSettings from './networking-whitelist-settings.component';
import NetworkWhitelistRules from './networking-whitelist-rules.component';

export default function NetworkingWhitelist(props: { tenantId: string }) {
  const { tenantId } = props;

  return <NetworkWhitelistRules tenantId={tenantId} />;
}

const SubheaderContainer = styled(Subheader)`
  justify-content: flex-end;
`;
