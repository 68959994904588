import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApiService } from '../api-service-provider';
import { TenantSettings } from '../../../store/types/branding';

export const queryKeys = {
  getTenantSettings: (organisationId: string, apiPath: string) => [
    'tenant-settings',
    'organisationId',
    organisationId,
    'apiPath',
    apiPath,
  ],
};

export interface UseTenantSettingsProps {
  id: string;
  tenantId: string;
  settings: TenantSettings;
}

export const useTenantSettings = (tenantId: string) => {
  const apiService = useApiService();

  const url = `api/tenant-settings/tenant/${tenantId}`;

  return useQuery(
    queryKeys.getTenantSettings(tenantId, 'tenant'),
    () => apiService.get<UseTenantSettingsProps>(url),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export const useCreateTenantSettings = ({ tenantId }: { tenantId: string }) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/tenant-settings`;

  return useMutation(
    (body: Partial<UseTenantSettingsProps>) => apiService.post(url, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getTenantSettings(tenantId, 'tenant'));
      },
    },
  );
};

export const useUpdateTenantSettings = ({
  tenantId,
  tenantSettingsId,
}: {
  tenantId: string;
  tenantSettingsId: string;
}) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/tenant-settings/${tenantSettingsId}`;

  return useMutation(
    (body: Partial<UseTenantSettingsProps>) => apiService.put(url, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getTenantSettings(tenantId, 'tenant'));
      },
    },
  );
};

export const useDeleteTenantSettings = ({
  tenantId,
  tenantSettingsId,
}: {
  tenantId: string;
  tenantSettingsId: string;
}) => {
  const apiService = useApiService();
  const queryClient = useQueryClient();

  const url = `/api/tenant-settings/${tenantSettingsId}`;

  return useMutation(() => apiService.delete(url), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getTenantSettings(tenantId, 'tenant'));
    },
  });
};
