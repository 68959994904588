import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import PhyhubDeviceStatusesBar, {
  ProgressSize,
} from '../../../phyhub-device-statuses-bar/phyhub-device-statuses-bar.component';
import PhyhubDeviceStatus from '../../../phyhub-device-status/phyhub-device-status';
import { GridBase, TableCell } from '../phyhub-devices-summary-common.components';
import { PhyhubDeviceTypeStatusBreakdown } from '../../../../../../services/phyhub/types/phyhub-device-type-status-breakdown.interface';
import { PHYHUB_DEVICE_STATUS_TRANSLATION_MAP } from '../../../../../../store/types/phyhub-devices';

interface PhyhubDevicesSummaryTableContentProps {
  typeStatusBreakdowns: PhyhubDeviceTypeStatusBreakdown[];
}

const PhyhubDevicesSummaryTableContent: FC<PhyhubDevicesSummaryTableContentProps> = (
  props,
) => {
  const { typeStatusBreakdowns } = props;

  const { t } = useTranslation();

  return (
    <TableContent>
      {typeStatusBreakdowns.map((deviceTypeBreakdown) => {
        const { type: deviceType, ...statusesBreakdown } = deviceTypeBreakdown;

        return (
          <TableRow>
            <TableCell>
              {t('phyhubDevices.summary.total', { count: statusesBreakdown.total })}
            </TableCell>
            <TableCell align="right">
              <PhyhubDeviceStatusesBar
                size={ProgressSize.SMALL}
                statusesBreakdown={statusesBreakdown}
              />
            </TableCell>
            {statusesBreakdown.statuses.map(({ type: statusType, count }) => (
              <TableCell key={`summary_content_${deviceType}_${statusType}`}>
                <PhyhubDeviceStatus status={statusType}>
                  {count} {t(PHYHUB_DEVICE_STATUS_TRANSLATION_MAP[statusType])}
                </PhyhubDeviceStatus>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        );
      })}
    </TableContent>
  );
};

const TableContent = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

const TableRow = styled(GridBase)`
  font-size: 14px;
  line-height: 22px;

  &:nth-child(n + 2) {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

export default memo(PhyhubDevicesSummaryTableContent);
