import styled from '@emotion/styled';
import { Button, Col, Icon, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import React, { useCallback, useMemo, useState } from 'react';
import PanelCard from '../../../../common/panel-card/panel-card.component';
import Overlay from '../../../../common/overlay/overlay.component';
import Spinner from '../../../../common/spinner/spinner.component';
import OrganisationApp from '../../../../../store/types/organisation-app';
import Pagination from '../../../../common/pagination';
import usePagination from '../../../../common/pagination/hook/use-pagination';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import { useSpaces } from '../../../../common/use-spaces';
import { useInstallationByAppNameDevices } from '../../../../common/use-devices';
import LocationTable from './location-table';
import TemporarySettingsVersionSwitcher from '../temporary-settings-version-switcher/temporary-settings-version-switcher.component';
import { permissionKeys } from '../../../../../utils/auth/permissions';
import useHasPermissions from '../../../../../utils/auth/use-has-permissions';

const { Title, Text } = Typography;

const Container = styled.div`
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
`;

const GlobeIcon = styled(Icon)`
  font-size: 18px;
  margin-right: 14px;
  background-color: #f5f5fa;
  color: rgba(0, 0, 0, 0.85);
  padding: 16px;
  border-radius: 8px;
`;

const ButtonStyle = styled(Button)`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 180px;
  font-size: 16px;

  @media screen and (max-width: 758px) {
    width: 100%;
    margin-top: 16px;
  }
` as any;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

const TableContainer = styled.div`
  margin-bottom: 32px;

  &:nth-last-of-type(-n + 1) {
    margin-bottom: 0;
  }
`;

const PaginationContainer = styled.div``;

const NoDeviceFoundContainer = styled.div`
  height: 100%;
  width: 100%;
  positon: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SettingsV2ComponentProps {
  app: OrganisationApp;
  organisationId: string;
  onEnableSettingsV2: (enable: boolean) => void;
  canSwitchSettingsV2: boolean;
}

const SettingsV2Component = ({
  app,
  organisationId,
  onEnableSettingsV2,
  canSwitchSettingsV2,
}: SettingsV2ComponentProps) => {
  const [isSettingsV2SwitchLoading, setIsSettingsV2SwitchLoading] = useState(false);
  const history = useHistory();

  const isEditAllowed = useHasPermissions(organisationId, permissionKeys.apps.update);

  const { t } = useTranslation();

  const {
    page,
    defaultPage,
    pageSize,
    setPage,
    setPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const spacesState = useSpaces({
    organisationId,
    limit: pageSize,
    skip: page === 1 ? 0 : pageSize * (page - 1),
    includeSubspaces: false,
  });

  const devicesState = useInstallationByAppNameDevices({ appName: app.appName });

  const spaces = useMemo(() => {
    if (!spacesState.isSuccess) {
      return [];
    }

    return spacesState.data.spaces;
  }, [spacesState]);

  const handleOnCLick = useCallback(
    ({
      configType,
      id,
      deviceUuid,
    }: {
      configType?: string;
      id?: string;
      deviceUuid?: string;
    }) =>
      history.push(
        `/organisations/${organisationId}/apps/installations/${
          app ? app.id : null
        }/settings-configuration${configType ? `/${configType}` : ''}${
          id ? `/${id}` : ''
        }${deviceUuid ? `/${deviceUuid}` : ''}`,
      ),
    [app, history, organisationId],
  );

  const handlePaginationSizeChange = useCallback(
    (pageNum: number, pageLimit: number) => {
      setPage(defaultPage);

      setPageSize(pageLimit);
    },
    [setPageSize, setPage, defaultPage],
  );

  const handlePageChange = useCallback(
    (pageNum: number) => {
      setPage(pageNum);
    },
    [setPage],
  );

  const handleEnableSettingsV2Layout = useCallback(
    (enable: boolean) => {
      setIsSettingsV2SwitchLoading(true);
      onEnableSettingsV2(enable);
    },
    [onEnableSettingsV2],
  );

  return (
    <ContentWrap>
      {canSwitchSettingsV2 ? (
        <TemporarySettingsVersionSwitcher
          value={app.enableLayoutV2}
          onValueChange={handleEnableSettingsV2Layout}
          loading={isSettingsV2SwitchLoading}
          isEditAllowed={isEditAllowed}
        />
      ) : null}
      <Row gutter={[16, 16]}>
        <Col>
          <PanelCard>
            <Container>
              <Row>
                <Col xs={24} sm={18}>
                  <Row type="flex" align="middle" justify="start">
                    <Col>
                      <GlobeIcon type="global" />
                    </Col>
                    <Col>
                      <Title level={3}>{t('globalSettingsForm.globalSettings')}</Title>
                      <Text type="secondary">
                        {t('globalSettingsForm.globalSettingsSubTitle')}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={6}>
                  <Row type="flex" align="middle" justify="center">
                    <ButtonStyle
                      size="large"
                      block
                      type="primary"
                      icon="setting"
                      onClick={() => handleOnCLick({ configType: 'global-settings' })}
                    >
                      {t(
                        isEditAllowed
                          ? 'globalSettingsForm.editSettings'
                          : 'globalSettingsForm.viewSettings',
                      )}
                    </ButtonStyle>
                  </Row>
                </Col>
              </Row>
            </Container>
          </PanelCard>
        </Col>
      </Row>

      {(spacesState.isLoading || devicesState.isLoading) && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {devicesState.isSuccess && devicesState.data.length === 0 && (
        <NoDeviceFoundContainer>
          <Title level={4}>
            {t('noDevicesFound')} <Icon type="frown" />
          </Title>
        </NoDeviceFoundContainer>
      )}

      {spacesState.isSuccess && devicesState.isSuccess && (
        <Row gutter={[16, 40]}>
          {devicesState.data.length > 0 && (
            <>
              <Col md={24}>
                {spaces.map((space, index) => {
                  const spaceDevices = devicesState.data.filter((device) => {
                    const deviceSpaces = device.spaces || [];

                    return deviceSpaces.includes(space.id);
                  });

                  const hasHeader = index === 0;

                  return (
                    <TableContainer key={space.id}>
                      <LocationTable
                        organisationId={organisationId}
                        app={app}
                        space={space}
                        spaceDevices={spaceDevices}
                        hasHeader={hasHeader}
                      />
                    </TableContainer>
                  );
                })}
              </Col>

              <Col md={24}>
                <PaginationContainer>
                  <Pagination
                    isLoading={spacesState.isLoading}
                    align="right"
                    showSizeChanger
                    onShowSizeChange={handlePaginationSizeChange}
                    current={page}
                    pageSize={pageSize}
                    total={spacesState.data.total}
                    onChange={handlePageChange}
                    pageSizes={defaultPageSizeOption}
                  />
                </PaginationContainer>
              </Col>
            </>
          )}
        </Row>
      )}

      {devicesState.isError && (
        <ErrorMessage>{t('errorDuringFetchingDevice')}</ErrorMessage>
      )}

      {spacesState.isError && (
        <ErrorMessage>{t('errorDuringFetchingSpaces')}</ErrorMessage>
      )}
    </ContentWrap>
  );
};

export default React.memo(SettingsV2Component);
