import * as React from 'react';
import { message } from 'antd';
import { useActions, useStore } from 'easy-peasy';
import qs from 'query-string';
import Content from './content.component';
import { RootModel, RootState } from '../../../../../store/models/root.model';
import { getPermissions } from '../../../../../utils/auth/permissions';
import { useEnableSettingsV2Layout } from '../../../../common/use-app';
import { canUserSwitchSettingsV2 } from '../temporary-settings-version-switcher/temporary-settings-version-switcher.component';

type ContentContainerProps = Omit<
  React.ComponentProps<typeof Content>,
  | 'user'
  | 'app'
  | 'schema'
  | 'metaSchema'
  | 'loaded'
  | 'fetchSchema'
  | 'error'
  | 'updateApp'
  | 'fetchMedia'
  | 'createBuild'
  | 'fetchBuilds'
  | 'activeBuilds'
  | 'fetchApp'
  | 'environments'
  | 'fetchEnvironments'
  | 'fetchGridapps'
>;

const ContentContainer = (props: ContentContainerProps) => {
  const {
    match: {
      params: { appId, organisationId },
      
    },
    location: { search },
    history,
  } = props;

  // pre-select gridapp after form is rendered
  const { gridapp = null } = search ? qs.parse(search) : {};

  const enableSettingsV2State = useEnableSettingsV2Layout(appId);
  const onEnableSettingsV2 = React.useCallback((enable: boolean) => {
    enableSettingsV2State.mutate({ id: appId, enable },
      {
        onSuccess: () => {
          history.replace(
            `/organisations/${organisationId}/apps/installations/${appId}/settings-v2`,
          );
        },
        onError: () => {
          message.error('Failed switching to settings v2 layout');
        },
      },
    );
  }, [appId, organisationId, history, enableSettingsV2State]);

  const user = useStore<RootState>((state) => state.auth.user);
  const environments = useStore<RootState>((state) =>
    state.environments.values(organisationId),
  );

  const { app, activeBuilds, canSwitchSettingsV2 } = useStore<RootState>(
    (state) => {
      const app = (state.organisationApps.data &&
        state.organisationApps.data[organisationId] &&
        state.organisationApps.data[organisationId][appId]
      ) || null;

      return {
        app,
        activeBuilds: state.appBuilds.activeBuilds(appId),
        canSwitchSettingsV2: canUserSwitchSettingsV2(app),
      };
    },
    [appId],
  );

  const { schema, metaSchema, loaded, error, permissions, appBuilds } = useStore<
    RootState
  >(
    (state) => {
      const appSchema =
        (app &&
          state.schema.data[app.provider] &&
          state.schema.data[app.provider][app.release]) ||
        null;

      return {
        permissions: getPermissions(state.auth.user, props.match.params.organisationId),
        schema: appSchema ? appSchema.schema : null,
        metaSchema: appSchema ? appSchema.metaSchema : null,
        loaded: state.schema.loaded(app.provider, app.release),
        appBuilds: state.organisationAppsLibraryBuilds.data,
        error:
          (app &&
            state.schema.error[app.provider] &&
            state.schema.error[app.provider][app.release]) ||
          null,
      };
    },
    [app],
  );

  const {
    fetchSchema,
    fetchMedia,
    updateApp,
    createBuild,
    fetchBuilds,
    fetchApp,
    fetchEnvironments,
    fetchGridapps,
  } = useActions<RootModel>((actions) => ({
    fetchSchema: actions.schema.fetch,
    fetchMedia: actions.organisationMedia.fetchAll,
    updateApp: actions.organisationApps.update,
    createBuild: actions.appBuilds.create,
    fetchBuilds: actions.appBuilds.fetch,
    fetchApp: actions.organisationApps.fetchSingle,
    fetchEnvironments: actions.environments.fetch,
    fetchGridapps: actions.organisationAppsLibrary.fetch,
  }));

  return (
    <Content
      {...props}
      user={user}
      app={app}
      schema={schema}
      metaSchema={metaSchema}
      loaded={loaded}
      fetchSchema={fetchSchema}
      error={error}
      updateApp={updateApp}
      fetchMedia={fetchMedia}
      createBuild={createBuild}
      fetchBuilds={fetchBuilds}
      activeBuilds={activeBuilds}
      fetchApp={fetchApp}
      environments={environments}
      fetchEnvironments={fetchEnvironments}
      fetchGridapps={fetchGridapps}
      canUpdate={permissions.apps.updateContents}
      canPublish={permissions.apps.publishContents}
      gridapp={typeof gridapp === 'string' ? gridapp : undefined}
      appBuilds={appBuilds}
      onEnableSettingsV2={onEnableSettingsV2}
      canSwitchSettingsV2={canSwitchSettingsV2}
    />
  );
};

export default ContentContainer;
