import React, { useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePagination } from '../../../../common/pagination';
import usePaginationQueryParams from '../../../../common/use-pagination-query-params';
import { PaginationQueryParams } from '../../../../common/use-pagination-query-params/use-pagination-query-params';
import { ColumnProps } from 'antd/lib/table';
import { Icon } from '../../../../common/schema-form/common';
import { Segment } from '../../../../../store/types/segment';
import styled from '@emotion/styled';
import Overlay from '../../../../common/overlay';
import { Button, Card, Col, Row, Typography, Breadcrumb } from 'antd';
import PaginatedListTable from '../../../../common/paginated-list/paginatied-list-table';
import moment from 'moment/moment';
import OrganisationMediaSegmentsListActionsComponent from './organisation-media-segments-list-actions.component';
import useSegmentList from '../../../../../store/hooks/segments/use-segment-list';
import useChannelById from '../../../../../store/hooks/channels/use-channel-by-id';
import usePermissions from '../../../../../utils/auth/use-permissions';
import permissionKeys from '../../../../../utils/auth/permissions';

const { Title } = Typography;

interface OrganizationMediaSegmentProps
  extends RouteComponentProps<{ organisationId: string; channelId: string }> {}

interface OrganizationMediaSegmentsListPaginationParams extends PaginationQueryParams {}

const formatDate = (
  dateToFormat: string | undefined,
  format: string = 'YYYY-MM-DD HH:mm',
) => {
  if (dateToFormat === undefined || !moment(dateToFormat).isValid()) {
    return 'N/A';
  }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return moment.tz(dateToFormat, userTimezone).format(format);
};

const OrganisationMediaSegmentsListComponent = (props: OrganizationMediaSegmentProps) => {
  const { match } = props;
  const { t } = useTranslation();
  const { organisationId, channelId } = match.params;

  const { isAllowed } = usePermissions(organisationId);

  const { createSegment, updateSegment } = useMemo(
    () => ({
      createSegment: isAllowed(permissionKeys.contentSegments.create),
      updateSegment: isAllowed(permissionKeys.contentSegments.update),
    }),
    [isAllowed],
  );

  const { data: channel } = useChannelById(organisationId, channelId);

  const breadcrumbItems = useMemo(() => {
    const baseItems = [
      {
        path: match.url.replace(/\/[a-zA-Z0-9]+\/segments$/, ''),
        breadcrumbName: t('contents.channels'),
      },
    ];

    if (!channel) {
      return baseItems;
    }

    return [
      ...baseItems,
      {
        path: '',
        breadcrumbName: channel.name,
      },
      {
        path: '',
        breadcrumbName: t('contents.segments'),
      },
    ];
  }, [t, match, channel]);

  const { page, defaultPage, pageSize, defaultPageSize } = usePagination();

  const [paginationQueryParams] = usePaginationQueryParams<
    OrganizationMediaSegmentsListPaginationParams
  >({
    page: page || defaultPage,
    limit: pageSize || defaultPageSize,
  });

  const queryParams = {
    limit: paginationQueryParams.limit || defaultPageSize,
    page: paginationQueryParams.page || defaultPage,
  };

  const {
    data: segmentsData,
    isLoading: isSegmentsLoading,
    isFetching: isSegmentsFetching,
    isError: isSegmentsListError,
  } = useSegmentList(organisationId, channelId, queryParams.limit, queryParams.page);

  const columns = useMemo<ColumnProps<Segment>[]>(() => {
    return [
      {
        title: t('contents.segmentName'),
        key: 'organization-media-segment-name',
        width: '35%',
        render: (_, record) => (
          <>
            <Row>
              <CapitalizeText>
                {updateSegment ? (
                  <Link
                    to={`/organisations/${organisationId}/content/channels/${channelId}/segments/edit/${record.id}`}
                  >
                    {record.name}
                  </Link>
                ) : (
                  <>{record.name}</>
                )}
              </CapitalizeText>
            </Row>
            <TagsRow>{/* {renderTags(record.includeContentTags)} */}</TagsRow>
          </>
        ),
      },
      {
        title: t('contents.start'),
        key: 'organization-media-segment-start',
        width: '20%',
        render: (_, record) => (
          <CapitalizeText>{formatDate(record.startDate)}</CapitalizeText>
        ),
      },
      {
        title: t('contents.stop'),
        key: 'organization-media-segment-stop',
        width: '20%',
        render: (_, record) => (
          <CapitalizeText>{formatDate(record.endDate)}</CapitalizeText>
        ),
      },
      {
        title: t('contents.updatedAt'),
        key: 'organization-media-segment-updatedAt',
        width: '20%',
        render: (_, record) => (
          <MutedText>{formatDate(record.updatedAt, 'DD-MM-YYYY hh:mm')}</MutedText>
        ),
      },
      {
        title: <Icon type="setting" />,
        key: 'organization-media-segment-actions',
        width: '5%',
        align: 'center',
        render: (_, record) =>
          updateSegment && (
            <OrganisationMediaSegmentsListActionsComponent
              segment={record}
              organizationId={organisationId}
              channelId={channelId}
            />
          ),
      },
    ];
  }, [t, organisationId, channelId]);

  return (
    <>
      <Overlay>
        {isSegmentsListError && (
          <Row>
            <ErrorCard>
              <ErrorIcon type="frown" />
              <Message level={1}>{t('somethingWentWrong')}</Message>
            </ErrorCard>
          </Row>
        )}
        {!isSegmentsListError && (
          <>
            <Row>
              <Col md={12}>
                <BreadcrumbWrap>
                  <Breadcrumb
                    routes={breadcrumbItems}
                    itemRender={(route, params, routes, paths) => {
                      const hasPath = route.path && route.path.length > 0;
                      return !hasPath ? (
                        <span>{route.breadcrumbName}</span>
                      ) : (
                        <Link to={`/${paths.join('/')}`}>{route.breadcrumbName}</Link>
                      );
                    }}
                  />
                </BreadcrumbWrap>
              </Col>
              {createSegment && (
                <Col md={12}>
                  <ButtonWrap>
                    <Link to={`${match.url}/create`}>
                      <Button icon="plus" type="primary" size="large">
                        {t('contents.createSegment')}
                      </Button>
                    </Link>
                  </ButtonWrap>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <PaginatedListTable<Segment>
                  data={segmentsData!}
                  columns={columns}
                  isDataLoading={isSegmentsLoading || isSegmentsFetching}
                />
              </Col>
            </Row>
          </>
        )}
      </Overlay>
    </>
  );
};

const CapitalizeText = styled.span`
  text-transform: capitalize;
`;

const ErrorCard = styled(Card)`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
`;

const TagsRow = styled(Row)`
  padding: 0.5em 0em;
`;

const ErrorIcon = styled(Icon)`
  font-size: 64px;
  margin-bottom: 16px;
`;

const Message = styled(Title)`
  margin-top: 12px;
`;

const BreadcrumbWrap = styled.div`
  margin-top: 40px;
  margin-left: 80px;

  @media screen and (max-width: 991px) {
    margin-left: 20px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 40px;
  gap: 1em;

  @media screen and (max-width: 991px) {
    margin-right: 20px;
  }
`;

const MutedText = styled.p`
  color: #6c757d !important;
`;

export default OrganisationMediaSegmentsListComponent;
