import { DataMatrixColumn } from '@ombori/grid-reports';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  InstallationEventsByDay,
  InstallationSessionsByDay,
} from '../../../use-analytics-report';
import { Table, Button } from 'antd';
import OrganisationApp from '../../../../../store/types/organisation-app';
import { TableRecord } from './types';
import groupBy from 'lodash/groupBy';
import getColumnValue from './get-column-value';
import { getInstallationAverageOrLowestPercentage } from './get-percentage';
import toLower from 'lodash/toLower';
import snakeCase from 'lodash/snakeCase';
import getSortFn from './get-sort-fn';
import { prepareDateRangeSearchParams } from '../../utils';

interface InstallationTableRecord extends TableRecord {
  installationId: string;
}

interface InstallationsTableProps {
  tenantId: string;
  columns: DataMatrixColumn[];
  installations: OrganisationApp[];
  eventsData: InstallationEventsByDay[];
  sessionsData: InstallationSessionsByDay[];
  dateFrom: string;
  dateTo: string;
}

const InstallationsTable: React.FC<InstallationsTableProps> = ({
  tenantId,
  installations,
  columns,
  eventsData,
  sessionsData,
  dateFrom,
  dateTo,
}) => {
  const { t } = useTranslation();

  const groupedEventsData = useMemo(
    () => groupBy(eventsData, (dataItem) => dataItem.installationId),
    [eventsData],
  );

  const groupedSessionsData = useMemo(
    () => groupBy(sessionsData, (dataItem) => dataItem.installationId),
    [sessionsData],
  );

  const data: InstallationTableRecord[] = installations
    .map((installation) => {
      const eventsData = groupedEventsData[installation.id] || [];
      const sessionsData = groupedSessionsData[installation.id] || [];

      return { installation, eventsData, sessionsData };
    })
    .filter(
      ({ eventsData, sessionsData }) => eventsData.length > 0 || sessionsData.length > 0,
    )
    .map(({ installation, eventsData, sessionsData }) => {
      const result = columns
        .map((column) => {
          const columnValue = getColumnValue<InstallationEventsByDay>({
            column,
            eventsData: eventsData,
            sessionData: sessionsData,
            getEventsAvgValue: getInstallationAverageOrLowestPercentage,
            getEventsLowestValue: getInstallationAverageOrLowestPercentage,
          });

          return { [toLower(snakeCase(column.title))]: columnValue };
        })
        .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue }), {});

      return {
        key: installation.id,
        installation: installation.displayName,
        installationId: installation.id,
        ...result,
      };
    });

  const tableColumns = columns.map((column) => {
    const sortFn = getSortFn(column);

    return {
      title: column.title,
      dataIndex: toLower(snakeCase(column.title)),
      key: toLower(snakeCase(column.title)),
      sorter: sortFn,
    };
  });

  const searchParams = useMemo(() => {
    return prepareDateRangeSearchParams(dateFrom, dateTo);
  }, [dateFrom, dateTo]);

  const allColumns = [
    {
      title: t('installation'),
      dataIndex: 'installation',
      key: 'installation',
      render: (text: string, record: InstallationTableRecord) => {
        return (
          <Button type="link">
            <Link
              to={{
                pathname: `/organisations/${tenantId}/apps/installations/${record.installationId}`,
                search: `${searchParams}`,
              }}
            >
              {text}
            </Link>
          </Button>
        );
      },
    },
    ...tableColumns,
  ];

  return <Table columns={allColumns} dataSource={data} size="small" />;
};

export default InstallationsTable;
