import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Card, Col, Row } from 'antd';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDeviceDetailsFormFields from './phyhub-device-details-screen-form/phyhub-device-details-form-fields/phyhub-device-details-form-fields.component';
import Environment from '../../../../../store/types/environment';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import PhyhubDeviceInfo from '../../../../common/phyhub-devices/phyhub-device-card/phyhub-device-card.component';
import PhyhubDeviceDetailsScreenForm, {
  PhyhubDeviceDetailsFormValues,
} from './phyhub-device-details-screen-form/phyhub-device-details-screen-form.component';
import { BlockContainer } from '../../../../common/phyhub-devices/phyhub-devices-common.components';

interface PhyhubDeviceDetailsScreenProps {
  deviceItem: PhyhubDevice;
  environments: Environment[];
  spaces: OrganisationSpace[];
  onFormSubmit: (values: PhyhubDeviceDetailsFormValues) => Promise<void>;
}

const PhyhubDeviceDetailsScreen: FC<PhyhubDeviceDetailsScreenProps> = (props) => {
  const { deviceItem, environments, spaces } = props;

  const { t } = useTranslation();

  const {
    id,
    deviceSerial,
    displayName,
    env,
    spaceId,
    externalId,
    notes,
    screenshotUrl,
    status,
  } = deviceItem;

  const initialFormValues = useMemo<PhyhubDeviceDetailsFormValues>(
    () => ({ deviceSerial, displayName, env, spaceId, externalId, notes, deviceId: id }),
    [id, deviceSerial, displayName, env, spaceId, externalId, notes],
  );

  return (
    <PhyhubDeviceDetailsScreenForm
      initialFormValues={initialFormValues}
      onFormSubmit={props.onFormSubmit}
    >
      {({ isSubmitting }) => (
        <Wrapper>
          <Row gutter={{ md: 20, xl: 40 }}>
            <Col xs={24} xl={15}>
              <StyledCard>
                <PhyhubDeviceDetailsFormFields
                  isSubmitting={isSubmitting}
                  environments={environments}
                  spaces={spaces}
                />
              </StyledCard>
            </Col>
            <Col xs={24} xl={9}>
              <StyledCard>
                <Sidebar>
                  <PhyhubDeviceInfo
                    deviceDisplayName={displayName}
                    deviceScreenshotUrl={screenshotUrl}
                    deviceStatus={status}
                  />
                  <Button
                    block
                    loading={isSubmitting}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    {t('saveAllChanges')}
                  </Button>
                </Sidebar>
              </StyledCard>
            </Col>
          </Row>
        </Wrapper>
      )}
    </PhyhubDeviceDetailsScreenForm>
  );
};

const Wrapper = styled(BlockContainer)`
  margin: 0 0 20px;

  @media screen and (min-width: 992px) {
    margin: 0 0 40px;
  }
`;

const StyledCard = styled(Card)`
  margin: 0 0 20px;
  padding: 20px;

  .ant-card-body {
    padding: 0;
  }
`;

const Sidebar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default memo(PhyhubDeviceDetailsScreen);
