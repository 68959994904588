import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import PhyhubDeviceStatus, {
  PhyhubDeviceStatusThemeEnum,
} from '../../../phyhub-device-status/phyhub-device-status';
import { GridBase, TableCell } from '../phyhub-devices-summary-common.components';
import { PhyhubDeviceStatusesBreakdown } from '../../../../../../services/phyhub/types/phyhub-device-statuses-breakdown.interface';
import { PHYHUB_DEVICE_STATUS_TRANSLATION_MAP } from '../../../../../../store/types/phyhub-devices';

interface PhyhubDevicesSummaryTableHeaderProps {
  isToggleOpen: boolean;
  summaryBreakdown: PhyhubDeviceStatusesBreakdown;
  onToggleClick: () => void;
}

const PhyhubDevicesSummaryTableHeader: FC<PhyhubDevicesSummaryTableHeaderProps> = (
  props,
) => {
  const { isToggleOpen, summaryBreakdown } = props;

  const { t } = useTranslation();

  return (
    <TableHeader>
      <TableCell>
        {t('phyhubDevices.summary.total', { count: summaryBreakdown.total })}
      </TableCell>
      <TableCell />
      {summaryBreakdown.statuses.map(({ type, count }) => (
        <TableCell key={`summary_header_${type}`}>
          <PhyhubDeviceStatus status={type} theme={PhyhubDeviceStatusThemeEnum.LARGE}>
            {count} {t(PHYHUB_DEVICE_STATUS_TRANSLATION_MAP[type])}
          </PhyhubDeviceStatus>
        </TableCell>
      ))}
      <TableCell align="center">
        <ToggleDevicesLink onClick={props.onToggleClick}>
          <Icon type={isToggleOpen ? 'up' : 'down'} />
          {t('phyhubDevices.toggleBreakdown')}
        </ToggleDevicesLink>
      </TableCell>
    </TableHeader>
  );
};

const TableHeader = styled(GridBase)`
  margin: 0 0 4px 0;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid transparent;
`;

const ToggleDevicesLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #1890ff;
`;

export default PhyhubDevicesSummaryTableHeader;
