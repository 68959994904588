import { useQuery } from 'react-query';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { DataResidencyEnum } from '../../types/organisation';
import {usePhyhubService} from "../../../app/common/phyhub-service-provider/phyhub-service.provider";

export interface PhyhubDevicesGetReportQueryParams {
  tenantId: string;
}

const usePhyhubDevicesGetReport = (
  phyhubDevicesGetReportQueryParams: PhyhubDevicesGetReportQueryParams,
) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesReportKeys(phyhubDevicesGetReportQueryParams),
    () =>
      phyhubService.getDevicesReport({
        tenantId: phyhubDevicesGetReportQueryParams.tenantId,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesGetReport;
