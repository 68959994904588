import { Card } from 'antd';
import styled from '@emotion/styled';

const PanelCard = styled(Card)<{ bodypadding?: string }>`
  box-shadow: 0 0 0;
  border: 1px solid #ddd;

  ${({ bodypadding }) => {
    return bodypadding
      ? `> .ant-card-body {
           padding: ${bodypadding} !important;
         }`
      : '';
  }}
`;

export default PanelCard;
