import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs } from 'antd';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component';
import Header from '../../common/app-layout/header/header.component';
import TabBar from '../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../common/protected-route/protected-route.container';
import AnalyticsOverviewContainer from './overview/analytics-overview.container';

const AnalyticsContainer = ({ match, history, location }: RouteComponentProps) => {
  const { t, i18n } = useTranslation();

  const onTabChange = useCallback(
    (key: string) => {
      history.push(key);
    },
    [history],
  );

  return (
    <ErrorBoundary>
      <HeaderStyled title={<span>{t('analytics')}</span>}>
        <Row>
          <Col>
            <TabBarStyled
              onChange={onTabChange}
              activeKey={location.pathname}
              lang={i18n.language}
            >
              <Tabs.TabPane
                tab={<span>{t('overview')}</span>}
                key={`${match.url}/overview`}
              />
            </TabBarStyled>
          </Col>
        </Row>
      </HeaderStyled>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.url}/overview`} />}
        />
        <ProtectedRouteContainer
          path={`${match.path}/overview`}
          component={AnalyticsOverviewContainer}
        />
      </Switch>
    </ErrorBoundary>
  );
};

const HeaderStyled = styled(Header)`
  padding-bottom: 0px;
`;

const TabBarStyled = styled(TabBar)`
  
`;

export default AnalyticsContainer;
