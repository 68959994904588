import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStore } from 'easy-peasy';
import { RootState } from '../../../../store/models/root.model';
import Organisation from '../../../../store/types/organisation';
import ErrorView from '../../../common/error-view/error-view.component';
import OrganizationPhyhubDeviceDetailsContainer from './organization-phyhub-device-details.container';

interface OrganizationPhyhubDeviceDetailsWrapperContainerProps
  extends RouteComponentProps<{ deviceId: string; organisationId: string }> {}

const OrganizationPhyhubDeviceDetailsWrapperContainer: FC<OrganizationPhyhubDeviceDetailsWrapperContainerProps> = (
  props,
) => {
  const { history, match } = props;

  const {
    params: { deviceId, organisationId: tenantId },
  } = match;

  const { t } = useTranslation();

  const { tenant } = useStore<
    RootState,
    {
      tenant: Organisation | null;
    }
  >((state) => {
    return {
      tenant: state.organisations.data && state.organisations.data[tenantId],
    };
  });

  const dataResidency = useMemo(() => tenant && tenant.dataResidency, [tenant]);

  if (!dataResidency) {
    return <ErrorView error={{ message: t('invalidDataResidency') }} />;
  }

  return (
    <OrganizationPhyhubDeviceDetailsContainer
      deviceId={deviceId}
      tenantId={tenantId}
      matchPath={match.path}
      matchUrl={match.url}
      history={history}
    />
  );
};

export default OrganizationPhyhubDeviceDetailsWrapperContainer;
