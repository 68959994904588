import React from 'react';
import { Table } from 'antd';

import Pagination, { usePagination } from '../../../../common/pagination'; 
import { ColumnProps } from 'antd/lib/table';
import { loadingIcon } from '../../../../common/spinner/spinner.component';
import styled from '@emotion/styled';

export interface ContentLibraryTablePaginationProps {
  page: number;
  limit: number;
  total: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page:  number, limit: number) => void;
}

export interface ContentLibraryTableProps<T> {
  items: T[];
  isLoadingItems: boolean;
  columns: ColumnProps<T>[];
  pagination: ContentLibraryTablePaginationProps;
}


const ContentLibraryTable = <T,>(props: ContentLibraryTableProps<T>) => {
  const { items, isLoadingItems, columns, pagination } = props;

  const { page, limit, total, onPageChange, onPageSizeChange } = pagination;

  const { defaultPageSizeOption } = usePagination();

  return (
    <ContentLibraryList>
      <ContentLibraryListTable>
        <Table
          columns={columns}
          dataSource={items && items.length ? items : []}
          pagination={false}
          size="small"
          scroll={{ x: false }}
          loading={{
            indicator: loadingIcon,
            spinning: isLoadingItems,
          }}
        />
        </ContentLibraryListTable>
      <Pagination
        align="right"
        showSizeChanger
        current={page}
        pageSize={limit}
        total={total}
        pageSizes={defaultPageSizeOption}
        onChange={onPageChange}
        onShowSizeChange={onPageSizeChange}
      />
    </ContentLibraryList>
  )
}

export default ContentLibraryTable;

const ContentLibraryList = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
`;

const ContentLibraryListTable = styled.div`
  flex-grow: 1;
  background: white;
  border-radius: 4px;

  .ant-table-body {
    margin: 0 !important;
  }

  .ant-table-thead {
    background: #F0F0F0 !important;
    border-radius: 3px 3px 0 0;
  }

  .ant-table-thead > tr > th {
    
    height: 56px;
    margin-left: 20px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding: 8px 20px !important;
  }
`;
