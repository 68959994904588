import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Empty } from 'antd';
import { GridStyles } from '@ombori/grid-reports';
import { processGridStyles } from './utils';

const Container = styled.div`
  position: relative;
  min-height: 130px;
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  background: #f5f5f5;
`;

const Report = styled.div<{
  columnsCount?: number;
  gridStyles?: GridStyles;
}>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  justify-items: stretch;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  && {
    ${({ columnsCount }) =>
      !!columnsCount &&
      `
    grid-template-columns: repeat(${columnsCount}, minmax(0, 1fr));
  `};

    ${({ gridStyles }) => !!gridStyles && css(processGridStyles(gridStyles))}
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

interface AnalyticsReportProps {
  cards: React.ReactNode[];
  columnsCount?: number;
  gridStyles?: GridStyles;
}

const AnalyticsReport: React.FC<AnalyticsReportProps> = ({
  cards,
  columnsCount,
  gridStyles,
}) => {
  return (
    <Container>
      <Header>
        <Empty description="No analytics data" css={{ gridColumn: '1/-1' }} />
      </Header>

      <Content>
        <Report columnsCount={columnsCount} gridStyles={gridStyles}>
          {cards.map((cards, index) => (
            <React.Fragment key={index}>{cards}</React.Fragment>
          ))}
        </Report>
      </Content>
    </Container>
  );
};

export default AnalyticsReport;
