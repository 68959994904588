import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Card, Layout } from 'antd';
import styled from '@emotion/styled';
import { useAppTheme } from '../../../utils/use-app-theme';
import Footer from '../../common/app-layout/footer/footer.component';

interface LoginComponentProps {
  login: () => void;
}

const LoginContent = styled(Layout.Content)`
  /* background-image: linear-gradient(-45deg, #0f111a 0%, #1e2233 50%); */
  background: #f5f5f5;
`;

const LoginRow = styled(Row)`
  height: calc(100vh - 30px);
`;

const LoginCard = styled(Card)`
  border-radius: 16px;
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02),
    0px 4px 8px 0px rgba(0, 0, 0, 0.02), 0px 8px 16px 0px rgba(0, 0, 0, 0.02),
    0px 16px 32px 0px rgba(0, 0, 0, 0.04);
`;

const LogoContainer = styled(Row)`
  margin-bottom: 50px;
`;

const Logo = styled.img`
  max-width: 264px;
`;

const Login = ({ login }: LoginComponentProps) => {
  const { t } = useTranslation();
  const appTheme = useAppTheme();

  return (
    <Layout>
      <LoginContent>
        <LoginRow type="flex" align="middle" justify="center">
          <Col xs={23} md={10} lg={10}>
            <LoginCard>
              <LogoContainer type="flex" align="middle" justify="center">
                <Col>
                  <Logo src={appTheme.loginLogo} alt="Ombori logo" />
                </Col>
              </LogoContainer>
              <Row>
                <Col>
                  <Button
                    onClick={login}
                    size="large"
                    block
                    type="primary"
                    data-cy="mainLoginButton"
                  >
                    {t('login')}
                  </Button>
                </Col>
              </Row>
            </LoginCard>
          </Col>
        </LoginRow>
      </LoginContent>
      <Footer showPoweredBy fullWidth />
    </Layout>
  );
};

export default Login;
