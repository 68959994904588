import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { PhyhubDeviceUpdatePayload } from '../../../services/phyhub/types/phyhub-device-update-body.inteface';
import {usePhyhubService} from "../../../app/common/phyhub-service-provider/phyhub-service.provider";

export interface PhyhubDevicesUpdateMutationParams {
  tenantId: string;
  deviceId: string;
  payload: PhyhubDeviceUpdatePayload;
}

const usePhyhubDevicesUpdate = () => {
  const phyhubService = usePhyhubService();
  const queryClient = useQueryClient();

  return useMutation(
    ({ tenantId, deviceId, payload }: PhyhubDevicesUpdateMutationParams) =>
      phyhubService.updateDevice(
        {
          deviceId,
          tenantId,
        },
        payload,
      ),
    {
      onSuccess: (updatedPhyhubDevice, { tenantId, deviceId }) => {
        const cacheKey = phyhubDevicesQueryKeys.getPhyhubDevicesGetKeys({
          tenantId,
          deviceId,
        });

        queryClient.setQueryData(cacheKey, (_) => ({ ...updatedPhyhubDevice }));
      },
      onError: (error) => {
        Sentry.captureException(error);
      },
    },
  );
};

export default usePhyhubDevicesUpdate;
