import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Icon } from '../../../common/schema-form/common';
import { Col, Table as AntdTable, Tag } from 'antd';
import { Subheader } from '../../../common/app-layout/header';
import moment from 'moment';
import Pagination, {
  transformActiveFiltersToSearchParams,
} from '../../../common/pagination';
import usePagination from '../../../common/pagination/hook/use-pagination';
import SearchBar from '../../../common/search-bar/search-bar.component';
import { ActiveFilter } from '../../../../store/types/filters';
import { useDeviceBundles } from '../../../common/use-device-bundle';
import { DeviceBundle as DeviceBundleData } from '../../../common/use-device-bundle';
import Overlay from '../../../common/overlay';
import { TableType } from '../../../../types/styled-component';
import { useDeviceBundleFilterOptions } from '../../../common/use-filter-options';
import Module from '../../../../store/types/module';
import { ColumnProps } from 'antd/lib/table';
import ActionButtons from './actions-buttons.component';
import useStage from '../../../common/use-stage';
import useDeviceArchitecture from '../../../common/use-device-architecture';

const DeviceBundle = () => {
  const paginationContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { t } = useTranslation();

  const { getDeviceArchitectureLabel } = useDeviceArchitecture();

  const { getStageLabel } = useStage(t);

  const { filterOptions } = useDeviceBundleFilterOptions();

  const {
    page,
    pageSize,
    searchParams,
    setPage,
    setPageSize,
    setSearchParams,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination();

  const {
    data,
    isLoading: isInitialLoading,
    isFetched,
    isFetching,
    isSuccess,
    isRefetching,
    error,
  } = useDeviceBundles<Module>({
    param: searchParams,
    page,
    limit: pageSize,
  });

  const isDataInvalidating = isFetching && isFetched;

  const currentPage = page ? page : defaultPage;
  const currentPageSize = pageSize ? pageSize : defaultPageSize;

  const deviceBundles = isSuccess && data ? data.docs : [];
  const totalCount = isSuccess && data ? data.totalDocs : 0;

  const handlePaginationSizeChange = useCallback(
    (page: number, pageLimit: number) => {
      setPage(page);
      setPageSize(pageLimit);
    },
    [setPage, setPageSize],
  );

  const handlePageChange = useCallback((pageNum: number) => setPage(pageNum), [setPage]);

  const handleSearch = useCallback(
    (value: string): void => {
      setSearchParams((prev) => ({ ...prev, searchKey: value }));
    },
    [setSearchParams],
  );

  const handleFilterChange = useCallback(
    (filters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null): void => {
      const transformedFilters = transformActiveFiltersToSearchParams(
        filters,
        lastRemovedFilter,
      );
      setSearchParams((prev) => ({ ...prev, ...transformedFilters }));
    },
    [setSearchParams],
  );

  const columns = useMemo<ColumnProps<DeviceBundleData<Module>>[]>(() => {
    return [
      {
        title: t('bundleVersion'),
        key: 'bundleVersion',
        align: 'center',
        render: (_, record) => record.bundleVersion,
      },
      {
        title: t('deviceArchitecture'),
        key: 'deviceArchitecture',
        align: 'center',
        render: (_, record) => {
          return record.deviceArchitectures.map(
            (architecture): JSX.Element => (
              <Tag key={architecture}>{getDeviceArchitectureLabel(architecture)}</Tag>
            ),
          );
        },
      },
      {
        title: t('stage'),
        key: 'stage',
        align: 'center',
        render: (_, record) => {
          return getStageLabel(record.stage);
        },
      },
      {
        title: t('gridos'),
        key: 'gridos',
        align: 'center',
        render: (_, record) => record.gridos,
      },
      {
        title: t('modules'),
        key: 'modules',
        align: 'center',
        render: (_, record) => {
          return record.derivedModules.map(
            (module): JSX.Element => {
              const { id, displayName } = module.module;

              return (
                <Tag key={id}>
                  {displayName} {module.version}
                </Tag>
              );
            },
          );
        },
      },
      {
        title: t('lastUpdated'),
        key: 'lastUpdated',
        align: 'center',
        render: (_, record) => moment(record.updatedAt).format('DD/MM/YYYY hh:mm A'),
      },
      {
        title: <Icon type="setting" />,
        key: 'settings',
        fixed: 'right',
        width: 50,
        align: 'center',
        render: (_, record) => {
          const { id, stage, releaseNotes } = record;

          const data = {
            stage,
            releaseNotes,
          };

          return <ActionButtons deviceBundleId={id} data={data} />;
        },
      },
    ];
  }, [getDeviceArchitectureLabel, getStageLabel, t]);

  return (
    <Overlay
      spinnerOverlay={{
        processingTip: null,
        isLoading: isInitialLoading,
      }}
      errorOverlay={{
        error,
      }}
    >
      <Subheader
        variant="dropshadow"
        components={[
          <SearchBar
            key="search-bar"
            searchInputProps={{
              placeholder: t('bundleVersion').toLowerCase(),
              onSearch: handleSearch,
            }}
            searchInputFilterProps={{
              filterOptions,
              onChange: handleFilterChange,
            }}
          />,
        ]}
      />
      <TableWrap ref={paginationContainerRef}>
        <Table
          rowKey="id"
          dataSource={deviceBundles}
          columns={columns}
          scroll={{ x: true }}
          pagination={false}
          size="small"
        />
        <Pagination
          parentContainerRef={paginationContainerRef}
          isLoading={
            !isInitialLoading && !isDataInvalidating && (isFetching || isRefetching)
          }
          align="right"
          showSizeChanger
          onShowSizeChange={handlePaginationSizeChange}
          current={currentPage}
          pageSize={currentPageSize}
          total={totalCount}
          onChange={handlePageChange}
          pageSizes={defaultPageSizeOption}
        />
      </TableWrap>
    </Overlay>
  );
};

const TableWrap = styled.div`
  position: relative;
  padding: 64px 80px 40px;

  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

export const StickyColumn = styled(Col)`
  width: 100%;
  z-index: 1;

  @media (min-width: 1200px) {
    position: sticky;
    display: block;
    width: 25%;
    top: 16px;
    height: fit-content;
  }
`;

const Table = styled(AntdTable)`
  margin-top: 20px;

  .ant-table-thead > tr > th {
    background: #F0F0F0 !important;
    height: 56px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }
` as TableType<DeviceBundleData<Module, string>>;

export default DeviceBundle;
