import React, { useCallback, useEffect, useMemo } from 'react';
import { useActions, useStore } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import AppsLibraryList from '../../../../../common/apps-library/gridapp-list/gridapp-list.component';
import { RootModel, RootState } from '../../../../../../store/models/root.model';
import Gridapp, {
  GridappAccess,
  GroupedGridapps,
} from '../../../../../../store/types/gridapp';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import GridappTypeEnum from '../../../../../../store/types/gridapp-type.enum';
import DeviceTypeEnum from '../../../../../../store/types/device-type.enum';
import useOrganizationGridapps from '../../../../../common/use-organization-gridapps/use-organization-gridapps';

type AppsLibraryListContainerProps = Pick<
  React.ComponentProps<typeof AppsLibraryList>,
  'match' | 'history' | 'location'
>;

const deviceTypes: { [s: string]: string } = {
  [GridappTypeEnum.MOBILE]: DeviceTypeEnum.MOBILE_WPA,
  [GridappTypeEnum.IOT_MODULE]: DeviceTypeEnum.IOT,
};

const DevelopersSectionAppsContainer = (props: AppsLibraryListContainerProps) => {
  const {
    match: {
      params: { organisationId },
    },
    history,
  } = props;

  const { t } = useTranslation();

  const gridappsState = useOrganizationGridapps(organisationId);

  const { gridapps, gridappsLoading, gridappsError } = useMemo(
    () => ({
      gridapps: gridappsState.data ? gridappsState.data : [],
      gridappsLoading: gridappsState.isLoading,
      gridappsError: gridappsState.isError,
    }),
    [gridappsState],
  );

  const { createGridapp, createApp, fetchReleases, fetchApps } = useActions<RootModel>(
    (actions) => ({
      fetchReleases: actions.releases.fetch,
      createGridapp: (data: Partial<Gridapp>) =>
        actions.organisationAppsLibrary.create({ data, key: organisationId }),
      createApp: (data: Partial<OrganisationApp>) =>
        actions.apps.create({ data, key: organisationId }),
      fetchApps: actions.organisationApps.fetch,
    }),
  );

  const { releases } = useStore(
    (state: RootState) => ({
      releases: state.releases.data,
    }),
    [organisationId],
  );

  useEffect(() => {
    fetchReleases({ deviceType: DeviceTypeEnum.WPA });
  }, [fetchReleases]);

  const installGridapp = useCallback(
    async ({
      id,
      displayName,
      type,
      installationGroupId,
    }: {
      id: string;
      type: GridappTypeEnum;
      displayName: string;
      installationGroupId: string;
    }) => {
      const deviceType = deviceTypes[type] || DeviceTypeEnum.WPA;
      const appId = await createApp({
        displayName,
        architecture: 'x64',
        deviceType,
        organizationId: organisationId,
        provider: 'app-container',
        release: releases[0],
        autoBuild: true,
        appContainerGridappId: id,
        installationGroupId,
      });
      await fetchApps({ organizationId: organisationId, silent: true });

      let redirectLocation;
      if ([GridappTypeEnum.IOT_MODULE, GridappTypeEnum.SCREEN].includes(type)) {
        redirectLocation = `/organisations/${organisationId}/apps/installations/${appId}/devices-universal`;
      } else {
        redirectLocation = `/organisations/${organisationId}/apps/installations/${appId}/builds`;
      }

      history.push(redirectLocation);
    },
    [createApp, fetchApps, history, organisationId, releases],
  );

  const groupedApps = useMemo(() => {
    if (gridappsLoading) return [];

    const appsAccessGroups: GroupedGridapps[] = [
      {
        type: GridappAccess.PUBLIC,
        title: t('appAccessPublic'),
        apps: [] as Gridapp[],
      },
      {
        type: GridappAccess.SUBTENANTS,
        title: t('appAccessSubtenants'),
        apps: [] as Gridapp[],
      },
      {
        type: GridappAccess.SPECIFIC_TENANTS,
        title: t('appAccessSpecificTenants'),
        apps: [] as Gridapp[],
      },
      {
        type: GridappAccess.PRIVATE,
        title: t('appAccessPrivate'),
        apps: [] as Gridapp[],
      },
    ];

    gridapps.forEach((app) => {
      let groupIndex = appsAccessGroups.findIndex((group) => group.type === app.access);

      // Default to private
      if (groupIndex < 0) {
        groupIndex = appsAccessGroups.findIndex(
          (group) => group.type === GridappAccess.PRIVATE,
        );
      }

      appsAccessGroups[groupIndex].apps.push(app);
    });

    return appsAccessGroups;
  }, [gridapps, t, gridappsLoading]);

  return (
    <AppsLibraryList
      {...props}
      createGridapp={createGridapp}
      isError={!!gridappsError}
      isLoading={gridappsLoading}
      groupedGridapps={groupedApps}
      installGridapp={installGridapp}
    />
  );
};

export default DevelopersSectionAppsContainer;
