import styled from '@emotion/styled';
import { DataResidencyEnum, GridProduct, Variant } from '@ombori/grid-products';
import { Button, Card, Icon, Modal, Row, Table } from 'antd';
import { useStore } from 'easy-peasy';
import { get, uniq } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldProps } from 'react-jsonschema-form';
import { useStoreActions } from '../../../../../store/initialize-store';
import { RootState } from '../../../../../store/models/root.model';
import Environment from '../../../../../store/types/environment';
import Organisation from '../../../../../store/types/organisation';
import {
  defaultLng,
  EnvDropdown,
  getFirstAvailablePriceLabel,
  getFirstSelectableImage,
} from '../../../../organisations/organisation-details/grid-products/grid-products.components';
import { getBestMatchByLanguage } from '../../../../organisations/organisation-details/grid-products/utils';
import Loader from '../../../loader/loader-component';
import SearchBar from '../../../search-bar/search-bar.component';
import { FormDataProps } from './types';
import ProductInfo from './product-info-in-the-picker';
import useSearchTerm from './use-search-term';
import useProductVariants from './variant-pickers/use-product-variants';
import VariantPicker from './variant-pickers/variant-picker';
import useProductsSearch from '../../../../../store/models/hooks/use-products-search';
import useProductsList from '../../../../../store/models/hooks/use-products-list';
import useProduct from '../../../../../store/models/hooks/use-product';

interface ColumnProps {
  dataIndex?: string;
  title?: string | JSX.Element;
  key?: string;
  width?: string | number;
  align?: string;
  fixed?: boolean | 'right' | 'left' | undefined;
  render?: (product: GridProduct) => JSX.Element | string | undefined;
}

const {
  REACT_APP_DEV_PRODUCTS_TENANT_ID,
  REACT_APP_DEV_PRODUCTS_ENV_TENANT_ID,
} = process.env;

const ProductsPicker = ({
  schema: { title: fieldTitle, description },
  formData,
  onChange,
  formContext,
  required,
}: FieldProps<FormDataProps | undefined> & {
  onChange: (data: Partial<FormDataProps>) => void;
}) => {
  const { term, onChangeTerm } = useSearchTerm();
  const isSearchEmpty = term.trim() === '';

  const { organisationId } = formContext;
  const tenantId = REACT_APP_DEV_PRODUCTS_TENANT_ID || organisationId;
  const envTenantId = REACT_APP_DEV_PRODUCTS_ENV_TENANT_ID || tenantId;

  const { t } = useTranslation();

  const { envs, tenant } = useStore<
    RootState,
    {
      envs: Environment[];
      tenant: Organisation | null;
    }
  >((state) => {
    const result = {
      envs: state.environments.values(envTenantId),
      tenant: state.organisations.data && state.organisations.data[envTenantId],
    };

    return result;
  });

  const dataResidency = get(
    tenant,
    'dataResidency',
    DataResidencyEnum.EU,
  ) as DataResidencyEnum;

  const { getFetchUrl } = useStoreActions((actions) => ({
    getFetchUrl: actions.gridProducts.getURLToFetchOne,
  }));

  // Local states
  const [env, setEnv] = useState('prod');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProductGroup, setSelectedProductGroup] = useState<GridProduct | null>(
    null,
  );
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  const [variantDetails, setVariantDetails] = useState<Variant | null>(null);
  const [productGroupDetails, setProductGroupDetails] = useState<GridProduct | null>(
    null,
  );

  const searchState = useProductsSearch({
    dataResidency,
    env,
    tenantId,
    term,
    enabled: isModalOpen && !isSearchEmpty,
  });

  const initialProductListState = useProductsList({
    dataResidency,
    env,
    tenantId,
    enabled: isModalOpen && !selectedProductGroup && isSearchEmpty,
  });

  const {
    selectedVariant,
    colorVariants,
    sizeVariants,
    styleVariants,
    variantColorImages,
    onChangeVariant,
  } = useProductVariants({
    variants: selectedProductGroup ? selectedProductGroup.variants : [],
    productId: variantDetails ? variantDetails.productId : undefined,
  });

  const isAnyColorVariantExists = colorVariants.length > 0;
  const isAnySizeVariantExists = sizeVariants.length > 0;
  const isAnyStyleVariantExists = styleVariants.length > 0;
  const isSaveButtonDisabled =
    !selectedProductGroup || !selectedVariant || !selectedImageUrl;

  const formProductGroupId = formData ? formData.productGroupId : '';
  const formEnv = formData ? formData.env : '';

  const initialProductState = useProduct({
    tenantId,
    dataResidency,
    env: formEnv || 'prod', // env is dependent on the field's saved data
    productGroupId: formProductGroupId,
    enabled:
      formEnv !== '' && !!formProductGroupId && !variantDetails && !productGroupDetails,
  });

  useEffect(() => {
    setSelectedProductGroup(null);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    onChangeTerm('');
  }, [onChangeTerm]);

  const handleBackToList = useCallback(() => {
    setSelectedProductGroup(null);
  }, []);

  const handleProductGroupSearch = useCallback(
    async (textRaw: string) => {
      if (!textRaw) {
        onChangeTerm('');

        return;
      }

      const text = textRaw.toLowerCase();

      onChangeTerm(text);
    },
    [onChangeTerm],
  );

  const handleProductRemove = useCallback(() => {
    onChange({
      productGroupId: null,
      productId: null,
      defaultImage: null,
      productSourceUrl: null,
      env: null,
    });

    setSelectedProductGroup(null);
    setProductGroupDetails(null);
    setVariantDetails(null);
  }, [onChange]);

  const handleModalSubmit = useCallback(async () => {
    if (!tenant) {
      return;
    }

    if (!selectedProductGroup) {
      return;
    }

    if (!selectedVariant) {
      return;
    }

    if (!selectedImageUrl) {
      return;
    }

    const productSourceUrl = await getFetchUrl({
      tenantId,
      dataResidency: tenant.dataResidency,
      env,
    });

    const data = {
      ref: 'grid-product',
      productGroupId: selectedProductGroup.productGroupId,
      productId: selectedVariant.productId,
      defaultImage: selectedImageUrl,
      productSourceUrl,
      env,
    };

    onChange(data);

    setVariantDetails(selectedVariant);
    setProductGroupDetails(selectedProductGroup);

    handleCloseModal();
  }, [
    selectedProductGroup,
    selectedVariant,
    selectedImageUrl,
    env,
    getFetchUrl,
    onChange,
    tenant,
    handleCloseModal,
    tenantId,
  ]);

  const handleSetEnvironment = useCallback(
    (newEnv: string) => {
      setEnv(newEnv);

      setTimeout(() => {
        handleProductGroupSearch(term);
      }, 200);
    },
    [handleProductGroupSearch, term],
  );

  const handleRowClick = useCallback((productGroup: any) => {
    setSelectedProductGroup(productGroup);
  }, []);

  const handleProductImageSelect = useCallback((url: string) => {
    setSelectedImageUrl(url);
  }, []);

  const selectedProductTitleLanguage = selectedProductGroup
    ? getBestMatchByLanguage(selectedProductGroup.productName, defaultLng)
    : null;

  const selectedProductTitle = selectedProductTitleLanguage
    ? selectedProductTitleLanguage.productName
    : '';

  const titleComponent = useMemo(
    () => (
      <>
        <Title>{selectedProductTitle || t('gridProducts.productsAreNotSelected')}</Title>
        <SearchWrapper>
          {!selectedProductGroup && (
            <EnvDropdown env={env} onChange={handleSetEnvironment} envs={envs} />
          )}

          {selectedProductGroup && (
            <BackButton onClick={handleBackToList}>
              <Icon type="arrow-left" />
            </BackButton>
          )}

          <SearchBarWrapper>
            <SearchBar
              disabled={!!selectedProductGroup}
              searchInputProps={{
                placeholder: t('gridProducts.searchProductsPlaceholder'),
                onSearch: handleProductGroupSearch,
              }}
              searchInputFilterProps={{}}
              extra={
                <Button
                  type="primary"
                  disabled={isSaveButtonDisabled}
                  size="large"
                  onClick={handleModalSubmit}
                >
                  {t('save')}
                </Button>
              }
            />
          </SearchBarWrapper>
        </SearchWrapper>
      </>
    ),
    [
      t,
      env,
      envs,
      handleSetEnvironment,
      handleProductGroupSearch,
      handleBackToList,
      selectedProductGroup,
      selectedProductTitle,
      handleModalSubmit,
      isSaveButtonDisabled,
    ],
  );

  const columns: ColumnProps[] = useMemo(
    () => [
      {
        title: t('gridProducts.columnTitleProductName').toUpperCase(),
        key: 'productName',
        render: (productGroup: GridProduct) => {
          const titleLanguage = getBestMatchByLanguage(
            productGroup.productName,
            defaultLng,
          );
          const title = titleLanguage ? titleLanguage.productName : '';
          const { image: img } = getFirstSelectableImage(productGroup);

          return (
            <Label htmlFor={productGroup.productGroupId}>
              <Picture src={img} alt={title} />
              <ProductNameTitle>{title}</ProductNameTitle>
            </Label>
          );
        },
      },
      {
        title: t('gridProducts.columnTitleInventory').toUpperCase(),
        key: 'inventory',
        render: (productGroup: GridProduct) => {
          const variantCount = productGroup.variants.length;
          const inventory = productGroup.productItemQuantity;
          const totalInventoryCount = inventory
            ? inventory.reduce(
                (acc, currInventory) => acc + currInventory.productItemQuantity,
                0,
              )
            : 0;
          return `${totalInventoryCount} in stock over ${variantCount} variants`;
        },
      },
      {
        title: t('gridProducts.columnTitlePrice').toUpperCase(),
        key: 'price',
        render: (productGroup: GridProduct) => {
          const priceLabel = getFirstAvailablePriceLabel(productGroup);
          return priceLabel;
        },
      },
    ],
    [t],
  );

  const uniqueProductImages: string[] = selectedProductGroup
    ? uniq(
        selectedProductGroup.catalogPageLocationProduct.map(
          (image) => image.catalogPageLocationProduct,
        ),
      )
    : [];

  const setInitialFormData = useCallback(() => {
    if (initialProductState.isSuccess) {
      const data = initialProductState.data as GridProduct;
      setProductGroupDetails(data);

      const variantId = formData ? formData.productId : '';

      const initialVariant = data.variants.find(
        (variant) => variant.productId === variantId,
      );

      if (initialVariant) {
        setVariantDetails(initialVariant);
      }
    }
  }, [formData, initialProductState]);

  const handleOpenModalWithInfo = useCallback(() => {
    setSelectedProductGroup(productGroupDetails);
    const formDefaultImage = formData ? formData.defaultImage : '';
    setSelectedImageUrl(formDefaultImage);
    setIsModalOpen(true);
  }, [formData, productGroupDetails]);

  useEffect(() => {
    setInitialFormData();
  }, [setInitialFormData]);

  const getSearchResult = useCallback(() => {
    if (isSearchEmpty && initialProductListState.isSuccess) {
      return initialProductListState.data.list;
    }

    if (!isSearchEmpty && searchState.isSuccess && searchState.data) {
      return searchState.data.products;
    }

    return [];
  }, [initialProductListState, searchState, isSearchEmpty]);

  return (
    <Container>
      <TitleLabel>
        {fieldTitle}
        {required && <span className="required">*</span>}
      </TitleLabel>

      <DescriptionLabel>{description}</DescriptionLabel>

      {formData && variantDetails && productGroupDetails ? (
        <ProductInfo
          product={variantDetails}
          productGroup={productGroupDetails as GridProduct}
          defaultImage={formData.defaultImage}
          onUpdateSelectedProduct={handleOpenModalWithInfo}
          onRemovingSelectedProduct={handleProductRemove}
        />
      ) : (
        <PickProductButton
          type="default"
          size="large"
          icon="plus"
          onClick={handleOpenModal}
          disabled={initialProductState.isLoading}
        >
          {t('gridProducts.productsAreNotSelected')}
        </PickProductButton>
      )}

      <FormModal
        footer={null}
        destroyOnClose
        title={titleComponent}
        visible={isModalOpen}
        onCancel={handleCloseModal}
        centered
      >
        <ResultsContainer>
          {!selectedProductGroup && (
            <Loader
              loading={initialProductListState.isLoading || searchState.isLoading}
              processingTip={t('gridProducts.loadingResults')}
            >
              <ResultsTable
                rowKey="productGroupId"
                dataSource={getSearchResult()}
                columns={columns as any}
                pagination={false}
                size="small"
                onRowClick={handleRowClick}
                scroll={{ y: 500 }}
              />
            </Loader>
          )}

          {selectedProductGroup && (
            <ProductInfoContainer>
              <VariantPickerCard>
                {isAnyColorVariantExists && (
                  <ProductVariantsItem>
                    <VariantPicker
                      variants={colorVariants}
                      variantColorImages={variantColorImages}
                      type="color"
                      value={get(selectedVariant, 'color', '')}
                      label={t('gridProducts.variantsColor')}
                      onChange={onChangeVariant}
                    />
                  </ProductVariantsItem>
                )}

                {isAnySizeVariantExists && (
                  <ProductVariantsItem>
                    <VariantPicker
                      variants={sizeVariants}
                      type="size"
                      value={get(selectedVariant, 'size', '')}
                      label={t('gridProducts.variantsSize')}
                      onChange={onChangeVariant}
                    />
                  </ProductVariantsItem>
                )}

                {isAnyStyleVariantExists && (
                  <ProductVariantsItem>
                    <VariantPicker
                      variants={styleVariants}
                      type="style"
                      value={get(selectedVariant, 'style', '')}
                      label={t('gridProducts.variantsStyle')}
                      onChange={onChangeVariant}
                    />
                  </ProductVariantsItem>
                )}
              </VariantPickerCard>
              <ImagePickerCard>
                <HeaderRow>
                  <IconWrapper>
                    <Icon type="picture" />
                  </IconWrapper>
                  <div>
                    <CardTitle>{t('gridProducts.chooseFeaturedImage')}</CardTitle>
                    <CardSubtitle>{t('gridProducts.selectImageDisplay')}</CardSubtitle>
                  </div>
                </HeaderRow>
                <ImagesWrapper>
                  {uniqueProductImages.map((imageUrl) => {
                    return (
                      <ImageButton
                        key={imageUrl}
                        onClick={() => handleProductImageSelect(imageUrl)}
                        selected={imageUrl === selectedImageUrl}
                      >
                        <ProductImageSelect src={imageUrl} />
                      </ImageButton>
                    );
                  })}
                </ImagesWrapper>
              </ImagePickerCard>
            </ProductInfoContainer>
          )}
        </ResultsContainer>
      </FormModal>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 8px;
`;

const TitleLabel = styled.div`
  width: 100%;
  display: flex;
  font-weight: bold;
`;

const DescriptionLabel = styled.div`
  width: 100%;
  display: flex;
  font-size: 12px;
  margin-bottom: 16px;
`;

const PickProductButton = styled(Button)`
  margin-bottom: 24px;
` as any;

const FormModal = styled(Modal)`
  border-radius: 16px;
  padding: 0px;
  overflow: hidden;

  .ant-modal-close-x {
    padding: 12px 4px 0px 0px;
  }

  @media (max-width: 940px) {
    min-width: auto;
  }

  @media (min-width: 940px) {
    min-width: 940px;
  }

  .ant-modal-body {
    max-height: 70vh;
    overflow: scroll;
    padding: 0px;
  }
`;

const Title = styled(Row)`
  font-size: 24px;
  padding: 8px 24px 24px 0px;
  line-height: 28px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchBarWrapper = styled.div`
  display: block;
  width: 100%;
  margin-left: 16px;
`;

const ResultsContainer = styled.div`
  min-height: 150px;
`;

const ResultsTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #F0F0F0 !important;
    height: 56px;
  }
  .ant-table-scroll > .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-tbody > tr {
    cursor: pointer;
  }

  .ant-table-column-title {
    font-size: 12px;
    font-weight: bold;
  }

  .ant-table-thead > tr > th:first-child,
  .ant-table-row > td:first-child {
    padding-left: 24px !important;
  }
`;

const Label = styled.label`
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
`;

const ProductNameTitle = styled.span`
  display: flex;
  font-weight: bold;
  cursor: pointer;
`;

const Picture = styled.img`
  float: left;
  margin-right: 10px;
  object-fit: cover;
  height: 50px;
  width: 50px;
  cursor: pointer;
`;

const BackButton = styled(Button)`
  width: 42px;
  height: 42px;
` as any;

const ProductVariantsItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 12px;

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const VariantPickerCard = styled(Card)``;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  background-color: #fafafa;
  padding: 16px;
  border-radius: 4px;
  margin-right: 16px;
`;

const ImagePickerCard = styled(Card)`
  margin-top: 24px;
`;

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const CardSubtitle = styled.div`
  font-size: 14px;
  font-weight: light;
`;

const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const ImageButton = styled(Button)<{ selected: boolean }>`
  margin: 16px 16px 0px 16px;
  grid-column: 4 span;
  height: 240px;

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid;
  `}

  @media (max-width: 940px) {
    grid-column: 6 span;
  }

  @media (max-width: 420px) {
    grid-column: 12 span;
  }
` as any;

const ProductImageSelect = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProductInfoContainer = styled.div`
  padding: 24px;
`;

export default ProductsPicker;
