import * as React from 'react';
import { Layout } from 'antd';
import styled from '@emotion/styled';

interface ContentProps {
  children: React.ReactNode;
}

const ContentWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
`;

const StyledContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;

  .content-body {
    padding: 40px;
    position: relative;
    flex: 1;
  }
  @media screen and (max-width: 991px) {
    .content-body {
      padding: 20px;
    }
  }
`;

const Content = ({ children }: ContentProps) => {
  return (
    <ContentWrapper>
      <StyledContent id="scrollable-content">{children}</StyledContent>
    </ContentWrapper>
  );
};

export default Content;
