import React, { createContext, useContext } from 'react';
import { PhyhubService } from '../../../services/phyhub/phyhub.service';

const PhyhubServiceContext = createContext<PhyhubService | null>(null);

interface PhyhubServiceProviderProps {
  children: React.ReactNode;
  service: PhyhubService;
}

const PhyhubServiceProvider: React.FC<PhyhubServiceProviderProps> = ({
  children,
  service,
}): JSX.Element => {
  return (
    <PhyhubServiceContext.Provider value={service}>
      {children}
    </PhyhubServiceContext.Provider>
  );
};

export const usePhyhubService = (): PhyhubService => {
  const ctx = useContext(PhyhubServiceContext);

  if (!ctx) {
    throw new Error(
      'Error caught while consuming PhyhubService context. Make sure you wrap the component inside the "PhyhubServiceProvider".',
    );
  }

  return ctx;
};

export default PhyhubServiceProvider;
