import React, { useState, useCallback } from 'react';
import { Button, message } from 'antd';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import NetworkWhitelistRuleForm, {
  NetworkWhitelistRuleFormFields,
} from './networking-whitelist-rule-form.component';
import {
  useAddNetworkWhitelistRule,
  NetworkWhitelistRuleCreatePayload,
} from '../../../../common/use-network-whitelist-rules';

interface NetworkWhitelistRuleAddProps {
  tenantId: string;
}

const NetworkWhitelistRuleInitialValues: NetworkWhitelistRuleFormFields = {
  env: '',
  hostname: '',
};

const NetworkWhitelistRuleAdd = (props: NetworkWhitelistRuleAddProps) => {
  const { tenantId } = props;

  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { mutateAsync: addWhitelistRule } = useAddNetworkWhitelistRule();

  const handleFormSubmit = useCallback(
    async (values: NetworkWhitelistRuleFormFields | Object) => {
      const { env, hostname } = values as NetworkWhitelistRuleFormFields;

      const createPayload: NetworkWhitelistRuleCreatePayload = {
        tenantId,
        env,
        hostname,
      };

      try {
        await addWhitelistRule(createPayload);
        setIsModalVisible(false);
        message.success(t('networking.networkWhitelistRuleSaveSuccess'));
      } catch (err) {
        message.error(t('networking.networkWhitelistRuleSaveFailure'));
      }
    },
    [addWhitelistRule, tenantId, t],
  );

  return (
    <>
      <ButtonWrap>
        <Button
          type="primary"
          icon="plus"
          size="large"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          {t('networking.networkWhitelistRuleAddButton')}
        </Button>
      </ButtonWrap>

      <NetworkWhitelistRuleForm
        tenantId={tenantId}
        isModalVisible={isModalVisible}
        updateIsModalVisibile={setIsModalVisible}
        initialValues={NetworkWhitelistRuleInitialValues}
        handleFormSubmit={handleFormSubmit}
      />
    </>
  );
};

export default NetworkWhitelistRuleAdd;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;
