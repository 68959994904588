import React, { FC, ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import Header from '../../app-layout/header/header.component';
import PhyhubDeviceStatus from '../phyhub-device-status/phyhub-device-status';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';

interface PhyhubDeviceHeaderProps {
  deviceDisplayName: string;
  deviceStatusUpdatedAt: string;
  deviceStatus: PhyhubDeviceStatusEnum;
  children: ReactNode;
}

const PhyhubDeviceHeader: FC<PhyhubDeviceHeaderProps> = (props) => {
  const { deviceDisplayName, deviceStatus, deviceStatusUpdatedAt, children } = props;

  const deviceStatusUpdateTime = useMemo<string>(
    () => moment(deviceStatusUpdatedAt).fromNow(),
    [deviceStatusUpdatedAt],
  );

  return (
    <Header title={deviceDisplayName}>
      <HeaderStatus>
        <PhyhubDeviceStatus status={deviceStatus} />
        <DeviceUpdateTime>{deviceStatusUpdateTime}</DeviceUpdateTime>
      </HeaderStatus>
      {!!children && <HeaderChildren>{children}</HeaderChildren>}
    </Header>
  );
};

const HeaderStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const HeaderChildren = styled.div`
  margin: 16px 0 0;
`;

const DeviceUpdateTime = styled.span`
  display: inline-block;
  color: #ccc;
`;

export default PhyhubDeviceHeader;
