import React, { FC } from 'react';
import styled from '@emotion/styled';
import PhyhubDeviceStatusCircle from './phyhub-device-status-circle/phyhub-devices-status-circle.component';
import usePhyhubDeviceStatusColor from './hooks/use-phyhub-device-status-color';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';

export enum PhyhubDeviceStatusColorEnum {
  ERROR = 'rgba(255, 77, 79, 1)',
  OK = 'rgba(82, 196, 26, 1)',
  WARNING = 'rgba(250, 173, 20, 1)',
}

export enum PhyhubDeviceStatusThemeEnum {
  LARGE,
  DEFAULT,
}

interface PhyhubDeviceStatusProps {
  status: PhyhubDeviceStatusEnum;
  theme?: PhyhubDeviceStatusThemeEnum;
}

const PhyhubDeviceStatus: FC<PhyhubDeviceStatusProps> = (props) => {
  const { children, status, theme = PhyhubDeviceStatusThemeEnum.DEFAULT } = props;

  const statusColor = usePhyhubDeviceStatusColor(status);

  return (
    <Status>
      <PhyhubDeviceStatusCircle statusColor={statusColor} />
      <StatusLabel theme={theme}>{children || status}</StatusLabel>
    </Status>
  );
};

const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StatusLabel = styled.span<{ theme: PhyhubDeviceStatusThemeEnum }>`
  display: inline-block;

  ${({ theme }) => {
    if (theme === PhyhubDeviceStatusThemeEnum.LARGE) {
      return `
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      `;
    }

    return `
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    `;
  }}

  color: rgba(0, 0, 0, 0.85);
`;

export default PhyhubDeviceStatus;
