import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router';
import MarketplaceItem from './marketplace-item';
import Spinner from '../../../../../common/spinner/spinner.component';
import Overlay from '../../../../../common/overlay/overlay.component';

interface PublicAppsProps extends RouteComponentProps<{ organisationId: string }> {
  marketplaceSolutions: any[];
  fetchMarketplaceSolutions: (params: { organisationId: string }) => void;
  loaded: boolean;
}

const PublicApps = ({
  match,
  loaded,
  fetchMarketplaceSolutions,
  marketplaceSolutions,
}: PublicAppsProps) => {
  const {
    params: { organisationId },
  } = match;

  useEffect(() => {
    fetchMarketplaceSolutions({ organisationId });
  }, [fetchMarketplaceSolutions, organisationId]);

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  return (
    <Solutions className="content-body">
      {marketplaceSolutions.map((solution) => (
        <MarketplaceItem
          url={`/organisations/${organisationId}/apps/marketplace/solution/${solution.id}`}
          key={solution.id}
          hoverable
          author={solution.author}
          title={solution.title}
          description={solution.shortDescription}
          screenshot={solution.screenshots[0]}
        />
      ))}
    </Solutions>
  );
};

const Solutions = styled.div`
  display: block;
  @media screen and (min-width: 768px) {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1200px) {
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export default PublicApps;
